import React from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getImageUrl } from "helpers/Image.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const AgencyInfoPopupOfChatComponent = (props: any) => {
    const { info, isInit, onClose, isOnNetworking } = props;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 p-4 !m-0">
            <div className="bg-white rounded-2xl p-6 w-full max-w-2xl mx-auto">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "agencyInfoPopupOfChat.txtTitle")}
                    </h3>
                    <button
                        type="button"
                        className="text-gray-400 hover:text-gray-600"
                        onClick={onClose}>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {isInit && info && (
                    <>
                        <div className="flex flex-col items-center">
                            <div className="w-32 h-32 rounded-xs overflow-hidden mb-4">
                                <img
                                    src={getImageUrl(info.agencyInfo.logoImageInfo)}
                                    alt={getLanguageValue(PAGE_TYPE.STUDENT, "agencyInfoPopupOfChat.txtLogo")}
                                    className="w-full h-full object-cover"
                                />
                            </div>

                            <div className="text-center mb-4">
                                <p className="text-gray-600">
                                    {info.agencyInfo.nameEn}
                                </p>
                                <p className="text-gray-600">
                                    {info.name}
                                </p>
                            </div>
                        </div>
                        <div className="block w-full justify-center mt-4">
                            <button
                                type="button"
                                onClick={onClose}
                                className="w-full px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyInfoPopupOfChat.btnClose")}
                            </button>
                        </div>
                    </>
                )}
                {!isInit &&
                    <LoadingComponent />
                }
            </div>
        </div>
    );
};

export default AgencyInfoPopupOfChatComponent;
