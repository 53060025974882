import React, { useEffect } from "react";
import LogoutComponent from "./Logout.component";
import { removePushToken } from "helpers/Firebase.helper";
import { setIsLogin } from "reducers/User";
import { removeAppAccessToken } from "helpers/appLocalStorageHelper";
import { removeAuthTokens } from "helpers/Auth.helper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setInfo as setUserInfo } from "reducers/User";
import { removeAllInstancePrefixCookies } from "helpers/Cookie.helper";
import { setBadgeCount, isInApp, removeData } from "helpers/InApp.helper";
const LogoutContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        doLogout();
    }, []);

    const doLogout = async () => {
        try {
            await removePushToken();
            await removeAppAccessToken()
            removeAuthTokens();
            dispatch(setIsLogin(false));
            dispatch(setUserInfo({}));
            removeAllInstancePrefixCookies();
            if (isInApp()) {
                setBadgeCount(0);
                removeData("isLogin");
            }
        } catch (error) {
            console.error('Failed to logout:', error);
        }

        setTimeout(() => {
            navigate("/", { replace: true });
        }, 500);
    }

    return (
        <LogoutComponent />
    );
};

export default LogoutContainer;
