import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

import * as StudentApi from "api/student/Student.api";
import * as AppApi from "api/App.api";
import * as AuthApi from "api/common/Auth.api";
import * as MyApi from "api/student/v2/my.api";
import MyComponent from "./Index.component";
import UpdateMy from "./UpdateMy.container";
import UpdateMyContact from "./UpdateMyContact.container";
import { removeAuthTokens } from "helpers/Auth.helper";
import { setInfo as setUserInfo, setIsLogin } from "reducers/User";
import { removePushToken } from "helpers/Firebase.helper";
import { removeAppAccessToken } from "helpers/appLocalStorageHelper";

const MyContainer = (props: any) => {
    const { isApp } = useSelector((state: any) => state.common);

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { info: myInfo } = useSelector((state: any) => state.user);

    const [inInit, setInInit] = useState(false);

    const [info, setInfo] = useState<any>(null);

    const [isOnSendingEmail, setIsOnSendingEmail] = useState(false);
    const [isOnToggle, setIsOnToggle] = useState(false);

    const getInfo = async () => {
        const result = await MyApi.getMyInfo();

        if (result?.info) {
            const { info } = result;
            setInfo(info);
        }

        setInInit(true);
    };


    const sendVerifyEmail = async () => {
        console.log('student/v2/my/Index.container:sendVerifyEmail')

        setIsOnSendingEmail(true);
        const params = {
            username: myInfo.username,
            accountTypeCode: "accountType:student",
        };
        const result: any = await AuthApi.sendVerifyEmail(params);

        if (result.status === 200) {
            alert(getLanguageValue(PAGE_TYPE.AGENCY, "my.sendEmail"));
        }

        setIsOnSendingEmail(false);
    };

    const handleToggleActivateAccount = async () => {
        console.log('student/v2/my/Index.container::handleToggleActivateAccount')
        if (isOnToggle) return;

        setIsOnToggle(true);
        const result = await MyApi.toggleActiveAccount();

        if (result?.status === 200) {
            const data = result.data.data;
            setInfo({
                ...info,
                userStudentInfo: {
                    ...info.userStudentInfo,
                    isActive: data.isActive
                }
            })
        }

        setIsOnToggle(false);
    }

    const sendVerifyParentEmail = async () => {
        console.log('student/v2/my/Index.container:sendVerifyParentEmail')

        if (isOnSendingEmail) return;

        setIsOnSendingEmail(true);
        const parentEmail = info?.userStudentInfo?.parentEmail;
        if (parentEmail) {
            const result: any = await AuthApi.sendVerifyParentEmail({ parentEmail });

            if (result.status === 200) {
                alert(getLanguageValue(PAGE_TYPE.AGENCY, "my.sendEmail"));
            }
        }

        setIsOnSendingEmail(false);
    };

    const logout = async () => {
        await removePushToken();
        removeAuthTokens();
        dispatch(setIsLogin(false));
        dispatch(setUserInfo({}));
        navigate("/", { replace: true });
        await removeAppAccessToken()
    };


    useEffect(() => {
        getInfo();
    }, []);

    return (
        <MyComponent
            isInit={inInit}
            isApp={isApp}
            email={myInfo.username}
            info={info}
            sendVerifyEmail={sendVerifyEmail}
            sendVerifyParentEmail={sendVerifyParentEmail}
            logout={logout}
            isOnSendingEmail={isOnSendingEmail}
            isOnToggle={isOnToggle}
            onToggleActivateAccount={handleToggleActivateAccount}
        />
    )
};

export default MyContainer;
