import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "i18next";

import { setInfo as setUserInfo, setIsLogin } from "reducers/User";

import { checkIsEmail } from "helpers/Reg.helper";
import { removeAgencyId, setAccessToken, setRefreshToken } from "helpers/Auth.helper";
import { getUserType } from "helpers/Common.helper";

import { getMyInfo, login, saveAppPushInfo, setPushActivate } from "api/common/Auth.api";
import * as AgencyApi from "api/agency/Agency.api";
import * as SchoolApi from "api/school/School.api";
import * as StudentApi from "api/student/Student.api";
import { requestNotificationPermission, listenForForegroundMessages } from "helpers/Firebase.helper";
import { getIsAppInitData, setAppAccessToken, setAppRefreshToken, setIsAppInitData } from "helpers/appLocalStorageHelper";
import { checkHasPushPermission } from "helpers/InApp.helper";
import AuthHeader from "../component/AuthHeader.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { setLastLoginEmail } from "helpers/Cookie.helper";

const LoginComponent = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const idRef = useRef<HTMLInputElement>(null);
    const pwRef = useRef<HTMLInputElement>(null);
    const { isPrev, path, backUrl } = props;
    const { isApp } = useSelector((state: any) => state.common);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    const doLogin = async () => {
        setIsButtonDisabled(true)
        const username: string = idRef?.current?.value || "";
        const password: string = pwRef?.current?.value || "";

        if (checkIsEmail(username)) {
            const result = await login({ username, password });

            if (result) {
                setAccessToken(result?.data?.data?.jwt);
                setRefreshToken(result?.data?.data?.refreshToken);
                setLastLoginEmail(username);

                const myInfoResult = await getMyInfo();

                if (myInfoResult) {
                    const myInfo = myInfoResult.info;
                    if (myInfoResult.info.accountTypeCode === "accountType:student") {
                        const result = await StudentApi.getInfoForStudent();
                        if (result) {
                            myInfoResult.info.profileImage = result.info.profileImageInfo ? result.info.profileImageInfo.url : null;
                        } else {
                            myInfoResult.info.profileImage = null;
                        }
                    }

                    if (myInfoResult.info.accountTypeCode === "accountType:agency") {
                        // cookie의 agencyId 삭제
                        removeAgencyId();

                        console.log(myInfoResult);

                        const result = await AgencyApi.getInfo(myInfoResult.info.userAgencyId);
                        if (result) {
                            myInfoResult.info.logoImageInfo = result.info.logoImageInfo ? result.info.logoImageInfo : null;
                            myInfoResult.info.agencyName = result.info.agencyName;
                        } else {
                            myInfoResult.info.logoImageInfo = null;
                        }
                    }

                    if (myInfoResult.info.accountTypeCode === "accountType:school") {
                        const result = await SchoolApi.getInfo(myInfoResult.info.userSchoolId);
                        if (result) {
                            myInfoResult.info.logoImageInfo = result.schoolInfo.logoImageInfo ? result.schoolInfo.logoImageInfo : null;
                        } else {
                            myInfoResult.info.logoImageInfo = null;
                        }
                    }
                    dispatch(setIsLogin(true));
                    dispatch(setUserInfo(myInfo));

                    // 웹인 경우에만 web push 허용 팝업 노출
                    if (!isApp) {
                        await requestNotificationPermission();
                    } else {
                        // push 정보 저장
                        await saveAppPushInfo();
                        // device의 push 세팅값 가져오기
                        const hasPushPermission = await checkHasPushPermission();
                        // device의 push 세팅값 db에 저장
                        await setPushActivate({ isActivated: hasPushPermission });
                        await setAppAccessToken(result?.data?.data?.jwt);
                        await setAppRefreshToken(result?.data?.data?.refreshToken);
                    }

                    let nextUrl = "";
                    // 로그인하지 않은 상태에서 private 페이지 접근 후 로그인페이지 redirect 한 경우
                    if (location.state?.nextUrl) {
                        nextUrl = location.state.nextUrl;
                    } else {
                        // account type에 따라 next url 분기처리
                        if (myInfo?.accountTypeCode === "accountType:agency") {
                            nextUrl = `/agency`;
                        } else {
                            nextUrl = `/${getUserType(myInfo?.accountTypeCode)}`;
                        }

                        // 에이전시의 경우 언어 설정이 한글일 경우 제외 영문 처리
                        if (getUserType(myInfo?.accountTypeCode) === "agency") {
                            const lang = i18n.language === "ko" ? "ko" : "en";
                            i18n.changeLanguage(lang);
                        }
                    }

                    if (isPrev) {
                        navigate(path, { replace: true });
                    } else {
                        navigate(nextUrl, { replace: true });
                    }
                }
            }
        }
        setIsButtonDisabled(false)
    };

    useEffect(() => {
        if (idRef.current) {
            idRef.current.focus();
        }
    }, []);

    return (
        <main className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <AuthHeader backUrl={backUrl ? backUrl : ""} />
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <div className="flex flex-col items-center max-w-md mx-auto w-full">
                    <div className="text-center mb-8">
                        <h2 className="text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">
                            {getLanguageValue(PAGE_TYPE.COMMON, "login.txtTitle")}
                        </h2>
                    </div>
                    <div className="w-full space-y-6">
                        <div className="space-y-4">
                            <div className="relative">
                                <input
                                    type="email"
                                    placeholder={getLanguageValue(PAGE_TYPE.COMMON, "login.txtEmailPlaceholder")}
                                    ref={idRef}
                                    onKeyDown={(evt) => {
                                        if (evt.key === "Enter") doLogin();
                                    }}
                                    className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 focus:border-[#CF3688] focus:ring-2 focus:ring-[#CF3688]/20 text-gray-900 transition-all duration-200 text-base sm:text-lg"
                                />
                            </div>
                            <div className="relative">
                                <input
                                    type="password"
                                    placeholder={getLanguageValue(PAGE_TYPE.COMMON, "login.txtPasswordPlaceholder")}
                                    ref={pwRef}
                                    onKeyDown={(evt) => {
                                        if (evt.key === "Enter") doLogin();
                                    }}
                                    className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 focus:border-[#CF3688] focus:ring-2 focus:ring-[#CF3688]/20 text-gray-900 transition-all duration-200 text-base sm:text-lg"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-end">
                            <button
                                onClick={(e) => {
                                    navigate("/password/reset");
                                }}
                                className="text-sm sm:text-base font-medium text-[#CF3688] hover:text-[#A62B6D] transition-colors duration-200"
                            >
                                {getLanguageValue(PAGE_TYPE.COMMON, "login.btnResetPassword")}
                            </button>
                        </div>

                        <div>
                            <button
                                disabled={isButtonDisabled}
                                onClick={doLogin}
                                className="w-full py-3 sm:py-4 px-6 text-base sm:text-lg font-semibold text-white bg-[#CF3688] hover:bg-[#A62B6D] rounded-lg transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed transform hover:scale-[1.02] active:scale-[0.98] shadow-lg hover:shadow-xl"
                            >
                                {getLanguageValue(PAGE_TYPE.COMMON, "login.btnLogin")}
                            </button>
                        </div>

                        {/* 회원가입 안내 영역 */}
                        <div className="mt-6 border-t border-gray-200 pt-6 text-center">
                            <p className="text-sm sm:text-base text-gray-600">
                                {getLanguageValue(PAGE_TYPE.COMMON, "login.txtSignup")}
                                <br />
                                <button
                                    onClick={() => navigate("/signup")}
                                    className="font-semibold text-[#CF3688] hover:text-[#A62B6D] transition-colors duration-200"
                                >
                                    {getLanguageValue(PAGE_TYPE.COMMON, "login.btnSignup")}
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    );
};

export default LoginComponent;
