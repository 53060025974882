import React from "react";
import FullPageLoading from "commons/loading/FullPageLoading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const QnAComponent = (props: any) => {
    const { isLoading, list, hasMorePage, answerList, handleDeleteQna, handleAnswerButton, handleAnswerChange, isOnAnswering, editingItem, setEditingItem, handleUpdateAnswerButton, handleEditingAnswerChange, handleWriteQuestion, question, handleQuestionChange, loadMoreList, isOnDeleting } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6">
                <h1 className="text-3xl font-bold text-gray-900 mb-6">
                    {getLanguageValue(PAGE_TYPE.STUDENT, "qna.txtTitle")}
                </h1>
                <p className="text-gray-600 mb-8">
                    {getLanguageValue(PAGE_TYPE.STUDENT, "qna.txtDesc")}
                </p>


                <div className="space-y-6">
                    {/* {!isLoading && list?.length === 0 && (
                        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                            <p className="text-gray-600 text-sm sm:text-base">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "qna.txtEmpty")}
                            </p>
                        </div>
                    )} */}

                    {!isLoading && list?.length < 3 &&
                        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow space-y-4">
                            <h3 className="text-gray-900 text-lg sm:text-xl font-bold">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "qna.txtQuestionTitle")}
                            </h3>
                            <p className="text-gray-600 text-sm sm:text-base">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "qna.txtQuestionDesc")}
                            </p>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleWriteQuestion();
                            }} className="space-y-4">
                                <input
                                    type="text"
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                                    placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "qna.txtQuestionPlaceholder")}
                                    value={question}
                                    onChange={(e) => handleQuestionChange(e.target.value)}
                                    maxLength={100}
                                />
                                <div className="flex justify-end mt-4">
                                    <button
                                        type="submit"
                                        className="px-4 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600 transition-colors"
                                        disabled={isOnAnswering}
                                    >
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "qna.btnQuestionSubmit")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    }


                    {!isLoading && list?.map((item: any, index: number) => (
                        <div key={index} className="bg-white p-4 sm:p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">

                            {/* 질문 영역 */}

                            <div className="flex flex-col gap-4">
                                <div className="flex justify-between items-center w-full">
                                    <div className="flex items-center space-x-2">
                                        <svg className="w-4 h-4 flex-shrink-0 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                        <span className="text-gray-400 text-xs">{new Date(item.createdAt).toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</span>
                                    </div>
                                    <button
                                        disabled={isOnDeleting}
                                        className="text-red-500 hover:text-red-700 flex items-center text-sm sm:text-base transition-colors my-auto"
                                        onClick={() => handleDeleteQna(item.id)}>
                                        <svg className="w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="w-full">
                                    <p className="text-base sm:text-lg font-medium text-gray-900 mb-3 hover:text-[#CF3688] transition-colors break-words">{item.question}</p>
                                    <div className="flex flex-wrap items-center text-sm text-gray-500 gap-2 sm:gap-4">
                                        <div className="flex items-center">
                                            <button className="flex items-center space-x-2 px-3 py-1 rounded-full bg-pink-50 hover:bg-pink-100 transition-colors">
                                                <svg className="w-4 h-4 text-[#CF3688] flex-shrink-0" fill="currentColor" stroke="none" viewBox="0 0 24 24">
                                                    <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                </svg>
                                                <span className="font-medium text-[#CF3688]">{item.likeCount}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 답변 영역 */}
                            {item.answer ? (
                                <div className="mt-6 pl-4 border-l-2 border-gray-200">
                                    <div className="flex justify-between items-start">
                                        <div className="flex-1">
                                            {editingItem?.id === item.id ? (
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleUpdateAnswerButton(item.id);
                                                }}>
                                                    <textarea
                                                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent resize-none text-sm sm:text-base"
                                                        maxLength={100}
                                                        rows={3}
                                                        value={editingItem.answer}
                                                        onChange={(e) => handleEditingAnswerChange(e.target.value)}
                                                    />
                                                    <div className="mt-3 flex justify-end gap-2">
                                                        <button
                                                            type="button"
                                                            onClick={() => setEditingItem(null)}
                                                            className="px-4 py-2 text-sm font-medium text-gray-500 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                                                        >
                                                            {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtCancel")}
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="px-4 py-2 text-sm font-medium text-white bg-[#CF3688] rounded-lg hover:bg-[#B82F77] transition-colors"
                                                        >
                                                            {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtSave")}
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (
                                                <>
                                                    <p className="text-gray-700 text-sm sm:text-base whitespace-pre-wrap break-words">{item.answer}</p>
                                                    <p className="mt-2 text-xs sm:text-sm text-gray-500">
                                                        {new Date(item.answeredAt).toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        {editingItem?.id !== item.id && (
                                            <button
                                                onClick={() => setEditingItem(item)}
                                                className="ml-4 text-gray-500 hover:text-[#CF3688] transition-colors"
                                            >
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="mt-6">
                                    <div className="bg-pink-50 p-4 rounded-lg mb-4">
                                        <p className="text-[#CF3688] text-sm font-medium">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "qna.txtNeedToAnswer" + Math.floor(Math.random() * 4 + 1))}
                                        </p>
                                    </div>
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleAnswerButton(item.id);
                                        }}>
                                        <textarea
                                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent resize-none text-sm sm:text-base"
                                            maxLength={100}
                                            rows={3}
                                            placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "qna.txtAnswerPlaceholder")}
                                            value={answerList[item.id]}
                                            onChange={(e) => handleAnswerChange(item.id, e.target.value)}
                                        />
                                        <div className="mt-3 flex justify-end">
                                            <button className="w-full sm:w-auto px-4 py-2 text-sm font-medium text-white bg-[#CF3688] rounded-lg hover:bg-[#B82F77] transition-colors"
                                                type="submit"
                                                disabled={isOnAnswering}
                                            >
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "qna.btnAnswer")}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    ))}
                    {isLoading && (
                        <div className="py-4 flex justify-center">
                            <FullPageLoading />
                        </div>
                    )}

                    {/* 더보기 버튼 */}
                    {(!isLoading && hasMorePage) && (
                        <div className="flex justify-center mt-8">
                            <button
                                onClick={() => loadMoreList()}
                                className="w-full sm:w-auto px-6 py-2.5 text-sm font-medium text-[#CF3688] border border-[#CF3688] rounded-lg hover:bg-[#CF3688] hover:text-white transition-colors"
                            >
                                {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtMore")}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </main>
    );
};

export default QnAComponent;