import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthHeader from "../component/AuthHeader.component";
const SignupComponent = (props: any) => {
  const { backUrl } = props;
  const navigate = useNavigate();
  const { setAccountTypeCode } = props;

  return (
    <main className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <AuthHeader backUrl={backUrl} />
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="md:text-4xl text-2xl font-bold text-gray-900 mb-8 md:mb-12">
            {getLanguageValue(PAGE_TYPE.COMMON, "signup.txtTitle")}
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* 지망생 가입 */}
            <div className="hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-1  bg-white rounded-xl shadow-lg">
              <div className="p-8 border-t-4 border-indigo-600 h-full flex flex-col">
                <div className="flex-grow">
                  <h3 className="text-2xl font-bold text-gray-800 mb-8">
                    {getLanguageValue(PAGE_TYPE.COMMON, "signup.txtAspirantSectionTitle")}
                  </h3>
                  <p className="text-gray-600 text-lg mb-8">
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "signup.txtAspirantSectionDesc"
                    )}
                  </p>
                </div>
                <div className="mt-8">
                  <Link
                    to="/signup/student"
                    className="relative inline-block font-extrabold bg-[#3E4352] !text-[#FFD700] px-5 py-3 rounded-full tracking-[0.1em]
                                hover:scale-105 transition-transform duration-300">
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "signup.btnSignupAspirant"
                    )}
                  </Link>
                </div>
              </div>
            </div>

            {/* 캐스팅 매니저 가입 */}
            <div className="hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-1  bg-white rounded-xl shadow-lg">
              <div className="p-8 border-t-4 border-purple-600 h-full flex flex-col">
                <div className="flex-grow">
                  <h3 className="text-2xl font-bold text-gray-800 mb-8">
                    {getLanguageValue(PAGE_TYPE.COMMON, "signup.txtCastingManagerSectionTitle")}
                  </h3>
                  <p className="text-gray-600 text-lg mb-8">
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "signup.txtCastingManagerSectionDesc"
                    )}
                  </p>
                </div>
                <div className="mt-8">
                  <Link
                    to="/signup/agency"
                    className="relative inline-block font-extrabold bg-[#3E4352] !text-[#FFD700] px-5 py-3 rounded-full tracking-[0.1em]
                                hover:scale-105 transition-transform duration-300">
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "signup.btnSignupCastingManager"
                    )}
                  </Link>
                </div>
              </div>
            </div>

            {/* 학원 가입 */}
            <div className="hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-1  bg-white rounded-xl shadow-lg">
              <div className="p-8 border-t-4 border-pink-600 h-full flex flex-col">
                <div className="flex-grow">
                  <h3 className="text-2xl font-bold text-gray-800 mb-8">
                    {getLanguageValue(PAGE_TYPE.COMMON, "signup.txtSchoolSectionTitle")}
                  </h3>
                  <p className="text-gray-600 text-lg mb-8">
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "signup.txtSchoolSectionDesc"
                    )}
                  </p>
                </div>
                <div className="mt-8">
                  <Link
                    to="/signup/school"
                    className="relative inline-block font-extrabold bg-[#3E4352] !text-[#FFD700] px-5 py-3 rounded-full tracking-[0.1em]
                                hover:scale-105 transition-transform duration-300">
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "signup.btnSignupSchool"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-200 pt-6 text-center">
          <p className="text-sm sm:text-base text-gray-600">
            {getLanguageValue(PAGE_TYPE.COMMON, "signup.txtAlreadyHaveAccount")}
            <br />
            <button
              onClick={() => navigate("/login")}
              className="font-semibold text-[#CF3688] hover:text-[#A62B6D] transition-colors duration-200"
            >
              {getLanguageValue(PAGE_TYPE.COMMON, "signup.btnGoToLogin")}
            </button>
          </p>
        </div>
      </div>
    </main>
  );
};

export default SignupComponent;
