import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { composeWithDevTools } from "@redux-devtools/extension";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import logger from "redux-logger";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";
import "./tailwind.css";

const container = ReactDOM.createRoot(
  document.getElementById("container") as HTMLElement
);

const middleware = [logger];

const enhancer =
  process.env.NODE_ENV === "production"
    ? compose
    : composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(rootReducer, enhancer);

if (process.env.REACT_APP_ENV === "prod") {
  console.log = () => { };
  console.debug = () => { };
  console.info = () => { };
  console.warn = () => { };
  console.error = () => { };
}

container.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
