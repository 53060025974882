import React from "react";
import { getImageUrl } from "helpers/Image.helper";
import LoadingComponent from "commons/loading/Loading.component";
import { getStudentNickname, getFlagByCountryCode, getCountryNameByCountryCode } from "helpers/String.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { useNavigate } from "react-router-dom";

const FollowComponent = (props: any) => {
    const navigate = useNavigate();
    const {
        activeTab,
        setActiveTab,
        list,
        isInit,
        onFollow,
        onUnfollow,
        onScroll,
        isOnLoadMore,
        hasMore,
        onShowStudentInfoPopup,
        recommendList,
        isOnLoadingRecommendList,
        errorMessageForRecommendList
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <div className="fixed top-0 left-0 w-full bg-gray-50 z-10">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex items-center gap-4 py-4">
                        <button onClick={() => navigate('/student')} className="text-gray-600 hover:text-gray-900">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                            </svg>
                        </button>
                        <h1 className="text-2xl font-bold text-gray-900">
                            {getLanguageValue(PAGE_TYPE.STUDENT, "follow.txtTitle")}
                        </h1>
                    </div>
                </div>

                {/* 추천 팔로잉 목록 */}
                <div className="mb-8">
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "follow.txtRecommendFollowing")}
                    </h2>

                    {isOnLoadingRecommendList ? (
                        <LoadingComponent />
                    ) : (
                        <>
                            {errorMessageForRecommendList ? (
                                <div className="flex items-center p-3 bg-red-50 border border-red-200 rounded-lg">
                                    <svg className="w-5 h-5 text-red-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                    </svg>
                                    <span className="text-red-600 font-medium text-sm">{errorMessageForRecommendList}</span>
                                </div>
                            ) : (
                                <>
                                    {recommendList?.length === 0 && (
                                        <button className="text-gray-600 hover:text-gray-900">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "follow.btnRecommendFollowing")}
                                        </button>
                                    )}

                                    <div className="w-full">
                                        {/* 모바일/태블릿용 가로 스크롤 및 PC용 그리드 통합 */}
                                        <div className="overflow-x-auto lg:hidden">
                                            <div className="flex gap-0.5 pb-2">
                                                {recommendList?.map((studentInfo: any) => (
                                                    <div key={studentInfo.id} className="flex-shrink-0 w-[100px] sm:w-[125px] cursor-pointer">
                                                        <div className="p-1 hover:bg-gray-50 rounded-lg"
                                                            onClick={() => onShowStudentInfoPopup(studentInfo.id)}>
                                                            <div className="w-20 h-20 mx-auto rounded-full bg-gray-200 overflow-hidden relative">
                                                                <img src={getImageUrl(studentInfo.profileImageInfo)} alt="" className="w-full h-full object-cover" />
                                                                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
                                                                    {getFlagByCountryCode(studentInfo.countryInfo.code)}
                                                                </div>
                                                            </div>
                                                            <div className="mt-0.5 text-center">
                                                                <p className="text-sm truncate">
                                                                    {getStudentNickname(studentInfo)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="hidden lg:grid lg:grid-cols-4 gap-0.5">
                                            {recommendList?.map((studentInfo: any) => (
                                                <div key={studentInfo.id} className="p-1 hover:bg-gray-50 rounded-lg">
                                                    <div className="flex flex-col items-center"
                                                        onClick={() => onShowStudentInfoPopup(studentInfo.id)}>
                                                        <div className="w-20 h-20 rounded-full bg-gray-200 overflow-hidden relative">
                                                            <img src={getImageUrl(studentInfo.profileImageInfo)} alt="" className="w-full h-full object-cover" />
                                                            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
                                                                {getFlagByCountryCode(studentInfo.countryInfo.code)}
                                                            </div>
                                                        </div>
                                                        <div className="mt-0.5 text-center">
                                                            <p className="text-sm truncate">{getStudentNickname(studentInfo)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>

                <div className="flex items-center justify-between mb-8">
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">
                        <div className="flex space-x-4">
                            <button
                                className={`${activeTab === 'following' ? 'text-[#CF3688]' : 'text-gray-400'}`}
                                onClick={() => setActiveTab('following')}
                            >
                                {getLanguageValue(PAGE_TYPE.STUDENT, "follow.txtTabFollowing")}
                            </button>
                            <button
                                className={`${activeTab === 'follower' ? 'text-[#CF3688]' : 'text-gray-400'}`}
                                onClick={() => setActiveTab('follower')}
                            >
                                {getLanguageValue(PAGE_TYPE.STUDENT, "follow.txtTabFollower")}
                            </button>
                        </div>
                    </h2>
                </div>

                <div className="max-w-2xl mx-auto space-y-6">
                    <div className="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-shadow">
                        {!isInit ? (
                            <LoadingComponent />
                        ) : list?.length === 0 ? (
                            <div className="flex flex-col items-center justify-center py-10 text-gray-500">
                                <svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                                <p className="text-center">
                                    {activeTab === 'following'
                                        ? getLanguageValue(PAGE_TYPE.STUDENT, "follow.txtNoFollowing")
                                        : getLanguageValue(PAGE_TYPE.STUDENT, "follow.txtNoFollower")}
                                </p>
                            </div>
                        ) : (
                            <div
                                className="calc-h-[100vh-10rem] overflow-y-auto mb-4"
                                onScroll={onScroll}
                            >
                                <div className="space-y-4">
                                    {list?.map((user: any) => (
                                        <div key={user.id} className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-lg">
                                            <div className="flex items-center space-x-3"
                                                onClick={() => onShowStudentInfoPopup(user.userStudentInfo?.id)}
                                            >
                                                <div className="w-10 h-10 rounded-full bg-gray-200 overflow-hidden">
                                                    <img src={getImageUrl(user.userStudentInfo?.profileImageInfo)} alt="" className="w-full h-full object-cover" />
                                                </div>
                                                <div>
                                                    <p className="font-medium">
                                                        {getStudentNickname(user.userStudentInfo)}
                                                    </p>
                                                </div>
                                            </div>
                                            {!user.isMine && (
                                                <button
                                                    className={`px-4 py-2 rounded-lg text-sm font-medium ${user.isFollowing ? 'bg-gray-100 text-gray-700 hover:bg-gray-200' : 'bg-[#CF3688] text-white hover:bg-[#CF3688]/90'}`}
                                                    onClick={() => user.isFollowing ? onUnfollow(user.userStudentInfo?.id) : onFollow(user.userStudentInfo?.id)}>
                                                    {activeTab === 'following' &&
                                                        <>
                                                            {((user.isFollowing && user.isFollower) && getLanguageValue(PAGE_TYPE.STUDENT, "follow.btnUnfollowBack"))}
                                                            {((user.isFollowing && !user.isFollower) && getLanguageValue(PAGE_TYPE.STUDENT, "follow.btnUnfollow"))}
                                                            {((!user.isFollowing && user.isFollower) && getLanguageValue(PAGE_TYPE.STUDENT, "follow.btnFollowBack"))}
                                                            {((!user.isFollowing && !user.isFollower) && getLanguageValue(PAGE_TYPE.STUDENT, "follow.btnFollow"))}
                                                        </>
                                                    }
                                                    {activeTab === 'follower' &&
                                                        (user.isFollowing ? getLanguageValue(PAGE_TYPE.STUDENT, "follow.btnUnfollow") : getLanguageValue(PAGE_TYPE.STUDENT, "follow.btnFollowBack"))
                                                    }
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                    {isOnLoadMore && (
                                        <div className="flex justify-center py-4">
                                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-[#CF3688]"></div>
                                        </div>
                                    )}

                                    {!isOnLoadMore && !hasMore && list.length > 0 && (
                                        <div className="text-center py-4 text-gray-500 text-sm">
                                            {activeTab === 'following'
                                                ? getLanguageValue(PAGE_TYPE.STUDENT, "follow.txtNoMoreFollowing")
                                                : getLanguageValue(PAGE_TYPE.STUDENT, "follow.txtNoMoreFollower")}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default FollowComponent;