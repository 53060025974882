import React, { useEffect, useState } from "react";
import StudentInfoPopupComponent from "./StudentInfoPopup.component";
import { getStudentInfo } from "api/student/Popup.api";
import { addFollow, cancelFollow } from "api/student/Follow.api";

const StudentInfoPopupContainer = (props: any) => {

    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isOnNetworking, setIsOnNetworking] = useState(false);

    const getStudentData = async () => {
        console.log("getStudentData", props.studentId);
        if (!props.studentId) return;

        try {
            setIsLoading(true);
            const data = await getStudentInfo(props.studentId);
            if (data.info) {
                setInfo(data.info);
            }
        } catch (err: any) {
            console.error("학생 정보를 불러오는데 실패했습니다:", err);
            setErrorMessage(err.message || "Unknown Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getStudentData();
    }, [props.studentId]);

    const onFollow = async (studentId: number) => {
        console.log("onFollow", studentId);
        if (isOnNetworking) return;
        try {
            setIsOnNetworking(true);
            const data = await addFollow(studentId);
            if (data) {
                setInfo((prev: any) => prev ? { ...prev, isFollowing: true } : null);
            }
        } catch (err: any) {
            console.error("팔로우 처리에 실패했습니다:", err);
            alert(err.message || "Unknown Error");
            // setErrorMessage(err.message || "Unknown Error");
        } finally {
            setIsOnNetworking(false);
        }
    };

    const onUnfollow = async (studentId: number) => {
        console.log("onUnfollow", studentId);
        if (isOnNetworking) return;
        try {
            setIsOnNetworking(true);
            const data = await cancelFollow(studentId);
            if (data) {
                setInfo((prev: any) => prev ? { ...prev, isFollowing: false } : null);
            }
        } catch (err: any) {
            console.error("팔로우 처리에 실패했습니다:", err);
            alert(err.message || "Unknown Error");
            // setErrorMessage(err.message || "Unknown Error");
        } finally {
            setIsOnNetworking(false);
        }
    };

    // if (isLoading) {
    //     return (
    //         <div className="flex justify-center items-center h-screen">
    //             <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    //         </div>
    //     );
    // }

    // if (error || !info) {
    //     return (
    //         <div className="flex justify-center items-center h-screen">
    //             <div className="text-center text-gray-600">
    //                 해당 학생 정보를 찾을 수 없습니다.
    //             </div>
    //         </div>
    //     );
    // }

    return <StudentInfoPopupComponent
        onClose={props.onClose}
        info={info}
        isLoading={isLoading}
        errorMessage={errorMessage}
        onFollow={onFollow}
        onUnfollow={onUnfollow}
        isOnNetworking={isOnNetworking}
    />;
};

export default StudentInfoPopupContainer;