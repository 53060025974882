import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ProfileBodyInfoEditComponent from "./PublicProfileBodyInfoEdit.component";
import { createBodyInfo, deleteBodyInfo, getBodyInfo, updateBodyInfo, updateBodyInfoIsPublic } from "api/student/v2/publicProfile.api";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const PublicProfileBodyInfoEditContainer = () => {
    const navigate = useNavigate();
    const { type } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isPublic, setIsPublic] = useState<boolean>(false);
    const [list, setList] = useState<any>([]);
    const [isOnNetworking, setIsOnNetworking] = useState(false);
    const [editingItemIdx, setEditingItemIdx] = useState<any>(null);
    const [editedValue, setEditedValue] = useState<any>("");
    const [editedValueLog, setEditedValueLog] = useState<any>("");
    const [inputStep, setInputStep] = useState<number>(0);
    const [inputMin, setInputMin] = useState<number>(0);
    const [inputMax, setInputMax] = useState<number>(0);

    useEffect(() => {
        if (!type) {
            navigate("/student/profile/public");
        }
    }, [type]);

    useEffect(() => {
        if (type === "height") {
            setInputStep(1);
            setInputMin(120);
            setInputMax(210);
        } else if (type === "weight") {
            setInputStep(1);
            setInputMin(20);
            setInputMax(150);
        } else if (type === "shoe-size") {
            setInputStep(5);
            setInputMin(140);
            setInputMax(300);
        }
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getBodyInfo(type)
            .then((data) => {
                if (data) {
                    setIsPublic(data.info.isPublic);
                    setList(data.list);
                    setEditedValue("");
                    setEditedValueLog("");
                }
            })
            .catch((err) => {
                console.error(err);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    const handleIsPublicChange = (value: boolean) => {
        console.log("student/profile/ProfileBodyInfoEdit.container::handleIsPublicChange", value);
        setIsOnNetworking(true);
        updateBodyInfoIsPublic(type, value)
            .then((data) => {
                if (data) {
                    setIsPublic(value);
                }
            }).finally(() => {
                setIsOnNetworking(false);
            });
    }

    const handleEditInputChange = (value: number) => {
        setEditedValue(value);
    }

    const handleSave = async () => {
        console.log("student/profile/ProfileBodyInfoEdit.container::handleSave", editedValue);

        if (!editedValue || isNaN(Number(editedValue))) {
            return;
        }

        const value = Number(editedValue);
        if (window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "btn.txtSaveConfirmation"))) {
            setIsOnNetworking(true);
            const response = await createBodyInfo(type, value)
                .then((data) => {
                    if (data) {
                        loadData();
                    }
                }).finally(() => {
                    setEditedValue("");
                    setIsOnNetworking(false);
                });
        }
    }

    const handleRemoveLog = async (id: number) => {
        if (window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "btn.txtDeleteConfirmation"))) {
            setIsOnNetworking(true);
            const response = await deleteBodyInfo(type, id)
                .then((data) => {
                    if (data) {
                        loadData();
                    }
                }).finally(() => {
                    setIsOnNetworking(false);
                });
        }
    }

    const handleEditInputLog = async (idx: number) => {
        if (editingItemIdx === idx) {
            setEditedValueLog("");
            setEditingItemIdx(null);
        } else {
            setEditedValueLog(list[idx].value);
            setEditingItemIdx(idx);
        }
    }

    const handleEditInputChangeLog = (value: number) => {
        setEditedValueLog(value);
    }

    const handleEditLog = async (id: number) => {
        console.log(id, editedValue);

        if (!editedValueLog || isNaN(Number(editedValueLog))) {
            return;
        }

        const value = Number(editedValueLog);
        if (window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "btn.txtUpdateConfirmation"))) {
            setIsOnNetworking(true);
            const response = await updateBodyInfo(type, id, value)
                .then((data) => {
                    if (data) {
                        loadData();
                    }
                }).finally(() => {
                    setIsOnNetworking(false);
                });
        }
    }

    return <ProfileBodyInfoEditComponent
        type={type} list={list} isLoading={isLoading}
        isPublic={isPublic}
        handleRemoveLog={handleRemoveLog}
        handleEditLog={handleEditLog}
        handleIsPublicChange={handleIsPublicChange}
        isOnNetworking={isOnNetworking}
        editingItemIdx={editingItemIdx}
        handleEditInputLog={handleEditInputLog}
        handleSave={handleSave}
        editedValue={editedValue}
        handleEditInputChange={handleEditInputChange}
        inputStep={inputStep}
        inputMin={inputMin}
        inputMax={inputMax}
        editedValueLog={editedValueLog}
        handleEditInputChangeLog={handleEditInputChangeLog}
    />;
};

export default PublicProfileBodyInfoEditContainer;