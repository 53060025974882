import FullPageLoadingComponent from "commons/loading/FullPageLoading.component";
import { getImageUrl, getProfileImageUrl } from "helpers/Image.helper";
import { formattedDateForLocale } from "helpers/Datetime.helper";
import { getStudentNickname, getCurrentLangName, getFlagByCountryCode, getStudentName, getFanPageUrl, } from "helpers/String.helper";
import React from "react";

const FullPageStudentInfoPopupComponent = (props: any) => {
    const { info, isInit, onClose, handleRequestChat, handleCancelChat, handleStartChat, onFavoriteToggle, isOnNetworking, onDetailInfo, onImageSlidePopup, errorMessage } = props;

    return (
        <>

            <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative bg-white w-full min-h-screen flex flex-col">
                        {!isInit && <FullPageLoadingComponent />}
                        {/* 고정된 헤더 */}
                        {errorMessage &&
                            <>
                                <div className="sticky top-0 bg-white z-10 border-b">
                                    <div className="p-6 flex items-center">
                                        <h2 className="text-2xl font-bold">지망생 정보</h2>
                                        <div className="ml-auto flex items-center gap-2">
                                            <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 overflow-y-auto">
                                    <div className="p-6 pb-24"> {/* 하단 버튼 영역만큼 여백 추가 */}
                                        <div className="text-center text-gray-500">{errorMessage}</div>
                                    </div>
                                </div>
                            </>
                        }

                        {info &&
                            <>
                                <div className="sticky top-0 bg-white z-10 border-b">
                                    <div className="p-6 flex items-center">
                                        <h2 className="text-2xl font-bold">지망생 정보</h2>
                                        <div className="ml-auto flex items-center gap-2">
                                            {info?.userStudentInfo?.isFavorite ?
                                                <button
                                                    onClick={onFavoriteToggle}
                                                    disabled={isOnNetworking}
                                                    className="p-2 rounded-full hover:bg-gray-100">
                                                    <img src="imgs/icons/icon-bookmark-active.svg" alt="즐겨찾기" className="w-6 h-6" />
                                                </button>
                                                :
                                                <button
                                                    onClick={onFavoriteToggle}
                                                    disabled={isOnNetworking}
                                                    className="p-2 rounded-full hover:bg-gray-100">
                                                    <img src="imgs/icons/icon-bookmark.svg" alt="즐겨찾기" className="w-6 h-6" />
                                                </button>
                                            }
                                            <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* 스크롤 가능한 컨텐츠 영역 */}
                                <div className="flex-1 overflow-y-auto">
                                    <div className="p-6 pb-24"> {/* 하단 버튼 영역만큼 여백 추가 */}
                                        {errorMessage && <div className="text-center text-gray-500">{errorMessage}</div>}
                                        <div className="space-y-6">
                                            <div className="flex items-center space-x-4">
                                                <div
                                                    onClick={() => onImageSlidePopup(0)}
                                                    className="w-24 h-24 relative">
                                                    <img
                                                        src={getProfileImageUrl(info.userStudentInfo?.profileImageInfo)}
                                                        alt={getStudentNickname(info.userStudentInfo)}
                                                        className="w-full h-full object-cover rounded-full bg-gray-200"
                                                    />
                                                </div>
                                                <div>
                                                    <h3 className="text-xl font-bold">
                                                        {getStudentName(info.userStudentInfo)}
                                                    </h3>
                                                    <p className="text-gray-600">
                                                        {getStudentNickname(info.userStudentInfo, "닉네임 없음")}
                                                    </p>
                                                    {/* 팬 페이지 감춤 */}
                                                    {/* <p className="text-gray-600">
                                                <a href={getFanPageUrl(info.userStudentInfo)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="!text-[#CF3688] !hover:text-[#CF3688] underline">
                                                    @{info.userStudentInfo?.slug}
                                                </a>
                                            </p> */}
                                                </div>
                                            </div>

                                            {/* 추가 이미지 영역 */}
                                            {info.userStudentInfo.userStudentImageList?.length > 0 && (
                                                <div className="w-full mb-4">
                                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 flex overflow-x-auto pb-2 gap-2">
                                                        {info.userStudentInfo.userStudentImageList.map((userStudentImage: any, index: number) => (
                                                            <img
                                                                onClick={() => onImageSlidePopup(index + 1)}
                                                                key={index}
                                                                src={getImageUrl(userStudentImage.imageInfo, 200)}
                                                                alt="추가이미지"
                                                                className="w-24 h-24 rounded-lg object-cover flex-shrink-0 sm:w-full sm:h-auto sm:aspect-square"
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                            <div className="p-6 bg-gray-50 rounded-xl shadow-sm hover:shadow-md transition-shadow">
                                                <h4 className="font-semibold text-lg mb-4 text-gray-800">기본 정보</h4>
                                                <div className="space-y-3">
                                                    <div className="flex items-center text-gray-700">
                                                        <span className="w-24 text-gray-500">국가</span>
                                                        <span className="flex items-center">
                                                            {getFlagByCountryCode(info.userStudentInfo.countryInfo?.code)}
                                                            <span className="ml-2">{getCurrentLangName(info.userStudentInfo.countryInfo?.names)}</span>
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center text-gray-700">
                                                        <span className="w-24 text-gray-500">생년월일</span>
                                                        <span>{formattedDateForLocale(info.userStudentInfo.birthday, navigator.language)}</span>
                                                    </div>
                                                    <div className="flex items-center text-gray-700">
                                                        <span className="w-24 text-gray-500">성별</span>
                                                        <span>{getCurrentLangName(info.userStudentInfo.genderInfo?.names)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-6 bg-gray-50 rounded-xl shadow-sm hover:shadow-md transition-shadow">
                                                <h4 className="font-semibold text-lg mb-4 text-gray-800">신체 정보</h4>
                                                <div className="space-y-3">
                                                    <div className="flex items-center text-gray-700">
                                                        <span className="w-24 text-gray-500">키</span>
                                                        <span>{info.userStudentInfo.isPublicHeight ? `${info.userStudentInfo.height} cm` : '비공개'}</span>
                                                    </div>
                                                    <div className="flex items-center text-gray-700">
                                                        <span className="w-24 text-gray-500">몸무게</span>
                                                        <span>{info.userStudentInfo.isPublicWeight ? `${info.userStudentInfo.weight} kg` : '비공개'}</span>
                                                    </div>
                                                    <div className="flex items-center text-gray-700">
                                                        <span className="w-24 text-gray-500">신발사이즈</span>
                                                        <span>{info.userStudentInfo.isPublicShoeSize ? `${info.userStudentInfo.shoeSize} mm` : '비공개'}</span>
                                                    </div>
                                                    <div className="flex items-center text-gray-700">
                                                        <span className="w-24 text-gray-500">혈액형</span>
                                                        <span>{info.userStudentInfo.bloodTypeInfo ? getCurrentLangName(info.userStudentInfo.bloodTypeInfo.names) : '비공개'}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-6 bg-gray-50 rounded-xl shadow-sm hover:shadow-md transition-shadow">
                                                <h4 className="font-semibold text-lg mb-4 text-gray-800">추가 정보</h4>
                                                <div className="space-y-3">
                                                    <div className="flex items-center text-gray-700">
                                                        <span className="w-24 text-gray-500">혈액형</span>
                                                        <span>{info.userStudentInfo.bloodTypeInfo ? getCurrentLangName(info.userStudentInfo.bloodTypeInfo.names) : '비공개'}</span>
                                                    </div>
                                                    <div className="flex items-center text-gray-700">
                                                        <span className="w-24 text-gray-500">MBTI</span>
                                                        <span>{info.userStudentInfo.mbtiCode ? info.userStudentInfo.mbtiCode : '비공개'}</span>
                                                    </div>
                                                    <div className="flex items-center text-gray-700">
                                                        <span className="w-24 text-gray-500">특기</span>
                                                        <span>
                                                            {info.userStudentInfo.specialtyInfoList
                                                                ? info.userStudentInfo.specialtyInfoList.map((specialty: any) =>
                                                                    getCurrentLangName(specialty?.names)).join(', ')
                                                                : '비공개'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* 고정된 하단 버튼 */}

                                <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg p-4">
                                    <div className="flex gap-4">
                                        <button
                                            onClick={onDetailInfo}
                                            className="w-1/2 px-4 py-3 text-sm font-medium text-gray-500 bg-gray-50 rounded-xl">
                                            상세 정보
                                        </button>

                                        {!info.messageInfo ? (
                                            <button
                                                onClick={handleRequestChat}
                                                className="w-1/2 py-3 px-4 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors flex items-center justify-center gap-2"
                                                disabled={isOnNetworking}
                                            >
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                                </svg>
                                                대화 요청
                                            </button>
                                        ) : info.messageInfo.messageStatusCode === 'messageType:request' ? (
                                            <button
                                                onClick={handleCancelChat}
                                                className="w-1/2 py-3 px-4 bg-gray-500 hover:bg-gray-600 text-white font-medium rounded-lg transition-colors flex items-center justify-center gap-2"
                                                disabled={isOnNetworking}
                                            >
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                                대화 요청 취소
                                            </button>
                                        ) : info.messageInfo.messageStatusCode === 'messageType:accept' && (
                                            <button
                                                onClick={handleStartChat}
                                                className="w-1/2 py-3 px-4 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors flex items-center justify-center gap-2"
                                            >
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                                </svg>
                                                대화 시작
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default FullPageStudentInfoPopupComponent;