import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";

const MissionComponent = (props: any) => {
    useEffect(() => {}, []);

    return (
        <main className="container mx-auto px-4 sm:px-6 lg:px-8 pt-24 sm:pt-28 lg:pt-32">
            <section className="mb-12 sm:mb-16 lg:mb-20 py-10 sm:py-16 rounded-2xl bg-gray-50">
                <div className="text-center">
                    <h1 className="text-3xl font-bold text-gray-800">MISSION</h1>
                    <h2 className="text-xl text-gray-700 mt-3">WE HELP MORE PEOPLE BECOME ENTERTAINERS.</h2>
                    <p className="text-base text-gray-600 mt-4 max-w-2xl mx-auto">{getLanguageValue(PAGE_TYPE.COMMON, "mission.title1")}</p>
                </div>
            </section>
            <section className="mb-12 sm:mb-16 lg:mb-20 py-10 sm:py-16 rounded-2xl bg-gray-50">
                <div className="text-center">
                    <h1 className="text-3xl font-bold text-gray-800">VISION</h1>
                    <h2 className="text-xl text-gray-700 mt-3">GLOBAL NO.1 SUPPLY CHAIN FOR NEXT-GENERATION K-POP IDOL WANNABES.</h2>
                    <p className="text-base text-gray-600 mt-4 max-w-2xl mx-auto">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: getLanguageValue(PAGE_TYPE.COMMON, "mission.title2"),
                            }}></div>
                    </p>
                </div>
            </section>
            <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
                    <h3 className="text-lg font-medium text-gray-800">
                        <span className="font-semibold">1.</span>
                        <span className="sub-title"> WANNABES</span>
                    </h3>
                    <div className="mt-3">
                        <p className="text-gray-600 mb-2">
                            <span className="text-gray-400">· </span>
                            {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub11")}
                        </p>
                        <p className="text-gray-600 mb-2">
                            <span className="text-gray-400">· </span>
                            {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub12")}
                        </p>
                        <p className="text-gray-600">
                            <span className="text-gray-400">· </span>
                            {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub13")}
                        </p>
                    </div>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
                    <h3 className="text-lg font-medium text-gray-800">
                        <span className="font-semibold">2.</span>
                        <span className="sub-title"> AGENCY</span>
                    </h3>
                    <div className="mt-3">
                        <p className="text-gray-600 mb-2">
                            <span className="text-gray-400">· </span>
                            {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub21")}
                        </p>
                        <p className="text-gray-600 mb-2">
                            <span className="text-gray-400">· </span>
                            {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub22")}
                        </p>
                        <p className="text-gray-600">
                            <span className="text-gray-400">· </span>
                            {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub23")}
                        </p>
                    </div>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
                    <h3 className="text-lg font-medium text-gray-800">
                        <span className="font-semibold">3.</span>
                        <span className="sub-title"> LOCAL SCHOOL AROUND THE WORLD</span>
                    </h3>
                    <div className="mt-3">
                        <p className="text-gray-600 mb-2">
                            <span className="text-gray-400">· </span>
                            {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub31")}
                        </p>
                        <p className="text-gray-600 mb-2">
                            <span className="text-gray-400">· </span>
                            {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub32")}
                        </p>
                        <p className="text-gray-600">
                            <span className="text-gray-400">· </span>
                            {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub33")}
                        </p>
                    </div>
                </div>
            </div>
        </main>
    );
};
export default MissionComponent;
