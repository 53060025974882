import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AlarmTooltipContainer from "./AlarmTooltip.container";
import InstallAppBannerContainer from "commons/banner/InstallAppBanner.container";

const HeaderComponent = (props: any) => {
    const { userInfo, showAlarmTooltip, onAlarmClick, onCloseAlarmTooltip, unreadCount } = props;
    return (
        <header className="fixed top-0 left-0 right-0 w-full bg-white shadow z-50">
            <div className="max-w-7xl mx-auto px-4">
                <div className="py-2 flex justify-between items-center">
                    <Link to="/agency" className="inline-block">
                        <img
                            src="./imgs/common/logo/img-logo/logo-t2-125.png"
                            alt="wannabes"
                            className="w-[40px] md:w-[35px] sm:w-[25px]"
                        />
                    </Link>

                    <div className="flex items-center gap-4">
                        <button onClick={onAlarmClick} className="text-gray-600 hover:text-gray-900 relative">
                            <img src="./imgs/icons/alarm-icon.png" alt="알림" className="w-7 h-7 sm:w-5 sm:h-5" />
                            {unreadCount > 0 && (
                                <div className="absolute -top-1 -right-1 w-2.5 h-2.5 bg-red-500 rounded-full animate-pulse"></div>
                            )}
                        </button>
                        {showAlarmTooltip && (
                            <AlarmTooltipContainer onClose={onCloseAlarmTooltip} />
                        )}
                        <Link to="/agency/my" className="text-gray-600 hover:text-gray-900">
                            <img src="/imgs/icons/agency/icon-my.svg" alt={userInfo?.name} className="w-7 h-7 sm:w-5 sm:h-5" />
                        </Link>
                    </div>
                </div>
            </div>
            {/* 모바일 웹에서 앱 설치 유도 배너 */}
            <InstallAppBannerContainer />

        </header>
    );
};

export default HeaderComponent;