import React from "react";
import { getCurrentLangName } from "helpers/String.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const ReportPopup = (props: any) => {
    const { onClose, reportTypeCodeList, reportTypeCode, etcReason, setReportTypeCode, setEtcReason, onReport, isReporting } = props;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 p-4
            !m-0">
            <div className="bg-white rounded-2xl p-6 w-full max-w-md mx-auto">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.txtTitle")}
                    </h3>
                    <button className="text-gray-400 hover:text-gray-600" onClick={onClose}>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="space-y-3">
                    <div className="space-y-1.5">
                        <label className="block text-xs font-medium text-gray-700">{getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.txtReportType")}</label>
                        <select
                            className="w-full p-2 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688]"
                            value={reportTypeCode}
                            onChange={(e) => setReportTypeCode(e.target.value)}
                            disabled={isReporting}
                        >
                            <option value="">{getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.txtReportTypeSelect")}</option>
                            {reportTypeCodeList.map((item: any) => (
                                <option key={item.code} value={item.code}>
                                    {getCurrentLangName(item.names)}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="space-y-1.5">
                        <label className="block text-xs font-medium text-gray-700">{getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.txtDetailReason")}</label>
                        <textarea
                            className="w-full h-28 p-2 text-sm border border-gray-300 rounded-lg resize-none
                                focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688]"
                            placeholder={getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.placeholderInputDetailReason")}
                            value={etcReason}
                            onChange={(e) => setEtcReason(e.target.value)}
                            disabled={isReporting}
                        />
                    </div>

                    <div className="flex justify-end space-x-2 mt-4">
                        <button
                            className="px-3 py-1.5 text-sm text-gray-500 hover:text-gray-700"
                            onClick={onClose}
                            disabled={isReporting}
                        >
                            {getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.btnCancel")}
                        </button>
                        <button
                            className="px-3 py-1.5 text-sm bg-[#CF3688] text-white rounded-lg hover:bg-[#CF3688]/90"
                            onClick={onReport}
                            disabled={isReporting}
                        >
                            {getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.btnReport")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportPopup;