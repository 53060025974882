import LoadingComponent from "commons/loading/Loading.component";
import { formatTimeAgo } from "helpers/Datetime.helper";
import React from "react";

const StudentGrowthHistoryPopupComponent = (props: any) => {
    const {
        isInit,
        list,
        bodyInfoTypeCode,
        onClose,
        errorMessage
    } = props;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 p-4 !m-0">
            <div className="bg-white rounded-2xl p-6 w-full max-w-2xl mx-auto">
                <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                        <h3 className="text-lg font-bold text-gray-900">신체 정보</h3>
                        <div className="px-4 py-1 bg-gray-100 rounded-full text-sm text-gray-600">
                            {bodyInfoTypeCode === 'bodyInfoType:height' && '키'}
                            {bodyInfoTypeCode === 'bodyInfoType:weight' && '몸무게'}
                            {bodyInfoTypeCode === 'bodyInfoType:shoeSize' && '신발사이즈'}
                        </div>
                    </div>
                    <button
                        type="button"
                        className="text-gray-400 hover:text-gray-600"
                        onClick={onClose}>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                {!isInit ?
                    <LoadingComponent />
                    :
                    <>
                        {errorMessage ?
                            <div className="w-full text-center py-8 text-gray-500">
                                {errorMessage}
                            </div>
                            :
                            <>
                                <div className="w-full max-h-[70vh] overflow-y-auto">
                                    {list?.map((item: any, index: number) => {
                                        const unit = bodyInfoTypeCode === 'bodyInfoType:height' ? 'cm'
                                            : bodyInfoTypeCode === 'bodyInfoType:weight' ? 'kg'
                                                : 'mm';

                                        const prevValue: number = list[index + 1]?.value;
                                        const diff: number = prevValue ? (Number(item.value) - Number(prevValue)) : null;
                                        const diffText = diff ? (diff > 0 ? `+${diff}` : diff) : '';

                                        return (
                                            <div
                                                key={index}
                                                className={`${index === 0
                                                    ? 'bg-[#FFD700] rounded-lg shadow-sm p-5 mb-6 border border-[#FFD700] hover:shadow-xl transition-shadow'
                                                    : 'py-4 border-b border-gray-100 hover:bg-gray-50 transition-colors'}`}
                                            >
                                                <div className="flex flex-col items-center">
                                                    <div className={`${index === 0
                                                        ? 'text-black font-bold text-lg'
                                                        : 'text-gray-500'}`}>
                                                        {item.value}{unit}
                                                        {diffText &&
                                                            <span className={`ml-2 text-sm opacity-75 ${index === 0
                                                                ? 'text-black font-semibold'
                                                                : 'text-gray-500'}`}>
                                                                ({diffText})
                                                            </span>
                                                        }
                                                    </div>
                                                    <div className={`text-sm ${index === 0
                                                        ? 'text-gray-500'
                                                        : 'text-gray-400'}`}>
                                                        {formatTimeAgo(item.createdAt)}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {list?.length === 0 &&
                                        <div className="w-full text-center py-8 text-gray-500">
                                            데이터가 없네요...
                                        </div>
                                    }
                                    {!list && (
                                        <div className="w-full text-center py-8 text-gray-500">
                                            데이터를 불러올 수 없어요...
                                        </div>
                                    )}
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default StudentGrowthHistoryPopupComponent;