import React from "react";
import { PAGE_TYPE } from "enums";
import { getImageUrl } from "helpers/Image.helper";
import { formatTimeAgo } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import LoadingComponent from "commons/loading/Loading.component";

interface CommentPopupComponentProps {
    targetId: string;
    commentList: any[];
    isLoading: boolean;
    handleCreateComment: (evt: React.FormEvent<HTMLFormElement>) => void;
    handleLoadMoreComment: () => void;
    page: number;
    text: string;
    handleTextChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    onToggleLike: (commentId: number) => void;
    openMenuId: number | null;
    setOpenMenuId: (openMenuId: number | null) => void;
    onDeleteComment: (commentId: number) => void;
    isInit: boolean;
    onTranslateComment: (commentId: number) => void;
    translatedComments: { [key: string]: string };
    setTranslatedComments: (translatedComments: { [key: string]: string }) => void;
    isOnTranslating: boolean;
    setIsOnTranslating: (isOnTranslating: boolean) => void;
    onClose: () => void;
    onShowStudentInfoPopup: (studentId: number) => void;
    onShowReportPopup: (commentId: number) => void;
    onShowLikePopup: (commentId: number) => void;
}

const CommentPopupComponent = (props: CommentPopupComponentProps) => {
    const { targetId, commentList, isLoading, handleCreateComment, handleLoadMoreComment, page, text, handleTextChange, onToggleLike, openMenuId, setOpenMenuId, onDeleteComment, isInit, onTranslateComment, translatedComments, isOnTranslating, onClose, onShowStudentInfoPopup, onShowReportPopup, onShowLikePopup } = props;

    return (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-[100dvh] top-0 left-0 p-4
            !m-0">
                <div className="bg-white rounded-2xl p-6 w-full max-w-2xl mx-auto">
                    <div className="flex items-center justify-between mb-4">
                        <h3 className="text-lg font-bold text-gray-900">
                            {getLanguageValue(PAGE_TYPE.STUDENT, "commentPopup.txtTitle")}
                        </h3>
                        <button className="text-gray-400 hover:text-gray-600" onClick={onClose}>
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    {/* 댓글 목록 */}
                    {!isInit ? (
                        <LoadingComponent />
                    ) : (
                        <>
                            <div className="max-h-[400px] overflow-y-auto mb-4">
                                <div className="space-y-4">
                                    {commentList.length === 0 ? (
                                        <div className="flex flex-col items-center justify-center py-10 text-gray-500">
                                            <svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                            </svg>
                                            <p className="text-center">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "commentPopup.txtNoComments") || "아직 작성된 댓글이 없습니다."}
                                            </p>
                                        </div>
                                    ) : (
                                        commentList.map((comment: any, index: number) => (
                                            <div key={index} className="flex space-x-3 p-3 hover:bg-gray-50 rounded-lg">
                                                <div className="flex-shrink-0"
                                                    onClick={() => onShowStudentInfoPopup(comment.userStudentInfo?.id)}
                                                >
                                                    <div className="w-10 h-10 rounded-full bg-gray-200 overflow-hidden">
                                                        <img src={getImageUrl(comment.userStudentInfo?.profileImageInfo)} alt="" className="w-full h-full object-cover" />
                                                    </div>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <div className="flex items-center justify-between">
                                                        <div className="min-w-0 flex-1 mr-2">
                                                            <p className="font-medium text-sm sm:text-base truncate">
                                                                {comment.userStudentInfo?.nickname && comment.userStudentInfo?.nickname !== "" ? comment.userStudentInfo?.nickname : "Unknown"}
                                                            </p>
                                                            <p className="text-xs sm:text-sm text-gray-500">
                                                                {formatTimeAgo(comment.createdAt)}
                                                            </p>
                                                        </div>
                                                        <div className="relative flex-shrink-0">
                                                            <button className="p-1.5 hover:bg-gray-100 rounded-full"
                                                                onClick={() => setOpenMenuId(openMenuId === comment.id ? null : comment.id)}
                                                            >
                                                                <svg className="w-4 h-4 sm:w-5 sm:h-5 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
                                                                    <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                                                </svg>
                                                            </button>

                                                            {openMenuId === comment.id && (
                                                                <div className="absolute right-0 mt-2 w-40 sm:w-48 bg-white rounded-lg shadow-lg z-10 py-1">
                                                                    {comment.isMine ? (
                                                                        <>
                                                                            <button
                                                                                onClick={() => {
                                                                                    onDeleteComment(comment.id);
                                                                                    setOpenMenuId(null);
                                                                                }}
                                                                                className="w-full px-3 sm:px-4 py-2 text-left text-xs sm:text-sm text-gray-700 hover:bg-gray-100">
                                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnDelete")}
                                                                            </button>
                                                                            <div className="w-full h-[1px] bg-gray-200 my-1"></div>
                                                                            <button
                                                                                onClick={() => setOpenMenuId(null)}
                                                                                className="w-full px-3 sm:px-4 py-2 text-left text-xs sm:text-sm text-gray-700 hover:bg-gray-100">
                                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnClose")}
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <button
                                                                                onClick={() => {
                                                                                    setOpenMenuId(null);
                                                                                    onShowReportPopup(comment.id);
                                                                                }}
                                                                                className="w-full px-3 sm:px-4 py-2 text-left text-xs sm:text-sm text-gray-700 hover:bg-gray-100">
                                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnReport")}
                                                                            </button>
                                                                            <div className="w-full h-[1px] bg-gray-200 my-1"></div>
                                                                            <button
                                                                                onClick={() => setOpenMenuId(null)}
                                                                                className="w-full px-3 sm:px-4 py-2 text-left text-xs sm:text-sm text-gray-700 hover:bg-gray-100">
                                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnClose")}
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="text-gray-900 mb-2 whitespace-pre-wrap break-words">
                                                        <p className="text-xs sm:text-sm">{comment.text}</p>
                                                        {!translatedComments[comment.id] && (
                                                            <button
                                                                className="mt-1.5 text-xs text-[#CF3688] hover:text-[#B32E74] transition-colors"
                                                                disabled={isOnTranslating}
                                                                onClick={() => onTranslateComment(comment.id)}>
                                                                {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtTranslate")}
                                                            </button>
                                                        )}
                                                        {translatedComments[comment.id] && (
                                                            <div className="mt-2 p-2 sm:p-3 bg-gray-50 border-l-4 border-[#CF3688] rounded">
                                                                <p className="text-xs text-gray-600">{translatedComments[comment.id]}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="flex flex-col gap-1.5 sm:gap-2">
                                                        <div className="flex items-center space-x-3">
                                                            <div className="flex items-center space-x-1.5">
                                                                <button
                                                                    className={`hover:text-[#CF3688] transition-colors ${comment.isLiked ? 'text-[#CF3688]' : ''}`}
                                                                    onClick={() => onToggleLike(comment.id)}>
                                                                    {comment.isLiked ? (
                                                                        <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="#CF3688" stroke="currentColor" viewBox="0 0 24 24">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                        </svg>
                                                                    ) : (
                                                                        <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                        </svg>
                                                                    )}
                                                                </button>
                                                                <button
                                                                    className="text-xs sm:text-sm text-gray-500 hover:text-gray-700"
                                                                    onClick={() => {
                                                                        onShowLikePopup(comment.id);
                                                                    }}>
                                                                    {comment.likeCount}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                    )}
                                </div>
                            </div>

                            {/* 댓글 입력 */}
                            <div className="border-t pt-4">
                                <form className="flex space-x-2"
                                    onSubmit={(evt) => handleCreateComment(evt)}>
                                    <input
                                        type="text"
                                        className="flex-1 px-4 py-2 text-base border border-gray-300 rounded-lg
                                        focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688]"
                                        placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "commentPopup.txtCommentPlaceholder")}
                                        value={text}
                                        onChange={(evt) => handleTextChange(evt)}
                                    />
                                    <button
                                        className="px-6 py-2 text-sm bg-[#CF3688] text-white rounded-lg hover:bg-[#CF3688]/90 whitespace-nowrap"
                                        onClick={(evt) => handleCreateComment(evt as unknown as React.FormEvent<HTMLFormElement>)}>
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "commentPopup.btnWrite")}
                                    </button>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default CommentPopupComponent;