import React, { memo } from 'react';

const ChatItemComponent = ({
    message,
    status,
    url,
    userInfo,
    messageDate,
    handleVideoPlay,
    handleVideoError,
    videoRef,
    showTooltip,
    handleLongPress,
    handleTooltipClose,
    handleCopyMessage,
    handleDeleteMessage,
    handleReportMessage,
    onLoadedImage,
    onLoadedVideo
}: {
    message: any,
    status: string,
    url: string,
    userInfo: any,
    messageDate: string,
    handleVideoPlay: () => void,
    handleVideoError: () => void,
    videoRef: React.RefObject<HTMLVideoElement>,
    showTooltip: boolean,
    handleLongPress: () => void,
    handleTooltipClose: () => void,
    handleCopyMessage: () => void,
    handleDeleteMessage: () => void,
    handleReportMessage: () => void,
    onLoadedImage: () => void,
    onLoadedVideo: () => void
}) => {
    const isMyMessage = message.userType === userInfo.accountTypeCode;

    return (
        <div className="relative">
            <div
                className={`flex ${isMyMessage ? 'justify-end' : 'justify-start'} mb-3`}
                onTouchStart={(e) => {
                    const touchStartTime = new Date().getTime();
                    const touchStartX = e.touches[0].clientX;
                    const touchStartY = e.touches[0].clientY;
                    
                    const timer = setTimeout(() => {
                        handleLongPress();
                    }, 500);

                    const handleTouchMove = (e: TouchEvent) => {
                        const touchMoveX = e.touches[0].clientX;
                        const touchMoveY = e.touches[0].clientY;
                        
                        // 드래그 감지를 위한 거리 계산 (10픽셀 이상 이동시 드래그로 간주)
                        const dragDistance = Math.sqrt(
                            Math.pow(touchMoveX - touchStartX, 2) + 
                            Math.pow(touchMoveY - touchStartY, 2)
                        );
                        
                        if (dragDistance > 10) {
                            clearTimeout(timer);
                            document.removeEventListener('touchmove', handleTouchMove);
                        }
                    };

                    document.addEventListener('touchmove', handleTouchMove);
                    
                    const clearTimer = () => {
                        clearTimeout(timer);
                        document.removeEventListener('touchmove', handleTouchMove);
                    };

                    document.addEventListener('touchend', clearTimer, { once: true });
                    document.addEventListener('touchcancel', clearTimer, { once: true });
                }}
            >
                <div className="flex items-end gap-2">
                    {message.userType === userInfo.accountTypeCode && (
                        <p className="text-[11px] text-gray-500">
                            {messageDate}
                        </p>
                    )}

                    {message.messageType === "text" && (
                        <div
                            className={`inline-block max-w-[80vw] relative ${message.userType === userInfo.accountTypeCode
                                ? 'bg-[#CF3688] text-white shadow-pink-200'
                                : 'bg-gray-100 text-gray-900 shadow-gray-200'
                                } rounded-2xl px-4 py-2 shadow-sm
                                                        ${message.userType === userInfo.accountTypeCode
                                    ? 'rounded-tr-none'
                                    : 'rounded-tl-none'}`}
                        >
                            <p className="whitespace-pre-wrap break-words text-[13px] leading-relaxed">
                                {message.message}
                            </p>
                        </div>
                    )}
                    {message.messageType === 'image' && (
                        <>
                            {status === 'loading' && (
                                <div className="rounded-lg max-w-[80vw] hover:opacity-95 transition-opacity shadow-sm bg-gray-100 p-4 flex flex-col items-center justify-center gap-2">
                                    <div className="w-6 h-6 border-2 border-gray-300 border-t-gray-600 rounded-full animate-spin"></div>
                                    <p className="text-sm text-gray-500">이미지 로드 중...</p>
                                </div>
                            )}
                            {status === 'error' && (
                                <div className="rounded-lg max-w-[80vw] hover:opacity-95 transition-opacity shadow-sm bg-gray-100 p-4 flex items-center justify-center">
                                    <p className="text-sm text-gray-500">이미지 로드 실패</p>
                                </div>
                            )}
                            {status === 'done' && (
                                <img
                                    src={url}
                                    alt="Uploaded"
                                    className="rounded-lg max-w-[80vw] hover:opacity-95 transition-opacity shadow-sm"
                                    onLoad={onLoadedImage}
                                />
                            )}
                        </>
                    )}
                    {message.messageType === 'video' && (
                        <>
                            {status === 'error' ? (
                                <div className="rounded-lg max-w-[80vw] hover:opacity-95 transition-opacity shadow-sm bg-gray-100 p-4 flex items-center justify-center">
                                    <p className="text-sm text-gray-500">비디오 로드 실패</p>
                                </div>
                            ) :
                                !url ? (
                                    <div
                                        onClick={handleVideoPlay}
                                        className="rounded-lg max-w-[80vw] hover:opacity-95 transition-opacity shadow-sm bg-gray-100 p-4 flex flex-col items-center justify-center gap-2 cursor-pointer"
                                    >
                                        <svg className="w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                ) : (
                                    <video
                                        ref={videoRef}
                                        src={url}
                                        controls
                                        className="rounded-lg max-w-[80vw] hover:opacity-95 transition-opacity shadow-sm"
                                        onError={handleVideoError}
                                        onLoadedData={onLoadedVideo}
                                    />
                                )}
                        </>
                    )}
                    {message.userType !== userInfo.accountTypeCode && (
                        <p className="text-[11px] text-gray-500">
                            {messageDate}
                        </p>
                    )}
                </div>
            </div>

            {showTooltip && (
                <>
                    <div
                        className="fixed inset-0 bg-black/20 z-1000"
                        onClick={handleTooltipClose}
                    />
                    <div className={`absolute z-50 bg-white rounded-lg shadow-lg py-1 ${isMyMessage ? 'right-0' : 'left-0'
                        } bottom-0 translate-y-full min-w-[120px]`}>
                        <button
                            className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                            onClick={handleCopyMessage}
                        >
                            메시지 복사
                        </button>
                        {isMyMessage ? (
                            <button
                                className="w-full px-4 py-2 text-left text-sm text-red-500 hover:bg-gray-100"
                                onClick={handleDeleteMessage}
                            >
                                메시지 삭제
                            </button>
                        ) : (
                            <button
                                className="w-full px-4 py-2 text-left text-sm text-red-500 hover:bg-gray-100"
                                onClick={handleReportMessage}
                            >
                                메시지 신고
                            </button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default memo(ChatItemComponent);