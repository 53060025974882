import React from 'react';
import { usePopup } from 'contexts/PopupContext';

const PopupManager: React.FC = () => {
    const { popups, hidePopup } = usePopup();

    if (popups.length === 0) return null;

    return (
        <>
            {popups.map((popupContent, index) => (
                <div key={index} className={`z-${100 + index}`}>
                    {React.cloneElement(popupContent as React.ReactElement,
                        {
                            onClose: () => {
                                hidePopup(index);
                            }
                        })}
                </div>
            ))}
        </>
    );
};

export default PopupManager; 