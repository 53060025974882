//* 팔로우 관련 API
import * as BaseApi from "api/Base.api";

//* 팔로우 추가
//  2025.02.25
//  희문
//* Params
//  userStudentId: number 팔로우할 사용자 아이디
export const addFollow = async (userStudentId: number) => {
  const url = `/student/follow`;
  return await BaseApi.post(url, { userStudentId });
};

//* 팔로우 취소
//  2025.02.25
//  희문
//* Params
//  userStudentId: number 팔로우할 사용자 아이디
export const cancelFollow = async (userStudentId: number) => {
  const url = `/student/follow/${userStudentId}`;
  return await BaseApi.remove(url);
};

//* 팔로잉 목록 조회
//  2025.02.25
//  희문
//* Params
//  page: number 페이지 번호
//  limit: number 페이지 당 아이템 수
//  userStudentId: number 사용자 아이디
export const getFollowingList = async (page: number, limit: number, userStudentId: number) => {
  const url = `/student/follow/following?page=${page}&limit=${limit}&userStudentId=${userStudentId}`;
  return await BaseApi.get(url);
};

//* 팔로워 목록 조회
//  2025.02.25
//  희문
//* Params
//  page: number 페이지 번호
//  limit: number 페이지 당 아이템 수
//  userStudentId: number 사용자 아이디
export const getFollowerList = async (page: number, limit: number, userStudentId: number) => {
  const url = `/student/follow/follower?page=${page}&limit=${limit}&userStudentId=${userStudentId}`;
  return await BaseApi.get(url);
};

//* 내 팔로우 목록 조회
//  2025.02.25
//  희문
//* Params
//  page: number 페이지 번호
//  limit: number 페이지 당 아이템 수
export const getMyFollowingList = async (page: number, limit: number) => {
  const url = `/student/follow/following/my?page=${page}&limit=${limit}`;
  return await BaseApi.get(url);
};

//* 내 팔로워 목록 조회
//  2025.02.25
//  희문
//* Params
//  page: number 페이지 번호
//  limit: number 페이지 당 아이템 수
export const getMyFollowerList = async (page: number, limit: number) => {
  const url = `/student/follow/follower/my?page=${page}&limit=${limit}`;
  return await BaseApi.get(url);
};

//* 추천 팔로잉 목록
//  2025.03.26
//  희문
//* Params
//  page: number 페이지 번호
//  limit: number 페이지 당 아이템 수
export const getRecommendFollowingList = async (page: number, limit: number) => {
  const url = `/student/follow/following/recommend?page=${page}&limit=${limit}`;
  return await BaseApi.get(url);
};
