import React from "react";
import { getImageUrl } from "helpers/Image.helper";
import { formatTimeAgo } from "helpers/Datetime.helper";
const PhotosPopupComponent = (props: any) => {
    const { selectedPhoto, onClose, isOnNetworking, photosList, handleDelete, isOnLoading, info } = props;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="relative max-w-4xl w-full max-h-[90vh] overflow-y-auto">
                <div className="relative flex flex-col items-center justify-center p-4 bg-white rounded-lg">
                    <img
                        src={getImageUrl(selectedPhoto.imageInfo)}
                        alt={selectedPhoto.title}
                        className="w-full h-auto rounded-lg max-h-[70vh] object-contain"
                    />
                    <button
                        className="absolute top-0 right-0 bg-white rounded-full p-2"
                        onClick={onClose}
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>

                    {/* 사진 아래 툴바 영역 */}
                    <div className="w-full mt-3 px-2 py-2 bg-gray-100 rounded-lg flex justify-between items-center">
                        <div className="flex items-center">
                            <svg className="w-5 h-5 text-gray-600 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                            <span className="text-sm font-medium text-gray-700">{selectedPhoto.viewCount || 0}</span>
                        </div>

                        {!isOnNetworking && (
                            <button
                                className="text-[#cf3688] rounded-md p-1 text-sm flex items-center"
                                onClick={() => handleDelete(selectedPhoto.id)}
                            >
                                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </button>
                        )}
                    </div>
                    {/* 사진을 본 기획사 목록 영역 */}
                    {!isOnLoading && info?.userStudentImageViewLogList && info.userStudentImageViewLogList.length > 0 && (
                        <div className="w-full mt-4 bg-gray-50 rounded-md p-4">
                            <h3 className="text-base font-medium text-gray-800 mb-3">이 사진을 본 기획사</h3>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                                {info.userStudentImageViewLogList.map((log: any, index: number) => (
                                    <div key={index} className="flex items-center">
                                        <div className="w-12 h-12 flex-shrink-0 rounded-md overflow-hidden flex items-center justify-center bg-gray-100 border border-gray-200">
                                            <img
                                                src={getImageUrl(log?.viewerUserInfo?.userAgencyInfo?.agencyInfo.logoImageInfo)}
                                                alt={log?.viewerUserInfo?.userAgencyInfo.agencyInfo.nameEn}
                                                className="object-contain w-full h-full"
                                            />
                                        </div>
                                        <div className="ml-4 flex flex-col">
                                            <p className="text-sm font-medium text-gray-900">{log?.viewerUserInfo?.userAgencyInfo.agencyInfo.nameEn}</p>
                                            <p className="text-xs font-light text-gray-500">
                                                {formatTimeAgo(log.createdAt)}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {isOnLoading && (
                        <div className="w-full mt-4 bg-gray-50 rounded-md p-4">
                            <div className="flex items-center justify-center">
                                <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse"></div>
                                <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse"></div>
                                <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse"></div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default PhotosPopupComponent;