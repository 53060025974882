import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

const StudentSignupComponent = (props: any) => {
    const {
        btnRef,
        accountParams,
        studentParams,
        parentEmail,
        setParentEmail,
        checkIsUnder14,
        termList,
        imageInfo,
        imageInfoList,
        isOnNetworking,
        uploadImage,
        onClickBtnDeleteAdditionalImage,
        onChangeInput,
        onChangeCheckbox,
        onClickBtnCancelSignup,
        onClickBtnSignup,
    } = props;

    return (
        <>
            <main className="bg-gray-50 min-h-screen">
                <form>
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                        <div className="flex items-center justify-between mb-8">
                            <h2 className="text-3xl font-bold text-gray-900">
                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtTitle")}
                            </h2>
                        </div>

                        {/* 계정 정보 섹션 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
                            <div className="p-6">
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAccount.txtTitle")}
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAccount.txtSubTitle")}
                                    </p>
                                </div>

                                <div className="space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="text-red-500">*</span>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAccount.txtEmail")}
                                        </label>
                                        <input
                                            type="email"
                                            value={accountParams.username}
                                            onChange={(evt) => onChangeInput("username", evt.target.value)}
                                            required
                                            className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                            placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAccount.txtEmailPlaceholder")}
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="text-red-500">*</span>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAccount.txtPassword")}
                                        </label>
                                        <input
                                            type="password"
                                            value={accountParams.password}
                                            onChange={(evt) => onChangeInput("password", evt.target.value)}
                                            required
                                            className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                            placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAccount.txtPasswordPlaceholder")}
                                            minLength={8}
                                            maxLength={16}
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="text-red-500">*</span>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAccount.txtConfirmPassword")}
                                        </label>
                                        <input
                                            type="password"
                                            value={accountParams.confirmedPassword}
                                            onChange={(evt) => onChangeInput("confirmedPassword", evt.target.value)}
                                            required
                                            className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                            placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAccount.txtConfirmPasswordPlaceholder")}
                                            minLength={8}
                                            maxLength={16}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
                            <div className="p-6">
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtTitle")}
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtSubTitle")}
                                    </p>
                                </div>


                                <div className="space-y-6">
                                    <div className="flex justify-center">
                                        <div className="relative w-40 h-40">
                                            <div
                                                className="w-full h-full rounded-full bg-center bg-cover"
                                                style={{
                                                    backgroundImage: `url(${imageInfo?.url || "./../../imgs/icons/camera.png"})`
                                                }}
                                            />
                                            <input
                                                type="file"
                                                id="input-file-img-profile"
                                                className="hidden"
                                                onChange={(evt) => uploadImage("profile", evt)}
                                                accept="image/*"
                                            />
                                            <label
                                                htmlFor="input-file-img-profile"
                                                className="absolute bottom-0 right-0 bg-[#CF3688] text-white p-2 rounded-full cursor-pointer hover:bg-[#B52E74] transition-colors"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                                </svg>
                                            </label>
                                        </div>
                                    </div>

                                    {/* 국적 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="text-red-500">*</span>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtCountry")}
                                        </label>
                                        <select
                                            value={studentParams.countryCode}
                                            onChange={(evt) => onChangeInput("countryCode", evt.target.value)}
                                            className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                        >
                                            <option value="" disabled selected>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtCountryOptional")}
                                            </option>
                                            {commonData.getCountryCodeList().map((item: any, idx: number) => (
                                                <option key={idx} value={item.code}>
                                                    {getCurrentLangName(item.names)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* 이름 */}
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                <span className="text-red-500">*</span>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtFirstName")}
                                            </label>
                                            <input
                                                type="text"
                                                value={studentParams.firstName}
                                                onChange={(evt) => onChangeInput("firstName", evt.target.value)}
                                                className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                                placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtFirstNamePlaceholder")}
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                <span className="text-red-500">*</span>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtLastName")}
                                            </label>
                                            <input
                                                type="text"
                                                value={studentParams.lastName}
                                                onChange={(evt) => onChangeInput("lastName", evt.target.value)}
                                                className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                                placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtLastNamePlaceholder")}
                                            />
                                        </div>
                                    </div>

                                    {/* 생년월일 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="text-red-500">*</span>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtBirthday")}
                                        </label>
                                        <input
                                            type="date"
                                            value={studentParams.birthday}
                                            onChange={(evt) => onChangeInput("birthday", evt.target.value)}
                                            data-placeholder="YYYY-MM-DD"
                                            aria-required="true"
                                            className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                            placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtBirthdayPlaceholder")}
                                        />
                                    </div>

                                    {/* 부모 이메일 */}
                                    {checkIsUnder14(studentParams.birthday) && (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                <span className="text-red-500">*</span>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtParentEmail")}
                                            </label>
                                            <input
                                                type="text"
                                                value={parentEmail}
                                                onChange={(evt) => setParentEmail(evt.target.value.trim())}
                                                className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                                placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtParentEmailPlaceholder")}
                                            />
                                        </div>
                                    )}

                                    {/* 성별 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="text-red-500">*</span>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtGender")}
                                        </label>
                                        <div className="flex gap-6">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="genderTypeCode"
                                                    value="genderType:girl"
                                                    onChange={(evt) => onChangeInput("genderTypeCode", evt.target.value)}
                                                    className="h-4 w-4 text-[#CF3688] focus:ring-[#CF3688]"
                                                />
                                                <span className="ml-2 text-gray-700">
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtGirl")}
                                                </span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="genderTypeCode"
                                                    value="genderType:boy"
                                                    onChange={(evt) => onChangeInput("genderTypeCode", evt.target.value)}
                                                    className="h-4 w-4 text-[#CF3688] focus:ring-[#CF3688]"
                                                />
                                                <span className="ml-2 text-gray-700">
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupEssential.txtBoy")}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
                            <div className="p-6">
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtTitle")}
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtSubTitle")}
                                    </p>
                                </div>

                                <div className="space-y-6">
                                    {/* 닉네임 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtNickname")}
                                        </label>
                                        <p className="text-sm text-gray-500 mb-2">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtNicknameDesc")}
                                        </p>
                                        <input
                                            type="text"
                                            value={studentParams.nickname}
                                            onChange={(evt) => onChangeInput("nickname", evt.target.value)}
                                            className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base"
                                            placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtNicknamePlaceholder")}
                                        />
                                    </div>

                                    {/* 키 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtHeight")}
                                        </label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                value={studentParams.height}
                                                onChange={(evt) => onChangeInput("height", evt.target.value)}
                                                className="block w-full px-4 py-3 pr-12 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base"
                                                placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtHeightPlaceholder")}
                                                onKeyPress={(evt) => {
                                                    if (!/[0-9]/.test(evt.key)) {
                                                        evt.preventDefault();
                                                    }
                                                }}
                                            />
                                            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                                <span className="text-gray-500">cm</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 몸무게 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtWeight")}
                                        </label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                value={studentParams.weight}
                                                onChange={(evt) => onChangeInput("weight", evt.target.value)}
                                                className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base"
                                                placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtWeightPlaceholder")}
                                                onKeyPress={(evt) => {
                                                    if (!/[0-9]/.test(evt.key)) {
                                                        evt.preventDefault();
                                                    }
                                                }}
                                            />
                                            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                                <span className="text-gray-500">kg</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 신발 사이즈 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtShoeSize")}
                                        </label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                value={studentParams.shoeSize}
                                                onChange={(evt) => onChangeInput("shoeSize", evt.target.value)}
                                                className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base"
                                                placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtShoeSizePlaceholder")}
                                                onKeyPress={(evt) => {
                                                    if (!/[0-9]/.test(evt.key)) {
                                                        evt.preventDefault();
                                                    }
                                                }}
                                            />
                                            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                                <span className="text-gray-500">mm</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* MBTI */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtMbti")}
                                        </label>
                                        <select
                                            value={studentParams.mbtiCode}
                                            onChange={(evt) => onChangeInput("mbtiCode", evt.target.value)}
                                            className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base"
                                        >
                                            <option value="" disabled selected>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtMbtiOptional")}
                                            </option>
                                            <option value="">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtNotSetOptional")}</option>
                                            {commonData.getMbtiCodeList().map((item: any, idx: number) => (
                                                <option key={idx} value={item.code}>
                                                    {item.names.en}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* 혈액형 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtBloodType")}
                                        </label>
                                        <div className="flex flex-wrap gap-4">
                                            <select
                                                value={studentParams.bloodTypeCode}
                                                onChange={(evt) => onChangeInput("bloodTypeCode", evt.target.value)}
                                                className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base"
                                            >
                                                <option value="">
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtNotSetOptional")}
                                                </option>
                                                {["A", "B", "O", "AB"].map((type) => (
                                                    <option key={type} value={`bloodType:${type.toLowerCase()}`}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* 특기 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtSpecialty")}
                                        </label>
                                        <div className="space-y-2">
                                            <label className="inline-flex items-center mr-6">
                                                <input
                                                    type="checkbox"
                                                    onChange={(evt) => onChangeCheckbox("vocal", null, evt.target.checked)}
                                                    value="specialty:vocal"
                                                    className="h-4 w-4 text-[#CF3688] focus:ring-[#CF3688] rounded"
                                                />
                                                <span className="ml-2">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtVocal")}</span>
                                            </label>
                                            <label className="inline-flex items-center mr-6">
                                                <input
                                                    type="checkbox"
                                                    onChange={(evt) => onChangeCheckbox("dance", null, evt.target.checked)}
                                                    value="specialty:dance"
                                                    className="h-4 w-4 text-[#CF3688] focus:ring-[#CF3688] rounded"
                                                />
                                                <span className="ml-2">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtDance")}</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    onChange={(evt) => onChangeCheckbox("rap", null, evt.target.checked)}
                                                    value="specialty:rap"
                                                    className="h-4 w-4 text-[#CF3688] focus:ring-[#CF3688] rounded"
                                                />
                                                <span className="ml-2">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignupAdditional.txtRap")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* 팬 페이지 정보 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-8">
                            <div className="p-6">
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupFanPage.txtTitle")}
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupFanPage.txtSubTitle")}
                                    </p>
                                </div>

                                <div className="p-4 bg-gray-50 rounded-xl">
                                    <div className="flex items-center space-x-2 mb-4">
                                        <span className="text-gray-500 break-all">
                                            {process.env.REACT_APP_FAN_PAGE_URL}/@{studentParams.slug}
                                        </span>
                                    </div>
                                    <div>
                                        <div className="relative">
                                            <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">@</span>
                                            <input
                                                type="text"
                                                value={studentParams.slug}
                                                onChange={(evt) => onChangeInput("slug", evt.target.value)}
                                                className="w-full pl-8 pr-4 py-2 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688]"
                                                placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignupFanPage.txtSlugSubTitle")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-2 text-sm text-gray-500">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignupFanPage.txtSlugDesc")}</p>
                            </div>
                        </section>

                        {/* 긴급 연락처 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-8">
                            <div className="p-6">
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupContact.txtTitle")}
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupContact.txtSubTitle")}
                                        <br />
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupContact.txtSubTitle2")}
                                    </p>
                                </div>

                                <div className="space-y-4">
                                    {/* 연락처 유형 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="text-red-500">*</span>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupContact.txtContactType")}
                                        </label>
                                        <select
                                            value={studentParams.contactTypeCode}
                                            onChange={(evt) => onChangeInput("contactTypeCode", evt.target.value)}
                                            className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688]"
                                        >
                                            <option value="" disabled selected>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupContact.txtContactSelect")}
                                            </option>
                                            {commonData.getCommonCodeListByParent("contactType").map((item: any, idx: number) => (
                                                <option key={idx} value={item.code}>
                                                    {getCurrentLangName(item.names)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* 연락처 상세 */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="text-red-500">*</span>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupContact.txtContactDetail")}
                                        </label>
                                        <input
                                            type="text"
                                            value={studentParams.contact}
                                            onChange={(evt) => onChangeInput("contact", evt.target.value)}
                                            placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtContactDetailDesc")}
                                            className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688]"
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* 약관 목록 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-8">
                            <div className="p-6">
                                <div className="space-y-4">
                                    {/* 약관 목록 */}
                                    {termList.map((item: any, idx: number) => (
                                        <div key={`term-${idx}`} className="flex items-center justify-between">
                                            <label className="flex items-center space-x-3">
                                                <input
                                                    type="checkbox"
                                                    onChange={(evt) => onChangeCheckbox(null, idx, evt.target.checked)}
                                                    checked={item.checked}
                                                    className="h-4 w-4 text-[#CF3688] focus:ring-[#CF3688] rounded"
                                                />
                                                <span className="text-sm text-gray-700">
                                                    ({item.isRequired
                                                        ? getLanguageValue(PAGE_TYPE.COMMON, "studentSignupTerms.txtRequired")
                                                        : getLanguageValue(PAGE_TYPE.COMMON, "studentSignupTerms.txtOptional")
                                                    }) {item.title}
                                                </span>
                                            </label>
                                            {item.termsTypeCode !== "termsType:over14" && (
                                                <button
                                                    type="button"
                                                    onClick={() => window.open(item.isRequired ? `/term/common/${item.id}` : `/term/etc/${item.id}`)}
                                                    className="text-sm text-[#CF3688] hover:text-[#B52E74] transition-colors"
                                                >
                                                    ({getLanguageValue(PAGE_TYPE.COMMON, "studentSignupTerms.txtShow")})
                                                </button>
                                            )}
                                        </div>
                                    ))}

                                    <div className="pt-4 border-t">
                                        <label className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                onChange={(evt) => onChangeCheckbox(null, "all", evt.target.checked)}
                                                checked={!termList.find((i: any) => !i.checked)}
                                                className="h-4 w-4 text-[#CF3688] focus:ring-[#CF3688] rounded"
                                            />
                                            <span className="text-sm font-medium text-gray-900">
                                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupTerms.txtAgreeWithAllTerms")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={onClickBtnCancelSignup}
                                className="px-6 py-3 text-gray-700 bg-gray-100 rounded-xl hover:bg-gray-200 transition-colors"
                            >
                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.btnCancel")}
                            </button>
                            <button
                                type="button"
                                ref={btnRef}
                                onClick={onClickBtnSignup}
                                disabled={isOnNetworking}
                                className="px-6 py-3 text-white bg-[#CF3688] rounded-xl hover:bg-[#B52E74] transition-colors disabled:opacity-50"
                            >
                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.btnSignup")}
                            </button>
                        </div>
                    </div>
                </form>
            </main>
        </>
    );
};

export default StudentSignupComponent;
