import React, { useEffect } from "react";
import AgencyInfoPopupOfChatComponent from "./AgencyInfoPopupOfChat.component";
import { useNetwork } from "contexts/NetworkContext";
import { useState } from "react";
import { fetchAgencyInfoByCastingManager } from "api/student/v2/popup.api";

const AgencyInfoPopupOfChatContainer = (props: any) => {
    const { userAgencyId, onClose } = props;
    const { isOnNetworking, setIsOnNetworking } = useNetwork();
    const [isInit, setIsInit] = useState(false);
    const [info, setInfo]: any = useState({});

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const data = await fetchAgencyInfoByCastingManager(userAgencyId);
            if (data?.info) {
                setInfo(data?.info);
            }
        } catch (error) {
            console.error('Failed to fetch agency info:', error);
        } finally {
            setIsInit(true);
        }
    }


    return <AgencyInfoPopupOfChatComponent
        isInit={isInit}
        info={info}
        isOnNetworking={isOnNetworking}
        onClose={onClose}
    />;
};

export default AgencyInfoPopupOfChatContainer;  