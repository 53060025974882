import React, { useEffect, useState } from "react";
import CommonHeaderComponent from "./CommonHeader.component";
import CommonHeaderV2Component from "./CommonHeaderV2.component";
import AgencyHeaderComponent from "./AgencyHeader.component";
import SchoolHeaderComponent from "./SchoolHeader.component";
import StudentHeaderComponent from "./StudentHeader.component";
import { PAGE_TYPE } from "enums";
import { useDispatch, useSelector } from "react-redux";
import { removeAuthTokens } from "helpers/Auth.helper";
import { setInfo as setUserInfo, setIsLogin } from "reducers/User";
import { useLocation, useNavigate } from "react-router-dom";
import * as NotificationApi from "api/notification/Notification.api";
import { listenForForegroundMessages, notificationListener, removePushToken } from "helpers/Firebase.helper";
import { getPopupList } from "api/bbs/Notice.api";
import { getLanguageValue } from "locales/i18n";
import { getCookie, setCookie, getPopupKey, getLastLoginEmail } from "helpers/Cookie.helper";
import { removeAppAccessToken } from "helpers/appLocalStorageHelper";
import CommonHeaderComponentV2 from "./CommonHeaderV2.component";
import { getUserType } from "helpers/Common.helper";
import { setBadgeCount } from "helpers/InApp.helper";

const HeaderContainer = (props: any) => {
    const { pageType } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isApp } = useSelector((state: any) => state.common);
    const [mobileMenuSlideClassName, setMobileMenuSlideClassName] = useState<string>("");

    const [mobileNotificationSlideClassName, setMobileNotificationMenuSlideClassName] = useState<string>("");

    const [showNotification, setShowNotification] = useState(false);
    const location = useLocation();
    const go = (target: string) => navigate(target);
    const render = (path: string) => {
        setMobileMenuSlideClassName("off");
        setMobileNotificationMenuSlideClassName("off");
        go(path);
    };
    const { isLogin, info: userInfo } = useSelector((state: any) => state.user);

    const limit = 10;
    const [page, setPage] = useState(1);
    const [notificationList, setNotificationList] = useState([]);
    const [totalCount, setTotalcount] = useState(0);
    const [totalUnReadCount, setTotalUnReadCount] = useState(0);
    const [noticePoupList, setNoticePoupList] = useState([]);

    const [consoleUrl, setConsoleUrl] = useState("");
    const [nextUrl, setNextUrl] = useState("");

    const logout = async () => {
        await removePushToken();
        removeAuthTokens();
        dispatch(setIsLogin(false));
        dispatch(setUserInfo({}));
        navigate("/", { replace: true });
        await removeAppAccessToken();
    };

    const getNoticePopupList = async () => {
        const result = await getPopupList();

        if (result?.list) {
            const popupList = [];

            for (const item of result.list) {
                const key = getPopupKey(item.id);
                const cookieValue = getCookie(key);

                if (!cookieValue) {
                    popupList.push(item);
                }
            }

            setNoticePoupList(popupList);
        }
    };

    const getNotificationList = async () => {
        const params = { page, limit };
        const result = await NotificationApi.getList(params);

        if (result?.list) {
            setNotificationList(result.list);
            setTotalcount(result.totalCount);

            const unreadCount = result.unreadCount;
            setTotalUnReadCount(unreadCount);

            // 뱃지 업데이트
            if ("setAppBadge" in navigator && "clearAppBadge" in navigator) {
                if (unreadCount && unreadCount > 0) {
                    (navigator as any).setAppBadge(unreadCount);
                } else {
                    (navigator as any).clearAppBadge();
                }
            }

            // 앱 뱃지 업데이트(ios)
            if (isApp) {
                setBadgeCount(unreadCount);
            }
        }
    };

    const onClickCheckNotification = async (item: any) => {
        if (item.isRead === 0) {
            await NotificationApi.updateReadInfo(item.id, {});
        }

        if (item.action || item.linkUrl) {
            if (item.type === "action") {
                if (item.action === "reload") {
                    window.location.reload();
                }
            }
            if (item.type === "link") {
                if (item.linkUrl) {
                    window.location.href = item.linkUrl;
                }
            }
        } else {
            if (pageType === PAGE_TYPE.AGENCY) {
                render("/agency/notification");
            } else if (pageType === PAGE_TYPE.SCHOOL) {
                render("/school/notification");
            } else if (pageType === PAGE_TYPE.STUDENT) {
                render("/student/notification");
            } else {
                render("/");
            }
        }
    };

    useEffect(() => {
        const lastLoginEmail = getLastLoginEmail();
        if (lastLoginEmail) {
            setNextUrl(`/login`);
        } else {
            setNextUrl(`/signup`);
        }
    }, []);

    useEffect(() => {
        if (isLogin) {
            getNotificationList();
            setShowNotification(false);

            //   listenForForegroundMessages(() => {
            //     getNotificationList();
            //   });
            listenForForegroundMessages();
            notificationListener.on("notification", getNotificationList);


            const userAccountType = getUserType(userInfo?.accountTypeCode);
            setConsoleUrl(`/${userAccountType}`);

            return () => {
                notificationListener.off("notification", getNotificationList);
            };
        }
    }, [location]);

    useEffect(() => {
        const handleVisibilityChange = async () => {
            // foreground로 돌아 왔을 때 알림 재 조회
            if (document.visibilityState === "visible" && isLogin) {
                await getNotificationList();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    // 알림 클릭했을 때 재 조회
    useEffect(() => {
        if (showNotification) {
            getNotificationList();
        }
    }, [showNotification]);

    useEffect(() => {
        if (mobileNotificationSlideClassName === "on") {
            getNotificationList();
        }
    }, [mobileNotificationSlideClassName]);

    useEffect(() => {
        if (isLogin) {
            getNoticePopupList();
        }
    }, [isLogin]);

    return (
        <>
            {pageType === PAGE_TYPE.COMMON ? (
                // <CommonHeaderComponent
                //     {...props}
                //     logout={logout}
                //     render={render}
                //     mobileMenuSlideClassName={mobileMenuSlideClassName}
                //     setMobileMenuSlideClassName={setMobileMenuSlideClassName}
                //     userInfo={userInfo}
                // />
                <CommonHeaderComponentV2
                    {...props}
                    logout={logout}
                    render={render}
                    mobileMenuSlideClassName={mobileMenuSlideClassName}
                    setMobileMenuSlideClassName={setMobileMenuSlideClassName}
                    userInfo={userInfo}
                    consoleUrl={consoleUrl}
                    nextUrl={nextUrl}
                />
            ) : null}

            {pageType === PAGE_TYPE.AGENCY ? (
                <AgencyHeaderComponent
                    {...props}
                    logout={logout}
                    render={render}
                    mobileMenuSlideClassName={mobileMenuSlideClassName}
                    setMobileMenuSlideClassName={setMobileMenuSlideClassName}
                    showNotification={showNotification}
                    setShowNotification={setShowNotification}
                    notificationList={notificationList}
                    totalUnReadCount={totalUnReadCount}
                    onClickCheckNotification={onClickCheckNotification}
                    mobileNotificationSlideClassName={mobileNotificationSlideClassName}
                    setMobileNotificationMenuSlideClassName={setMobileNotificationMenuSlideClassName}
                />
            ) : null}

            {pageType === PAGE_TYPE.SCHOOL ? (
                <SchoolHeaderComponent
                    {...props}
                    logout={logout}
                    render={render}
                    mobileMenuSlideClassName={mobileMenuSlideClassName}
                    setMobileMenuSlideClassName={setMobileMenuSlideClassName}
                    showNotification={showNotification}
                    setShowNotification={setShowNotification}
                    notificationList={notificationList}
                    totalUnReadCount={totalUnReadCount}
                    onClickCheckNotification={onClickCheckNotification}
                    mobileNotificationSlideClassName={mobileNotificationSlideClassName}
                    setMobileNotificationMenuSlideClassName={setMobileNotificationMenuSlideClassName}
                />
            ) : null}

            {pageType === PAGE_TYPE.STUDENT ? (
                <StudentHeaderComponent
                    {...props}
                    logout={logout}
                    render={render}
                    mobileMenuSlideClassName={mobileMenuSlideClassName}
                    setMobileMenuSlideClassName={setMobileMenuSlideClassName}
                    showNotification={showNotification}
                    setShowNotification={setShowNotification}
                    notificationList={notificationList}
                    totalUnReadCount={totalUnReadCount}
                    onClickCheckNotification={onClickCheckNotification}
                    mobileNotificationSlideClassName={mobileNotificationSlideClassName}
                    setMobileNotificationMenuSlideClassName={setMobileNotificationMenuSlideClassName}
                />
            ) : null}
            {noticePoupList && noticePoupList?.length > 0
                ? noticePoupList.map((item: any, index: number) => (
                    <div key={index} className="popup popup-notice">
                        <div className="con">
                            <div className="wrap">
                                <h2>{item.title}</h2>
                                <div className="notice-info">
                                    {item.attachedPcImageUrl ? (
                                        <div className="img pc">
                                            <img className="pc" src={item.attachedPcImageUrl} alt={item.title} />
                                        </div>
                                    ) : null}

                                    {item.attachedMobileImageUrl ? (
                                        <div className="img mb">
                                            <img className="mb" src={item.attachedMobileImageUrl} alt={item.title} />
                                        </div>
                                    ) : null}

                                    <div className="cont">
                                        <p>
                                            {item.content.split("\n").map((line: any, index: any) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                                <div className="btn">
                                    <button
                                        onClick={(e) => {
                                            const expires = new Date();
                                            expires.setFullYear(expires.getFullYear() + 100);
                                            
                                            const key = getPopupKey(item.id);
                                            setCookie(key, "Y", expires);

                                            const newPoupList = noticePoupList.filter((v) => v.id !== item.id);
                                            setNoticePoupList(newPoupList);
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "messageDetail.txtClose")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                : null}
        </>
    );
};
export default HeaderContainer;
