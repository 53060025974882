import FullPageLoadingComponent from "commons/loading/FullPageLoading.component";
import { PAGE_TYPE } from "enums";
import { formattedDateTimeForLocale, formatTimeAgo } from "helpers/Datetime.helper";
import { getImageUrl } from "helpers/Image.helper";
import { getStudentName } from "helpers/String.helper";
import { getLanguageValue } from "locales/i18n";
import React from "react";
import { Link } from "react-router-dom";

const IndexComponent = (props: any) => {
    const { isLoading, roomList, requestList, onRequestClick } = props;
    return (
        <main className="bg-gray-50 min-h-screen">
            {isLoading && <FullPageLoadingComponent />}
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6">
                {/* 대화 요청 목록 */}
                <div className="mb-8">
                    <h2 className="text-xl font-bold text-gray-900 mb-4">
                        대화 요청 목록
                    </h2>
                    <div className="overflow-x-auto relative">
                        <div className="inline-flex space-x-4 px-4 pb-2">
                            {requestList.map((request: any) => (
                                <div
                                    onClick={() => onRequestClick(request.id)}
                                    key={request.id} className="min-w-[100px]">
                                    <div className="flex flex-col items-center">
                                        <div className="rounded-full overflow-hidden w-14 h-14 sm:w-16 sm:h-16 flex-shrink-0 border-2 border-gray-100">
                                            <img
                                                src={getImageUrl(request.userStudentInfo?.profileImageInfo)}
                                                alt={request.userStudentInfo?.nickname}
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                        <div className="mt-2 text-center w-full">
                                            <h3 className="text-gray-900 text-sm truncate px-2">
                                                {getStudentName(request.userStudentInfo)}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {requestList?.length === 0 &&
                                <div className="text-center py-4 text-gray-500">
                                    요청한 대화가 없습니다.
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/* 대화방 목록 */}
                <div>
                    <h2 className="text-xl font-bold text-gray-900 mb-4">
                        진행중인 대화 목록
                    </h2>
                    {roomList?.length > 0 ? (
                        <div className="space-y-4">
                            {roomList.map((room: any, idx: number) => (
                                <Link
                                    key={idx}
                                    to={`/agency/message/${room.channelId}/chat`}
                                    className="block bg-white p-4 rounded-xl shadow-sm hover:shadow-md transition-shadow">
                                    <div className="flex items-start gap-4 p-2 hover:bg-gray-50 transition-colors rounded-lg">
                                        {/* 왼쪽 프로필 이미지 */}
                                        <div className="w-14 h-14 flex-shrink-0 rounded-full border-2 border-gray-100 overflow-hidden shadow-sm">
                                            <img
                                                src={getImageUrl(room.userStudentInfo?.profileImageInfo)}
                                                alt={`${room.userStudentInfo?.firstName} ${room.userStudentInfo?.lastName}`}
                                                className="w-full h-full object-cover transition-transform hover:scale-110"
                                            />
                                        </div>

                                        {/* 가운데 내용 */}
                                        <div className="flex-1 min-w-0">
                                            <p className="text-sm font-medium text-gray-600 mb-1 truncate">
                                                {room.userStudentInfo?.firstName} {room.userStudentInfo?.lastName}
                                            </p>
                                            <h3 className="text-base text-gray-900 leading-snug truncate">
                                                {room.lastMessage}
                                            </h3>
                                            <p className="text-xs text-gray-400 mt-1.5 flex items-center gap-1">
                                                <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                {formatTimeAgo(room.lastMessageAt)}
                                            </p>
                                        </div>

                                        {/* 오른쪽 읽음여부 */}
                                        <div className="w-8 flex-shrink-0 flex items-start justify-end pt-1">
                                            {!room.isReadAgency && (
                                                <span className="inline-flex items-center justify-center w-3 h-3 bg-[#CF3688] text-white text-xs rounded-full animate-pulse"></span>
                                            )}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center py-8 text-gray-500">
                            {getLanguageValue(PAGE_TYPE.AGENCY, "message.txtNoRoom")}
                        </div>
                    )}
                </div>
            </div>
        </main>
    );
};

export default IndexComponent;