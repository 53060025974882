const ALPHABET_REG = /^[A-Za-z]+$/;
const UPPERCASE_ALPHABET_REG = /^[A-Z]+$/;
const LOWERCASE_ALPHABET_REG = /^[a-z]+$/;
const DIGIT_LOWERCASE_UPPERCASE_SPECIAL_8_16 = `^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]).{8,16}$`;
const DIGIT_ALPHABET_8_20 = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
// const PASSWORD_REG = /^.{8,}$/;
const DIGIT = /^(?=.*[0-9])+$/;
const LOWERCASE = /^(?=.*[a-z])+$/;
const TIME_HHMM = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
const DATETIME_YYYYMMDDHHMM =
  /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]$/;
const DATE_YYYYMMDD = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
const DATE_YYYY = /^[0-9]{4}$/;
const CALENDAR_YYYYMMDD = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
const EMAIL_REG =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const checkIsEmail = (str: string) => {
  return EMAIL_REG.test(str);
};
export const checkAlphabet = (str: string) => {
  return ALPHABET_REG.test(str);
};
export const checkUpperCaseAlphabet = (str: string) => {
  return UPPERCASE_ALPHABET_REG.test(str);
};
export const checkLowerCaseAlphabet = (str: string) => {
  return LOWERCASE_ALPHABET_REG.test(str);
};

// 비밀번호 정규식
export const checkPassword = (str: string) => {
  console.log("reg.helper::checkPassword", str);
  if (process.env.STAGE !== "prod") {
    return true;
  }

  // 비밀번호 길이가 8자 이상인지 확인
  if (str.length < 8) {
    console.log("length");
    return false;
  }

  // 대문자 포함 여부 확인
  if (!/[A-Z]/.test(str)) {
    console.log("uppercase");
    return false;
  }

  // 소문자 포함 여부 확인
  if (!/[a-z]/.test(str)) {
    console.log("lowercase"); 
    return false;
  }

  // 숫자 포함 여부 확인
  if (!/[0-9]/.test(str)) {
    console.log("number");
    return false;
  }

  // 특수문자 포함 여부 확인
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(str)) {
    console.log("specialchar");
    return false;
  }

  // 연속된 숫자나 문자가 있는지 확인
  if (/(\d)\1+/.test(str) || /([a-zA-Z])\1+/.test(str)) {
    console.log("contains any consecutive numbers or letters");
    return false;
  }

  // 모든 검증을 통과하면 true 반환
  return true;

};
export const checkTimeHHMM = (str: string) => {
  return TIME_HHMM.test(str);
};
export const checkDatetimeYYYYMMDDHHMM = (str: string) => {
  console.log("reg.helper:checkDatetimeYYYYMMDDHHMM", str);
  return DATETIME_YYYYMMDDHHMM.test(str);
};
export const checkDateYYYYMMDD = (str: string) => {
  console.log("reg.helper:checkDateYYYYMMDD", str);
  return DATE_YYYYMMDD.test(str);
};
export const checkCalendarYYYYMMDD = (str: string) => {
  console.log("reg.helper:checkCalendarYYYYMMDD", str);
  return CALENDAR_YYYYMMDD.test(str);
};

const ZIPCODE = /^[0-9]{5}$/;
export const checkZipcode = (str: string) => {
  return ZIPCODE.test(str);
};

const CELLPHONE = /^\d{3}-\d{3,4}-\d{4}$/;
export const checkCellphone = (str: string) => {
  return CELLPHONE.test(str);
};

const CELLPHONE_ONLY_NUMBER = /^\d{10,11}$/;
export const checkCellphoneOnlyNumber = (str: string) => {
  return CELLPHONE_ONLY_NUMBER.test(str);
};

// 연도가 형식(YYYY)에 맞는지 확인하는 함수. 이다인(2021.03.11생성)
export const checkDateYYYY = (str: string) => {
  console.log("reg.helper:checkDateYYYY", str);
  return DATE_YYYY.test(str);
};

// Card Number가 형식(nnnn-nnnn-nnnn-nnn)에 맞는지 확인하는 함수. 이다인(2021.03.11생성)
const CARD_NUMBER = /^\d{4}-\d{4}-\d{4}-\d{3}$/;
export const checkCardNumber = (str: string) => {
  console.log("reg.helper:checkCardNumber");
  return CARD_NUMBER.test(str);
};

// Business Number가 형식(nnn-nn-nnnnn)에 맞는지 확인하는 함수. 이다인(2021.03.11생성)
const BUSINESS_REGISTRATION_NUMBER = /^\d{3}-\d{2}-\d{5}$/;
export const checkBusinessRegistrationNumber = (str: string) => {
  console.log("reg.helper:checkBusinessRegistrationNumber");
  return BUSINESS_REGISTRATION_NUMBER.test(str);
};

// 주민등록번호(외국인 등록번호) (nnnnnn-nnnnnnn) 정규식
const SOCIAL_NUMBER_REG =
  /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-][1-6]\d{6}/;
export const checkSocialNumber = (str: string) => {
  return SOCIAL_NUMBER_REG.test(str);
};

// SLUG 정규식
const SLUG_REG = /(^[\w.\-_]{4,24})+$/;
export const checkSlug = (str: string) => {
  return SLUG_REG.test(str);
};

// 학생 슬러그 정규식
const STUDENT_SLUG_REG = /^[a-zA-Z0-9._-]{4,16}$/;
export const checkStudentSlug = (str: string) => {
  return STUDENT_SLUG_REG.test(str);
};
// 학생 슬러그 정규식(텍스트만)
const STUDENT_SLUG_REG_TEXT = /^[a-zA-Z0-9._-]{1,16}$/;
export const checkStudentSlugText = (str: string) => {
  return STUDENT_SLUG_REG_TEXT.test(str);
};

// 0으로 시작하지 않는 숫자
// 0으로 시작하지 않는 숫자
const NUMBER_THAT_DO_NOT_BEGIN_WITH_ZERO = /^([1-9][0-9]*)?$/;
export const checkIsNumberThatDoNotBeginWithZero = (str: string) => {
  return +NUMBER_THAT_DO_NOT_BEGIN_WITH_ZERO.test(str);
};

// 유튜브 ID
const YOUTUBE_ID = /^[a-zA-Z0-9_-]{11}$/;
export const checkYoutubeId = (str: string) => {
  return YOUTUBE_ID.test(str);
};

// 전화번호
const PHONE_NUMBER = /^\d+-\d+-\d+$/;
export const checkPhoneNumber = (str: string) => {
  return PHONE_NUMBER.test(str);
};

// 모든 국가별 전화번호 형식 확인
const PHONE_NUMBER_FORMAT = /^(\+?\d{1,3}[-.]?)?\s*\(?(\d{1,4})\)?[-.\s]*(\d{1,4})[-.\s]*(\d{1,9})$/;
export const checkPhoneNumberFormat = (str: string) => {
  return PHONE_NUMBER_FORMAT.test(str);
};
