import React from "react";
import { getCurrentLangName } from "helpers/String.helper";
import { formatTimeAgo } from "helpers/Datetime.helper";
import LoadingComponent from "commons/loading/Loading.component";
import { useNavigate } from "react-router-dom";
const AlarmTooltipComponent = (props: any) => {
    const { list, onClose, isInit } = props;
    const navigate = useNavigate();
    return (
        <div className="absolute top-full right-0 mt-2 w-[80%] max-w-[400px] bg-white rounded-lg shadow-xl p-4 border border-gray-100">
            {!isInit ?
                <div className="flex justify-center items-center h-full">
                    <LoadingComponent />
                </div>
                : (
                    <>
                        <div className="space-y-4 overflow-hidden">
                            {list.slice(0, 5).map((alarm: any, index: number) => (
                                <div key={index} className="flex items-start gap-3 p-2 hover:bg-gray-50 rounded-lg transition-colors duration-200">
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm text-gray-800 font-medium leading-snug truncate">
                                            {getCurrentLangName(alarm.contents)}
                                        </p>
                                        <p className="text-xs text-gray-500 mt-1 truncate">
                                            {formatTimeAgo(alarm.createdAt)}
                                        </p>
                                    </div>
                                    {!alarm.isRead && (
                                        <div className="w-2.5 h-2.5 rounded-full bg-red-500 mt-1 flex-shrink-0"></div>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="mt-4 pt-4 border-t border-gray-200">
                            <button
                                onClick={() => {
                                    navigate('/agency/alarm');
                                    onClose();
                                }}
                                className="w-full text-center text-sm font-medium text-gray-600 hover:text-blue-600 transition-colors duration-200 py-1 rounded-md hover:bg-gray-50"
                            >
                                모든 알림 보기
                            </button>
                        </div>
                    </>
                )}
        </div>
    );
};

export default AlarmTooltipComponent;