import { BAD_WORDS, F_WORDS } from "data/badWords.data";
import { getCurrentLanguage } from "locales/i18n";

// 세자리 마다 comma를 붙인 숫자 리턴
export const getNumberWithComma = (value: number | string) => {
    if (!value) {
        return value;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//* 현재 언어에 맞는 문자열 리턴
// 예) {ko: "한글", en: "Korean"} -> "한글"
export const getCurrentLangName = (json: any) => {
    if (!json) {
        return "";
    }
    const currentLang = getCurrentLanguage();

    return json[currentLang] ? json[currentLang] : json["en"];
};

//* en 문자열 리턴
export const getEnName = (json: any) => (json["en"] ? json["en"] : "");

export const getOnlyNumberValue = (value: string) => value.replace(/[^0-9]/g, "");

export const isBadWord = (value: string) => {
    if (!value) {
        return false;
    }

    return BAD_WORDS.some((word: string) => value.toLowerCase().includes(word.toLowerCase()));
};

export const isFWord = (value: string) => {
    if (!value) {
        return false;
    }

    return F_WORDS.some((word: string) => value.toLowerCase().includes(word.toLowerCase()));
};

export function censorText(inputText: string) {
    if (!inputText) {
        return inputText
    }
    F_WORDS.forEach((word: string) => {
        const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // 정규식 특수문자 이스케이프
        const regex = new RegExp(escapedWord, "gi"); // 대소문자 구분 없이 치환
        inputText = inputText.replace(regex, "ㅁㅁㅁ"); // 금칙어를 'xxx'로 치환
    });
    return inputText;
}

export function getStudentNickname(studentInfo: any, defaultNickname: string = "Unknown") {
    if (!studentInfo) {
        return defaultNickname;
    } else if (studentInfo.nickname) {
        return studentInfo.nickname;
    } else {
        return defaultNickname || getStudentName(studentInfo);
    }
}
export function getStudentName(studentInfo: any) {
    if (!studentInfo) {
        return "Unknown";
    }

    const countryCode = studentInfo?.countryCode || studentInfo?.countryInfo?.code;

    if (countryCode) {
        if (countryCode === "KR") {
            return `${studentInfo.firstName}`;
        } else if (countryCode === "US") {
            return `${studentInfo.firstName}`;
        } else if (countryCode === "JP") {
            return `${studentInfo.lastName}`;
        } else if (countryCode === 'ID') {
            return `${studentInfo.firstName}`;
        } else if (countryCode === "TH") {
            return `${studentInfo.firstName}`;
        } else if (countryCode === "VN") {
            return `${studentInfo.firstName}`;
        } else if (countryCode === "CN") {
            return `${studentInfo.lastName}`;
        } else {
            return `${studentInfo.firstName}`;
        }
    } else {
        if (studentInfo.firstName && studentInfo.lastName) {
            return studentInfo.firstName + " " + studentInfo.lastName;
        } else if (studentInfo.firstName) {
            return studentInfo.firstName;
        } else if (studentInfo.lastName) {
            return studentInfo.lastName;
        } else {
            return "WANNABE";
        }
    }
}

export function getFanPageUrl(studentInfo: any) {
    if (studentInfo?.slug) {
        return `${process.env.REACT_APP_FAN_PAGE_URL}/@${studentInfo.slug}`;
    } else {
        return `#`;
    }
}

export function getFanPageUrlBySlug(slug: string) {
    return `${process.env.REACT_APP_FAN_PAGE_URL}/@${slug}`;
}

export function getFlagByCountryCode(countryCode: string): string {
    if (!countryCode || countryCode.length !== 2) {
        return "🏳️";
    }
    return Array.from(countryCode.toUpperCase())
        .map((char: string) => String.fromCodePoint(127397 + char.charCodeAt(0)))
        .join("");
}

export const getCountryNameByCountryCode = (countryInfo: any, languageCode: string = 'en') => {
    if (!countryInfo || !countryInfo.names) {
        return "Unknown";
    }
    return countryInfo.names[languageCode] || countryInfo.names['en'];
}

// utils/normalizeEmail.ts

/**
 * 전각 문자 제거 및 이메일 유효성 검증
 * @param rawEmail 입력된 이메일
 * @returns 정규화된 이메일 or 오류
 */
export const normalizeAndValidateEmail = (rawEmail: string): string => {
    const trimmed = rawEmail.trim();
  
    // 1. 전각 문자 → 반각 문자로 변환
    const normalized = toHalfWidth(trimmed);
  
    // 2. ASCII 문자 외 포함 여부 확인
    if (/[^\x00-\x7F]/.test(normalized)) {
      throw new Error("メールアドレスに全角または特殊文字が含まれています。半角英数字で入力してください。");
    }
  
    // 3. 이메일 포맷 검증
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(normalized)) {
      throw new Error("正しいメールアドレスの形式で入力してください。");
    }
  
    return normalized;
  }
  
  /**
   * 전각 문자 → 반각 문자 변환 (일부 주요 문자만 포함)
   */
export const toHalfWidth = (str: string): string => {
    return str.replace(/[\uff01-\uff5e]/g, (ch) =>
        String.fromCharCode(ch.charCodeAt(0) - 0xfee0)
    ).replace(/\u3000/g, ' '); // 전각 스페이스 → 일반 스페이스
}