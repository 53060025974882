import { useParams } from "react-router-dom";
import VideoPlayerComponent from "./VideoPlayer.component";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { getVideoPresignedUrl } from "api/common/Data.api";
import { deleteIntroVideo } from "api/student/v2/agencyProfile.api";
const VideoPlayer = (props: any) => {

    const { videoInfo } = props;

    const videoRef = useRef<HTMLVideoElement>(null);
    const [videoUrl, setVideoUrl] = useState("");
    const [status, setStatus] = useState("idle");
    const [isOnNetworking, setIsOnNetworking] = useState(false);

    useEffect(() => {
        console.log("videoInfo", videoInfo);
        if (videoInfo) {
            if (videoInfo.isPublic) {
                setVideoUrl(videoInfo.url);
            } else {
                fetchVideoUrl(videoInfo);
            }
        }
    }, [videoInfo]);

    const fetchVideoUrl = async (videoInfo: any) => {
        const data = await getVideoPresignedUrl(videoInfo.id);
        setVideoUrl(data.result);
    }
    const handleVideoPlay = async () => {
        if (!videoRef.current) return;

        if (status === 'loading') {
            return;
        }

        if (videoInfo.isPublic) {
            setVideoUrl(videoInfo.url);
            return;
        } else {
            setStatus('loading');
            try {
                const data = await getVideoPresignedUrl(videoInfo.id);
                setVideoUrl(data.result);
                setStatus('done');
            } catch (error) {
                console.error("Failed to fetch presigned URL", error);
                setStatus('error');
            }
        }
    }

    const handleVideoError = async () => {
        if (!videoRef.current) return;

        if (!videoInfo) return;

        if (status === 'loading') {
            return;
        }

        console.log("Video playback error, attempting to refresh URL");
        const currentTime = videoRef.current.currentTime;
        videoRef.current.pause();

        setStatus('loading');
        try {
            const data = await getVideoPresignedUrl(videoInfo.id);
            setVideoUrl(data.result);
            setStatus('done');

            // URL이 새로 설정된 후 이전 재생 시점부터 재생
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.currentTime = currentTime;
                    videoRef.current.play().catch(console.error);
                }
            }, 100);
        } catch (error) {
            console.error("Failed to refresh video URL", error);
            setStatus('error');
        }
    };

    return <VideoPlayerComponent
        videoUrl={videoUrl}
        videoRef={videoRef}
        onPlayVideo={handleVideoPlay}
        onVideoError={handleVideoError}
        status={status}
    />
}

export default VideoPlayer;