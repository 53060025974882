import React, { useState } from "react";
import { getProfileImageUrl } from "helpers/Image.helper";
const PostImagePopupComponent = (props: any) => {
    const {
        onClose,
        imageInfoList,
        slideIdx,
        setSlideIdx
    } = props;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
            <div className="relative w-full max-w-4xl p-4">
                {/* 닫기 버튼 */}
                <button
                    className="absolute top-4 right-4 z-10 text-white hover:text-gray-300"
                    onClick={onClose}
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                {/* 이미지 슬라이더 */}
                <div className="relative">
                    <div className="w-full h-[80vh] flex items-center justify-center overflow-hidden">
                        <div className="flex w-full h-full transition-transform duration-300 ease-in-out" style={{ transform: `translateX(-${(slideIdx) * 100}%)` }}>
                            {imageInfoList.map((image: any, index: number) => (
                                <div key={index} className="w-full h-full flex-shrink-0 flex items-center justify-center">
                                    <img
                                        src={getProfileImageUrl(image)}
                                        alt={`이미지 ${index + 1}`}
                                        className="max-w-full max-h-full object-contain"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* 이전/다음 버튼 */}
                    {slideIdx > 0 && (
                        <button
                            className="absolute left-4 top-1/2 -translate-y-1/2 text-white hover:text-gray-300"
                            onClick={() => setSlideIdx(slideIdx - 1)}
                        >
                            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                            </svg>
                        </button>
                    )}
                    {slideIdx < imageInfoList.length - 1 && (
                        <button
                            className="absolute right-4 top-1/2 -translate-y-1/2 text-white hover:text-gray-300"
                            onClick={() => setSlideIdx(slideIdx + 1)}
                        >
                            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PostImagePopupComponent;
