import React, { useState, useEffect } from "react";
import { MainLayout } from "./commons";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import {

    // common
    CommonHome,
    CommonSearch,
    CommonLogin,
    CommonLogout,
    CommonAbout,
    CommonContact,
    CommonMission,
    CommonPartner,
    CommonSignup,
    CommonPasswordReset,
    CommonPasswordResetDone,
    CommonVerifyPasswordEmail,
    CommonVerifyPasswordEmailDone,
    CommonPricing,
    CommonTerm,
    CommonUpdatePassword,
    CommonUpdateEmail,
    CommonDeleteAccount,
    CommonVerifyEmail,
    CommonVerifyCastingEmail,
    CommonVerifyParentEmail,
    CommonAudition,
    CommonAuditionDetail,
    CommonStudentDetail,
    CommonSubscribeEmail,
    CommonSubscribeEmailDone,
    CommonUnSubscribeEmail,
    CommonUnSubscribeEmailDone,
    CommonNotice,
    CommonNoticeDetail,
    CommonLandingInfoContainer,
    SignupStudent,
    CommonNeedAppUpdate,

    // Student
    V2StudentLayout,
    V2StudentHome,

    V2StudentProfileHome,
    V2StudentProfilePublicProfile,
    V2StudentProfilePublicProfileEssentialInfoEdit,
    V2StudentProfilePublicProfileAdditionalInfoEdit,
    V2StudentProfilePublicProfileBodyInfoEdit,
    V2StudentProfileAgencyProfile,
    V2StudentProfileAgencyProfilePhoto,
    V2StudentProfileAgencyProfileVideo,
    // Statistics
    V2StudentStatisticsHome,

    // Fan page
    V2StudentFanPageHome,
    V2StudentFanPagePhotos,
    V2StudentFanPageQnA,
    V2StudentFanPagePost,
    V2StudentFanPagePostForm,
    V2StudentFanPagePostInfo,

    // Casting
    V2StudentCastingHome,
    V2StudentCastingMessageHome,
    V2StudentCastingMessageChat,
    // export { V2CastingMessageDetailContainer as V2StudentCastingMessageDetail } from "./student/v2";

    // Student Hub
    V2StudentHubHome,
    V2StudentHubCommunity,
    V2StudentHubFollow,

    // Notification
    V2StudentAlarmHome,

    // Follow
    V2FollowHome,

    // My Page
    V2StudentMyHome,
    V2StudentMyChangePassword,
    V2StudentMyChangeParentEmail,
    V2StudentMyContact,
    V2StudentMyUpdateMyContact,
    V2StudentMyDeleteAccount,
    // StudentProfileIntro,
    // StudentProfileImage,
    // StudentPracticeDetail,
    // StudentPracticeNew,
    // StudentFanPageHome,
    // StudentFanPagePhotos,
    // StudentFanPagePost,
    // StudentFanPageQnA,
    // StudentFanPagePostForm,
    // StudentFanPagePostInfo,


    // StudentHubHome,
    // StudentHubCommunity,
    // StudentHubFollow,

    StudentAudition,
    StudentAuditionDetail,
    StudentAuditionApply,

    StudentMessageDetail,
    StudentMessageAgencyDetail,
    StudentMy,
    StudentNotification,
    StudentHomeV2,
    StudentLayout,

    // agency
    V2AgencyLayout,
    V2AgencyHome,
    V2AgencySearch,
    V2AgencyFavorite,
    V2AgencyMessage,
    V2AgencyMessageChat,
    V2AgencyMy,
    V2AgencyMyPassword,
    V2AgencyMyCellphone,
    V2AgencyMyDeleteAccount,
    V2AgencyStudent,
    V2AgencyAlarm,

    AgencyHome,
    AgencySignupDone,
    AgencyWannabeInfo,
    AgencyWannabePracticeVideo,
    AgencyWannabePracticeVideoInfo,
    AgencyWannabeProfile,
    AgencyWannabeSchoolEvaluation,
    AgencyFavorite,
    AgencyAudition,
    AgencyAuditionNew,
    AgencyAuditionDetail,
    AgencyAuditionApplicant,
    AgencyMessage,
    AgencyMessageDetail,
    AgencySearch,
    AgencyMy,
    AgencyNotification,


    // school
    SchoolHome,
    SchoolSignupDone,
    SchoolStudent,
    SchoolStudentDetail,
    SchoolStudentRegister,
    SchoolPortfolio,
    SchoolPortfolioDetail,
    SchoolCreatePortfolio,
    SchoolUpdatePortfolio,
    SchoolAudition,
    SchoolMessage,
    SchoolMessageDetail,
    SchoolMy,
    SchoolNotification,
    V2StudentMyCellphone,
    V2StudentInfoHome,

} from "./pages";

import { PAGE_TYPE } from "enums";
import { Error500Container, Error400Container } from "pages/common/error";
import LayoutCompactContainer from "commons/layout/LayoutCompact.container";
import LayoutOnlyHomeButton from "commons/layout/LayoutOnlyHomeButton.component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendPageView } from "helpers/Gtm.helper";
import { getCookieSetting } from "helpers/Cookie.helper";
import CookieSettingPopupContainer from "commons/popup/CookieSettingPopup.container";
import { usePopup } from "contexts/PopupContext";
import { setNotiFilterByPath } from "helpers/InApp.helper";

const Router = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { showPopup } = usePopup();
    const { info: myInfo } = useSelector((state: any) => state.user);
    const { isApp } = useSelector((state: any) => state.common);

    useEffect(() => {
        // 주소가 로그아웃이 아닐경우
        // 계정 타입에 따라 페이지 이동
        if (!location.pathname.startsWith('/logout')) {
            // 계정 타입이 있는 경우
            if (myInfo?.accountTypeCode) {
                // 학생 계정인 경우
                if (myInfo?.accountTypeCode === 'accountType:student') {
                    if (!location.pathname.startsWith("/student")) {
                        navigate("/student");
                    }
                } else if (myInfo?.accountTypeCode === 'accountType:agency') {
                    // 기획사 계정인 경우
                    if (!location.pathname.startsWith("/agency")) {
                        navigate("/agency");
                    }
                } else if (myInfo?.accountTypeCode === 'accountType:school') {
                    // 학교 계정인 경우
                    if (!location.pathname.startsWith("/school")) {
                        navigate("/school");
                    }
                }
            } else {
                // 계정 타입이 없는 경우
                if (location.pathname.startsWith("/student") ||
                    location.pathname.startsWith("/agency") ||
                    location.pathname.startsWith("/school")) {
                    // 학생, 기획사, 학교 페이지로 이동한 경우 홈으로 이동
                    navigate("/");
                }
            }
        }

        if (!getCookieSetting()) {
            showPopup(<CookieSettingPopupContainer />);
        }

        if (process.env.REACT_APP_ENV !== "local") {
            sendPageView(location.pathname);
        }

        if (isApp) {
            setNotiFilterByPath(location.pathname);
        }
    }, [location.pathname]);

    return (
        <Routes>
            {/* common*/}
            {/* <Route element={<MainLayout isPrivate={false} pageType={PAGE_TYPE.COMMON} />}>
                <Route path="/" element={<CommonHome />} />
                <Route path="/about" element={<CommonAbout />} />
                <Route path="/mission" element={<CommonMission />} />
                <Route path="/partner" element={<CommonPartner />} />
                <Route path="/pricing" element={<CommonPricing />} />
                <Route path="/contact" element={<CommonContact />} />
                <Route path="/search/:keyword" element={<CommonSearch />} />
                <Route path="/audition" element={<CommonAudition />} />
                <Route path="/info/:id" element={<CommonLandingInfoContainer />} />
                <Route path="/audition/:id/:type" element={<CommonAuditionDetail />} />
                <Route path="/wannabe/info/:slug" element={<CommonStudentDetail />} />
            </Route> */}

            <Route element={<MainLayout isPrivate={false} pageType={PAGE_TYPE.COMMON} />}>
                <Route path="/" element={<CommonHome />} />
                <Route path="/mission" element={<CommonMission />} />
                <Route path="/info/:id" element={<CommonLandingInfoContainer />} />
                <Route path="/wannabe/info/:slug" element={<CommonStudentDetail />} />
            </Route>

            {/* school*/}
            <Route path="/school" element={<MainLayout isPrivate={true} pageType={PAGE_TYPE.SCHOOL} />}>
                <Route index element={<SchoolHome />} />
                <Route path="student" element={<SchoolStudent />} />
                <Route path="student/:id" element={<SchoolStudentDetail />} />
                <Route path="student/new" element={<SchoolStudentRegister />} />
                <Route path="student/:id/edit" element={<SchoolStudentRegister />} />
                <Route path="portfolio" element={<SchoolPortfolio />} />
                <Route path="portfolio/:id" element={<SchoolPortfolioDetail />} />
                <Route path="portfolio/new" element={<SchoolCreatePortfolio />} />
                <Route path="portfolio/edit/:id" element={<SchoolUpdatePortfolio />} />
                <Route path="message" element={<SchoolMessage />} />
                <Route path="message/:id" element={<SchoolMessageDetail />} />
                <Route path="audition" element={<SchoolAudition />} />
                <Route path="my" element={<SchoolMy />} />
                <Route path="my/update-password" element={<CommonUpdatePassword />} />
                <Route path="my/update-email" element={<CommonUpdateEmail />} />
                <Route path="my/update-school" element={<SchoolMy />} />
                <Route path="my/update-contact" element={<SchoolMy />} />
                <Route path="my/update-sns" element={<SchoolMy />} />
                <Route path="my/delete-account" element={<CommonDeleteAccount />} />
                <Route path="notification" element={<SchoolNotification />} />
                <Route path="notice" element={<CommonNotice />} />
                <Route path="notice/:id" element={<CommonNoticeDetail />} />
            </Route>

            {/* agency*/}
            <Route path="/agency" element={<V2AgencyLayout />}>
                <Route index element={<V2AgencyHome />} />
                <Route path="search" element={<V2AgencySearch />} />
                <Route path="favorite" element={<V2AgencyFavorite />} />
                <Route path="message" element={<V2AgencyMessage />} />
                <Route path="message/:channelId/chat" element={<V2AgencyMessageChat />} />
                <Route path="student/:userStudentId" element={<V2AgencyStudent />} />
                {/* My Page */}
                <Route path="my" element={<V2AgencyMy />} />
                <Route path="my/password" element={<V2AgencyMyPassword />} />
                <Route path="my/cellphone" element={<V2AgencyMyCellphone />} />
                <Route path="my/withdraw" element={<V2AgencyMyDeleteAccount />} />
                <Route path="alarm" element={<V2AgencyAlarm />} />
            </Route>
            {/* 
            <Route path="/agency-bak" element={<MainLayout isPrivate={true} pageType={PAGE_TYPE.AGENCY} />}>
                <Route index element={<AgencyHome />} />
                <Route index element={<V2AgencyHome />} />
                <Route path="wannabe/info/:id" element={<AgencyWannabeInfo />} />
                <Route path="wannabe/practice-video/:id" element={<AgencyWannabePracticeVideo />} />
                <Route path="wannabe/practice-video-info/:id" element={<AgencyWannabePracticeVideoInfo />} />
                <Route path="wannabe/profile/:id" element={<AgencyWannabeProfile />} />
                <Route path="wannabe/:id" element={<AgencyWannabeInfo />} />
                <Route path="wannabe/school-evaluation/:id" element={<AgencyWannabeSchoolEvaluation />} />
                <Route path="favorite" element={<AgencyFavorite />} />
                <Route path="audition" element={<AgencyAudition />} />
                <Route path="audition/:id" element={<AgencyAuditionDetail />} />
                <Route path="audition/new" element={<AgencyAuditionNew />} />
                <Route path="audition/applicant/:id" element={<AgencyAuditionApplicant />} />
                <Route path="message" element={<AgencyMessage />} />
                <Route path="message/:id" element={<AgencyMessageDetail />} />
                <Route path="my" element={<AgencyMy />} />
                <Route path="my/update-password" element={<CommonUpdatePassword />} />
                <Route path="my/update-email" element={<CommonUpdateEmail />} />
                <Route path="my/update-agency" element={<AgencyMy />} />
                <Route path="my/update-caster" element={<AgencyMy />} />
                <Route path="my/update-sns" element={<AgencyMy />} />
                <Route path="my/update-artist" element={<AgencyMy />} />
                <Route path="my/delete-account" element={<CommonDeleteAccount />} />
                <Route path="search" element={<AgencySearch />} />
                <Route path="notification" element={<AgencyNotification />} />
                <Route path="notice" element={<CommonNotice />} />
                <Route path="notice/:id" element={<CommonNoticeDetail />} />
            </Route> */}

            {/* student*/}
            <Route path="/student" element={<V2StudentLayout isPrivate={true} pageType={PAGE_TYPE.STUDENT} />}>
                <Route index element={<V2StudentHome />} />

                <Route path="profile" element={<V2StudentProfileHome />} />

                <Route path="profile/public" element={<V2StudentProfilePublicProfile />} />
                <Route path="profile/public/essential-info/edit" element={<V2StudentProfilePublicProfileEssentialInfoEdit />} />
                <Route path="profile/public/body-info/:type/edit" element={<V2StudentProfilePublicProfileBodyInfoEdit />} />
                <Route path="profile/public/additional-info/edit" element={<V2StudentProfilePublicProfileAdditionalInfoEdit />} />

                <Route path="profile/agency" element={<V2StudentProfileAgencyProfile />} />
                <Route path="profile/agency/photo/:category/:type" element={<V2StudentProfileAgencyProfilePhoto />} />
                <Route path="profile/agency/intro-video" element={<V2StudentProfileAgencyProfileVideo />} />

                {/* <Route path="statistics" element={<V2StudentStatisticsHome />} /> */}

                <Route path="fan-page" element={<V2StudentFanPageHome />} />
                <Route path="fan-page/photos" element={<V2StudentFanPagePhotos />} />
                <Route path="fan-page/post" element={<V2StudentFanPagePost />} />
                <Route path="fan-page/post/:id" element={<V2StudentFanPagePostInfo />} />
                <Route path="fan-page/post/:id/edit" element={<V2StudentFanPagePostForm />} />
                <Route path="fan-page/qna" element={<V2StudentFanPageQnA />} />

                <Route path="casting" element={<V2StudentCastingHome />} />
                <Route path="casting/message" element={<V2StudentCastingMessageHome />} />
                <Route path="casting/message/:channelId/chat" element={<V2StudentCastingMessageChat />} />
                <Route path="alarm" element={<V2StudentAlarmHome />} />

                {/* 특정 메뉴에 속하지 않은 페이지 */}
                <Route path="follow" element={<V2FollowHome />} />
                <Route path="wannabe/:studentId" element={<V2StudentInfoHome />} />


                {/* My */}
                <Route path="my" element={<V2StudentMyHome />} />
                <Route path="my/password" element={<V2StudentMyChangePassword />} />
                <Route path="my/parent-email" element={<V2StudentMyChangeParentEmail />} />
                <Route path="my/cellphone" element={<V2StudentMyCellphone />} />
                <Route path="my/contact" element={<V2StudentMyUpdateMyContact />} />
                <Route path="my/delete-account" element={<V2StudentMyDeleteAccount />} />
                <Route path="my/update-email" element={<CommonUpdateEmail />} />
                <Route path="my/update-profile" element={<StudentMy />} />
                <Route path="my/update-contact" element={<StudentMy />} />
                <Route path="notification" element={<StudentNotification />} />
                <Route path="notice" element={<CommonNotice />} />
                <Route path="notice/:id" element={<CommonNoticeDetail />} />

                {/* <Route path="profile/agency/intro" element={<StudentProfileIntro />} />
                    <Route path="profile/agency/:category/:type" element={<StudentProfileImage />} /> */}
                {/* <Route
                    path="profile/practice/:id"
                    element={<StudentPracticeDetail />}
                />

                <Route path="profile/practice/new" element={<StudentPracticeNew />} />
                <Route
                    path="profile/practice/:id/edit"
                    element={<StudentPracticeNew />}
                /> */}

                {/* <Route path="casting/message/:id" element={<StudentCastingMessageDetail />} /> */}

                {/* <Route path="hub" element={<V2StudentHubHome />} />
                <Route path="hub/community" element={<V2StudentHubCommunity />} />
                <Route path="hub/follow" element={<V2StudentHubFollow />} /> */}


                {/* <Route path="my/contact" element={<V2StudentMyContact />} /> */}
                {/* <Route path="my/update-my" element={<V2StudentMyUpdateMy />} />
                    <Route path="my/update-my-contact" element={<V2StudentMyUpdateMyContact />} /> */}

                {/* <Route path="audition" element={<StudentAudition />} />
                    <Route path="audition/:id" element={<StudentAuditionDetail />} />
                    <Route path="audition/apply/:id" element={<StudentAuditionApply />} />
                    <Route path="audition/apply/:id/id/:applicant" element={<StudentAuditionApply />} />
                    <Route path="message" element={<StudentMessageHome />} />
                    <Route path="message/list" element={<StudentMessageList />} />
                    <Route path="message/:id" element={<StudentMessageDetail />} />
                    <Route path="message/agency/:id" element={<StudentMessageAgencyDetail />} /> */}
            </Route>


            {/* 헤더가 불필요한 페이지 */}
            <Route element={<LayoutCompactContainer />}>
                <Route path="/login" element={<CommonLogin />} />
                <Route path="/logout" element={<CommonLogout />} />
                <Route path="/signup" element={<CommonSignup />} />
                <Route path="/signup/agency" element={<CommonSignup />} />
                <Route path="/signup/agency/done" element={<AgencySignupDone />} />
                <Route path="/signup/school" element={<CommonSignup />} />
                <Route path="/signup/student" element={<CommonSignup />} />
                <Route path="/signup/school/done" element={<SchoolSignupDone />} />
                <Route path="/password/reset" element={<CommonPasswordReset />} />
                <Route path="/password/reset/done" element={<CommonPasswordResetDone />} />
            </Route>

            <Route element={<LayoutOnlyHomeButton />}>
                <Route path="/subscribed/new-wannabe" element={<CommonSubscribeEmail />} />
                <Route path="/subscribed/new-wannabe/done" element={<CommonSubscribeEmailDone />} />
                <Route path="/unsubscribed/new-wannabe" element={<CommonUnSubscribeEmail />} />
                <Route path="/unsubscribed/new-wannabe/done" element={<CommonUnSubscribeEmailDone />} />
                <Route path="/verification/email/signup/:token" element={<CommonVerifyEmail />} />
                <Route path="/verification/email/casting/signup/:token" element={<CommonVerifyCastingEmail />} />
                <Route path="/verification/email/parent/:token" element={<CommonVerifyParentEmail />} />
                <Route path="/verification/email/password/reset/:token" element={<CommonVerifyPasswordEmail />} />
                <Route path="/verification/email/password/reset/done" element={<CommonVerifyPasswordEmailDone />} />
            </Route>

            <Route path="/term/common/:id" element={<CommonTerm />} />
            <Route path="/term/etc/:id" element={<CommonTerm />} />
            {/* <Route path="/app/need-update" element={<CommonNeedAppUpdate />} /> */}
            {/* 500 */}
            <Route path="/error/500" element={<Error500Container />} />
            {/* 404 */}
            <Route path="/*" element={<Error400Container />} />
        </Routes>
    );
};

export default Router;
