import React, { useEffect, useState } from "react";
import PhotosComponent from "./Photos.component";
import { getPhotosList, createPhoto, deletePhoto } from "api/student/v2/FanPage.api";
import * as ImageHelper from "helpers/Image.helper";
import * as DataApi from "api/common/Data.api";
import { usePopup } from "contexts/PopupContext";
import PhotosPopupContainer from "../popup/PhotosPopup.container";
import { getCurrentLanguage, getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";


const PhotosContainer = () => {
    const [isOnLoading, setIsOnLoading] = useState(false);
    const [photosList, setPhotosList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [hasMorePage, setHasMorePage] = useState(true);
    const [isOnNetworking, setIsOnNetworking] = useState(false);
    const { showPopup, hidePopup } = usePopup();

    useEffect(() => {
        console.log("PhotosContainer");
        init();
    }, []);

    const init = async () => {
        await loadPhotosList(page, limit);
    };

    const loadPhotosList = async (page: number, limit: number) => {
        console.log("loadPhotosList", page, limit);
        if (isOnLoading)
            return;

        setIsOnLoading(true);
        await getPhotosList(page, limit)
            .then((data) => {
                setPage(page);
                setPhotosList([...photosList, ...data.list]);
                setHasMorePage(data.list.length === limit);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setIsOnLoading(false);
            });
    };

    const handleUploadPhoto = async (evt: any) => {
        console.log("handleUploadPhoto", evt);
        if (isOnNetworking)
            return;

        if (!evt) {
            return;
        }

        if (evt.target.files.length > 10) {
            alert("You can only upload up to 10 photos at a time");
            return;
        }

        setIsOnNetworking(true);

        const files = evt.target.files;
        const newPhotos = [];

        try {
            for (const file of files) {
                const imageInfo = await ImageHelper.getImageInfo(file);
                if (!imageInfo) {
                    alert("Please choose image file");
                    return false;
                }

                const data = await DataApi.uploadImage(file);
                if (data) {
                    const result = await createPhoto(data.id);
                    if (result?.info) {
                        newPhotos.push(result.info);
                    }
                } else {
                    alert("Failed to upload image");
                }
            }

            setPhotosList([...newPhotos, ...photosList]);
        } catch (err) {
            console.error(err);
            alert("Error uploading photos");
        } finally {
            setIsOnNetworking(false);
        }

    }

    const handleShowPhotosPopup = (photo: any) => {
        console.log("handleShowPhotosPopup", photo);
        showPopup(<PhotosPopupContainer selectedPhoto={photo} />);
    }

    return (
        <PhotosComponent
            isOnLoading={isOnLoading}
            photosList={photosList}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            loadPhotosList={loadPhotosList}
            handleUploadPhoto={handleUploadPhoto}
            handleShowPhotosPopup={handleShowPhotosPopup}
            selectedPhoto={selectedPhoto}
            setSelectedPhoto={setSelectedPhoto}
            hasMorePage={hasMorePage}
            isOnNetworking={isOnNetworking}
        />
    );
};

export default PhotosContainer;