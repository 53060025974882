import React from 'react';
import { formatTimeAgo } from "helpers/Datetime.helper";
import LoadingComponent from 'commons/loading/Loading.component';

const GrowthInfoComponent = (props: any) => {
    const { info, isInit, onShowBodyInfoPopup, errorMessage } = props;

    return (
        <>
            {!isInit &&
                <LoadingComponent />
            }
            {isInit && info &&
                <>
                    {/* 섹션 1: 키 정보 */}
                    <section className="bg-white rounded-lg shadow-sm p-6 mb-6">
                        <h3 className="text-lg font-medium text-gray-500 mb-3">키</h3>
                        {info?.isPublicHeight ? (
                            <>
                                {info?.recentlyHeightList?.length > 0 &&
                                    <div className="flex items-center justify-between w-full"
                                        onClick={() => onShowBodyInfoPopup('bodyInfoType:height')}>
                                        <div className="flex flex-wrap items-center gap-4">
                                            {info?.recentlyHeightList?.slice(0, 2).map((item: any, index: number) => {
                                                if (index === 0) {
                                                    const diff = info?.recentlyHeightList?.[1] ?
                                                        item.value - info.recentlyHeightList[1].value : null;
                                                    return (
                                                        <div key={`height-${index}`} className="flex flex-col gap-1">
                                                            <div className="text-gray-900">
                                                                <span className="font-bold">
                                                                    <span className="text-gray-900 text-base sm:text-xl">{item.value}cm</span>

                                                                    {diff !== null && (
                                                                        <span className={`${diff > 0 ? 'text-red-500' : 'text-blue-500'} text-xs sm:text-sm ml-1`}>
                                                                            ({diff > 0 ? '+' : ''}{diff})
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="text-xs sm:text-sm text-gray-400">
                                                                {formatTimeAgo(item.createdAt)}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                        <div className="text-[#CF3688] flex-shrink-0">
                                            <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </div>
                                }
                                {info?.recentlyHeightList?.length === 0 &&
                                    <div className="text-gray-500">
                                        데이터가 없네요...
                                    </div>
                                }
                            </>
                        ) : (
                            <p className="text-sm sm:text-base text-gray-500">지망생이 해당 정보를 비공개 처리했습니다.</p>
                        )}
                    </section>

                    {/* 섹션 2: 몸무게 정보 */}
                    <section className="bg-white rounded-lg shadow-sm p-6 mb-6">
                        <h3 className="text-lg font-medium text-gray-500 mb-3">몸무게</h3>
                        {info?.isPublicWeight ? (
                            <>
                                {info?.recentlyWeightList?.length > 0 &&
                                    <div className="flex items-center justify-between w-full"
                                        onClick={() => onShowBodyInfoPopup('bodyInfoType:weight')}>
                                        <div className="flex flex-wrap items-center gap-4">
                                            {info?.recentlyWeightList?.slice(0, 2).map((item: any, index: number) => {
                                                if (index === 0) {
                                                    const diff = info?.recentlyWeightList?.[1] ?
                                                        item.value - info.recentlyWeightList[1].value : null;
                                                    return (
                                                        <div key={`weight-${index}`} className="flex flex-col gap-1">
                                                            <div className="text-gray-900">
                                                                <span className="font-bold">
                                                                    <span className="text-gray-900 text-base sm:text-xl">{item.value}kg</span>

                                                                    {diff !== null && (
                                                                        <span className={`${diff > 0 ? 'text-red-500' : 'text-blue-500'} text-xs sm:text-sm ml-1`}>
                                                                            ({diff > 0 ? '+' : ''}{diff})
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="text-xs sm:text-sm text-gray-400">
                                                                {formatTimeAgo(item.createdAt)}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                        <div className="text-[#CF3688] flex-shrink-0">
                                            <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </div>}
                                {info?.recentlyWeightList.length === 0 &&
                                    <div className="text-gray-500">
                                        데이터가 없네요...
                                    </div>
                                }
                            </>
                        ) : (
                            <p className="text-sm sm:text-base text-gray-500">지망생이 키를 비공개 처리했습니다.</p>
                        )}
                    </section>

                    {/* 섹션 3: 신발 사이즈 정보 */}
                    <section className="bg-white rounded-lg shadow-sm p-6 mb-6">
                        <h3 className="text-lg font-medium text-gray-500 mb-3">신발 사이즈</h3>
                        {info?.isPublicShoeSize ? (
                            <>
                                {info?.recentlyShoeSizeList?.length > 0 &&
                                    <div className="flex items-center justify-between w-full"
                                        onClick={() => onShowBodyInfoPopup('bodyInfoType:shoeSize')}>
                                        <div className="flex flex-wrap items-center gap-4">
                                            {info?.recentlyShoeSizeList?.slice(0, 2).map((item: any, index: number) => {
                                                if (index === 0) {
                                                    const diff = info?.recentlyShoeSizeList?.[1] ?
                                                        item.value - info.recentlyShoeSizeList[1].value : null;
                                                    return (
                                                        <div key={`shoe-${index}`} className="flex flex-col gap-1">
                                                            <div className="text-gray-900">
                                                                <span className="font-bold">
                                                                    <span className="text-gray-900 text-base sm:text-xl">{item.value}mm</span>

                                                                    {diff !== null && (
                                                                        <span className={`${diff > 0 ? 'text-red-500' : 'text-blue-500'} text-xs sm:text-sm ml-1`}>
                                                                            ({diff > 0 ? '+' : ''}{diff})
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="text-xs sm:text-sm text-gray-400">
                                                                {formatTimeAgo(item.createdAt)}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                        <div className="text-[#CF3688] flex-shrink-0">
                                            <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </div>
                                }
                                {info?.recentlyShoeSizeList?.length === 0 &&
                                    <div className="text-gray-500">
                                        데이터가 없네요...
                                    </div>
                                }
                            </>
                        ) : (
                            <p className="text-sm sm:text-base text-gray-500">지망생이 몸무게를 비공개 처리했습니다.</p>
                        )}
                    </section>
                </>
            }
            {(isInit && errorMessage) &&
                <div className="w-full text-center py-8 text-red-500 bg-red-100 border border-red-400 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z" />
                    </svg>
                    {errorMessage}
                </div>
            }
        </>
    );
};

export default GrowthInfoComponent;