import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";
import * as commonData from "data/common.data";
import { getCurrentLangName, getOnlyNumberValue } from "helpers/String.helper";
import { getImageUrl } from "helpers/Image.helper";

const VerifyEmailComponent = (props: any) => {
    const { isLoading, userInfo, params, onChangeInput, saveInfo, btnRef } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <>
                    {isLoading && (<LoadingComponent />)}
                    {!isLoading && userInfo === false && (
                        <div className="max-w-3xl mx-auto">
                            <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
                                <div className="p-6 sm:p-8 border-b border-gray-100">
                                    <strong>{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtFailed1")}</strong>
                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtFailed2")}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isLoading && userInfo && !userInfo?.isParentConfirm && (
                        <div className="max-w-3xl mx-auto">
                            <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
                                <div className="p-6 sm:p-8 border-b border-gray-100">
                                    <div className="flex items-start gap-6">
                                        <div className="flex-shrink-0">
                                            <img
                                                src={getImageUrl(userInfo?.profileImageInfo)}
                                                alt="Profile"
                                                className="w-20 h-20 rounded-full object-cover"
                                            />
                                        </div>
                                        <div>
                                            <h2 className="text-xl sm:text-2xl font-bold text-gray-900">
                                                {userInfo?.firstName} {userInfo?.lastName}
                                            </h2>
                                            <p className="mt-2 text-gray-500">
                                                {getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtDesc")}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-6 sm:p-8 space-y-8">
                                    <div>
                                        <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                            <span className="text-[#CF3688] mr-1">*</span>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtCountry")}
                                        </label>
                                        <div className="relative">
                                            <select
                                                className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                        focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                        text-base transition-colors appearance-none"
                                                required
                                                value={params.countryCode}
                                                onChange={(evt) => onChangeInput("countryCode", evt.target.value)}
                                            >
                                                <option value="" disabled selected>
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtCountryOptional")}
                                                </option>
                                                {commonData.getCountryCodeList().map((item: any, idx: number) => (
                                                    <option key={idx} value={item.code}>
                                                        {getCurrentLangName(item.names)}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                                <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="space-y-8">
                                        <div>
                                            <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                <span className="text-[#CF3688] mr-1">*</span>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtFirstName")}
                                            </label>
                                            <div className="flex gap-4">
                                                <input
                                                    className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                            focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                            text-base transition-colors"
                                                    required
                                                    placeholder={getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtFirstNamePlaceholder")}
                                                    value={params.firstName}
                                                    onChange={(evt) => onChangeInput("firstName", evt.target.value)}
                                                />
                                                <input
                                                    className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                            focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                            text-base transition-colors"
                                                    required
                                                    placeholder={getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtLastNamePlaceholder")}
                                                    value={params.lastName}
                                                    onChange={(evt) => onChangeInput("lastName", evt.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                <span className="text-[#CF3688] mr-1">*</span>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtPhoneNumber")}
                                            </label>
                                            <div className="space-y-2">
                                                <select
                                                    className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                            focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                            text-base transition-colors appearance-none"
                                                    value={params.phoneCode}
                                                    onChange={(evt) => onChangeInput("phoneCode", evt.target.value)}
                                                    required
                                                >
                                                    <option value="" disabled selected>
                                                        {getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtPhoneCodePlaceholder")}
                                                    </option>
                                                    {commonData.getCountryPhoneCodeList().map((item: any, idx: number) => (
                                                        <option key={idx} value={item.phoneCode}>
                                                            {`${item.phoneCode} (${getCurrentLangName(item.names)})`}
                                                        </option>
                                                    ))}
                                                </select>
                                                <input
                                                    className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                            focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                            text-base transition-colors"
                                                    required
                                                    placeholder={getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtPhoneNumberPlaceholder")}
                                                    value={params.phoneNumber}
                                                    onChange={(evt) => {
                                                        const value = getOnlyNumberValue(evt.target.value);
                                                        if (value.length < 20) {
                                                            onChangeInput("phoneNumber", value);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex items-start space-x-3">
                                            <input
                                                type="checkbox"
                                                required
                                                className="h-5 w-5 text-[#CF3688] border-2 border-gray-300 rounded focus:ring-[#CF3688] mt-1"
                                                onChange={(evt) => onChangeInput("isAgreeTerm", evt.target.checked)}
                                            />
                                            <div>
                                                <p className="text-sm text-gray-700">{getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtTermAgree")}</p>
                                                <p className="mt-1 text-xs text-gray-500">{getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.txtTermWarn")}</p>
                                            </div>
                                        </div>

                                        <button
                                            ref={btnRef}
                                            onClick={saveInfo}
                                            className="w-full px-4 py-3 text-sm font-medium text-white bg-[#CF3688] rounded-xl
                                                    hover:bg-[#CF3688]/90 focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                                    transition-colors"
                                        >
                                            {getLanguageValue(PAGE_TYPE.COMMON, "verifyParentEmail.btnSave")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            </div>
        </main>

    );
};

export default VerifyEmailComponent;
