import React, { useEffect, useState } from "react";
import IndexComponent from "./Index.component";
import * as MyApi from "api/agency/v2/My.api";
import { usePopup } from "contexts/PopupContext";
import { useNavigate } from "react-router-dom";
import * as AuthApi from "api/common/Auth.api";
import { removeAuthTokens } from "helpers/Auth.helper";
import { removePushToken } from "helpers/Firebase.helper";
import { removeAppAccessToken } from "helpers/appLocalStorageHelper";
import { useDispatch, useSelector } from "react-redux";
import { setInfo as setUserInfo, setIsLogin } from "reducers/User";
import * as DataApi from "api/common/Data.api";

const IndexContainer = () => {
    const { isApp } = useSelector((state: any) => state.common);
    
    const [info, setInfo] = useState<any>(null);
    const [isInit, setIsInit] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loadMyInfo = async () => {
        try {
            const data = await MyApi.getMyPageInfo();
            if (data?.info) {
                setInfo(data.info);
            }
        } catch (error) {
            console.error('Failed to load my info:', error);
        } finally {
            setIsInit(true);
        }
    };

    useEffect(() => {
        loadMyInfo();
    }, []);

    const [isOnUpdateBusinessCard, setIsOnUpdateBusinessCard] = useState<boolean>(false);
    const handleUpdateBusinessCard = async (type: string, file: File) => {
        if (isOnUpdateBusinessCard) {
            return;
        }

        setIsOnUpdateBusinessCard(true);

        const result = await DataApi.uploadImage(file);
        if (result?.id) {
            if (type === "front") {
                const data = await AuthApi.updateBusinessCardFrontImageId(result.id);
                if (data?.imageInfo) {
                    let newInfo = { ...info };
                    newInfo['userAgencyInfo']['businessCardFrontImageInfo'] = data.imageInfo;
                    setInfo(newInfo);
                }
            } else if (type === "back") {
                const data = await AuthApi.updateBusinessCardBackImageId(result.id);
                if (data?.imageInfo) {
                    let newInfo = { ...info };
                    newInfo['userAgencyInfo']['businessCardBackImageInfo'] = data.imageInfo;
                    setInfo(newInfo);
                }
            }
        }
        setIsOnUpdateBusinessCard(false);
    };

    return (
        <IndexComponent
            isInit={isInit}
            info={info}
            isOnUpdateBusinessCard={isOnUpdateBusinessCard}
            onUpdateBusinessCard={handleUpdateBusinessCard}
            isApp={isApp}
        />
    );
};

export default IndexContainer;