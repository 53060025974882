import React from "react";

const GlobalLoadingComponent = ({ ...props }) => {
    const { message } = props;

    return (
        <>
            <div className="global-loading-wrap">
                <div className="space-gradient"></div>
                <div className="container mx-auto flex flex-col items-center justify-end min-h-screen z-0 pb-10">
                    <div className="star" />
                    <img id="logo" src="/imgs/common/logo/img-logo/logo-t2-250.png" alt="WANNABES" className="mb-4 hidden" />
                    <h1 className="text-center mt-4 message">
                        {message ? message : "NOW LOADING..."}
                    </h1>
                </div>
            </div>
            <style>
                {`
                .global-loading-wrap {
                    position: relative;
                    width: 100%;
                    height: 100vh;
                    overflow: hidden;
                }

                .space-gradient {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: radial-gradient(circle at 50% 50%, #ffffff, #fefdf5, #fdf5e6);
                    background-size: 200% 200%;
                    animation: gradientMove 10s ease infinite;
                    z-index: -1;
                }

                @keyframes gradientMove {
                    0% {
                        background-position: 0% 50%;
                    }
                    50% {
                        background-position: 100% 50%;
                    }
                    100% {
                        background-position: 0% 50%;
                    }
                }

                .star {
                    width: 30px;
                    height: 30px;
                    background-image: url('/imgs/icons/icon-star-fill.svg');
                    background-size: cover;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    animation: rotateAndBlink 2s infinite linear;
                }

                @media (min-width: 640px) {
                    .star {
                        width: 40px;
                        height: 40px;
                    }
                }

                @media (min-width: 768px) {
                    .star {
                        width: 50px;
                        height: 50px;
                    }
                }

                @media (min-width: 1024px) {
                    .star {
                        width: 60px;
                        height: 60px;
                    }
                }

                @keyframes rotateAndBlink {
                    0% {
                        transform: translate(-50%, -50%) rotate(0deg);
                        opacity: 1;
                    }
                    50% {
                        opacity: 0.5;
                    }
                    100% {
                        transform: translate(-50%, -50%) rotate(360deg);
                        opacity: 1;
                    }
                }

                .message {
                    color: #888;
                    font-weight: bold;
                    font-size: 0.8rem; /* 기본 크기 */
                }

                @media (min-width: 640px) {
                    .message {
                        font-size: 0.9rem; /* 작은 화면 */
                    }
                }

                @media (min-width: 768px) {
                    .message {
                        font-size: 1rem; /* 중간 화면 */
                    }
                }

                @media (min-width: 1024px) {
                    .message {
                        font-size: 1.1rem; /* 큰 화면 */
                    }
                }
                `}
            </style>
        </>
    );
};

export default GlobalLoadingComponent;