//* 팬 페이지 관련 API
import * as BaseApi from "api/Base.api";
const BASE_URL = "/student/v2/fan";

//* 팬 페이지 요약 조회
//  2025.03.13
//  희문
export const getFanPageSummary = async () => {
    const result = await BaseApi.get(BASE_URL + "/summary");
    return result;
}