import React from "react";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import { getImageUrl, getProfileImageUrl } from "helpers/Image.helper";
import { useNavigate } from "react-router-dom";
import { getStudentNickname, getFlagByCountryCode, getCurrentLangName } from "helpers/String.helper";
import FullPageLoading from "commons/loading/FullPageLoading.component";
import LoadingComponent from "commons/loading/Loading.component";

const StudentInfoComponent = (props: any) => {
    const navigate = useNavigate();

    const { isInit,
        info, isOnLoading, errorMessage, photos,
        errorMessageForPhotos, hasMore, loadMorePhotos, handleFollow, handleUnfollow, isOnNetworking } = props;

    return (
        !isInit ?
            <FullPageLoading />
            :
            <main className="bg-gray-50 min-h-screen">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                    <div className="fixed top-0 left-0 w-full bg-gray-50 z-10">
                        <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex items-center gap-4 py-4">
                            <button onClick={() => navigate('/student')} className="text-gray-600 hover:text-gray-900">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "studentInfo.txtTitle")}
                            </h1>
                        </div>
                    </div>
                    {errorMessage &&
                        <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
                            <div className="flex items-center">
                                <svg className="w-5 h-5 text-red-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                                <p className="text-red-600 font-medium">{errorMessage}</p>
                            </div>
                        </div>
                    }
                    {info &&
                        <div className="block relative">
                            {/* 기본정보 영역 */}
                            <div className="mb-8">
                                <div className="flex flex-col sm:flex-row items-center sm:space-x-6">
                                    <div className="w-28 h-28 rounded-full bg-gray-200 overflow-hidden border-4 border-[#CF3688]/20 shadow-lg mb-4 sm:mb-0">
                                        <img src={getProfileImageUrl(info?.profileImage)} alt={getStudentNickname(info)} className="w-full h-full object-cover" />
                                    </div>
                                    <div className="text-center sm:text-left">
                                        <p className="text-2xl font-bold text-gray-800 mb-2">{getStudentNickname(info)}</p>
                                        <div className="flex flex-col sm:flex-row sm:space-x-4 items-center sm:items-start mb-6">
                                            <p className="text-gray-600">
                                                {info?.birthyear}
                                            </p>
                                            <p className="text-gray-600 mb-1 sm:mb-0">
                                                {getFlagByCountryCode(info?.countryInfo?.code)} {getCurrentLangName(info?.countryInfo?.names)}
                                            </p>
                                        </div>

                                        {!info?.isActiveOfPublicProfile &&
                                            <div className="block relative mb-6">
                                                <p className="text-[#cf3688] font-medium text-center">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "studentInfo.txtDisabledPublicProfile")}
                                                </p>
                                            </div>
                                        }
                                        <div className="mt-4 flex justify-center sm:justify-start w-full">
                                            {info?.isFollowing ?
                                                <button
                                                    disabled={isOnNetworking}
                                                    onClick={() => handleUnfollow(info?.id)}
                                                    className="w-full sm:w-auto px-4 py-2 bg-[#CF3688] text-white rounded-sm text-sm font-medium hover:bg-[#CF3688]/90 transition-colors shadow-md hover:shadow-lg">
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtUnfollow")}
                                                </button>
                                                :
                                                <button
                                                    disabled={isOnNetworking}
                                                    onClick={() => handleFollow(info?.id)}
                                                    className="w-full sm:w-auto px-4 py-2 bg-[#CF3688] text-white rounded-sm text-sm font-medium hover:bg-[#CF3688]/90 transition-colors shadow-md hover:shadow-lg">
                                                    {!info.isFollowing && info.isFollower
                                                        ? getLanguageValue(PAGE_TYPE.COMMON, "btn.txtFollowBack")
                                                        : info.isFollowing
                                                            ? getLanguageValue(PAGE_TYPE.COMMON, "btn.txtUnfollow")
                                                            : getLanguageValue(PAGE_TYPE.COMMON, "btn.txtFollow")}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {info?.isActiveOfPublicProfile &&
                                <>
                                    {/* 통계 영역 */}
                                    <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                            <div className="bg-gray-50 p-3 rounded-lg text-center">
                                                <p className="text-gray-500 text-xs mb-2">{getLanguageValue(PAGE_TYPE.STUDENT, "studentInfo.txtProfileManagerViewCount")}</p>
                                                <p className="text-xl font-bold text-gray-800">
                                                    {info?.castingManagerViewCount}
                                                </p>
                                            </div>
                                            <div className="bg-gray-50 p-3 rounded-lg text-center">
                                                <p className="text-gray-500 text-xs mb-2">{getLanguageValue(PAGE_TYPE.STUDENT, "studentInfo.txtFavoriteByAgency")}</p>
                                                <p className="text-xl font-bold text-gray-800">
                                                    {info?.favoriteCount}
                                                </p>
                                            </div>
                                            <div className="bg-gray-50 p-3 rounded-lg text-center">
                                                <p className="text-gray-500 text-xs mb-2">{getLanguageValue(PAGE_TYPE.STUDENT, "studentInfo.txtFollower")}</p>
                                                <p className="text-xl font-bold text-gray-800">
                                                    {info?.followerCount}
                                                </p>
                                            </div>
                                            <div className="bg-gray-50 p-3 rounded-lg text-center">
                                                <p className="text-gray-500 text-xs mb-2">{getLanguageValue(PAGE_TYPE.STUDENT, "studentInfo.txtFollowing")}</p>
                                                <p className="text-xl font-bold text-gray-800">
                                                    {info?.followingCount}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {errorMessageForPhotos &&
                                        <div className="mt-16 p-4 bg-red-50 border border-red-200 rounded-lg">
                                            <div className="flex items-center">
                                                <svg className="w-5 h-5 text-red-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg>
                                                <p className="text-red-600 font-medium">{errorMessageForPhotos}</p>
                                            </div>
                                        </div>
                                    }
                                    {photos && photos.length === 0 &&
                                        <div className="mt-16 p-4 bg-gray-50 border border-gray-200 rounded-lg">
                                            <p className="text-gray-600 font-medium">{getLanguageValue(PAGE_TYPE.STUDENT, "studentInfo.txtNoPhotos")}</p>
                                        </div>
                                    }
                                    {photos && photos.length > 0 &&
                                        <div className="block relative">
                                            <h2 className="text-xl font-bold mb-2 text-gray-800 flex items-center">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "studentInfo.txtTitlePhotos")}
                                            </h2>
                                            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 p-4">
                                                {photos.map((photo: any, index: number) => (
                                                    <div
                                                        key={index}
                                                        className="aspect-square bg-gray-200 overflow-hidden rounded-lg shadow-md hover:shadow-lg transition-shadow transform hover:scale-105 transition-transform duration-200 relative">
                                                        <img src={getImageUrl(photo?.imageInfo)} alt={`추가 사진 ${index + 1}`} className="w-full h-full object-cover" />
                                                        <div className="absolute bottom-2 right-2 bg-black bg-opacity-60 text-white text-xs px-2 py-1 rounded-full flex items-center">
                                                            <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                                <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                                            </svg>
                                                            <span>{photo?.viewCount}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {/* 추가 사진 영역 */}
                                    {isOnLoading &&
                                        <LoadingComponent />
                                    }

                                    {!isOnLoading && hasMore &&
                                        <div className="mt-4 text-center">
                                            <button className="px-4 py-2 text-[#CF3688] border border-[#CF3688] rounded-full text-sm font-medium hover:bg-[#CF3688]/5 transition-colors"
                                                onClick={loadMorePhotos}>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtMore")}
                                            </button>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>
            </main>
    )
};

export default StudentInfoComponent;