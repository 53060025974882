import React, { useCallback, useEffect, useState } from "react";
import IndexComponent from "./Index.component";
import { getSearchHome, getRecommendList, getSearchResult } from "api/agency/v2/Search.api";
import StudentInfoPopupContainer from "../popup/StudentInfoPopup.container";
import { usePopup } from "contexts/PopupContext";
import FullPageStudentInfoPopupContainer from "../popup/FullPageStudentInfoPopup.container";

const LIST_LIMIT = 30;
const IndexContainer = () => {
    const { showPopup } = usePopup();
    const [hasSearch, setHasSearch] = useState<boolean>(false);

    const [isInit, setIsInit] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);

    const [keyword, setKeyword] = useState<string>("");
    const [list, setList] = useState<any[]>([]);
    const [updatedProfileList, setUpdatedProfileList] = useState<any[]>([]);
    const [recentlyViewedProfileList, setRecentlyViewedProfileList] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    interface ISearchFilter {
        countryCodeList: string[];
        genderTypeCode: string;
        minAge: number;
        maxAge: number;
        minWeight: number;
        maxWeight: number;
        minHeight: number;
        maxHeight: number;
    }

    const [searchFilter, setSearchFilter] = useState<ISearchFilter>({
        countryCodeList: [],
        genderTypeCode: "",
        minAge: 0,
        maxAge: 99,
        minWeight: 0,
        maxWeight: 999,
        minHeight: 0,
        maxHeight: 999,
    });

    const handleSearchFilter = (key: string, value: any) => {
        console.log("page/agency/v2/search/Index.container::handleSearchFilter");
        console.log({ key, value });

        if (key === "countryCodeList") {
            setSearchFilter({ ...searchFilter, countryCodeList: [...searchFilter.countryCodeList, value] });
        } else {
            setSearchFilter({ ...searchFilter, [key]: value });
        }
    }

    const handleDeleteCountryCode = (code: string) => {
        setSearchFilter({ ...searchFilter, countryCodeList: searchFilter.countryCodeList.filter((item: string) => item !== code) });
    }

    const handleScroll = useCallback(() => {
        if (!hasSearch) return;

        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        // 스크롤이 바닥에 도달했는지 체크
        if (scrollHeight - scrollTop <= clientHeight + 100) { // 100px 여유를 둠
            console.log('scroll to bottom')
            // 더 로드할 데이터가 있고 현재 로딩 중이 아닐 때만 다음 페이지 로드
            console.log({ hasMore, isLoading });
            if (hasMore && !isLoading) {
                fetchList(page + 1);
            }
        }
    }, [hasMore, isLoading]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const handleSearch = async (page: number) => {
        console.log("page/agency/v2/search/Index.container::handleSearch");
        console.log({ page });
        fetchList(1);
    };

    const fetchList = async (page: number) => {
        console.log("page/agency/v2/search/Index.container::fetchList");
        console.log({ page });
        console.log({ isLoading });
        if (isLoading) return;

        console.log({ page });
        if (page === 0) return;

        console.log({ page });
        try {
            setIsLoading(true);
            if (page === 1) {
                setList([]);
            }
            const newSearchFilter = {
                ...searchFilter,
                countryCodes: searchFilter.countryCodeList.join(","),
            };
            const data = await getSearchResult(keyword, page, LIST_LIMIT, newSearchFilter);

            if (data?.list) {
                setList(prev => page === 1 ? data.list : [...prev, ...data.list]);
                setPage(page);
                setHasMore(data.list.length === LIST_LIMIT);
            }
        } catch (error) {
            console.error("Failed to fetch search results:", error);
        } finally {
            setHasSearch(true);
            setIsLoading(false);
            setIsInit(true);
        }
    };

    // useEffect(() => {
    //     fetchList(1);
    // }, [fetchList]);

    const handleClickStudent = (userStudentId: number) => {
        console.log("page/agency/v2/search/Index.container::handleClickStudent");
        console.log({ userStudentId });
        showPopup(
            <FullPageStudentInfoPopupContainer
                userStudentId={userStudentId}
            />
        );
    }

    useEffect(() => {
        const fetchHomeInfo = async () => {
            try {
                const data = await getSearchHome();
                console.log({ data });
                if (data) {
                    if (data?.updatedProfileList) {
                        setUpdatedProfileList(data.updatedProfileList);
                    }
                    if (data?.recentlyViewedProfileList) {
                        setRecentlyViewedProfileList(data.recentlyViewedProfileList);
                    }
                }
            } catch (error) {
                console.error("Failed to fetch recommend list:", error);
            } finally {
                setIsInit(true);
            }
        };
        fetchHomeInfo();
    }, []);

    return (
        <IndexComponent
            isInit={isInit}
            keyword={keyword}
            setKeyword={setKeyword}
            list={list}
            hasSearch={hasSearch}
            isLoading={isLoading}
            onSearch={handleSearch}
            updatedProfileList={updatedProfileList}
            recentlyViewedProfileList={recentlyViewedProfileList}
            showModal={showModal}
            setShowModal={setShowModal}
            searchFilter={searchFilter}
            onSearchFilter={handleSearchFilter}
            onDeleteCountryCode={handleDeleteCountryCode}
            onClickStudent={handleClickStudent}
        />
    );
};

export default IndexContainer;
