import React from "react";

const ImageWithWatermarkForPresignedUrlPopupComponent = (props: any) => {
    const { canvasRef, onClose } = props;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="relative w-full h-full p-4">
                <button
                    type="button"
                    className="absolute top-4 right-4 text-white hover:text-gray-300 z-10"
                    onClick={onClose}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <div className="w-full h-full flex items-center justify-center">
                    <canvas
                        ref={canvasRef}
                        style={{
                            maxWidth: '95vw',
                            maxHeight: '90vh',
                            width: 'auto',
                            height: 'auto',
                            objectFit: 'contain'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ImageWithWatermarkForPresignedUrlPopupComponent;