import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import { Link, useLocation, useNavigate } from "react-router-dom";

const PublicProfileEssentialInfoEditComponent = (props: any) => {
    const {
        id,
        profileParams,
        imageInfo,
        setImageInfo,
        isOnNetworking,
        uploadImage,
        onChangeInput,
        onClickBtnUpdateInfo,
    } = props;

    const { pathname } = useLocation();

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <div className="flex items-center mb-6">
                    <Link
                        to="/student/profile/public"
                        className="inline-flex items-center text-gray-600 hover:text-gray-900"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </Link>
                </div>
                <div className="flex items-center justify-between mb-8">
                    <h2 className="text-3xl font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtTitle")}
                    </h2>
                </div>

                {id ? (
                    <div className="space-y-6">
                        {/* 프로필 이미지 섹션 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6">
                                {/* 전체 컨테이너를 그리드로 변경 */}
                                <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                                    {/* 이미지 섹션 - 큰 화면에서는 4칸 차지 */}
                                    <div className="lg:col-span-4">
                                        <div className="space-y-6">
                                            {/* 메인 프로필 이미지 */}
                                            <div className="flex flex-col items-center">
                                                <div className="relative w-40 h-40 lg:w-48 lg:h-48">
                                                    {imageInfo?.url ? (
                                                        <>
                                                            <img
                                                                src={imageInfo.url}
                                                                alt="Profile"
                                                                className="w-full h-full object-cover rounded-full"
                                                            />
                                                            <button
                                                                onClick={() => setImageInfo({})}
                                                                className="absolute -top-2 -right-2 p-1 bg-white rounded-full shadow-sm">
                                                                <svg className="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                                </svg>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <div className="w-full h-full bg-gray-100 rounded-full flex items-center justify-center">
                                                            <svg className="w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                            </svg>
                                                        </div>
                                                    )}
                                                </div>
                                                <input
                                                    type="file"
                                                    id="input-profile-img"
                                                    className="hidden"
                                                    onChange={(evt) => uploadImage("profile", evt)}
                                                    accept="image/*"
                                                />
                                                <label
                                                    htmlFor="input-profile-img"
                                                    className="mt-4 inline-flex items-center px-4 py-2 text-sm font-medium text-[#CF3688] bg-white border border-[#CF3688] rounded-lg hover:bg-[#CF3688] hover:text-white transition-colors cursor-pointer">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.btnChooseImage")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 입력 폼 섹션 - 큰 화면에서는 8칸 차지 */}
                                    <div className="lg:col-span-8 space-y-8">
                                        {/* 국가 선택 */}
                                        <div className="relative">
                                            <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                <span className="text-[#CF3688] mr-1">*</span>
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtCountry")}
                                            </label>
                                            <div className="relative">
                                                <select
                                                    required
                                                    value={profileParams.countryCode}
                                                    onChange={(evt) => onChangeInput("countryCode", evt.target.value)}
                                                    className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                        focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                        text-base transition-colors appearance-none">
                                                    {commonData.getCountryCodeList().map((item: any, idx: number) => (
                                                        <option key={idx} value={item.code}>
                                                            {getCurrentLangName(item.names)}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                                    <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        {/* 이름 입력 */}
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                            <div>
                                                <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                    <span className="text-[#CF3688] mr-1">*</span>
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtLastName")}
                                                </label>
                                                <input
                                                    required
                                                    type="text"
                                                    value={profileParams.lastName}
                                                    onChange={(evt) => onChangeInput("lastName", evt.target.value)}
                                                    placeholder="성"
                                                    className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                        focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                        text-base transition-colors"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                    <span className="text-[#CF3688] mr-1">*</span>
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtFirstName")}
                                                </label>
                                                <input
                                                    required
                                                    type="text"
                                                    value={profileParams.firstName}
                                                    onChange={(evt) => onChangeInput("firstName", evt.target.value)}
                                                    placeholder="이름"
                                                    className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                        focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                        text-base transition-colors"
                                                />
                                            </div>
                                        </div>

                                        {/* 생년월일 */}
                                        <div>
                                            <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                <span className="text-[#CF3688] mr-1">*</span>
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtBirthday")}
                                            </label>
                                            <input
                                                required
                                                type="date"
                                                value={profileParams.birthday}
                                                onChange={(evt) => onChangeInput("birthday", evt.target.value)}
                                                className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                    focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                    text-base transition-colors"
                                            />
                                        </div>

                                        {/* 성별 */}
                                        <div>
                                            <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                <span className="text-[#CF3688] mr-1">*</span>
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtGender")}
                                            </label>
                                            <div className="flex gap-8 px-2">
                                                <label className="relative flex items-center">
                                                    <input
                                                        required
                                                        type="radio"
                                                        name="genderTypeCode"
                                                        value="genderType:girl"
                                                        checked={profileParams.genderTypeCode === "genderType:girl"}
                                                        onChange={(evt) => onChangeInput("genderTypeCode", evt.target.value)}
                                                        className="form-radio h-6 w-6 text-[#CF3688] border-2 border-gray-200
                                                            focus:ring-2 focus:ring-[#CF3688]/20"
                                                    />
                                                    <span className="ml-2 text-base text-gray-700">
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtGirl")}
                                                    </span>
                                                </label>
                                                <label className="relative flex items-center">
                                                    <input
                                                        required
                                                        type="radio"
                                                        name="genderTypeCode"
                                                        value="genderType:boy"
                                                        checked={profileParams.genderTypeCode === "genderType:boy"}
                                                        onChange={(evt) => onChangeInput("genderTypeCode", evt.target.value)}
                                                        className="form-radio h-6 w-6 text-[#CF3688] border-2 border-gray-200
                                                            focus:ring-2 focus:ring-[#CF3688]/20"
                                                    />
                                                    <span className="ml-2 text-base text-gray-700">
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtBoy")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>

                                        {/* 닉네임 */}
                                        <div>
                                            <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtNickname")}
                                                <p className="text-sm text-gray-500 mt-1">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtNicknameDesc")}
                                                </p>
                                            </label>
                                            <input
                                                type="text"
                                                value={profileParams.nickname}
                                                onChange={(evt) => onChangeInput("nickname", evt.target.value)}
                                                placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.txtNicknamePlaceholder")}
                                                className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                    focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                    text-base transition-colors"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* 버튼 그룹 추가 */}
                                <div className="mt-8 flex flex-col sm:flex-row gap-4 sm:gap-6">
                                    <Link
                                        to="/student/profile/public"
                                        className="w-full sm:w-1/2 px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 border-2 border-gray-200 rounded-xl
                                            hover:bg-gray-80 focus:outline-none focus:ring-2 focus:ring-gray-200
                                            text-center
                                            transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.btnCancel")}
                                    </Link>
                                    <button
                                        disabled={isOnNetworking}
                                        onClick={onClickBtnUpdateInfo}
                                        className="w-full sm:w-1/2 px-4 py-3 text-sm font-medium text-white bg-[#CF3688] rounded-xl
                                            hover:bg-[#B82F77] focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                            transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileEdit.btnSave")}
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </main>
    );
};

export default PublicProfileEssentialInfoEditComponent;
