import React from "react";
import "./error.css";
import Error500Component from "./500.component";

const Error500Container = () => {
    return (
        <Error500Component />
    );
};

export default Error500Container;