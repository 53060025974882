import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CellphoneComponent from "./Cellphone.component";
import * as commonData from "data/common.data";
import * as MyApi from "api/agency/v2/My.api";
import * as AuthApi from "api/common/Auth.api";
import { checkCellphoneOnlyNumber } from "helpers/Reg.helper";

const CellphoneContainer = () => {
    const navigate = useNavigate();
    const [isInit, setIsInit] = useState<boolean>(false);
    const [currentCellphone, setCurrentCellphone] = useState<string>("");
    const [newCellphone, setNewCellphone] = useState<string>("");
    const [verifyingNumber, setVerifyingNumber] = useState<string | null>(null);
    const [isOnSedingVerifyNumber, setIsOnSedingVerifyNumber] = useState<boolean>(false);
    const [hasSentVerifyNumber, setHasSentVerifyNumber] = useState<boolean>(false);
    const [verifyingId, setVerifyingId] = useState<string>("");
    const [hasVerified, setHasVerified] = useState<boolean>(false);

    const countryPhoneCode = "+82";
    const countryPhoneCodeList = commonData.getCountryPhoneCodeList();

    const init = async () => {
        const resultData = await MyApi.getCellphone();
        if (resultData.cellphone) {
            setCurrentCellphone(resultData.cellphone);
        }
        setIsInit(true);
    };

    const handleSendVerifyNumber = async () => {
        console.log("handleSendVerifyNumber");
        if (isOnSedingVerifyNumber) {
            return;
        }

        if (!newCellphone) {
            window.alert("휴대전화 번호를 입력해주세요.");
            return;
        } else if (!checkCellphoneOnlyNumber(newCellphone)) {
            window.alert("휴대전화 번호 형식이 올바르지 않아요.");
            return;
        } else if (newCellphone === currentCellphone) {
            window.alert("같은 휴대전화 번호는 인증할 수 없어요.");
            return;
        }

        setIsOnSedingVerifyNumber(true);
        const data = await AuthApi.sendSmsVerifyingNumber(countryPhoneCode, newCellphone);
        if (data?.id) {
            setVerifyingId(data.id);
            window.alert("인증번호가 발송되었습니다.");
        }

        setIsOnSedingVerifyNumber(false);
    };

    const handleVerifyNumber = async () => {
        console.log("handleVerifyNumber");
        if (!verifyingId) {
            return;
        }

        if (!verifyingNumber) {
            window.alert("인증번호를 입력해주세요.");
            return;
        }

        const data = await AuthApi.verifySmsVerifyingNumber(verifyingId, verifyingNumber);
        console.log({ data });
        if (data?.result) {
            setHasVerified(true);
            window.alert("인증 되었습니다. 저장 버튼을 눌러 저장해주세요.");
        }
    };

    const handleSave = async () => {
        console.log("handleSave");
        if (!hasVerified) {
            window.alert("인증 되지 않았습니다. 인증 버튼을 눌러 인증해주세요.");
            return;
        }

        const data = await AuthApi.updateAgencyCellphone(verifyingId, countryPhoneCode, newCellphone);
        if (data?.result) {
            window.alert("휴대전화 번호가 수정되었습니다.");
            navigate(-1);
        }
    };

    useEffect(() => {
        init();
    }, []);

    return <CellphoneComponent
        currentCellphone={currentCellphone}
        setCurrentCellphone={setCurrentCellphone}
        newCellphone={newCellphone}
        setNewCellphone={setNewCellphone}
        verifyingId={verifyingId}
        verifyingNumber={verifyingNumber}
        setVerifyingNumber={setVerifyingNumber}
        countryPhoneCodeList={countryPhoneCodeList}
        onSendVerifyNumber={handleSendVerifyNumber}
        onVerifyNumber={handleVerifyNumber}
        isOnSedingVerifyNumber={isOnSedingVerifyNumber}
        hasSentVerifyNumber={hasSentVerifyNumber}
        hasVerified={hasVerified}
        onSave={handleSave}
    />;
};

export default CellphoneContainer;