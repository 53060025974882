import PhotosPopupComponent from "./PhotosPopup.component";
import { useEffect, useState } from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getPhotosList, createPhoto, deletePhoto, getPhotoInfo } from "api/student/v2/FanPage.api";

const PhotosPopupContainer = (props: any) => {
    const { selectedPhoto, onClose } = props;

    const [isOnNetworking, setIsOnNetworking] = useState(false);
    const [photosList, setPhotosList] = useState([]);

    const [isOnLoading, setIsOnLoading] = useState(true);
    const [info, setInfo] = useState({});

    const handleDelete = async (photoId: string) => {
        if (isOnNetworking)
            return

        if (window.confirm(getLanguageValue(PAGE_TYPE.STUDENT, "photos.txtConfirmDelete"))) {
            setIsOnNetworking(true);
            await deletePhoto(photoId)
                .then((data: any) => {
                    if (data) {
                        setPhotosList(photosList.filter((photo: any) => photo.id !== photoId));
                    }
                })
                .finally(() => {
                    setIsOnNetworking(false);
                });
        }
    }

    useEffect(() => {
        init();
    }, [selectedPhoto]);

    const init = async () => {
        try {
            const data = await getPhotoInfo(selectedPhoto.id);
            if (data.info) {
                setInfo(data.info);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsOnLoading(false);
        }
    }

    return <PhotosPopupComponent selectedPhoto={selectedPhoto} onClose={onClose}
        isOnNetworking={isOnNetworking}
        photosList={photosList}
        handleDelete={handleDelete}
        isOnLoading={isOnLoading}
        info={info}
    />;
};

export default PhotosPopupContainer;