import React from "react";
import { Outlet } from "react-router-dom";

const LayoutOnlyHomeButton = () => {
    return (
        <div className="relative min-h-screen w-full">
            <div className="fixed top-4 left-4 z-50 sm:top-5 sm:left-5">
                <button 
                    onClick={() => window.location.href = "/"} 
                    className="px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-white border border-gray-200 cursor-pointer shadow-sm hover:bg-gray-50 text-sm sm:text-base"
                >
                    HOME
                </button>
            </div>
            <div className="w-full">
                <Outlet />
            </div>
        </div>
    );
};

export default LayoutOnlyHomeButton;