//* 지망생 정보 관련 API
// 2025.03.26
// 희문

import * as BaseApi from "api/Base.api";
const BASE_URL = "/student/v2/student";

//* 지망생 정보 조회
// 2025.03.26
// 희문
//* Params
// studentId: string
export const getStudentInfo = async (studentId: string) => {
    const url = `${BASE_URL}/${studentId}`;
    return await BaseApi.get(url);
};

//* 추가 사진 목록 요청
// 2025.03.26
// 희문
//* Params
// studentId: string
// page: number
// limit: number
export const getStudentPhotos = async (studentId: string, page: number = 1, limit: number = 10) => {
    const url = `${BASE_URL}/${studentId}/photos`;
    return await BaseApi.get(url, { page, limit });
};
