import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const BASE_PATH = '/agency';
const MenuComponent = () => {
    const { pathname } = useLocation();

    const getIconSrc = (path: string, icon: string) => {
        return path === BASE_PATH
            ? pathname === path ? `./imgs/icons/agency/${icon}-active.svg` : `./imgs/icons/agency/${icon}.svg`
            : pathname.startsWith(path) ? `./imgs/icons/agency/${icon}-active.svg` : `./imgs/icons/agency/${icon}.svg`;
    };

    return (

        <div className="fixed bottom-0 left-0 right-0 flex justify-center pb-2">
            {/* 메인 메뉴 */}
            <div className={`bg-white/80 backdrop-blur-md rounded-2xl shadow-sm px-1 py-1 transition-all duration-300 opacity-100 translate-y-0 visible'}`}>
                <div className="flex gap-2 sm:gap-4 md:gap-6">
                    {/* 홈 */}
                    <Link to={BASE_PATH} className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                            <img src={getIconSrc(BASE_PATH, 'icon-home')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtHome")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname === BASE_PATH ? 'text-[#CF3688]' : 'text-gray-700'} text-center`}>
                                HOME
                            </p>
                        </div>
                    </Link>

                    {/* 통계 */}
                    {/* <Link to={BASE_PATH + "/statistics"} className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                            <img src={getIconSrc(BASE_PATH + "/statistics", 'icon-statistics')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtCasting")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname.startsWith(BASE_PATH + "/statistics") ? 'text-[#CF3688]' : 'text-gray-700'} text-center`}>
                                STATISTICS
                            </p>
                        </div>
                    </Link> */}

                    {/* 즐겨찾기 */}
                    <Link to={BASE_PATH + "/favorite"} className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                            <img src={getIconSrc(BASE_PATH + "/favorite", 'icon-favorite')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtFavorite")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname.startsWith(BASE_PATH + "/favorite") ? 'text-[#CF3688]' : 'text-gray-700'} text-center`}>
                                FAVORITE
                            </p>
                        </div>
                    </Link>

                    {/* 팬페이지 */}
                    <Link to={BASE_PATH + "/message"} className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                            <img src={getIconSrc(BASE_PATH + "/message", 'icon-message')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtMessage")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname.startsWith(BASE_PATH + "/message") ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                MESSAGE
                            </p>
                        </div>
                    </Link>

                    {/* 검색 */}
                    <Link to={BASE_PATH + "/search"} className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                            <img src={getIconSrc(BASE_PATH + "/search", 'icon-search')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtSearch")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname.startsWith(BASE_PATH + "/search") ? 'text-[#CF3688]' : 'text-gray-700'} text-center`}>
                                SEARCH
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )

        ;
};

export default MenuComponent;