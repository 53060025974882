import React from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getImageUrl } from "helpers/Image.helper";
import { Link } from "react-router-dom";
import { formattedDateTimeForLocale } from "helpers/Datetime.helper";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";

interface PostInfoComponentProps {
    post: any;
    comments: Array<any>;
    isLoading: boolean;
    isLoadingComments: boolean;
    hasMoreComments: boolean;
    onLoadMoreComments: () => void;
    translatedComments: { [key: string]: string };
    onTranslateComment: (commentId: number, text: string) => void;
    commentText: string;
    isPostingComment: boolean;
    onCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onCreateComment: () => void;
    onDeleteComment: (commentId: number) => void;
}

const PostInfoComponent: React.FC<PostInfoComponentProps> = ({
    post,
    comments,
    isLoading,
    isLoadingComments,
    hasMoreComments,
    onLoadMoreComments,
    translatedComments,
    onTranslateComment,
    commentText,
    isPostingComment,
    onCommentChange,
    onCreateComment,
    onDeleteComment,
}) => {
    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <LoadingComponent />
            </div>
        );
    }

    if (!post) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p className="text-gray-500">포스트를 찾을 수 없습니다.</p>
            </div>
        );
    }

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <div className="flex items-center mb-6">
                    <Link
                        to="/student/fan-page/post"
                        className="inline-flex items-center text-gray-600 hover:text-gray-900"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </Link>
                </div>
                <div className="flex items-center justify-between mb-8">
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "postInfo.txtTitle")}
                    </h2>
                </div>

                <div className="max-w-2xl mx-auto">
                    {/* 포스트 카드 */}
                    <div className="bg-white rounded-2xl shadow-sm p-6">
                        {/* 헤더 영역 */}
                        <div className="flex justify-between items-start mb-6">
                            <div>
                                <p className="text-sm text-gray-500">
                                    {formattedDateTimeForLocale(post.createdAt, navigator.language || 'en-US')}
                                </p>
                            </div>
                            <div className="flex space-x-2">
                                <Link to={`/student/fan-page/post/${post.id}/edit`}
                                    className="p-2 text-gray-500 hover:text-[#CF3688] transition-colors">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                    </svg>
                                </Link>
                                <button className="p-2 text-gray-500 hover:text-red-500 transition-colors">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        {/* 본문 영역 */}
                        <div className="mb-6">
                            <p className="text-gray-900 whitespace-pre-wrap break-words text-sm sm:text-base md:text-lg">
                                {post.text}
                            </p>
                        </div>

                        {/* 이미지 영역 */}
                        {post.postImageList?.length > 0 && (
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-6">
                                {post.postImageList.map((image: any) => (
                                    <div key={image.id} className="relative aspect-square bg-gray-100 rounded-xl overflow-hidden">
                                        <img
                                            src={getImageUrl(image.imageInfo)}
                                            alt=""
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* 메타 정보 */}
                        <div className="flex items-center space-x-6 text-gray-500 border-t border-gray-100 pt-4">
                            <div className="flex items-center space-x-2">
                                <button className="hover:text-[#CF3688] transition-colors">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                    </svg>
                                </button>
                                <span className="text-sm">{post.likeCount || 0}</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                </svg>
                                <span className="text-sm">{post.commentCount || 0}</span>
                            </div>
                        </div>

                        {/* 댓글 입력란 */}
                        <div className="mt-4 flex items-center space-x-2">
                            <input
                                type="text"
                                value={commentText}
                                onChange={onCommentChange}
                                placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "postInfo.txtCommentPlaceholder")}
                                disabled={isPostingComment}
                                className="flex-1 p-3 border border-gray-200 rounded-xl
                                    focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688]
                                    disabled:bg-gray-50 disabled:text-gray-500"
                            />
                            <button
                                onClick={onCreateComment}
                                disabled={!commentText.trim() || isPostingComment}
                                className={`px-4 py-3 rounded-xl transition-colors
                                    focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                    ${(!commentText.trim() || isPostingComment)
                                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                                        : 'bg-[#CF3688] text-white hover:bg-[#B32E74]'
                                    }`}>
                                {isPostingComment ? getLanguageValue(PAGE_TYPE.STUDENT, "postInfo.txtCommentWriting") : getLanguageValue(PAGE_TYPE.STUDENT, "postInfo.txtCommentWrite")}
                            </button>
                        </div>
                    </div>

                    {/* 댓글 섹션 */}
                    <div className="mt-6 bg-white rounded-2xl shadow-sm p-6">
                        <h2 className="text-lg font-semibold text-gray-900 mb-6">
                            {getLanguageValue(PAGE_TYPE.STUDENT, "postInfo.txtCommentTitle")}
                        </h2>

                        {/* 댓글 목록 */}
                        <div className="space-y-6">
                            {comments.map(comment => (
                                <div key={comment.id} className="flex space-x-4">
                                    <div className="flex-1">
                                        {/* 헤더 영역 */}
                                        <div className="flex items-center justify-between mb-3">
                                            {/* 좌측: 프로필, 이름, 날짜 */}
                                            <div className="flex items-center space-x-3">
                                                <div className="w-10 h-10 rounded-full bg-gray-200 overflow-hidden">
                                                    {comment.userFanInfo?.profileImageInfo && (
                                                        <img
                                                            src={getImageUrl(comment.userFanInfo.profileImageInfo)}
                                                            alt={comment.userFanInfo.name}
                                                            className="w-full h-full object-cover"
                                                        />
                                                    )}
                                                    {comment.userStudentInfo?.profileImageInfo && (
                                                        <img
                                                            src={getImageUrl(comment.userStudentInfo.profileImageInfo)}
                                                            alt={comment.userStudentInfo.name}
                                                            className="w-full h-full object-cover"
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    <div className="font-medium text-gray-900">
                                                        {comment.userFanInfo?.name && comment.userFanInfo.name}
                                                        {comment.userStudentInfo?.nickname && comment.userStudentInfo.nickname}
                                                    </div>
                                                    <div className="text-sm text-gray-500">
                                                        {formattedDateTimeForLocale(comment.createdAt, navigator.language || 'en-US')}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* 우측: 삭제 버튼 */}
                                            <button
                                                onClick={() => onDeleteComment(comment.id)}
                                                className="text-gray-400 hover:text-red-500 transition-colors">
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                </svg>
                                            </button>
                                        </div>

                                        {/* 본문 영역 */}
                                        <div className="space-y-2">
                                            <p className="text-gray-700">{comment.text}</p>

                                            {/* 번역 영역 */}
                                            <div className="flex flex-col space-y-2">
                                                {!translatedComments[comment.id] && (
                                                    <button
                                                        onClick={() => onTranslateComment(comment.id, comment.text)}
                                                        className="text-sm text-[#CF3688] hover:text-[#B32E74] transition-colors self-start">
                                                        {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtTranslate")}
                                                    </button>
                                                )}

                                                {translatedComments[comment.id] && (
                                                    <div className="mt-2 p-3 bg-gray-50 border-l-4 border-[#CF3688] rounded">
                                                        <p className="text-sm text-gray-600">
                                                            {translatedComments[comment.id]}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>

                                            {/* 좋아요 버튼 영역 */}
                                            <div className="flex items-center space-x-2 text-gray-500">
                                                <button className="hover:text-[#CF3688] transition-colors">
                                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                    </svg>
                                                </button>
                                                <span className="text-sm">{comment.likeCount || 0}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* 댓글 더보기 */}
                        {hasMoreComments && (
                            <div className="flex justify-center mt-6">
                                <button
                                    onClick={onLoadMoreComments}
                                    disabled={isLoadingComments}
                                    className="px-6 py-2 bg-white text-[#CF3688] border-2 border-[#CF3688] rounded-xl
                                        hover:bg-[#CF3688] hover:text-white transition-colors
                                        focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                        disabled:opacity-50 disabled:cursor-not-allowed">
                                    {isLoadingComments ? getLanguageValue(PAGE_TYPE.COMMON, "btn.txtLoading") : getLanguageValue(PAGE_TYPE.COMMON, "btn.txtCommentMore")}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default PostInfoComponent;