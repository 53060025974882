import React, { useEffect, useState } from "react";
import IndexComponent from "./Index.component";
import { getCurrentLangName } from "helpers/String.helper";
import * as StudentApi from "api/student/Student.api";
import * as commonData from "data/common.data";
import * as ProfileApi from "api/student/Profile.api";
import { usePopup } from "contexts/PopupContext";
import AgencyInfoPopupContainer from "../popup/AgencyInfoPopup.container";

const IndexContainer = () => {
    const { showPopup } = usePopup();

    const [info, setInfo] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        getInfo();
    }, []);

    const getInfo = async () => {
        try {
            const data = await ProfileApi.getProfileSummary();

            if (data?.info) {
                const { info } = data;
                setInfo(info);
                setIsLoading(false);
            }
        } catch (error: any) {
            console.log(error);
            setErrorMessage(error.message || "Unknown error occurred");

        } finally {
            setIsLoading(false);
        }
    };

    const showAgencyInfoPopup = (agencyId: number) => {
        showPopup(<AgencyInfoPopupContainer
            agencyId={agencyId}
        />);
    };

    return <IndexComponent info={info}
        isLoading={isLoading}
        errorMessage={errorMessage}
        showAgencyInfoPopup={showAgencyInfoPopup}
    />
};

export default IndexContainer;