import React, { useState, useRef, useEffect } from "react";
import ImageWithWatermarkForPresignedUrlPopupComponent from "./ImageWithWatermarkForPresignedUrlPopup.component";
import { getImagePresignedUrl } from "api/common/Data.api";

const ImageWithWatermarkForPresignedUrlPopupContainer = (props: any) => {
    const { imageId, watermarkText, onClose } = props;

    const [isInit, setIsInit] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const init = async () => {
        console.log("commons/image/ImageWithWatermarkForPresignedUrl.container::init");
        const canvas = canvasRef.current;
        if (!canvas) return;

        console.log("commons/image/ImageWithWatermarkForPresignedUrl.container::canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        try {
            console.log("commons/image/ImageWithWatermarkForPresignedUrl.container::getImagePresignedUrl");
            const imageResult = await getImagePresignedUrl(imageId);
            if (!imageResult?.result) return;

            const presignedUrl = imageResult.result;
            console.log("commons/image/ImageWithWatermarkForPresignedUrl.container::imageUrl", presignedUrl);

            // 이미지 로딩을 Promise로 래핑
            await new Promise((resolve, reject) => {
                const image = new Image();
                image.crossOrigin = "anonymous";  // CORS 이슈 방지

                image.onload = () => {
                    try {
                        canvas.width = image.width;
                        canvas.height = image.height;

                        // 이미지 그리기
                        ctx.drawImage(image, 0, 0);

                        // 워터마크 추가
                        const fontSize = Math.max(canvas.width, canvas.height) * 0.025;
                        ctx.font = `${fontSize}px Arial`;
                        ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
                        ctx.textAlign = "center";
                        ctx.textBaseline = "middle";  // bottom에서 middle로 변경

                        const text = watermarkText;
                        const lineGap = fontSize * 8;
                        const textAngle = (-45 * Math.PI) / 180;

                        for (let y = 0; y < canvas.height * 2; y += lineGap) {
                            for (let x = 0; x < canvas.width * 2; x += lineGap * 3) {
                                ctx.save();
                                ctx.translate(x - canvas.width / 2, y - canvas.height / 2);
                                ctx.rotate(textAngle);
                                ctx.fillText(text, 0, 0);
                                ctx.restore();
                            }
                        }

                        setImageUrl(presignedUrl);
                        resolve(true);
                    } catch (error) {
                        reject(error);
                    }
                };

                image.onerror = (error) => {
                    reject(error);
                };

                image.src = presignedUrl;
            });

            
        } catch (error) {
            console.error("이미지 로딩 중 오류 발생:", error);
        } finally {
            setIsInit(true);
        }
    };

    useEffect(() => {
        init();
    }, [imageId, watermarkText]);

    return <ImageWithWatermarkForPresignedUrlPopupComponent
        isInit={isInit}
        imageUrl={imageUrl}
        canvasRef={canvasRef}
        imageId={imageId}
        watermarkText={watermarkText}
        onClose={onClose}
    />;
};

export default ImageWithWatermarkForPresignedUrlPopupContainer;