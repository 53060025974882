 import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

// import * as commonData from "data/common.data";
// import { getCurrentLangName } from "helpers/String.helper";

const DeleteAccountComponent = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        onClickBtnDeleteAccount,
        // setWithdrawalReasonCode,
        setWithdrawalOtherReason,
        isButtonDisabled,
        password,
        setPassword,
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen py-6">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center gap-4 mb-6">
                    <button onClick={() => navigate(-1)} className="text-gray-600 hover:text-gray-900">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <h1 className="text-2xl font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtTitle")}
                    </h1>
                </div>

                <div className="bg-white rounded-2xl p-6 shadow-sm">
                    <div className="space-y-6">
                        {/* <h3 className="text-lg font-bold text-gray-900">{getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtDeleteAccount1")}</h3> */}
                        <div className="text-gray-700">
                            <p className="mb-4">{getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtDeleteAccount1")}</p>
                            <p className="mb-4">・{getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtDeleteAccount2")}</p>
                            <p>・{getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtDeleteAccount3")}</p>
                        </div>

                        {location.pathname.includes("student") && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    {getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtEnterReason")}
                                </label>
                                <textarea
                                    onChange={(evt) => setWithdrawalOtherReason(evt.target.value)}
                                    placeholder={getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtEnterReasonPlaceholder")}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base min-h-[120px]"
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="mt-6 bg-white rounded-2xl p-6 shadow-sm">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            {getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtPassword")}
                        </label>
                        <input
                            type="password"
                            onChange={(evt) => props.setPassword(evt.target.value)}
                            value={password}
                            placeholder={getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtPasswordPlaceholder")}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base"
                        />
                    </div>
                </div>

                <div className="mt-6 space-y-4">
                    <button
                        disabled={isButtonDisabled}
                        onClick={() => onClickBtnDeleteAccount()}
                        className="w-full py-3 px-4 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors disabled:bg-gray-300"
                    >
                        {getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.btnDelete")}
                    </button>
                    <button
                        onClick={() => navigate(location.pathname.replace("/delete-account", ""))}
                        className="w-full py-3 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium rounded-lg transition-colors"
                    >
                        {getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.btnCancel")}
                    </button>
                </div>
            </div>
        </main>
    );
};

export default DeleteAccountComponent;
