import React from 'react';
import { getImageUrl } from 'helpers/Image.helper';
import LoadingComponent from 'commons/loading/Loading.component';

const RequestChatPopupComponent = (props: any) => {
    const { info, onClose, isInit, onDenyChatRequest, onAcceptChatRequest, isOnNetworking } = props;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 p-4 !m-0">
            <div className="bg-white rounded-2xl p-6 w-full max-w-2xl mx-auto">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-bold text-gray-900">대화 요청</h3>
                    <button
                        type="button"
                        className="text-gray-400 hover:text-gray-600"
                        onClick={onClose}>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {isInit && info && (
                    <div className="flex flex-col items-center">
                        <div className="h-32 mb-4">
                            <img
                                src={getImageUrl(info.userAgencyInfo.agencyInfo.logoImageInfo)}
                                alt="에이전시 로고"
                                className="w-full h-full object-contain"
                            />
                        </div>

                        <div className="text-center mb-4">
                            <h4 className="text-md mb-2">
                                {info.userAgencyInfo.agencyInfo.nameEn}
                            </h4>
                        </div>

                        <div className="flex gap-4 w-full">
                            {info.messageStatusInfo?.code === 'messageType:request' && (
                                <>
                                    <button
                                        className="w-full px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 border-2 border-gray-200 rounded-xl
                                    hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200
                                    transition-colors"
                                        disabled={isOnNetworking}
                                        onClick={onDenyChatRequest}>
                                        요청 거절
                                    </button>
                                    <button
                                        className="w-full px-4 py-3 text-sm font-medium text-white bg-[#CF3688] rounded-xl
                                    hover:bg-[#B82F77] focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                    transition-colors"
                                        disabled={isOnNetworking}
                                        onClick={onAcceptChatRequest}>
                                        요청 수락
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                )}
                {!isInit &&
                    <LoadingComponent />
                }
            </div>
        </div>
    );
};

export default RequestChatPopupComponent;