import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomeComponent from "./MessageHome.component";
import { getRequestList, getChatList } from "api/student/v2/chat.api";
import RequestChatPopupContainer from "../popup/RequestChatPopup.container";
import { usePopup } from "contexts/PopupContext";
const HomeContainer = () => {
    const navigate = useNavigate();
    const { showPopup } = usePopup();
    const [isOnNetworking, setIsOnNetworking] = useState(false);
    const [requestList, setRequestList] = useState<Array<any>>([]);
    const [roomList, setRoomList] = useState<Array<any>>([]);

    // 홈 정보 조회
    const fetchHomeInfo = useCallback(async () => {
        try {
            setIsOnNetworking(true);

            const requestData = await getRequestList();
            if (requestData?.list) {
                setRequestList(requestData.list || []);
            }

            // 채팅방 목록 설정
            const channelData = await getChatList("messageType:accept");
            if (channelData?.list) {
                setRoomList(channelData.list || []);
            }
        } catch (error) {
            console.error('Failed to fetch home info:', error);
        } finally {
            setIsOnNetworking(false);
        }
    }, []);

    const handleRequestClick = (messageRequestId: number) => {
        showPopup(<RequestChatPopupContainer
            messageRequestId={messageRequestId}
            onCloseCallback={(channelId: string | boolean) => {
                if (channelId == true) {
                    fetchHomeInfo();
                } else if (channelId) {
                    navigate(`/student/casting/message/${channelId}/chat`);
                }

            }}
        />);
    };

    useEffect(() => {
        fetchHomeInfo();
    }, [fetchHomeInfo]);

    return (
        <HomeComponent
            isOnNetworking={isOnNetworking}
            requestList={requestList}
            roomList={roomList}
            onRequestClick={handleRequestClick}
        />
    );
};

export default HomeContainer;