import React, { useEffect, useState } from "react";
import StudentImagePopup from "./StudentImagePopup.component";

const StudentImagePopupContainer = (props: any) => {
    const { profileImageInfo, imageInfoList, setIsShowPopup, initialSlideIdx } = props;
    const [slideIdx, setSlideIdx] = useState(0);
    const [imageList, setImageList] = useState([]);

    useEffect(() => {
        setSlideIdx(initialSlideIdx);
        setImageList([profileImageInfo, ...imageInfoList]);

    }, []);

    useEffect(() => {
        console.log('imageList', imageList);
    }, [imageList]);

    return <StudentImagePopup {...props}
        imageList={imageList}
        slideIdx={slideIdx}
        setSlideIdx={setSlideIdx}
        setIsShowPopup={setIsShowPopup} />;
};

export default StudentImagePopupContainer;
