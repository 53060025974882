import { useState } from "react";
import { useEffect } from "react";
import GrowthInfoComponent from "./GrowthInfo.component";
import { fetchStudentGrowthInfo } from "api/agency/v2/Student.api";
import StudentBodyInfoPopupContainer from "../popup/StudentGrowthHistoryPopup.container";
import { usePopup } from "contexts/PopupContext";
interface GrowthInfoContainerProps {
    id: number;
    isPublicHeight: boolean;
    height: boolean;
    heightCreatedAt: string;
    isPublicWeight: boolean;
    weight: boolean;
    weightCreatedAt: string;
    isPublicShoeSize: boolean;
    shoeSize: boolean;
    shoeSizeCreatedAt: string;
}

const GrowthInfoContainer = (props: any) => {
    const { userStudentId } = props;
    const { showPopup } = usePopup();
    const [isInit, setIsInit] = useState(false);
    const [info, setInfo] = useState<GrowthInfoContainerProps>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    useEffect(() => {
        fetchInfo();
    }, [userStudentId]);

    const fetchInfo = async () => {
        try {
            const data = await fetchStudentGrowthInfo(userStudentId);
            if (data?.userStudentInfo) {
                setInfo(data?.userStudentInfo);
            }
        } catch (error: any) {
            setErrorMessage(error.message || "성장 정보를 불러오는데 실패했습니다.");
        } finally {
            setIsInit(true);
        }
    };

    const handleShowBodyInfoPopup = (bodyInfoTypeCode: string) => {
        console.log("GrowthInfoContainer::handleShowBodyInfoPopup");
        console.log({ bodyInfoTypeCode });

        showPopup(<StudentBodyInfoPopupContainer
            userStudentId={userStudentId}
            bodyInfoTypeCode={bodyInfoTypeCode}
        />);
    };

    return <GrowthInfoComponent
        isInit={isInit}
        info={info}
        onShowBodyInfoPopup={handleShowBodyInfoPopup}
        errorMessage={errorMessage}
    />;
};

export default GrowthInfoContainer;