import { useCallback, useEffect } from "react";
import { useState } from "react";
import PhotoComponent from "./Photo.component";
import { fetchStudentPhotoList } from "api/agency/v2/Student.api";
import { usePopup } from "contexts/PopupContext";
import StudentImageSlidePopup from "pages/agency/v2/popup/StudentImageSlidePopup.container";
const MAX_LIMIT = 10;
const PhotoContainer = (props: any) => {
    const { userStudentId } = props;
    const { showPopup, hidePopup } = usePopup();
    const [list, setList] = useState<any[]>([]);
    const [isInit, setIsInit] = useState<boolean>(false);
    const [isOnLoadMore, setIsOnLoadMore] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState<string>("");

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const res = await fetchStudentPhotoList(userStudentId, 1, MAX_LIMIT);
    //         setList(res.data);
    //     };
    //     fetchData();
    // }, [userStudentId]);


    const handleScroll = useCallback(() => {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        // 스크롤이 바닥에 도달했는지 체크
        if (scrollHeight - scrollTop <= clientHeight + 100) { // 100px 여유를 둠
            console.log('scroll to bottom')
            // 더 로드할 데이터가 있고 현재 로딩 중이 아닐 때만 다음 페이지 로드
            if (isInit && hasMore && !isOnLoadMore) {
                console.log('scroll to bottom')
                setPage(prevPage => prevPage + 1);
            }
        }
    }, [hasMore, isOnLoadMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const fetchList = useCallback(async () => {
        console.log("agency/v2/student/Photo.container::fetchList");
        console.log({ page });
        if (!hasMore) return;

        if (isOnLoadMore) return;

        try {
            setIsOnLoadMore(true);
            const data = await fetchStudentPhotoList(userStudentId, page, MAX_LIMIT);
            if (data?.userStudentInfo) {
                if (data?.userStudentInfo?.userStudentImageList) {
                    console.log(data?.userStudentInfo?.userStudentImageList);
                    setList(prev => page === 1 ? data?.userStudentInfo?.userStudentImageList : [...prev, ...data?.userStudentInfo?.userStudentImageList]);
                    setPage(page);
                    setHasMore(data?.userStudentInfo?.userStudentImageList?.length === MAX_LIMIT);
                }
            }
        } catch (error: any) {
            console.error("Failed to fetch list:", error);
            setErrorMessage(error.message || "사진 목록을 불러오는데 실패했습니다.");
        } finally {
            setIsOnLoadMore(false);
            setIsInit(true);
        }
    }, [page]);

    useEffect(() => {
        fetchList();
    }, [fetchList]);

    const handleShowPhotoPopup = (index: number) => {
        console.log("agency/v2/student/Photo.container::handleShowPhotoPopup");
        console.log({ index });
        const imageInfoList = list.map((item: any) => item.imageInfo);
        console.log({ imageInfoList });
        showPopup(<StudentImageSlidePopup
            initialSlideIdx={index}
            userStudentImageList={list} />);
    };

    return <PhotoComponent
        list={list}
        isInit={isInit}
        isOnLoadMore={isOnLoadMore}
        hasMore={hasMore}
        page={page}
        handleShowPhotoPopup={handleShowPhotoPopup}
        errorMessage={errorMessage}
    />;
};

export default PhotoContainer;