//* 채팅 관련 API
//  2025-02-27 추가
//  희문

import * as BaseApi from "api/Base.api";

const BASE_URL = "/agency/v2/chat";

//* 채팅 목록 조회
export const getChatList = async (messageStatusCode: string) => {
    return await BaseApi.get(`${BASE_URL}`, { messageStatusCode });
};

//* 채팅 요청 목록 조회
export const getRequestList = async () => {
    return await BaseApi.get(`${BASE_URL}/request`);
};

//* 채팅 요청
export const requestChat = async (userStudentId: string) => {
    return await BaseApi.post(`${BASE_URL}/request`, { userStudentId })
        .then((res: any) => {
            return res?.data?.data;
        });
};

//* 채팅 요청 상세 조회
export const fetchRequestDetail = async (requestId: string) => {
    return await BaseApi.get(`${BASE_URL}/request/${requestId}`);
};

//* 채팅 요청 취소
export const cancelChatRequest = async (requestId: string) => {
    return await BaseApi.post(`${BASE_URL}/${requestId}/cancel`, {})
        .then((res: any) => {
            return res?.data?.data;
        });
};

//* 채팅 시작
export const startChat = async (userStudentId: string) => {
    return await BaseApi.post(`${BASE_URL}/start`, { userStudentId })
        .then((res: any) => {
            return res?.data?.data;
        });
};

//* 채팅 종료
export const endChat = async (channelId: string) => {
    return await BaseApi.post(`${BASE_URL}/end`, { channelId })
        .then((res: any) => {
            return res?.data?.data;
        });
};

//* 채팅 신고
export const doReport = async (channelId: string, messageReportReasonCode: string, messageReportReason: string) => {
    const data = {
        channelId,
        messageReportReasonCode,
        messageReportReason
    }
    return await BaseApi.post(`${BASE_URL}/report`, data)
        .then((res: any) => {
            return res?.data?.data;
        });
};