import * as BaseApi from "api/Base.api";

//* 홈 정보 조회    
//  2025.03.04
//  희문
//* Params
//  none
export const fetchHomeInfo = async () => {
    const url = "/student/v2/home";
    return await BaseApi.get(url);
};
