import React, { useState, useEffect } from "react";
import FullPageFollowPopupComponent from "./FullPageFollowPopup.component";
import { fetchFollowerList, fetchFollowingList } from "api/student/v2/popup.api";

const FullPageFollowPopupContainer = (props: any) => {
        const { onClose } = props;
    const [activeTab, setActiveTab] = useState<"followers" | "following">("followers");
    const [followers, setFollowers] = useState<any[]>([]);
    const [following, setFollowing] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (activeTab === "followers") {
                    const data = await fetchFollowerList();

                    setFollowers(data.list || []);
                } else {
                    const data = await fetchFollowingList();
                    setFollowing(data.list || []);
                }
            } catch (error : any)  {
                console.error("Failed to fetch follow data:", error);
                setErrorMessage(error?.message || "Failed to fetch follow data");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [activeTab]);

    const handleShowStudentInfoPopup = (studentId: number) => {
        console.log("handleShowStudentInfoPopup", studentId);
    };

    const handleFollow = (studentId: number) => {
        console.log("handleFollow", studentId);
    };

    const handleUnfollow = (studentId: number) => {
        console.log("handleUnfollow", studentId);
    };

    return (
        <FullPageFollowPopupComponent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            followers={followers}
            following={following}
            isLoading={isLoading}
            onClose={onClose}
            onShowStudentInfoPopup={handleShowStudentInfoPopup}
            onFollow={handleFollow}
            onUnfollow={handleUnfollow}
        />
    );
};

export default FullPageFollowPopupContainer;