import React from "react";
import HeaderContainer from "./Header.container";
import MenuContainer from "./Menu.container";
import { Outlet, useLocation } from "react-router-dom";
const LayoutComponent = (props: any) => {
    const { needStandaloneMain } = props;
    return (
        <>
            {needStandaloneMain ? null : <HeaderContainer />}
            <Outlet />
            {needStandaloneMain ? null : <MenuContainer />}
        </>
    );
};

export default LayoutComponent;