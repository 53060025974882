import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const WannabeInfoComponent = (props: any) => {
    useEffect(() => {}, []);
    const navigate = useNavigate();

    const goSignupPage = () => {
        navigate("/signup/student");
    };

    return (
        <main className="v2-landing-main v2-landing-main-wannabe">
            <div className="con">
                <section className="v2-landing-header-section v2-landing-header-section-wannabe">
                    <div className="con">
                        <div className="title">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtTitle"),
                                }}></p>
                        </div>
                        <div className="body">
                            <div className="header-img">
                                <img alt="profile-card" src="./imgs/landing/wannabe-header-img.png" />
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 지망생 프로필카드 섹션 --> */}
                <section className="v2-landing-section-wannabe-1">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection101")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection102"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="item-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="item-info">
                                                <div className="title">
                                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection103")}</p>
                                                </div>
                                                <div className="desc">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection104"),
                                                        }}></p>
                                                </div>
                                                <div className="warn">
                                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection105")}</p>
                                                </div>
                                            </div>
                                            <div className="item-img">
                                                <div className="img">
                                                    <img alt="default info" src="./imgs/landing/wannabe-section-1-img-1.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item item-2">
                                            <div className="item-info">
                                                <div className="title">
                                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection106")}</p>
                                                </div>
                                                <div className="desc">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection107"),
                                                        }}></p>
                                                </div>
                                                <div className="warn">
                                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection108")}</p>
                                                </div>
                                            </div>
                                            <div className="item-img">
                                                <div className="img">
                                                    <img alt="default info" src="./imgs/landing/wannabe-section-1-img-2.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item item-3">
                                            <div className="item-info">
                                                <div className="title">
                                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection109")}</p>
                                                </div>
                                                <div className="desc">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection110"),
                                                        }}></p>
                                                </div>
                                            </div>
                                            <div className="item-img">
                                                <div className="img">
                                                    <img alt="default info" src="./imgs/landing/wannabe-section-1-img-3.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 캐스팅 매니저 전용 프로필 섹션 --> */}
                <section className="v2-landing-section-wannabe-2">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection201")}</p>
                            </div>
                            <div className="desc">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection202")}</p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="item-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="item-info">
                                                <div className="title">
                                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection203")}</p>
                                                </div>
                                                <div className="desc">
                                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection204")}</p>
                                                </div>
                                            </div>
                                            <div className="item-img">
                                                <div className="img">
                                                    <img alt="default info" src="./imgs/landing/wannabe-section-2-img-1.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item item-2">
                                            <div className="item-info">
                                                <div className="title">
                                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection205")}</p>
                                                </div>
                                                <div className="desc">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection206"),
                                                        }}></p>
                                                </div>
                                            </div>
                                            <div className="item-img">
                                                <div className="img">
                                                    <img alt="default info" src="./imgs/landing/wannabe-section-2-img-2.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item item-3">
                                            <div className="item-info">
                                                <div className="title">
                                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection207")}</p>
                                                </div>
                                                <div className="desc">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection208"),
                                                        }}></p>
                                                </div>
                                            </div>
                                            <div className="item-img">
                                                <div className="img">
                                                    <img alt="default info" src="./imgs/landing/wannabe-section-1-img-3.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 프로필 통계 섹션 --> */}
                <section className="v2-landing-section-wannabe-3">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection301")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection302"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="img">
                                                <img alt="statistics" src="./imgs/landing/wannabe-section-3-img-1.png" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item item-2">
                                            <div className="img">
                                                <img alt="statistics" src="./imgs/landing/wannabe-section-3-img-2.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 본인 인증섹션 --> */}
                <section className="v2-landing-section-wannabe-4">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection401")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection402"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-pc">
                                            <div className="img">
                                                <img alt="self verification" src="./imgs/landing/wannabe-section-4-img-pc.png" />
                                            </div>
                                        </div>
                                        <div className="item item-mb">
                                            <div className="img">
                                                <img alt="self verification" src="./imgs/landing/wannabe-section-4-img-mb.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 프로필 완성도 섹션 --> */}
                <section className="v2-landing-section-wannabe-5">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection501")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection502"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="text">
                                                <p>84%</p>
                                            </div>
                                            <div className="img">
                                                <img alt="Profile completeness" src="./imgs/landing/wannabe-section-5-img.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 캐스팅 채팅 섹션 --> */}
                <section className="v2-landing-section-wannabe-6">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection601")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection602"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="img">
                                                <img alt="self verification" src="./imgs/landing/wannabe-section-6-img.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 써드파티 섹션 --> */}
                <section className="v2-landing-section-wannabe-7">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection701")}</p>
                            </div>
                            <div className="desc">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection702")}</p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="text">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection703")}</p>
                                            </div>
                                            <div className="img">
                                                <img alt="self verification" src="./imgs/landing/wannabe-section-7-img-1.png" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <div className="text">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSection704")}</p>
                                            </div>
                                            <div className="img">
                                                <img alt="self verification" src="./imgs/landing/wannabe-section-7-img-2.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoWannabe.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="v2-landing-section-faq">
                    <div className="con">
                        <div className="body">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQnATitle")}</p>
                            </div>
                            <div className="faq-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="question">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQuestion1")}</p>
                                            </div>
                                            <div className="answer">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtAnswer1")}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <div className="question">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQuestion2")}</p>
                                            </div>
                                            <div className="answer">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtAnswer2")}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>
        </main>
    );
};

export default WannabeInfoComponent;
