import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingComponent from "commons/loading/Loading.component";
import { getCurrentLangName, getFanPageUrl, getFanPageUrlBySlug, getStudentNickname } from "helpers/String.helper";
import { getProfileImageUrl } from "helpers/Image.helper";
import StudentImagePopupContainer from "commons/popup/StudentImagePopup.container";
const StudentDetailComponent = (props: any) => {
  const navigate = useNavigate();

  const {
    studentInfo,
    slug,
    //isPublic,
    isLogin,
    myInfo,
    // favorite
    btnRef,
    isFavorited,
    changeFavoriteStatus,
    // image popup
    sliderRef,
    showImagePopup,
    isShowPopup,
    setIsShowPopup,
    initialSlideIdx,
    setInitialSlideIdx,
    // message
    messageRequest,
    createRequest,
    cancelRequest,
    isLoading,
    agencyId,
  } = props;

  const Arrow = () => {
    return <div />;
  };

  const settings = {
    className: "slider",
    infinite: studentInfo?.imageInfoList.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlideIdx,
    prevArrow: <Arrow />,
    nextArrow: <Arrow />,
  };

  return (
    <>
      <main className="container mx-auto px-4 sm:px-6 lg:px-8 pt-32 sm:pt-36 lg:pt-40">
        {/* 뒤로가기 버튼 */}
        <div className="mb-6">
          <button
            onClick={() => navigate("/")}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <img src="/imgs/icons/back.png" alt="back" className="w-6 h-6 mr-2" />
          </button>
        </div>

        {isLoading && (
          <div className="flex justify-center items-center h-64">
            <LoadingComponent />
          </div>
        )}

        {!isLoading && studentInfo && (
          <>
            <div className="max-w-7xl mx-auto">
              {/* 프로필 섹션 */}
              <div className="">
                <div className="relative mb-4  rounded-2xl overflow-hidden shadow-sm">
                  {/* 메인 프로필 이미지 */}
                  <div className="w-full">
                    <div className="relative pb-[100%]">
                      <img
                        src={getProfileImageUrl(studentInfo.profileImageInfo)}
                        alt={getStudentNickname(studentInfo)}
                        className="absolute inset-0 w-full h-full object-cover rounded-2xl cursor-pointer"
                        onClick={() => showImagePopup(1)}
                      />
                    </div>
                  </div>

                  {/* 프로필 정보 */}
                  <div className="absolute bottom-0 left-0 right-0 p-3 sm:p-4 lg:p-6 bg-gradient-to-t from-black/70 to-transparent">
                    <div className="flex items-center mb-1">
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-white">
                          {studentInfo.countryCodeInfo ? getCurrentLangName(studentInfo.countryCodeInfo.names) : 'unknown'}
                        </span>
                      </div>
                    </div>

                    <h1 className="text-2xl sm:text-3xl font-bold text-white">
                      {getStudentNickname(studentInfo)}
                    </h1>
                    <div className="flex flex-wrap gap-2 sm:gap-3">
                      {studentInfo.specialtyCodeInfoList
                        .map((info: any, index: number) => (
                          <span key={index} className="px-3 py-1 text-xs sm:text-sm lg:text-base bg-gray-100/90 rounded-full text-gray-600">
                            {getCurrentLangName(info.names)}
                          </span>
                        ))}
                    </div>
                  </div>
                </div>

                {/* 추가 사진 목록 */}
                <div className="w-full">
                  {/* <h2 className="text-lg sm:text-xl lg:text-2xl font-bold text-gray-900 mb-4">추가 사진</h2> */}
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                    {studentInfo.imageInfoList.map((image: any, index: number) => (
                      <div key={index} className="relative pb-[100%]">
                        <img
                          src={getProfileImageUrl(image)}
                          alt={`추가 사진 ${index + 1}`}
                          className="absolute inset-0 w-full h-full object-cover rounded-lg hover:opacity-90 transition-opacity cursor-pointer"
                          onClick={() => showImagePopup(index + 2)}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* 이미지 팝업 */}
                {isShowPopup && (
                  <StudentImagePopupContainer
                    isShowPopup={isShowPopup}
                    setIsShowPopup={setIsShowPopup}
                    initialSlideIdx={initialSlideIdx}
                    profileImageInfo={studentInfo.profileImageInfo}
                    imageInfoList={studentInfo.imageInfoList}
                  />
                )}
              </div>
            </div>


            <div className="mt-8 flex flex-col sm:flex-row gap-4 justify-center items-center">
              {/* 팬 페이지 */}
              {/* <div className="flex flex-col gap-2 w-full sm:w-1/3">
                <div className="bg-white/90 rounded-lg p-4 shadow-sm">
                  <p className="text-center font-medium text-gray-600 mb-3">{getLanguageValue(PAGE_TYPE.COMMON, "studentInfo.txtForFan")}</p>
                  <a
                    className="block w-full sm:w-auto px-8 py-3 bg-gradient-to-r from-[#FF0066] via-[#FF1493] to-[#FF69B4] !text-white font-bold rounded-lg hover:opacity-90 transition-opacity text-center hover:text-white"
                    href={getFanPageUrlBySlug(slug)}>
                    {getLanguageValue(PAGE_TYPE.COMMON, "studentInfo.btnForFan")}
                  </a>
                </div>
              </div> */}

              {/* 캐스팅 매니저 */}
              <div className="flex flex-col gap-2 w-full sm:w-1/3">
                <div className="bg-white/90 rounded-lg p-4 shadow-sm">
                  <p className="text-center font-medium text-gray-600 mb-3">{getLanguageValue(PAGE_TYPE.COMMON, "studentInfo.txtForCastingManager")}</p>
                  <Link
                    className="block w-full sm:w-auto px-8 py-3 bg-[#CF3688] !text-white font-bold rounded-lg hover:opacity-90 transition-opacity text-center hover:text-white"
                    to={`/signup/agency`}>
                    {getLanguageValue(PAGE_TYPE.COMMON, "studentInfo.btnForCastingManager")}
                  </Link>
                </div>
              </div>

              {/* 지망생 */}
              <div className="flex flex-col gap-2 w-full sm:w-1/3">
                <div className="bg-white/90 rounded-lg p-4 shadow-sm">
                  <p className="text-center font-medium text-gray-600 mb-3">{getLanguageValue(PAGE_TYPE.COMMON, "studentInfo.txtForStudent")}</p>
                  <Link
                    className="block w-full sm:w-auto px-8 py-3 bg-[#CF3688] !text-white font-bold rounded-lg hover:opacity-90 transition-opacity text-center hover:text-white"
                    to={`/signup/student`}>
                    {getLanguageValue(PAGE_TYPE.COMMON, "studentInfo.btnForStudent")}
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}

        {!isLoading && !studentInfo && (
          <div className="flex justify-center items-center h-64">
            <p className="text-gray-600">{getLanguageValue(PAGE_TYPE.COMMON, "studentInfo.txtEmpty")}</p>
          </div>
        )}
      </main>
    </>
  );
};
export default StudentDetailComponent;
