import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";
import { Link, useLocation } from "react-router-dom";
import { getCurrentLangName } from "helpers/String.helper";

const AdditionalInfoPublicProfileEditComponent = (props: any) => {
    const {
        id,
        profileParams,
        isOnNetworking,
        onChangeInput,
        onClickBtnUpdateInfo,
        bloodTypeCodeList,
        mbtiCodeList,
        specialtyCodeList,
        handleChangeSpecialty,
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <div className="flex items-center mb-6">
                    <Link
                        to="/student/profile/public"
                        className="inline-flex items-center text-gray-600 hover:text-gray-900"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </Link>
                </div>
                <div className="flex items-center justify-between mb-8">
                    <h2 className="text-3xl font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileAdditionalInfoEdit.txtTitle")}
                    </h2>
                </div>

                {id ? (
                    <div className="space-y-6">
                        {/* 추가 정보 섹션 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6">
                                {/* 전체 컨테이너를 그리드로 변경 */}
                                <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                                    {/* 입력 폼 섹션 - 전체 12칸 차지 */}
                                    <div className="lg:col-span-12 space-y-8">
                                        {/* MBTI */}
                                        <div>
                                            <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileAdditionalInfoEdit.txtMBTI")}
                                            </label>
                                            <div className="relative">
                                                <select
                                                    value={profileParams.mbtiCode}
                                                    onChange={(evt) => onChangeInput("mbtiCode", evt.target.value)}
                                                    className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                        focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                        text-base transition-colors appearance-none">
                                                    <option value="">
                                                        {getLanguageValue(PAGE_TYPE.COMMON, "select.optionWontChoose")}
                                                    </option>
                                                    {mbtiCodeList.map((item: any, idx: number) => (
                                                        <option key={idx} value={item.code}>
                                                            {getCurrentLangName(item.names)}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                                    <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        {/* 혈액형 */}
                                        <div>
                                            <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileAdditionalInfoEdit.txtBloodType")}
                                            </label>
                                            <div className="relative">
                                                <select
                                                    value={profileParams.bloodTypeCode}
                                                    onChange={(evt) => onChangeInput("bloodTypeCode", evt.target.value)}
                                                    className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                        focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                        text-base transition-colors appearance-none">
                                                    <option value="">
                                                        {getLanguageValue(PAGE_TYPE.COMMON, "select.optionWontChoose")}
                                                    </option>
                                                    {bloodTypeCodeList.map((item: any, idx: number) => (
                                                        <option key={idx} value={item.code}>
                                                            {getCurrentLangName(item.names)}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                                    <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        {/* 특기 */}
                                        <div>
                                            <label className="block text-sm sm:text-base font-medium text-gray-700 mb-2">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileAdditionalInfoEdit.txtSpecialty")}
                                            </label>
                                            <div className="flex gap-8 px-2">
                                                {specialtyCodeList.map((item: any, idx: number) => (
                                                    <label key={idx} className="relative flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={profileParams.specialtyCodeList?.includes(item.code)}
                                                            onChange={(e) => { handleChangeSpecialty(item.code, e.target.checked) }}
                                                            className="form-checkbox h-6 w-6 text-[#CF3688] border-2 border-gray-200 focus:ring-2 focus:ring-[#CF3688]/20"
                                                        />
                                                        <span className="ml-2 text-base text-gray-700">
                                                            {getCurrentLangName(item.names)}
                                                        </span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* 버튼 그룹 추가 */}
                                <div className="mt-8 flex flex-col sm:flex-row gap-4 sm:gap-6">
                                    <Link
                                        to="/student/profile/public"
                                        className="w-full sm:w-1/2 px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 border-2 border-gray-200 rounded-xl
                                            hover:bg-gray-80 focus:outline-none focus:ring-2 focus:ring-gray-200
                                            text-center
                                            transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileAdditionalInfoEdit.btnCancel")}
                                    </Link>
                                    <button
                                        disabled={isOnNetworking}
                                        onClick={onClickBtnUpdateInfo}
                                        className="w-full sm:w-1/2 px-4 py-3 text-sm font-medium text-white bg-[#CF3688] rounded-xl
                                            hover:bg-[#B82F77] focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                            transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileAdditionalInfoEdit.btnSave")}
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </main>
    );
};

export default AdditionalInfoPublicProfileEditComponent;
