import { getCurrentLanguage } from "locales/i18n";
import moment from "moment";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ko";
import "dayjs/locale/ja";
import "dayjs/locale/th";
import "dayjs/locale/vi";
import "dayjs/locale/id";

dayjs.extend(relativeTime);

// 시간 다하기
export const addHours = (
  hours: number,
  targetDate?: any,
  format = "YYYYMMDD"
) => {
  const dateInfo = targetDate ? moment(targetDate) : moment();
  const addDate = dateInfo.add(hours, "hours");
  const addDateFormatData = addDate.format(format);

  return addDateFormatData;
};

// 날짜 다하기
export const addDate = (
  days: number,
  targetDate?: any,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  const dateInfo = targetDate ? moment(targetDate) : moment();
  const addHours = dateInfo.add(days, "days");
  const addHoursFormatData = addHours.format(format);

  return addHoursFormatData;
};

// 날짜 빼기
export const subtractDate = (
  days: number,
  targetDate?: any,
  format = "YYYYMMDD"
) => {
  const dateInfo = targetDate ? moment(targetDate) : moment();
  const subtractDate = dateInfo.subtract(days, "days");
  const subtractDateFormatData = subtractDate.format(format);

  return subtractDateFormatData;
};

// 분 더하기
export const addMinutesValue = (
  mins: number,
  targetDate: any,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  const dateInfo = targetDate ? moment(targetDate) : moment();
  const addDate = dateInfo.add(mins, "minutes");
  const addDateFormatData = addDate.format(format);

  return addDateFormatData;
};

// 분 빼기
export const subtractMinutesValue = (
  mins: number,
  targetDate: any,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  const dateInfo = targetDate ? moment(targetDate) : moment();
  const subtractDate = dateInfo.subtract(mins, "minutes");
  const subtractDateFormatData = subtractDate.format(format);

  return subtractDateFormatData;
};

export const getFormattedDateValue = (
  time: string,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  const _time = time ? moment(time) : moment();
  return _time.format(format);
};

export const isBeforeDate = (targetDate: string, refDate = "") => {
  if (!refDate) {
    return moment().isBefore(targetDate);
  } else {
    return moment(refDate).isBefore(targetDate);
  }
};

export const isSameOrAfterDate = (targetDate: string, refDate = "") => {
  if (!refDate) {
    return moment().isSameOrAfter(targetDate);
  } else {
    // console.log(111, targetDate, refDate);
    // console.log(222, moment(refDate).isSameOrAfter(targetDate));
    return moment(refDate).isSameOrAfter(targetDate);
  }
};

export const isSameOrBeforeDate = (targetDate: string, refDate = "") => {
  if (!refDate) {
    return moment().isSameOrBefore(targetDate);
  } else {
    // console.log(333, targetDate, refDate);
    // console.log(444, moment(refDate).isSameOrAfter(targetDate));
    return moment(refDate).isSameOrBefore(targetDate);
  }
};

// 월요일:1 ~ 일요일: 7 리턴
export const getDayValueForDB = (dateValue: string) => {
  const result = moment(dateValue).day();
  return result === 0 ? 7 : result;
};

export const addMinutes = (
  targetTime: string,
  addMinutesValue: number,
  returnFormat = "HH:mm"
) => {
  return moment(targetTime, "HH:mm")
    .add(addMinutesValue, "minutes")
    .format(returnFormat);
};

export const getCurrentDate = (format = "YYYY-MM-DD") => {
  return moment().format(format);
};

export const utcTimestampToKstTimestamp = (
  timestamp: number,
  isMilliSec = false
) => {
  return timestamp + 32400 * (isMilliSec ? 1000 : 1);
};

export const getMaxBirthday = (age: number) => {
  const today = new Date();
  const year = today.getFullYear() - age;
  const month = today.getMonth() + 1;
  const day = today.getDate();

  return (
    year +
    "-" +
    (month < 10 ? "0" : "") +
    month +
    "-" +
    (day < 10 ? "0" : "") +
    day
  );
};

// 최소 생년월일 구하기
export const getMinBirthday = (age: number) => {
  const today = new Date();
  const year = today.getFullYear() - (age + 1);
  const month = today.getMonth() + 1;
  const day = today.getDate();

  let date = new Date(`${year}-${month}-${day}`);
  const birthday = getFormattedDateValue(
    new Date(date.setDate(date.getDate() + 1)).toString(),
    "YYYY-MM-DD"
  );

  return birthday;
};

export const formatTimeAgo = (date: any) => {
  if (!date) {
    return "N/A";
  }

  try {
    const language = getCurrentLanguage();
    dayjs.locale(language);
    return dayjs(date).fromNow();
  } catch (error) {
    console.error(error);
    return "N/A";
  }
};

export const formattedDateTimeForLocale = (date: any, locale: string) => {
  try {
    const formattedDate = new Date(date).toLocaleDateString(
      locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    })
    return formattedDate;
  } catch (error) {
    console.error(error);
    return date;
  }
};

export const formattedDateForLocale = (date: any, locale: string) => {
  try {
    console.log("formattedDateForLocale", date, locale);
    // 언어 코드만 추출 (예: ko-KR -> ko)
    locale = locale.split('-')[0];

    const formattedDate = new Date(date).toLocaleDateString(locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short'
    })
    return formattedDate;
  } catch (error) {
    console.error(error);
    return date;
  }
};

export const formattedTimeForLocale = (date: any, locale: string) => {
  try {
    const formattedTime = new Date(date).toLocaleTimeString(locale, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    return formattedTime;
  } catch (error) {
    console.error(error);
    return date;
  }
};