import * as BaseApi from "api/Base.api";

const BASE_URL = "/student/v2/alarm";

//* 알림 목록 조회
//  희문
//  2025. 03. 04
//* Params
//   page: number
//   limit: number
//   needUpdateToRead: boolean
export const getAlarmList = async (page: number, limit: number, needUpdateToRead: boolean = false) => {
    const url = `${BASE_URL}`;
    return await BaseApi.get(url, { page, limit, needUpdateToRead });
};

//* 읽지 않은 알람 수 요청
//  희문
//  2025. 03. 04
export const getUnreadAlarmCount = async () => {
    const url = `${BASE_URL}/count/unread`;
    return await BaseApi.get(url);
};

//* 알림 읽음 처리
export const readAlarm = async (alarmId: string) => {
    const url = `${BASE_URL}/${alarmId}/read`;
};