import React from "react";
import { Link } from "react-router-dom";

const AuthHeader = (props: any) => {
    const { backUrl } = props;

    return (
        <div className="w-full z-50 mb-4">
            <div className="container mx-auto">
                <Link to={backUrl ? backUrl : "/"} className="inline-flex items-center justify-center p-2 rounded-full hover:bg-gray-100">
                    <span className="sr-only">뒤로가기</span>
                    <svg className="w-8 h-8 sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </Link>
            </div>
        </div>
    );
};

export default AuthHeader;