import CookieSettingPopup from "./CookieSettingPopup.component";
import { useState, useEffect } from "react";
import { getCookieSetting, setCookieSetting } from "helpers/Cookie.helper";
import { enableGtm, disableGtm } from "helpers/Gtm.helper";
const CookieSettingPopupContainer = (props: any) => {
    const { onClose } = props;

    const [isCheckedAnalytics, setIsCheckedAnalytics] = useState(true);

    const handleSave = () => {
        setCookieSetting(isCheckedAnalytics);

        if (isCheckedAnalytics) {
            enableGtm();
        } else {
            disableGtm();
        }

        onClose();
    }

    return (
        <CookieSettingPopup
            isCheckedAnalytics={isCheckedAnalytics}
            setIsCheckedAnalytics={setIsCheckedAnalytics}
            handleSave={handleSave}
        />)

};

export default CookieSettingPopupContainer;