import React, { useCallback, useEffect, useState } from "react";
import IndexComponent from "./Index.component";
import { getFavoriteList, deleteFavorite } from "api/agency/v2/Favorite.api";
import { usePopup } from "contexts/PopupContext";
// import FullPageStudentInfoPopup from "pages/agency/v2/popup/FullPageStudentInfoPopup.container";
import { useNavigate } from "react-router-dom";
const LIMIT = 10;

const IndexContainer = () => {
    const navigate = useNavigate();
    const [list, setList] = useState<any[]>([]);
    const [isInit, setIsInit] = useState<boolean>(false);
    const [isOnLoadMore, setIsOnLoadMore] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);

    const handleScroll = useCallback(() => {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        // 스크롤이 바닥에 도달했는지 체크
        if (scrollHeight - scrollTop <= clientHeight + 100) { // 100px 여유를 둠
            console.log('scroll to bottom')
            // 더 로드할 데이터가 있고 현재 로딩 중이 아닐 때만 다음 페이지 로드
            if (hasMore && !isOnLoadMore) {
                setPage(prevPage => prevPage + 1);
            }
        }
    }, [hasMore, isOnLoadMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const fetchList = useCallback(async () => {
        console.log("agency/v2/favorite/Index.container::fetchList");
        console.log({ page });
        if (!hasMore) return;

        if (isOnLoadMore) return;

        try {
            setIsOnLoadMore(true);
            const data = await getFavoriteList(page, LIMIT);

            if (data?.list) {
                setList(prev => page === 1 ? data.list : [...prev, ...data.list]);
                setPage(page);
                setHasMore(data.list.length === LIMIT);
            }
        } catch (error) {
            console.error("Failed to fetch list:", error);
        } finally {
            setIsOnLoadMore(false);
            setIsInit(true);
        }
    }, [page]);

    useEffect(() => {
        fetchList();
    }, [fetchList]);

    const handleShowStudentInfo = (userStudentId: number) => {
        navigate(`/agency/student/${userStudentId}`);
    };

    const handleDeleteFavorite = async (userStudentId: number) => {
        console.log("agency/v2/favorite/Index.container::handleDeleteFavorite");
        console.log({ userStudentId });

        const data = await deleteFavorite(userStudentId);
        if (data?.userStudentId) {
            setList(prev => prev.filter(item => item.userStudentId !== userStudentId));
        }
    };

    return (
        <IndexComponent
            list={list}
            isInit={isInit}
            onScroll={handleScroll}
            isOnLoadMore={isOnLoadMore}
            hasMore={hasMore}
            onShowStudentInfo={handleShowStudentInfo}
            onDeleteFavorite={handleDeleteFavorite}
        />
    );
};

export default IndexContainer;
