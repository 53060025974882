import axios from "axios";
import { getQueryStringFromObject } from "helpers/Api.helper";

export const get = async (url: string, queryString = {}) => {
    console.log("Api.service:get");
    const params = getQueryStringFromObject(queryString);
    return axios
        .get(`${url}${params}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const post = async (url: string, params: any) => {
    console.log("Api.service:post");

    return axios
        .post(url, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const put = async (url: string, params: any): Promise<any> => {
    console.log("Api.service:put");

    return axios
        .put(url, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const patch = async (url: string, params: any): Promise<any> => {
    console.log("Api.service:put");

    return axios
        .patch(url, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const remove = async (url: string, queryString = {}) => {
    console.log("Api.service:remove");
    const params = getQueryStringFromObject(queryString);

    return axios
        .delete(`${url}${params}`)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

const errorHandler = ({ response }: any) => {
    console.log(response);
    if (!response) {
        window.location.href = '/error/500';
    } else if (response.status === 403) {
        alert(response.data.message);
        window.location.href = "/login";
    } else {
        if (response.data) {
            if (!response.data.message) {
                response.data.message = "Unknown Error Occurred";
            }
        }
        throw response.data;
    }
};
