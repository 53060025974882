// My 페이지 관련 API
import * as BaseApi from "api/Base.api";
const BASE_URL = "/student/v2/my";
//* 마이페이지 정보 요청
//  2025.03.04
//  희문
export const getMyInfo = async () => {
    console.log('api/student/v2/my.api:getMyInfo')
    const result = await BaseApi.get(BASE_URL);
    return result;
};

// 계정 활성화
export const toggleActiveAccount = async () => {
    console.log('api/student/v2/my.api:toggleActiveAccount')
    const result = await BaseApi.patch(BASE_URL + "/is-active/toggle", null);
    return result;
};

//* 부모님 이메일 정보 요청
//  2025.03.04
//  희문
export const getParentEmail = async () => {
    console.log('api/student/v2/my.api:getParentEmail')
    const result = await BaseApi.get(BASE_URL + "/parent-email");
    return result;
};

//* 부모 이메일 정보 수정
//  2025.03.04
//  희문
//* Params
//  newParentEmail: 새로운 이메일
export const updateParentEmail = async (newParentEmail: string) => {
    console.log('api/student/v2/my.api:updateParentEmail')
    const result = await BaseApi.patch(BASE_URL + "/parent-email", { newParentEmail });
    return result;
};

//* 휴대전화 정보 요청
//  2025.03.04
//  희문
export const getCellphone = async () => {
    console.log('api/student/v2/my.api:getCellphone')
    const result = await BaseApi.get(BASE_URL + "/cellphone");
    return result;
};

//* 연락처 정보 요청
//  2925.03.05
//  희문
export const getContactInfo = async () => {
    console.log('api/student/v2/my.api:getContactInfo')
    const result = await BaseApi.get(BASE_URL + "/contact");
    return result;
};

//* 연락처 정보 수정
//  2925.03.05
//  희문
export const updateContactInfo = async (newContactTypeCode: string, newContact: string) => {
    console.log('api/student/v2/my.api:updateContactInfo')
    const result = await BaseApi.put(BASE_URL + "/contact", { newContactTypeCode, newContact });
    return result;
};