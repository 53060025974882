import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WannabeInfoComponent from "./WannabeInfo.component";
import SchoolInfoComponent from "./SchoolInfo.component";
import ThirdPartyInfoComponent from "./ThirdPartyInfo.component";
import CastingManagerInfoComponent from "./CastingManagerInfo.component";

const LandingInfoContainer = (props: any) => {
    useEffect(() => {}, []);

    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (!(id === "wannabe" || id === "casting-manager" || id === "third-party" || id === "school")) {
            navigate("/");
        }
    }, [id]);

    return (
        <>
            {id === "wannabe" ? <WannabeInfoComponent /> : null}
            {id === "casting-manager" ? <CastingManagerInfoComponent /> : null}
            {id === "third-party" ? <ThirdPartyInfoComponent /> : null}
            {id === "school" ? <SchoolInfoComponent /> : null}
        </>
    );
};

export default LandingInfoContainer;
