export const getImageInfo = async (fileObj: any): Promise<any | null> => {
    console.log("fileObj", fileObj);

    const result = new Promise((resolve, reject) => {
        var reader = new FileReader();
        try {
            reader.readAsDataURL(fileObj);

            reader.onload = (evt: any) => {
                var img = new Image();
                img.src = evt.target.result;

                img.onload = (e: any) => {
                    console.log(e);
                    console.log(img);
                    console.log(img.width, img.height);

                    resolve({
                        name: fileObj.name,
                        size: fileObj.size,
                        url: evt.target.result,
                        width: img.width,
                        height: img.height,
                    });
                };

                img.onerror = (err) => {
                    console.error(err);
                    resolve(null);
                };
            };
        } catch {
            console.log("image error!")
        }
    });

    return result;
};

export const getImageInfoByUrl = async (url: string): Promise<any | null> => {
    const result = new Promise((resolve, reject) => {
        var img = new Image();
        img.src = url;

        img.onload = (e: any) => {
            console.log(e);
            console.log(img);
            console.log(img.width, img.height);

            resolve({
                url: url,
                width: img.width,
                height: img.height,
            });
        };

        img.onerror = (err) => {
            console.error(err);
            resolve(null);
        };
    });

    return result;
};

export const getAgencyLogoImageUrl = (agencyLogoImageInfo: any, width: number = 720, height: number = null) => {
    if (agencyLogoImageInfo) {
        return getImageUrl(agencyLogoImageInfo, width, height);
    } else {
        return "/imgs/dummy/basic-img.png";
    }
};

export const getProfileImageUrl = (profileImageInfo: any, width: number = 720, height: number = null) => {
    // console.log("profileImageInfo", profileImageInfo);
    if (profileImageInfo) {
        return getImageUrl(profileImageInfo, width, height);
    } else {
        return "/imgs/dummy/basic-img.png";
    }
};

export const getBusinessCardImageUrl = (imageInfo: any, width: number = 720, height: number = null) => {
    if (imageInfo) {
        return getImageUrl(imageInfo, width, height);
    } else {
        return "/imgs/dummy/basic-img.png";
    }
};

export const getImageUrl = (imageInfo: any, width: number = 720, height: number = null) => {
    // console.log("imageInfo", imageInfo);
    if (imageInfo) {
        let url = "";
        if (imageInfo.url) {
            url = imageInfo.url;
        } else if (imageInfo.subUrl) {
            url = `${process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX}${imageInfo.subUrl}`;
        } else {
            return "/imgs/dummy/basic-img.png";
        }
        
        if (!url.toLowerCase().endsWith('.svg')) {
            if (width) {
                url += `?w=${width}`;
            }
            if (height) {
                url += `&h=${height}`;
            }
        }
        return url;
    } else {
        return "/imgs/dummy/basic-img.png";
    }
};
