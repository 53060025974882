//* 채팅 관련 API
//  2025-03-13 추가
//  희문

import * as BaseApi from "api/Base.api";

const BASE_URL = "/student/v2/chat";

//* 채팅 목록 조회
export const getChatList = async (messageStatusCode: string) => {
    return await BaseApi.get(BASE_URL, { messageStatusCode });
};

//* 채팅 요청 목록 조회
export const getRequestList = async () => {
    return await BaseApi.get(BASE_URL + "/request");
};

//* 채팅 요청 거절
export const denyChatRequest = async (messageRequestId: string): Promise<any> => {
    const url = `${BASE_URL}/${messageRequestId}/deny`;
    return await BaseApi.patch(url, null)
        .then((res: any) => {
            return res?.data?.data;
        });
};

//* 채팅 수락
// 2025-03-25 추가
//  chat.api 의 acceptMessageRequest 에서 처리중
//  다이나모 DB관련 루틴이 있어 chat.api 에서 처리, 나머지 채팅 관련 내용을 chat.api 로 옮길지
//  이쪽 api 로 옮길지 결정 필요
export const acceptChatRequest = async (messageRequestId: string): Promise<any> => {
    const url = `${BASE_URL}/${messageRequestId}/accept`;
    return await BaseApi.patch(url, null)
        .then((res: any) => {
            return res?.data?.data;
        });
};

//* 채팅 종료
export const endChat = async (messageRequestId: string): Promise<any> => {
    return await BaseApi.post(BASE_URL + "/end", { messageRequestId });
};

//* 채팅 신고
export const doReport = async (channelId: string, messageReportReasonCode: string, messageReportReason: string) => {
    const data = {
        channelId,
        messageReportReasonCode,
        messageReportReason
    }
    return await BaseApi.post(`${BASE_URL}/report`, data)
        .then((res: any) => {
            return res?.data?.data;
        });
};