import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React from "react";

const PushCheckComponent = (props: any) => {
    const { isActivated, changeActivated } = props;

    return (
        <div className="flex items-center justify-between">
            <div>
                <p className="text-sm text-gray-600">
                    {getLanguageValue(PAGE_TYPE.COMMON, "app.txtAppPushReceive")}
                </p>
            </div>
            <div className="relative inline-flex items-center">
                <input
                    type="checkbox"
                    checked={isActivated}
                    onChange={(evt) => {
                        changeActivated(evt);
                    }}
                    className="sr-only peer"
                    id="toggle-push"
                />
                <label
                    htmlFor="toggle-push"
                    className="flex items-center w-14 h-7 bg-gray-200 rounded-full cursor-pointer 
                             peer-checked:bg-[#CF3688] peer-focus:ring-2 peer-focus:ring-[#CF3688]/20 
                             after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                             after:bg-white after:rounded-full after:h-6 after:w-6 after:transition-all
                             after:shadow-sm peer-checked:after:translate-x-7"
                >
                    <span className="sr-only">알림 설정</span>
                </label>
            </div>
        </div>
    )
};

export default PushCheckComponent;
