import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PasswordResetDoneComponent = (props: any) => {
  const navigate = useNavigate();
  const { email } = props;

  return (
    <main className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
        <div className="flex flex-col items-center max-w-md mx-auto w-full">
          <div className="text-center mb-8">
            <h2 className="text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">
              {email}
            </h2>
          </div>
          <div className="w-full space-y-6">
            <div className="text-center space-y-2">
              <p className="text-base sm:text-lg text-gray-600">
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "passwordResetDone.txtEmailSent"
                )}
              </p>
              <p className="text-base sm:text-lg text-gray-600">
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "passwordResetDone.txtCheckInbox"
                )}
              </p>
            </div>

            <div>
              <button
                onClick={(e) => {
                  navigate("/login");
                }}
                className="w-full py-3 sm:py-4 px-6 text-base sm:text-lg font-semibold text-white bg-[#CF3688] hover:bg-[#A62B6D] rounded-lg transition-all duration-200 transform hover:scale-[1.02] active:scale-[0.98] shadow-lg hover:shadow-xl"
              >
                {getLanguageValue(PAGE_TYPE.COMMON, "passwordResetDone.btnLogin")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default PasswordResetDoneComponent;
