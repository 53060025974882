import React, { useState, useRef, useCallback, useEffect } from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import LoadingComponent from "commons/loading/Loading.component";
import { getImageUrl } from "helpers/Image.helper";

interface PostFormComponentProps {
    content: string;
    images: Array<{ id: string; url: string }>;
    isLoading: boolean;
    isPosting: boolean;
    fileInputRef: React.RefObject<HTMLInputElement>;
    onContentChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRemoveImage: (idx: number) => void;
    onCancel: () => void;
    onSubmit: () => void;
}

const PostFormComponent: React.FC<PostFormComponentProps> = ({
    content,
    images,
    isLoading,
    isPosting,
    fileInputRef,
    onContentChange,
    onImageUpload,
    onRemoveImage,
    onCancel,
    onSubmit
}) => {
    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <LoadingComponent />
            </div>
        );
    }

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <div className="flex items-center justify-between mb-8">
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "postForm.txtTitle")}
                    </h2>
                </div>

                <div className="max-w-2xl mx-auto space-y-6">
                    <div className="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-shadow">
                        <div className="space-y-4">
                            <textarea
                                className="w-full h-32 p-4 border-2 border-gray-200 rounded-xl
                                    focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                    text-base transition-colors resize-none"
                                placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "postForm.txtPostWritePlaceholder")}
                                value={content}
                                onChange={onContentChange}
                                maxLength={1000}
                            />
                            <div className="text-right text-sm text-gray-500">
                                {content.length}/1000
                            </div>

                            {images.length > 0 && (
                                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
                                    {images.map((image: any, idx: number) => (
                                        <div key={idx} className="relative aspect-square bg-gray-100 rounded-xl overflow-hidden">
                                            {image.file && <img src={image.url} alt="" className="w-full h-full object-cover" />}
                                            {image.imageInfo && <img src={getImageUrl(image.imageInfo)} alt="" className="w-full h-full object-cover" />}
                                            <button
                                                onClick={() => onRemoveImage(idx)}
                                                className="absolute top-2 right-2 p-1.5 bg-gray-900/50 rounded-full text-white 
                                                    hover:bg-gray-900/75 transition-colors">
                                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className="flex justify-between items-center">
                                <div className="flex space-x-2">
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        className="hidden"
                                        accept="image/*"
                                        multiple
                                        onChange={onImageUpload}
                                        disabled={isPosting}
                                    />
                                    <button
                                        onClick={() => fileInputRef.current?.click()}
                                        disabled={isPosting || images.length >= 10}
                                        className={`p-2 transition-colors ${isPosting || images.length >= 10
                                            ? 'text-gray-400 cursor-not-allowed'
                                            : 'text-gray-500 hover:text-[#CF3688]'
                                            }`}
                                        title={`이미지 추가 (${images.length}/10)`}>
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="flex space-x-2">
                                    <button
                                        onClick={onCancel}
                                        className="px-4 py-2 rounded-xl bg-gray-200 text-gray-700 hover:bg-gray-300 transition-colors
                                            focus:outline-none focus:ring-2 focus:ring-gray-300">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtCancel")}
                                    </button>
                                    <button
                                        onClick={onSubmit}
                                        disabled={isPosting || !content.trim()}
                                        className={`px-4 py-2 rounded-xl transition-colors
                                            focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                            ${isPosting
                                                ? 'bg-gray-300 text-gray-500 cursor-wait'
                                                : content.trim()
                                                    ? 'bg-[#CF3688] text-white hover:bg-[#B32E74]'
                                                    : 'bg-gray-200 text-gray-500 cursor-not-allowed'}`}>
                                        {isPosting ? getLanguageValue(PAGE_TYPE.COMMON, "btn.txtUpdating") : getLanguageValue(PAGE_TYPE.COMMON, "btn.txtUpdate")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default PostFormComponent;