import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import LoadingComponent from "commons/loading/Loading.component";
import { getAgencyLogoImageUrl } from "helpers/Image.helper";

const IndexComponent = (props: any) => {
    const { info, errorMessage, isLoading, showAgencyInfoPopup } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <h2 className="text-2xl font-extrabold text-gray-800 mb-10">
                    {getLanguageValue(PAGE_TYPE.STUDENT, "profileSummary.txtTitle")}
                </h2>
                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <LoadingComponent />
                    </div>
                ) : errorMessage ? (
                    <div className="flex justify-center items-center h-64 bg-red-200 border border-red-500 text-red-800 px-5 py-4 rounded-lg" role="alert">
                        <strong className="font-bold">Error: </strong>
                        <span className="ml-2">{errorMessage}</span>
                    </div>
                ) : (
                    info && (
                        <div className="space-y-10">
                            {/* 내 프로필 정보 */}
                            <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4 sm:mb-6">
                                <div className="p-3 sm:p-4 md:p-6">
                                    <div className="space-y-4 sm:space-y-6">
                                        {/* 프로필 통계 섹션 */}
                                        <div>
                                            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4">
                                                {/* 나를 즐겨찾기한 기획사 수 */}
                                                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                hover:bg-gray-100 transition-all duration-200 
                                                transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                        <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                        mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profileSummary.txtFavoriteCount")}
                                                        </h3>
                                                        <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                            {info.favoriteCount?.toLocaleString() || "N/A"}
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* 총 내 프로필 확인 수 */}
                                                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                hover:bg-gray-100 transition-all duration-200 
                                                transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                        <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                        mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profileSummary.txtTotalViewCount")}
                                                        </h3>
                                                        <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                            {info.viewCount?.toLocaleString() || "N/A"}
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* 오늘 프로필 확인 수 */}
                                                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                hover:bg-gray-100 transition-all duration-200 
                                                transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                        <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                        mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profileSummary.txtTodayViewCount")}
                                                        </h3>
                                                        <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                            {info.todayViewCount?.toLocaleString() || "N/A"}
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* 주간 프로필 확인 수 */}
                                                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                hover:bg-gray-100 transition-all duration-200 
                                                transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                        <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                        mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profileSummary.txtWeeklyViewCount")}
                                                        </h3>
                                                        <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                            {info.weeklyViewCount?.toLocaleString() || "N/A"}
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* 월간 프로필 확인 수 */}
                                                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                hover:bg-gray-100 transition-all duration-200 
                                                transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                        <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                        mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profileSummary.txtMonthlyViewCount")}
                                                        </h3>
                                                        <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                            {info.monthlyViewCount?.toLocaleString() || "N/A"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* 나를 즐겨찾기한 기획사 목록 */}
                            <section>
                                <h3 className="text-xl font-bold text-gray-800 mb-6">
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profileSummary.txtFavoriteListByAgency")}
                                </h3>
                                <ul className="space-y-6">
                                    {info.favoriteList.map((favorite: any) => (
                                        <div
                                            key={favorite.userAgencyInfo.agencyInfo.id}
                                            onClick={() => showAgencyInfoPopup(favorite.userAgencyInfo.agencyInfo.id)}
                                            className="w-[100px] sm:w-[125px] lg:w-[150px] flex-shrink-0">
                                            <div className="flex flex-col items-center">
                                                <div className="rounded-sm overflow-hidden w-14 h-14 sm:w-16 sm:h-16 flex-shrink-0 border-2 border-gray-100">
                                                    <img
                                                        src={getAgencyLogoImageUrl(favorite.userAgencyInfo.agencyInfo.logoImageInfo)}
                                                        alt={favorite.userAgencyInfo.agencyInfo.nameEn}
                                                        className="w-full h-full object-contain"
                                                    />
                                                </div>
                                                <div className="mt-2 text-center w-full">
                                                    <p className="text-gray-900 text-xs truncate px-2">
                                                        {favorite.userAgencyInfo.agencyInfo.nameEn}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {info.favoriteList.length === 0 && (
                                        <div className="text-gray-500 text-sm">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profileSummary.txtNoFavoriteList")}
                                        </div>
                                    )}
                                </ul>
                            </section>

                        </div>
                    )
                )}
            </div>
        </main>
    );
};

export default IndexComponent;