import React, { useEffect, useState } from "react";
import FullPageStudentInfoPopupComponent from "./FullPageStudentInfoPopup.component";
import { getFullPopupStudentInfo } from "api/agency/v2/Popup.api";
import { requestChat, cancelChatRequest } from "api/agency/v2/Chat.api";
import { addFavorite, deleteFavorite } from "api/agency/v2/Favorite.api";
import { useNavigate } from "react-router-dom";
import StudentImageSlidePopupContainer from "./StudentImageSlidePopup.container";
import { usePopup } from "contexts/PopupContext";
const FullPageStudentInfoPopupContainer = (props: any) => {
    const navigate = useNavigate();
    const { showPopup } = usePopup();
    const { userStudentId, onClose } = props;
    const [info, setInfo] = useState<any>(null);
    const [isInit, setIsInit] = useState<boolean>(false);
    const [isOnNetworking, setIsOnNetworking] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");


    const loadStudentInfo = async () => {
        try {
            const data = await getFullPopupStudentInfo(userStudentId);
            if (data?.info) {
                setInfo(data.info);
            }
        } catch (error: any) {
            console.error("agency/v2/popup/FullPageStudentInfoPopup.container.tsx loadStudentInfo error", error);
            setErrorMessage(error.message);
        } finally {
            setIsInit(true);
        }
    };

    useEffect(() => {
        loadStudentInfo();
    }, [userStudentId]);

    const handleRequestChat = async () => {
        console.log("agency/v2/popup/FullPageStudentInfoPopup.container.tsx handleRequestChat");
        if (isOnNetworking) return;

        setIsOnNetworking(true);
        const data = await requestChat(userStudentId) as any;

        if (data?.messageStatusCode) {
            const newInfo = { ...info, messageInfo: data.messageInfo };
            setInfo(newInfo);
        }
        setIsOnNetworking(false);
    };

    const handleCancelChat = async () => {
        console.log("agency/v2/popup/FullPageStudentInfoPopup.container.tsx handleCancelChat");
        if (isOnNetworking) return;

        setIsOnNetworking(true);
        const data = await cancelChatRequest(info.messageInfo.id) as any;
        if (data?.result) {
            const newInfo = { ...info };
            newInfo.messageInfo = null;
            setInfo(newInfo);
        }
        setIsOnNetworking(false);
    };

    const handleStartChat = async () => {
        console.log("agency/v2/popup/FullPageStudentInfoPopup.container.tsx handleStartChat");
        navigate(`/agency/message/${info.messageInfo.channelId}/chat`);
        onClose();
    };

    const handleFavoriteToggle = async () => {
        console.log("pages/agency/v2/popup/FullPageStudentInfoPopup.container:handleFavoriteToggle");
        if (isOnNetworking) return;

        setIsOnNetworking(true);
        if (info.userStudentInfo.isFavorite) {
            await deleteFavorite(userStudentId);
            setInfo({ ...info, userStudentInfo: { ...info.userStudentInfo, isFavorite: false } });
        } else {
            await addFavorite(userStudentId);
            setInfo({ ...info, userStudentInfo: { ...info.userStudentInfo, isFavorite: true } });
        }
        setIsOnNetworking(false);
    };

    const handleDetailInfo = () => {
        navigate(`/agency/student/${userStudentId}`);
        onClose();
    };

    const showImageSlidePopup = (imageIdx: number) => {
        console.log("agency/v2/popup/FullPageStudentInfoPopup.container:showImageSlidePopup");
        console.log({ imageIdx });

        showPopup(
            <StudentImageSlidePopupContainer
                profileImageInfo={info.userStudentInfo.profileImageInfo}
                userStudentImageList={info.userStudentInfo.userStudentImageList}
                initialSlideIdx={imageIdx}
                onClose={onClose}
            />
        );
    }

    return (
        <FullPageStudentInfoPopupComponent
            info={info}
            isInit={isInit}
            onClose={onClose}
            handleRequestChat={handleRequestChat}
            handleCancelChat={handleCancelChat}
            handleStartChat={handleStartChat}
            onFavoriteToggle={handleFavoriteToggle}
            isOnNetworking={isOnNetworking}
            onDetailInfo={handleDetailInfo}
            onImageSlidePopup={showImageSlidePopup}
            errorMessage={errorMessage}
        />
    );
};

export default FullPageStudentInfoPopupContainer;