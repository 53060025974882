import { createContext, useContext, useState } from "react";

export const NetworkContext = createContext({
    isOnNetworking: false,
    setIsOnNetworking: (status: boolean) => { },
});

export const NetworkProvider = ({ children }: { children: React.ReactNode }) => {
    const [isOnNetworking, setIsOnNetworking] = useState(false);

    return (
        <NetworkContext.Provider value={{ isOnNetworking, setIsOnNetworking }}>
            {children}
        </NetworkContext.Provider>
    );
};

export const useNetwork = () => useContext(NetworkContext);