import React from "react";
import { Link } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const Error500Component = () => {
    return (
        <div className="global-error-wrap">
            <div className="stars">
                {/* Left Top */}
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `${Math.random() * 30}%`,
                        top: `${Math.random() * 30}%`
                    }}
                ></div>
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `${Math.random() * 30}%`,
                        top: `${Math.random() * 30}%`
                    }}
                ></div>
                {/* Center Top */}
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `30 + ${Math.random() * 30}%`,
                        top: `${Math.random() * 30}%`
                    }}
                ></div>
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `30 + ${Math.random() * 30}%`,
                        top: `${Math.random() * 30}%`
                    }}
                ></div>
                {/* Right Top */}
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        right: `${Math.random() * 30}%`,
                        top: `${Math.random() * 30}%`
                    }}
                ></div>
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        right: `${Math.random() * 30}%`,
                        top: `${Math.random() * 30}%`
                    }}
                ></div>
                {/* Right Center */}
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        right: `${Math.random() * 30}%`,
                        top: `30 + ${Math.random() * 30}%`
                    }}
                ></div>
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        right: `${Math.random() * 30}%`,
                        top: `30 + ${Math.random() * 30}%`
                    }}
                ></div>
                {/* Right Bottom */}
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        right: `${Math.random() * 30}%`,
                        bottom: `${Math.random() * 30}%`
                    }}
                ></div>
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        right: `${Math.random() * 30}%`,
                        bottom: `${Math.random() * 30}%`
                    }}
                ></div>
                {/* Center Bottom */}
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `30 + ${Math.random() * 30}%`,
                        bottom: `${Math.random() * 30}%`
                    }}
                ></div>
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `30 + ${Math.random() * 30}%`,
                        bottom: `${Math.random() * 30}%`
                    }}
                ></div>
                {/* Left Bottom */}
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `${Math.random() * 30}%`,
                        bottom: `${Math.random() * 30}%`
                    }}
                ></div>
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `${Math.random() * 30}%`,
                        bottom: `${Math.random() * 30}%`
                    }}
                ></div>
                {/* Left Center */}
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `${Math.random() * 30}%`,
                        bottom: `30 + ${Math.random() * 30}%`
                    }}
                ></div>
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `${Math.random() * 30}%`,
                        bottom: `30 + ${Math.random() * 30}%`
                    }}
                ></div>
                {/* Center Center */}
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `30 + ${Math.random() * 30}%`,
                        bottom: `30 + ${Math.random() * 30}%`
                    }}
                ></div>
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `30 + ${Math.random() * 30}%`,
                        bottom: `30 + ${Math.random() * 30}%`
                    }}
                ></div>
                {/* Center Center */}
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `30 + ${Math.random() * 30}%`,
                        bottom: `30 + ${Math.random() * 30}%`
                    }}
                ></div>
                <div className="star"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/icons/icon-star-fill.svg)`,
                        left: `30 + ${Math.random() * 30}%`,
                        bottom: `30 + ${Math.random() * 30}%`
                    }}
                ></div>
            </div>
            <div className="container mx-auto flex flex-col items-center justify-center min-h-screen z-1">
                <img src="/imgs/common/logo/img-logo/logo-t2-250.png" alt="WANNABES" className="mb-4 w-1/3 max-w-[300px]" />
                <h1 className="text-center text-4xl font-extrabold mb-4">500</h1>
                <p className="text-center text-xl font-bold mb-4">
                    {getLanguageValue(PAGE_TYPE.COMMON, 'error.txt500Title')}
                </p>
                <p className="text-center text-sm">
                    {getLanguageValue(PAGE_TYPE.COMMON, 'error.txt500Desc')}
                </p>

                <div className="flex gap-4 mt-8">
                    <button
                        onClick={() => window.history.back()}
                        className="px-6 py-2 bg-[#CF3380] !text-white rounded-lg hover:bg-[#CF3380] font-medium text-md"
                    >
                        {getLanguageValue(PAGE_TYPE.COMMON, 'btn.txtPrevPage')}
                    </button>
                    <button
                        onClick={() => window.location.replace('/')}
                        className="px-6 py-2 bg-[#CF3380] !text-white rounded-lg hover:bg-[#CF3380] font-medium text-md"
                    >
                        {getLanguageValue(PAGE_TYPE.COMMON, 'btn.txtHomePage')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Error500Component;