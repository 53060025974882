import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import { getLastLoginEmail } from "helpers/Cookie.helper";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const CommonHeaderComponentV2 = (props: any) => {
    const { logout, render, mobileMenuSlideClassName, setMobileMenuSlideClassName, userInfo, nextUrl } = props;
    const { consoleUrl } = props;
    const location = useLocation();

    return (
        <header className="fixed top-0 left-0 right-0 flex justify-between items-center p-4 bg-transparent z-50">
            <div className="flex items-center">
                <Link
                    to="/"
                    onClick={(e: any) => {
                        e.preventDefault();
                        render("/");
                    }}
                    className="flex items-center"
                >
                    <img alt="home" src="/imgs/landing/header-logo.png" className="h-12 lg:h-14" />
                </Link>
            </div>
            <div className="flex items-center">
                <ul className="flex space-x-4">
                    {userInfo?.username ? (
                        <li>
                            <Link
                                to={consoleUrl ? consoleUrl : "/login"}
                                className="px-5 py-3 
                                    bg-[#3E4352] !text-[#FFD700] 
                                    text-sm lg:text-lg 
                                    font-medium rounded-full shadow-sm hover:text-[#FFD700]/90 
                                    transition duration-300"
                            >
                                CONSOLE
                            </Link>
                        </li>
                    ) : (
                        <>
                            <li>
                                <Link to={nextUrl} className="px-5 py-3 
                                    bg-[#3E4352] !text-[#FFD700] 
                                    text-sm lg:text-lg 
                                    font-medium rounded-full shadow-sm hover:text-[#FFD700]/90 
                                    transition duration-300">
                                    {getLanguageValue(PAGE_TYPE.COMMON, "header.btnNext")}
                                </Link>
                            </li>
                        </>
                        // <>
                        //     <li>
                        //         <Link
                        //             to="/login"
                        //             className="text-sm font-medium text-gray-700 hover:text-gray-900"
                        //         >
                        //             Log in
                        //         </Link>
                        //     </li>
                        //     <li>
                        //         <Link
                        //             to="/signup"
                        //             className="text-sm font-medium text-gray-700 hover:text-gray-900"
                        //         >
                        //             Sign up Free
                        //         </Link>
                        //     </li>
                        // </>
                    )}
                </ul>
            </div>
        </header>
    );
};
export default CommonHeaderComponentV2;
