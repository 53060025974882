import React, { useState } from "react";
import PasswordComponent from "./ChangePassword.component";
import { updatePassword } from "api/common/Auth.api";
import { useNavigate } from "react-router-dom";
const PasswordContainer = () => {
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    // const { showAlert } = usePopup();

    const handleSubmit = async () => {
        if (!currentPassword || !newPassword || !confirmPassword) {
            window.alert("모든 항목을 입력해주세요.");
            return;
        }

        if (newPassword !== confirmPassword) {
            window.alert("새 비밀번호가 일치하지 않습니다.");
            return;
        }

        if (newPassword.length < 8) {
            window.alert("비밀번호는 8자 이상이어야 합니다.");
            return;
        }

        try {
            setIsLoading(true);
            const result = await updatePassword({
                currentPassword,
                password: newPassword,
                confirmedPassword: confirmPassword
            });

            if (result) {
                window.alert("비밀번호가 성공적으로 변경되었습니다.");
                navigate("/student/my");
            }
        } catch (error: any) {
            window.alert(error.message || "비밀번호 변경에 실패했습니다.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <PasswordComponent
            currentPassword={currentPassword}
            setCurrentPassword={setCurrentPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            onSubmit={handleSubmit}
            onCancel={() => navigate(-1)}
            isLoading={isLoading}
        />
    );
};

export default PasswordContainer; 