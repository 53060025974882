import React from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import PostItemContainer from "./PostItem.container";

interface PostComponentProps {
    images: Array<{ id: string; url: string }>;
    content: string;
    posts: Array<any>;
    isLoading: boolean;
    hasMore: boolean;
    fileInputRef: React.RefObject<HTMLInputElement>;
    onImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRemoveImage: (id: string) => void;
    onContentChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onPost: () => void;
    onLoadMore: () => void;
    isPosting: boolean;
    setPosts: (posts: Array<any>) => void;
}

const PostComponent: React.FC<PostComponentProps> = ({
    images,
    content,
    posts,
    isLoading,
    hasMore,
    fileInputRef,
    onImageUpload,
    onRemoveImage,
    onContentChange,
    onPost,
    onLoadMore,
    isPosting,
    setPosts
}) => {
    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <div className="flex items-center justify-between mb-8">
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "post.txtPostList")}
                    </h2>
                </div>

                <div className="max-w-2xl mx-auto space-y-6">
                    {/* 포스트 작성 폼 */}
                    <div className="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-shadow">
                        <div className="flex items-center space-x-4 mb-6">
                            <div className="w-12 h-12 rounded-full flex items-center justify-center">
                                <svg className="w-6 h-6 text-[#CF3688]/50" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-gray-900">
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "post.txtPostWriteTitle")}
                                </h3>
                                <p className="text-sm text-gray-500">
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "post.txtPostWriteDesc" + (Math.floor(Math.random() * 3) + 1))}
                                </p>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <textarea
                                className="w-full h-32 p-4 border-2 border-gray-200 rounded-xl
                                    focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                    text-base transition-colors resize-none"
                                placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "post.txtPostWritePlaceholder")}
                                value={content}
                                onChange={onContentChange}
                                maxLength={1000}
                            />
                            <div className="text-right text-sm text-gray-500">
                                {content.length}/1000
                            </div>

                            {/* 이미지 미리보기 영역 */}
                            {images.length > 0 && (
                                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
                                    {images.map(image => (
                                        <div key={image.id} className="relative aspect-square bg-gray-100 rounded-xl overflow-hidden">
                                            <img src={image.url} alt="" className="w-full h-full object-cover" />
                                            <button
                                                onClick={() => onRemoveImage(image.id)}
                                                className="absolute top-2 right-2 p-1.5 bg-gray-900/50 rounded-full text-white 
                                                    hover:bg-gray-900/75 transition-colors">
                                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className="flex justify-between items-center">
                                <div className="flex space-x-2">
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        className="hidden"
                                        accept="image/*"
                                        multiple
                                        onChange={onImageUpload}
                                        disabled={isPosting}
                                    />
                                    <button
                                        onClick={() => fileInputRef.current?.click()}
                                        disabled={isPosting || images.length >= 10}
                                        className={`p-2 transition-colors ${isPosting || images.length >= 10
                                            ? 'text-gray-400 cursor-not-allowed'
                                            : 'text-gray-500 hover:text-[#CF3688]'
                                            }`}
                                        title={`이미지 추가 (${images.length}/10)`}>
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </button>
                                </div>
                                <button
                                    onClick={onPost}
                                    disabled={isPosting || !content.trim()}
                                    className={`px-4 py-2 rounded-xl transition-colors
                                        focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                        ${isPosting
                                            ? 'bg-gray-300 text-gray-500 cursor-wait'
                                            : content.trim()
                                                ? 'bg-[#CF3688] text-white hover:bg-[#B32E74]'
                                                : 'bg-gray-200 text-gray-500 cursor-not-allowed'}`}>
                                    {isPosting ? getLanguageValue(PAGE_TYPE.STUDENT, "post.txtPosting") : getLanguageValue(PAGE_TYPE.STUDENT, "post.btnWrite")}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* 포스트 목록 */}
                    {isLoading && posts.length === 0 ? (
                        <div className="flex justify-center items-center h-32">
                            <LoadingComponent />
                        </div>
                    ) : (
                        <>
                            {posts.length === 0 ? (
                                // 포스트가 없을 경우
                                <div className="bg-white p-8 rounded-2xl text-center">
                                    <p className="text-gray-500">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "post.txtEmpty")}
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div className="space-y-4">
                                        {posts.map((post, index) => (
                                            <PostItemContainer
                                                key={index}
                                                post={post}
                                                posts={posts}
                                                setPosts={setPosts}
                                            />
                                        ))}
                                    </div>

                                    {/* 더보기 버튼 */}
                                    {(!isLoading && hasMore) && (
                                        <div className="flex justify-center mt-6">
                                            <button
                                                onClick={onLoadMore}
                                                className="px-6 py-2 bg-white text-[#CF3688] border-2 border-[#CF3688] rounded-xl
                                                    hover:bg-[#CF3688] hover:text-white transition-colors
                                                    focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20">
                                                {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtMore")}
                                            </button>
                                        </div>
                                    )}

                                    {/* 추가 로딩 인디케이터 */}
                                    {isLoading && (
                                        <div className="flex justify-center items-center h-16">
                                            <LoadingComponent />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default PostComponent;