import { formattedDateTimeForLocale } from "helpers/Datetime.helper";
import { getImageUrl } from "helpers/Image.helper";
import React from "react";
import { Link } from "react-router-dom";

const PostItemComponent = (props: any) => {
    const { post, onShowLikePopup } = props;
    return (
        <div key={post.id} className="bg-white p-6 rounded-2xl shadow-sm">
            <div className="flex items-center justify-between mb-4">
                <span className="text-sm text-gray-500">
                    {formattedDateTimeForLocale(post.createdAt, navigator.language || 'en-US')}
                </span>
            </div>

            {/* 본문 영역 - 클릭시 상세화면으로 이동 */}
            <Link to={`/student/fan-page/post/${post.id}`}>
                <div className="mb-6 cursor-pointer hover:text-[#CF3688] transition-colors">
                    {/* 포스트 내용 */}
                    <p className="text-gray-900 mb-4 whitespace-pre-wrap break-words overflow-hidden 
                    text-sm sm:text-base md:text-lg
                    line-clamp-3 sm:line-clamp-4 md:line-clamp-5">
                        {post.text}
                    </p>

                    {/* 포스트 이미지 */}
                    {post.imageList?.length > 0 && (
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
                            {post.imageList.map((image: any) => (
                                <div key={image.id} className="relative aspect-square bg-gray-100 rounded-xl overflow-hidden">
                                    <img src={getImageUrl(image.imageInfo)} alt="" className="w-full h-full object-cover" />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Link>

            {/* 포스트 메타 정보 영역 */}
            <div className="flex flex-col gap-3 pt-4 border-t border-gray-100">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-6">
                        <div className="flex items-center space-x-2 text-gray-500">
                            <button className="hover:text-[#CF3688] transition-colors">
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <span className="text-sm"
                                onClick={() => onShowLikePopup(post.id)}
                            >{post.likeCount || 0}</span>
                        </div>
                        <div className="flex items-center space-x-2 text-gray-500">
                            <button className="hover:text-[#CF3688] transition-colors">
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                </svg>
                            </button>
                            <span className="text-sm">{post.commentCount || 0}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostItemComponent;