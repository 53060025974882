import React from 'react';
import PhotoContainer from './Photo.container';
import ProfileForAgencyContainer from './ProfileForAgency.container';
import GrowthInfoContainer from './GrowthInfo.container';
import { Link } from 'react-router-dom';
import LoadingComponent from 'commons/loading/Loading.component';
import { getStudentName } from 'helpers/String.helper';
import FullPageLoadingComponent from 'commons/loading/FullPageLoading.component';

const IndexComponent = (props: any) => {
  const {
    tabIdx,
    setTabIdx,
    userStudentId,
    homeInfo,
    isOnNetworking,
    onRequestChat,
    onCancelChat,
    isInit,
    showBasicInfoPopup,
    onClickBack,
    errorMessage
  } = props;

  return (
    <main className="bg-gray-50 min-h-screen !pt-4">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {!isInit && (
          <FullPageLoadingComponent />
        )}
        {errorMessage && (
          <div className="fixed top-0 left-0 right-0 bg-gray-50 z-10 border-b border-gray-200">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
              <div className="flex items-center gap-4 mb-4">
                <button onClick={onClickBack} className="text-gray-600 hover:text-gray-900">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                </button>
              </div>
              <div className="flex items-center justify-center text-center text-red-500 bg-red-100 border border-red-400 rounded p-4 h-full">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z" />
                </svg>
                {errorMessage}
              </div>
            </div>
          </div>
        )}
        {homeInfo &&
          <>
            {/* 헤더 영역 - 고정 */}
            <div className="fixed top-0 left-0 right-0 bg-gray-50 z-10 border-b border-gray-200">
              <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
                <div className="flex items-center gap-4 mb-4">
                  <button onClick={onClickBack} className="text-gray-600 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                  {isInit && homeInfo && (
                    <h1 className="text-lg font-bold text-gray-900">
                      {getStudentName(homeInfo?.userStudentInfo)}
                    </h1>
                  )}
                  <button
                    onClick={() => showBasicInfoPopup()}
                    className="text-[#cf3688] ml-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <h2 className="text-xl sm:text-2xl font-bold text-gray-900">
                    <div className="flex space-x-4">
                      <button
                        onClick={() => setTabIdx(0)}
                        className={tabIdx === 0 ? `text-[#CF3688]` : `text-gray-400`}>
                        성장 정보
                      </button>
                      <button
                        onClick={() => setTabIdx(1)}
                        className={tabIdx === 1 ? `text-[#CF3688]` : `text-gray-400`}>
                        기획사용 정보
                      </button>
                      <button
                        onClick={() => setTabIdx(2)}
                        className={tabIdx === 2 ? `text-[#CF3688]` : `text-gray-400`}>
                        사진첩
                      </button>
                    </div>
                  </h2>
                </div>
              </div>
            </div>

            {/* 콘텐츠 영역 - 헤더 아래 스크롤 */}
            <div className="pt-32">
              <div className="max-w-2xl mx-auto space-y-6">
                {tabIdx === 0 && <GrowthInfoContainer userStudentId={userStudentId} />}
                {tabIdx === 1 && <ProfileForAgencyContainer userStudentId={userStudentId} />}
                {tabIdx === 2 && <PhotoContainer userStudentId={userStudentId} />}
              </div>
            </div>

            {/* 채팅 버튼 */}

            <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
              {isInit && homeInfo && (
                <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
                  {!homeInfo.messageInfo && (
                    <button
                      disabled={isOnNetworking}
                      className="bg-[#CF3688] text-white px-4 py-2 rounded-lg w-full" onClick={onRequestChat}>
                      대화 요청
                    </button>
                  )}
                  {homeInfo?.messageInfo?.messageStatusCode === 'messageType:request' && (
                    <button
                      disabled={isOnNetworking}
                      className="bg-[#CF3688] text-white px-4 py-2 rounded-lg w-full" onClick={onCancelChat}>
                      대화 요청 취소
                    </button>
                  )}
                  {homeInfo?.messageInfo?.messageStatusCode === 'messageType:accept' && (
                    <Link to={`/agency/message/${homeInfo.messageInfo.channelId}/chat`}
                      className="block bg-[#CF3688] !text-white px-4 py-2 rounded-lg w-full text-center">
                      대화 시작
                    </Link>
                  )}
                </div>
              )}
            </div>
          </>
        }
      </div>
    </main>
  );
};

export default IndexComponent;