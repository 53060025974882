import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";
import PostItemContainer from "./PostItem.container";
import { getImageUrl } from "helpers/Image.helper";
import { Link } from "react-router-dom";
import { getFlagByCountryCode, getStudentNickname } from "helpers/String.helper";
const TXT_IDX = (Math.floor(Math.random() * 3) + 1);
const HomeComponent = (props: any) => {
    const { info,
        isLoading,
        isOnSendEmailForParentConfirmation,
        isOnSendEmailForEmailVerification,
        handleSendEmailForParentConfirmation,
        handleSendEmailForEmailVerification,
        requestChatList,
        posts,
        isLoadingPosts,
        isPosting,
        hasMore,
        fileInputRef,
        onImageUpload,
        onRemoveImage,
        onContentChange,
        onPost,
        onLoadMore,
        images,
        content,
        setPosts,
        onRequestClick,
        handleShowStudentInfoPopup,
        handleShowFullPageFollowPopup,
    } = props;

    return (
        <>
            <main className="bg-gray-50 min-h-screen">
                <>
                    {isLoading ? (
                        <LoadingComponent />
                    ) : (
                        <div className="container mx-auto pt-6">
                            {/* <h2 className="text-3xl font-bold text-gray-900 mb-8">Dashboard</h2> */}

                            {info &&
                                <>
                                    {/* 부모 이메일 인증 */}
                                    {info.under14 && !info.isParentConfirm && (
                                        <div className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4 sm:mb-6">
                                            <div className="p-4 sm:p-6 border-b border-gray-100">
                                                <h2 className="text-base sm:text-lg md:text-xl font-bold text-gray-900">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, 'home.txtNeedParentConfirm')}
                                                </h2>
                                            </div>
                                            <div className="p-4 sm:p-6">
                                                <div className="space-y-3 sm:space-y-4">
                                                    <p className="text-sm sm:text-base text-gray-600">
                                                        <strong className="text-[#CF3688] break-all">{info.parentEmail}</strong> {getLanguageValue(PAGE_TYPE.STUDENT, 'home.txtNeedParentConfirmDescription')}
                                                    </p>
                                                    <p className="text-sm sm:text-base text-gray-600">
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, 'home.txtWarningEmailDesc')}
                                                    </p>
                                                    <button className="w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-2.5 text-sm sm:text-base bg-[#CF3688] text-white font-medium rounded-lg 
                                                hover:bg-[#a62d6d] focus:ring-4 focus:ring-pink-300 transition duration-200
                                                disabled:bg-gray-300 disabled:cursor-not-allowed disabled:hover:bg-gray-300"
                                                        onClick={handleSendEmailForParentConfirmation}
                                                        disabled={isOnSendEmailForParentConfirmation}>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, 'home.btnSendEmailForParentConfirmation')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* 가입 이메일 인증 */}
                                    {!info.hasVerifiedEmail && (
                                        <div className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4 sm:mb-6">
                                            <div className="p-4 sm:p-6 border-b border-gray-100">
                                                <h2 className="text-base sm:text-lg md:text-xl font-bold text-gray-900">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, 'home.txtNeedVerifyEmail')}
                                                </h2>
                                            </div>
                                            <div className="p-4 sm:p-6">
                                                <div className="space-y-3 sm:space-y-4">
                                                    <p className="text-sm sm:text-base text-gray-600">
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, 'home.txtNeedVerifyEmailDescription')}
                                                    </p>
                                                    <p className="text-sm sm:text-base text-gray-600">
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, 'home.txtWarningEmailDesc')}
                                                    </p>
                                                    <button className="w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-2.5 text-sm sm:text-base bg-[#CF3688] text-white font-medium rounded-lg
                                                hover:bg-[#a62d6d] focus:ring-4 focus:ring-pink-300 transition duration-200
                                                disabled:bg-gray-300 disabled:cursor-not-allowed disabled:hover:bg-gray-300"
                                                        onClick={handleSendEmailForEmailVerification}
                                                        disabled={isOnSendEmailForEmailVerification}>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, 'home.btnSendEmailForEmailVerification')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* 대화요청 목록 */}
                                    {info?.requestChatList?.length > 0 && (
                                        <div className="mb-4">
                                            <div className="p-2 sm:p-3">
                                                <h2 className="text-lg sm:text-xl font-bold text-gray-900">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "home.txtRequestChatListTitle")}
                                                    ({info?.requestChatList?.length})
                                                </h2>
                                            </div>
                                            <div className="overflow-x-auto">
                                                <div className="flex gap-1">
                                                    {info?.requestChatList?.map((item: any) => (
                                                        <div
                                                            key={item.id}
                                                            onClick={() => onRequestClick(item)}
                                                            className="w-[100px] sm:w-[125px] lg:w-[150px] flex-shrink-0 cursor-pointer">
                                                            <div className="flex flex-col items-center">
                                                                <div className="rounded-sm overflow-hidden w-14 h-14 sm:w-16 sm:h-16 flex-shrink-0 border-2 border-gray-100">
                                                                    <img
                                                                        src={getImageUrl(item?.userAgencyInfo?.agencyInfo?.logoImageInfo)}
                                                                        alt={item?.userAgencyInfo?.agencyInfo?.nameEn}
                                                                        className="w-full h-full object-contain"
                                                                    />
                                                                </div>
                                                                <div className="mt-2 text-center w-full">
                                                                    <p className="text-gray-900 text-xs truncate px-2">
                                                                        {item?.userAgencyInfo?.agencyInfo?.nameEn}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* 팔로잉 목록 */}
                                    {info?.recommendFollowingList?.length > 0 && (
                                        <div className="mb-8">
                                            <Link to="/student/follow" className="p-2 sm:p-3 cursor-pointer flex items-center">
                                                <h2 className="text-lg sm:text-xl font-bold text-gray-900">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "home.txtRecommendedFollowingListTitle")}
                                                </h2>
                                                <span className="text-gray-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" className="inline-block ml-1">
                                                        <path d="M9 18l6-6-6-6" />
                                                    </svg>
                                                </span>
                                            </Link>
                                            <div className="overflow-x-auto">
                                                <div className="flex gap-0.25">
                                                    {info?.recommendFollowingList?.map((studentInfo: any) => (
                                                        <div key={studentInfo.id} className="w-[95px] sm:w-[120px] lg:w-[145px] flex-shrink-0 cursor-pointer"
                                                            onClick={() => handleShowStudentInfoPopup(studentInfo?.id)}
                                                        >
                                                            <div className="flex flex-col items-center">
                                                                <div className="rounded-full overflow-hidden w-14 h-14 sm:w-16 sm:h-16 flex-shrink-0 border-2 border-gray-100 relative">
                                                                    <img
                                                                        src={getImageUrl(studentInfo?.profileImageInfo)}
                                                                        alt={getStudentNickname(studentInfo)}
                                                                        className="w-full h-full object-cover"
                                                                    />
                                                                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
                                                                        {getFlagByCountryCode(studentInfo?.countryInfo?.code)}
                                                                    </div>
                                                                </div>
                                                                <div className="mt-1 text-center w-full">
                                                                    <p className="text-gray-900 text-xs truncate px-1">
                                                                        {getStudentNickname(studentInfo)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* 팔로잉 목록 */}
                                    {info?.followingList?.length > 0 && (
                                        <div className="mb-8">
                                            <Link to="/student/follow" className="p-2 sm:p-3 cursor-pointer flex items-center">
                                                <h2 className="text-lg sm:text-xl font-bold text-gray-900">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "home.txtFollowingListTitle")}
                                                </h2>
                                                <span className="text-gray-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" className="inline-block ml-1">
                                                        <path d="M9 18l6-6-6-6" />
                                                    </svg>
                                                </span>
                                            </Link>
                                            <div className="overflow-x-auto">
                                                <div className="flex gap-1">
                                                    {info?.followingList?.map((item: any) => (
                                                        <div key={item.id} className="w-[100px] sm:w-[125px] lg:w-[150px] flex-shrink-0 cursor-pointer"
                                                            onClick={() => handleShowStudentInfoPopup(item?.followingUserInfo?.userStudentInfo?.id)}
                                                        >
                                                            <div className="flex flex-col items-center">
                                                                <div className="rounded-full overflow-hidden w-14 h-14 sm:w-16 sm:h-16 flex-shrink-0 border-2 border-gray-100">
                                                                    <img
                                                                        src={getImageUrl(item?.followingUserInfo?.userStudentInfo?.profileImageInfo)}
                                                                        alt={item?.followingUserInfo?.userStudentInfo?.name}
                                                                        className="w-full h-full object-cover"
                                                                    />
                                                                </div>
                                                                <div className="mt-2 text-center w-full">
                                                                    <p className="text-gray-900 text-xs truncate px-2">
                                                                        {item?.followingUserInfo?.userStudentInfo?.name}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* 팬페이지 */}
                                    {/* <div className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4 sm:mb-6">
                                <div className="p-4 sm:p-6 border-b border-gray-100">
                                    <h2 className="text-lg sm:text-xl font-bold text-gray-900">내 팬페이지</h2>
                                </div>
                                <div className="p-4 sm:p-6">
                                    <a
                                        href={getFanPageUrlBySlug(info.slug)}
                                        className="text-[#CF3688] hover:text-[#a62d6d] transition-colors duration-200 break-all underline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {getFanPageUrlBySlug(info.slug)}
                                    </a>
                                </div>
                            </div> */}
                                </>
                            }




                            <div className="max-w-2xl mx-auto space-y-6 px-2">
                                {/* 포스트 작성 폼 */}
                                <div className="bg-white p-4 rounded-2xl shadow-sm">
                                    {info.myPostCount === 0 &&
                                        <div className="bg-[#FFD700] p-4 rounded-2xl shadow-sm mb-4">
                                            <div className="flex items-center space-x-3">
                                                <div className="rounded-full flex items-center justify-center flex-shrink-0">
                                                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <p className="text-sm text-gray-700">
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "home.txtNoticeWritePost")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="flex items-center space-x-2 mb-2">
                                        <div className="w-12 h-12 rounded-full flex items-center justify-center">
                                            <svg className="w-6 h-6 text-[#CF3688]/50" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-gray-900">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.txtPostWriteTitle")}
                                            </h3>
                                            {/* <p className="text-sm text-gray-500">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.txtPostWriteDesc" + TXT_IDX)}
                                            </p> */}
                                        </div>
                                    </div>
                                    <div className="space-y-4">
                                        <textarea
                                            className="w-full h-32 px-4 py-2 border-2 border-gray-200 rounded-xl
                                                     focus:!border-[#CF3688] 
                                                    text-base transition-colors resize-none"
                                            placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.txtPostWritePlaceholder")}
                                            maxLength={1000}
                                            value={content}
                                            onChange={onContentChange}
                                        />
                                        {/* 이미지 미리보기 영역 */}
                                        {images.length > 0 && (
                                            <div className="relative">
                                                <div className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide space-x-3">
                                                    {images.map((image: any) => (
                                                        <div key={image.id} className="flex-none w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 relative snap-center rounded-xl overflow-hidden">
                                                            <img src={image.url} alt="" className="w-full h-full object-cover" />
                                                            <button
                                                                onClick={() => onRemoveImage(image.id)}
                                                                className="absolute top-0 right-0 p-1.5 bg-[#cf3688]/70 rounded-full text-white ">
                                                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        <div className="flex justify-between items-center">
                                            <div className="flex space-x-2">
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    className="hidden"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={onImageUpload}
                                                    disabled={isPosting}
                                                />
                                                <button
                                                    onClick={() => fileInputRef.current?.click()}
                                                    disabled={isPosting || images.length >= 10}
                                                    className={`p-2 transition-colors ${isPosting || images.length >= 10
                                                        ? 'text-gray-400 cursor-not-allowed'
                                                        : 'text-gray-500 hover:text-[#CF3688]'
                                                        }`}
                                                    title={`이미지 추가 (${images.length}/10)`}>
                                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                    </svg>
                                                </button>

                                                <div className="flex items-center gap-2 text-sm text-gray-500">
                                                    <span>{images.length}/10</span>
                                                </div>
                                            </div>

                                            <button
                                                onClick={onPost}
                                                disabled={isPosting}
                                                className="px-4 py-2 rounded-xl bg-[#CF3688] text-white hover:bg-[#B32E74] transition-colors
                                        focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnWrite")}
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                {!isLoadingPosts && posts.length === 0 && (
                                    <div className="bg-white p-8 rounded-2xl text-center">
                                        <p className="text-gray-500">{getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.txtEmptyList")}</p>
                                    </div>
                                )}

                                <div className="space-y-4 px-2">
                                    {posts.map((post: any) => (
                                        <PostItemContainer
                                            key={post.id}
                                            post={post}
                                            setPosts={setPosts}
                                            posts={posts}
                                        />
                                    ))}
                                </div>

                                {/* 추가 로딩 인디케이터 */}
                                {isLoadingPosts && (
                                    <div className="flex justify-center items-center h-16">
                                        <LoadingComponent />
                                    </div>
                                )}
                            </div>
                        </div>)
                    }
                </>
            </main >
        </>
    );
};

export default HomeComponent;
