import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import * as AuthApi from "api/common/Auth.api";
import { platform } from "os";
import EventEmitter from "events";
import { getPushToken, isInApp } from "./InApp.helper";
const notificationEmitter = new EventEmitter();

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const isFcmSupported = () => {
    return "serviceWorker" in navigator && "Notification" in window;
};

let messaging: any = null;
// Initialize Firebase
if (isFcmSupported()) {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);
}

export const requestNotificationPermission = async () => {
    if (isFcmSupported()) {
        try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                const registration = await registerServiceWorker();
                console.log(registration);
                if (registration && registration.active) {
                    const token = await getToken(messaging, {
                        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                        serviceWorkerRegistration: registration,
                    });
                    //alert(token);
                    if (token) {
                        await sendTokenToServer(token);
                    }
                    // else {
                    //   alert("토큰 등록이 불가능 합니다. 생성하려면 권한을 허용해주세요");
                    // }
                }
            }
            // else if (permission === "denied") {
            //   alert(
            //     "web push 권한이 차단되었습니다. 알림을 사용하시려면 권한을 허용해주세요"
            //   );
            // }
        } catch (error) {
            console.error("푸시 토큰 가져오는 중에 에러 발생", error);
        }
    }
};

const sendTokenToServer = async (token: string) => {
    await AuthApi.createFcmTokenInfo({ token, platform: "web" });
};

export const registerServiceWorker = async (): Promise<ServiceWorkerRegistration | null> => {
    if ("serviceWorker" in navigator) {
        try {
            let registration = await navigator.serviceWorker.getRegistration();
            if (!registration) {
                registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
            }
            // 서비스 워커가 활성화될 때까지 기다립니다.
            await navigator.serviceWorker.ready;
            // Initialize Firebase in the service worker
            registration.active?.postMessage({
                type: "INIT_FIREBASE",
                config: firebaseConfig,
            });
            return registration;
        } catch (err) {
            console.log("Service Worker 등록 실패:", err);
            return null;
        }
    } else {
        console.warn("서비스 워커를 지원하지 않습니다.");
        return null;
    }
};

export const removePushToken = async () => {
    // 웹 푸쉬 token
    if (isFcmSupported()) {
        try {
            const currentToken = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
            });
            if (currentToken) {
                await deleteToken(messaging);
                await removeTokenOfServer(currentToken, "web");

                console.log("Push notification token deleted.");
            }
        } catch (error) {
            console.error("Failed to delete push notification token:", error);
        }
    }

    // 앱 푸쉬 token
    if (isInApp()) {
        const token = await getPushToken();
        await removeTokenOfServer(token, "app");
    }
};

const removeTokenOfServer = async (token: string, platform: string) => {
    await AuthApi.deleteFcmTokenInfo({ token, platform });
};

export const listenForForegroundMessages = async () => {
    if (isFcmSupported()) {
        onMessage(messaging, (payload) => {
            console.log("Foreground message received:", payload);
            //if (payload.notification) {
            //  return;
            //}

            const data = payload.data;
            const notificationTitle = data.title;
            const notificationOptions = {
                body: data.body,
                icon: data.logo || "/default-icon.png",
                data: {
                    linkUrl: data.linkUrl || null,
                    action: data.action || null,
                },
            };

            if (Notification.permission === "granted") {
                const notification = new Notification(notificationTitle, notificationOptions);
                notification.onclick = (event) => {
                    event.preventDefault(); // Prevent the browser from focusing the Notification's tab
                    console.log(notificationOptions.data);
                    if (notificationOptions.data.linkUrl) {
                        window.open(notificationOptions.data.linkUrl, "_blank");
                    }
                    if (notificationOptions.data.action === "reload") {
                        window.location.reload(); // 알림 클릭 시 페이지 리로드
                    }
                };
            } else {
                console.log("Notification permission not granted");
            }
            //fetchNotification();
            //onMessageReceived(payload);
            console.log("notificationEmitter.emit", payload);
            notificationEmitter.emit("notification", payload);
        });
    }
};

export const notificationListener = notificationEmitter;
