import FullPageLoadingComponent from "commons/loading/FullPageLoading.component";
import LoadingComponent from "commons/loading/Loading.component";
import SocketWrapContainer from "pages/common/chat/SocketWrap.container";
import ChatItemContainer from "pages/common/chat/ChatItem.container";
import React from "react";
import { useNavigate } from "react-router-dom";
import { formattedDateForLocale } from "helpers/Datetime.helper";
import { formattedTimeForLocale } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const ChatComponent = (props: any) => {
    const navigate = useNavigate();
    const {
        channelId,
        channelInfo,

        isInit,
        chatRef,
        messages,

        userInfo,
        isError,
        quitChannel,
        openReportPopup,
        openProfilePopup,
        isOnLoading,
        fileInputRef,
        handleFileChange,
        handleScroll,

        sendMyTextMessage,
        setMyText,
        myText,
        messageHandler,
        isOnLoadPrev,
        hasPrev,
        setWebSocket,
        setSocketStatus,
        socketStatus,
        isFileUploadAreaVisible,
        setIsFileUploadAreaVisible,
        isOpenTooltip,
        setIsOpenTooltip,

        isOnBottom,
        scrollToBottom,
        onMessageAdded,
        newMessageTip
    } = props;

    return (
        <main className="h-[100dvh] flex flex-col bg-white !p-0">
            {isError &&
                <div className="flex flex-col items-center justify-center h-full w-full p-4">
                    <p className="text-gray-500 text-base sm:text-lg mb-4 text-center">
                        죄송합니다... 채팅에 오류가 있어요...
                    </p>
                    <button
                        onClick={e => quitChannel(false)}
                        className="bg-[#cf3688] text-white px-6 py-3 rounded-lg text-sm sm:text-base font-medium hover:bg-[#b62f75] transition-colors">
                        대화 종료
                    </button>
                </div>
            }
            {isInit &&
                <>
                    {!channelInfo && ""}
                    {channelInfo &&
                        <>

                            <div className="flex flex-col border-b border-gray-100">
                                {/* 헤더 */}
                                <div className="px-3 sm:px-4 py-2 sm:py-3 flex items-center justify-between">
                                    {/* 좌측 뒤로가기 버튼 */}
                                    <button
                                        onClick={() => navigate("/agency/message")}
                                        className="text-gray-400 hover:text-gray-600 transition-colors">
                                        <svg className="w-5 h-5 sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                                        </svg>
                                    </button>

                                    {/* 채팅방 제목 */}
                                    <div className="text-sm font-bold">
                                        {channelInfo.schoolName
                                            ? channelInfo.schoolName
                                            : channelInfo.userStudentLastName + " " + channelInfo.userStudentFirstName
                                        }
                                        {/* ({isOnBottom ? "bottom" : "not bottom"}) */}
                                    </div>
                                    {/* 우측 */}
                                    <div className="flex items-center gap-2 sm:gap-3">
                                        <button
                                            onClick={() => setIsOpenTooltip(!isOpenTooltip)}
                                            className="flex items-center justify-center w-7 h-7 sm:w-8 sm:h-8 text-gray-600 hover:bg-gray-50 rounded-full transition-colors"
                                            title="채팅방 나가기"
                                        >
                                            <svg className="w-6 h-6 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                            </svg>
                                        </button>

                                        {/* 툴팁 */}
                                        {isOpenTooltip && (
                                            <div className="absolute right-0 top-12 mt-2 w-48 rounded-md shadow-sm bg-white ring-1 ring-black ring-opacity-5 z-10">
                                                <div className="py-1" role="menu" aria-orientation="vertical">
                                                    <button
                                                        onClick={openProfilePopup}
                                                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                        role="menuitem"
                                                    >
                                                        {getLanguageValue(PAGE_TYPE.COMMON, "chatTooltip.btnShowProfile")}
                                                    </button>
                                                    <button
                                                        onClick={openReportPopup}
                                                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                        role="menuitem"
                                                    >
                                                        {getLanguageValue(PAGE_TYPE.COMMON, "chatTooltip.btnReport")}
                                                    </button>

                                                    <hr className="my-1 border-gray-200" />

                                                    {channelInfo.messageStatusCode === 'messageType:accept' &&
                                                        <button
                                                            onClick={quitChannel}
                                                            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                            role="menuitem"
                                                        >
                                                            {getLanguageValue(PAGE_TYPE.COMMON, "chatTooltip.btnQuit")}
                                                        </button>
                                                    }
                                                    {/* {channelInfo.messageStatusCode === 'messageType:close' &&
                                                        <button
                                                            onClick={quitChannel}
                                                            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                            role="menuitem"
                                                        >
                                                            {getLanguageValue(PAGE_TYPE.COMMON, "chatTooltip.btnRemove")}
                                                        </button>
                                                    } */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* 소켓 컨테이너 */}
                                {channelInfo.messageStatusCode === 'messageType:accept' &&
                                    <SocketWrapContainer
                                        channelId={channelId}
                                        userId={userInfo.userAgencyId}
                                        accountTypeCode={userInfo.accountTypeCode}
                                        messageHandler={messageHandler}
                                        setWebSocket={setWebSocket}
                                        setSocketStatusFromParent={setSocketStatus}
                                    />
                                }
                            </div>


                            <div className="flex-1 flex flex-col min-h-0">
                                {/* 채팅 메시지 목록 */}
                                {/* 채팅 영역 */}
                                <div
                                    ref={chatRef}
                                    onScroll={handleScroll}
                                    className="flex-1 overflow-y-auto px-3 sm:px-4 pb-safe"
                                >
                                    {/* 이전 메시지 로딩 중 */}
                                    {isOnLoadPrev && (
                                        <div className="flex justify-center items-center h-fit py-2">
                                            <LoadingComponent />
                                        </div>
                                    )}

                                    {/* 이전 메시지가 없을 경우 */}
                                    {!isOnLoadPrev && !hasPrev && (
                                        <div className="flex justify-center items-center h-fit py-2">
                                            <div className="text-gray-500 text-sm">이전 메시지가 없습니다.</div>
                                        </div>
                                    )}

                                    {/* 메시지 로딩 중 */}
                                    {isOnLoading ? (
                                        <div className="flex justify-center items-center h-full">
                                            <FullPageLoadingComponent />
                                        </div>
                                    ) : messages ? (
                                        messages.map((message: any, index: number) => {
                                            const currentDate = new Date(message.createdAt).toLocaleDateString();
                                            const prevDate = index > 0 ? new Date(messages[index - 1].createdAt).toLocaleDateString() : null;

                                            let messageDate = null;
                                            if (index === messages.length - 1 ||
                                                messages[index + 1].userType !== message.userType ||
                                                new Date(messages[index + 1].createdAt).getHours() !== new Date(message.createdAt).getHours() ||
                                                new Date(messages[index + 1].createdAt).getMinutes() !== new Date(message.createdAt).getMinutes()) {
                                                messageDate = formattedTimeForLocale(message.createdAt, navigator.language);
                                            }

                                            return (
                                                <React.Fragment key={message.messageId}>
                                                    {(index > 0 && currentDate !== prevDate) && (
                                                        <div className="flex justify-center my-4 w-full">
                                                            <div className="relative text-center w-full">
                                                                <div className="absolute inset-0 flex items-center w-full">
                                                                    <div className="w-full border-t border-solid border-gray-200"></div>
                                                                </div>
                                                                <div className="relative">
                                                                    <span className="bg-white text-xs text-gray-500 px-3 py-1 rounded-full">
                                                                        {formattedDateForLocale(currentDate, navigator.language)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <ChatItemContainer
                                                        message={message}
                                                        messageDate={messageDate}
                                                        userInfo={userInfo}
                                                        id={message.messageId}
                                                        onMessageAdded={onMessageAdded}
                                                    />
                                                </React.Fragment>
                                            );
                                        })
                                    ) : null}
                                </div>

                                {/* 입력란 */}
                                {channelInfo.messageStatusCode === 'messageType:accept' ?
                                    <div className="relative border-t pb-safe">
                                        {newMessageTip && (
                                            <div className="absolute top-[-60px] left-0 right-0 flex justify-center items-center py-2 z-10"
                                                onClick={() => {
                                                    scrollToBottom();
                                                }}
                                            >
                                                <div className="bg-gray-800 text-white text-sm px-4 py-2 rounded-full shadow-sm">{newMessageTip}</div>
                                            </div>
                                        )}
                                        {/* 파일 업로드 선택 영역 - 기본적으로 숨김 */}
                                        <div
                                            className={`transition-all duration-200 ease-in-out backdrop-blur-sm ${isFileUploadAreaVisible
                                                ? 'max-h-[60px] opacity-100'
                                                : 'max-h-0 opacity-0 overflow-hidden'
                                                }`}
                                        >
                                            <div className="px-3 sm:px-4 py-2 sm:py-3 flex items-center justify-between bg-gray-50/80 backdrop-blur-sm border-b border-gray-100">
                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleFileChange}
                                                    className="hidden"
                                                />
                                                <div className="flex gap-2">
                                                    <button
                                                        onClick={() => {
                                                            if (fileInputRef.current) {
                                                                fileInputRef.current.accept = "image/*";
                                                                fileInputRef.current.click();
                                                            }
                                                        }}
                                                        className="px-3 py-1.5 text-sm text-gray-700 hover:bg-white/80 rounded-full transition-colors flex items-center gap-1 backdrop-blur-sm"
                                                    >
                                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                        </svg>
                                                        이미지
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            if (fileInputRef.current) {
                                                                fileInputRef.current.accept = "video/*";
                                                                fileInputRef.current.click();
                                                            }
                                                        }}
                                                        className="px-3 py-1.5 text-sm text-gray-700 hover:bg-white/80 rounded-full transition-colors flex items-center gap-1 backdrop-blur-sm"
                                                    >
                                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                                        </svg>
                                                        비디오
                                                    </button>
                                                </div>
                                                <button
                                                    className="text-gray-500 hover:text-gray-700 transition-colors p-1.5 rounded-full hover:bg-white/80 backdrop-blur-sm"
                                                    onClick={() => setIsFileUploadAreaVisible(false)}
                                                >
                                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="px-3 sm:px-4 py-2 sm:py-3 flex items-center gap-2 border-gray-100 bg-white">
                                            {/* 메시지 입력 영역 */}
                                            <button
                                                onClick={() => setIsFileUploadAreaVisible(!isFileUploadAreaVisible)}
                                                className={`p-1.5 sm:p-2 rounded-full transition-colors ${isFileUploadAreaVisible
                                                    ? 'text-[#CF3688] bg-pink-50'
                                                    : 'text-gray-400 hover:text-gray-600'
                                                    }`}
                                            >
                                                <svg className="w-5 h-5 sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                                </svg>
                                            </button>
                                            {/* 메시지 입력란 */}
                                            <input
                                                className="flex-1 border border-gray-200 rounded-full px-3 sm:px-4 py-1.5 sm:py-2 text-sm sm:text-base focus:outline-none focus:border-[#CF3688] focus:ring-1 focus:ring-[#CF3688]"
                                                placeholder="메시지를 입력하세요..."
                                                value={myText}
                                                onChange={(e) => setMyText(e.target.value)}
                                                disabled={socketStatus !== "connected"}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter" && myText.trim() !== "") {
                                                        console.log("sendMyTextMessage");
                                                        sendMyTextMessage(channelId, myText);
                                                    }
                                                }}
                                            />
                                            {/* 메시지 전송 버튼 */}
                                            <button
                                                disabled={socketStatus !== "connected"}
                                                onClick={() => {
                                                    if (myText.trim() !== "") {
                                                        console.log("sendMyTextMessage");
                                                        sendMyTextMessage(channelId, myText);
                                                    }
                                                }}
                                                className="p-1.5 sm:p-2 text-[#CF3688] hover:text-[#CF3688]/80 transition-colors"
                                            >
                                                <svg className="w-5 h-5 sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className="px-3 sm:px-4 py-4 sm:py-6 flex flex-col items-center justify-center gap-3 border-t border-gray-100 bg-gray-50">
                                        <p className="text-gray-600 text-sm font-medium">
                                            대화가 종료되었습니다
                                        </p>
                                    </div>
                                }
                            </div>

                        </>
                    }
                </>
            }
            {!isInit && !isError && (
                <FullPageLoadingComponent />
            )}
        </main>
    );
};

export default ChatComponent;