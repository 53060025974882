import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";
import { useNavigate } from "react-router-dom";

const StudentSignupDoneComponent = (props: any) => {
    const { accountParams, studentParams, parentEmail, checkIsUnder14, imageInfo } = props;

    const navigate = useNavigate();
    return (
        <main className="min-h-screen w-full bg-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupDone.txtTitle")}
                </h2>

                {/* 이미지 정보 */}
                <div className="flex justify-center">
                    <div className="w-32 h-32 relative">
                        <div className={`w-full h-full rounded-full overflow-hidden ${imageInfo?.url ? 'bg-cover bg-center' : 'bg-gray-100'}`}
                            style={{
                                backgroundImage: `url(${imageInfo?.url || "./../../imgs/icons/camera.png"})`
                            }}
                        />
                    </div>
                </div>

                {/* 설명 */}
                <div className="mt-8 space-y-6">
                    <div className="text-center text-gray-700 space-y-4">
                        <p className="text-lg">
                            🎉
                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupDone.txtDesc1").replace(
                                /%NAME%/g,
                                `${studentParams.firstName} ${studentParams.lastName}`
                            )}
                            🎉
                        </p>
                        <p className="text-base">
                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupDone.txtDesc2").replace(
                                /%NAME%/g,
                                `${studentParams.firstName} ${studentParams.lastName}`
                            )}
                        </p>
                        <p className="text-base">
                            {!checkIsUnder14(studentParams.birthday) ? (
                                <>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignupDone.txtDesc3").replace("%EMAIL%", accountParams.username)}</>
                            ) : (
                                <>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupDone.txtDesc4")
                                        .replace("%EMAIL%", accountParams.username)
                                        .replace("%PARENT_EMAIL%", parentEmail)}
                                    <br />
                                    <br />
                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupDone.txtDesc5")}
                                </>
                            )}
                        </p>
                    </div>
                </div>

                <div className="mt-8">
                    <button
                        onClick={(e) => {
                            navigate("/login");
                        }}
                        className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#CF3688] hover:bg-[#B82F77] focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20 transition duration-150 ease-in-out"
                    >
                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupDone.btnGoToLogin")}
                    </button>
                </div>
            </div>
        </main>
    );
};

export default StudentSignupDoneComponent;
