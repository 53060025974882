// axios 인터 셉터
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { getAccessToken, setAccessToken, getRefreshToken, setRefreshToken, removeAuthTokens, getNewAccessTokenValue } from "../helpers/Auth.helper";
import { removeAppAccessToken } from "helpers/appLocalStorageHelper";
import { getNewAccessToken } from "./common/Auth.api";
import { Cookies } from "react-cookie";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const init = async () => {
    console.log("Axios.interceptor::init()");
    axios.interceptors.request.use(
        (config) => {
            console.log("Axios.interceptor::request");
            const accessToken = getAccessToken();
            if (accessToken) {
                config.headers["Authorization"] = `Bearer ${accessToken}`;
            }
            config.headers["accesskey"] = process.env.REACT_APP_API_ACCESS_KEY;
            config.headers["secretkey"] = process.env.REACT_APP_API_SECRET_KEY;
            config.headers["language-code"] = navigator.language;
            config.headers["X-Requested-With"] = "XMLHttpRequest";
            config.headers["Access-Control-Allow-Credentials"] = true;
            config.headers["Access-Control-Allow-Origin"] = "*";
            config.headers["Access-Control-Allow-Methods"] = "GET, PUT, POST, DELETE, OPTIONS";
            config.headers["Cache-Control"] = "no-cache";
            config.headers["Pragma"] = "no-cache";

            const cookies = new Cookies();
            // config.headers["browserId"] = cookies.get('browserId');
            // config.withCredentials = true;

            return config;
        },
        (err) => {
            console.error(err);
            Promise.reject(err);
        }
    );

    axios.interceptors.response.use(
        (response) => response,
        async (err: AxiosError) => {
            const response: any = err.response;

            if (!response) {
                return Promise.reject(err); // 네트워크 오류 또는 예상치 못한 에러
            }

            const config = response.config as AxiosRequestConfig;
            const headers = config.headers || {};

            // EXPIRED_ACCESS_TOKEN 처리
            if (response.status === 401) {
                console.log("Refreshing access token...");

                try {
                    const jwtToken = await getNewAccessTokenValue();

                    // Authorization 헤더 업데이트
                    headers["Authorization"] = `Bearer ${jwtToken}`;

                    // 원래 요청 재시도
                    config.headers = headers;
                    return axios(config);
                } catch (refreshError) {
                    console.error("Failed to refresh access token", refreshError);
                    removeAuthTokens();
                    await removeAppAccessToken(); // 로그아웃 처리
                    return Promise.reject(refreshError);
                }
            }

            // EXPIRED_REFRESH_TOKEN 처리
            // if (response.status === 401) {
            //     console.log("Refresh token expired, logging out...");
            //     removeAuthTokens();
            //     await removeAppAccessToken(); // 로그아웃 처리
            //     return Promise.reject(err);
            // }

            // INVALID_ACCESS_TOKEN 또는 INVALID_REFRESH_TOKEN 처리
            if (response.status === 403) {
                console.log("Invalid token, logging out...");
                removeAuthTokens();
                await removeAppAccessToken(); // 로그아웃 처리
                return Promise.reject(err);
            }

            // 기타 에러 처리
            if (response.status === 400) {
                // alert(response.data.message);
                return Promise.reject(err);
            }

            if (response.status === 504) {
                alert("The Server is not responding. Please try again later.");
                return Promise.reject(err);
            }

            console.error("Unhandled error", response);
            return Promise.reject(err);
        }
    );
};
