import React from "react";

const LoadingComponent = (props: any) => {
    return (
        <div id="likes-content" className="px-6 py-2 overflow-y-auto max-h-[60vh]">
            <div className="flex justify-center items-center">
                <div className="flex flex-col items-center gap-2">
                    <div className="animate-spin h-8 w-8">
                        <img src="/imgs/icons/icon-star-fill.svg" alt="Loading..."
                            className="w-8 h-8" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingComponent;
