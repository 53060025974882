import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SchoolInfoComponent = (props: any) => {
    useEffect(() => {}, []);

    const navigate = useNavigate();

    const goSignupPage = () => {
        navigate("/signup/school");
    };

    return (
        <main className="v2-landing-main v2-landing-main-school">
            <div className="con">
                <section className="v2-landing-header-section v2-landing-header-section-school">
                    <div className="con">
                        <div className="title">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtTitle"),
                                }}></p>
                        </div>
                        <div className="body">
                            <div className="header-img">
                                <img alt="profile-card" src="./imgs/landing/school-header-img.png" />
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 학원정보 등록 섹션 --> */}
                <section className="v2-landing-section-school-1">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection101")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection102"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="img">
                                                <img alt="register school info" src="./imgs/landing/school-section-img-1.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 재원생 관리 --> */}
                <section className="v2-landing-section-school-2">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection201")}</p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="text">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection202"),
                                                    }}></p>
                                            </div>
                                            <div className="img">
                                                <img alt="register student" src="./imgs/landing/school-section-img-2-1.png" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item item-2">
                                            <div className="text">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection203"),
                                                    }}></p>
                                            </div>
                                            <div className="img">
                                                <img alt="manage student" src="./imgs/landing/school-section-img-2-2.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 캐스팅 채팅 --> */}
                <section className="v2-landing-section-school-3">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection301")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection302"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="img">
                                                <img alt="casting chat" src="./imgs/landing/school-section-img-3.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 광고 섹션 --> */}
                <section className="v2-landing-section-school-4">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection401")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection402"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="img">
                                                <img alt="advertize" src="./imgs/landing/school-section-img-4.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 학원 관리 --> */}
                <section className="v2-landing-section-school-5">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection501")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSection502"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="img">
                                                <img alt="manage school" src="./imgs/landing/school-section-img-5.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoSchool.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="v2-landing-section-faq">
                    <div className="con">
                        <div className="body">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQnATitle")}</p>
                            </div>
                            <div className="faq-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="question">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQuestion1")}</p>
                                            </div>
                                            <div className="answer">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtAnswer1")}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <div className="question">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQuestion2")}</p>
                                            </div>
                                            <div className="answer">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtAnswer2")}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>
        </main>
    );
};

export default SchoolInfoComponent;
