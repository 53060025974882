import React, { useEffect, useState } from "react";
import FollowComponent from "./Follow.component";
import { getMyFollowerList, getMyFollowingList, addFollow, cancelFollow, getRecommendFollowingList } from "api/student/Follow.api";
import { usePopup } from "contexts/PopupContext";
import StudentInfoPopupContainer from "commons/student/popup/StudentInfoPopup.container";

const LIMIT = 10;

const FollowContainer = () => {
    const [activeTab, setActiveTab] = useState<'following' | 'follower'>('following');
    const [page, setPage] = useState<number>(1);
    const [list, setList] = useState<any[]>([]);
    const [isInit, setIsInit] = useState<boolean>(false);
    const [isOnNetworking, setIsOnNetworking] = useState<boolean>(false);
    const [isOnLoadMore, setIsOnLoadMore] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [recommendList, setRecommendList] = useState<any[]>([]);
    const [isOnLoadingRecommendList, setIsOnLoadingRecommendList] = useState<boolean>(true);
    const [errorMessageForRecommendList, setErrorMessageForRecommendList] = useState<string | null>(null);

    const { showPopup, hidePopup } = usePopup();

    const loadList = async (currentPage: number) => {
        if (!hasMore && currentPage > 1) return;

        setIsOnLoadMore(true);
        try {

            const data = await (activeTab === 'following'
                ? getMyFollowingList(currentPage, LIMIT)
                : getMyFollowerList(currentPage, LIMIT));

            if (data?.list) {
                setList(prev => currentPage === 1 ? data.list : [...prev, ...data.list]);
                setHasMore(data.list.length === LIMIT);
            }
        } catch (err: any) {
            console.error(err);
            setErrorMessage(err.message || "Unknown error occurred while loading list");
        } finally {
            setIsOnLoadMore(false);
            setIsInit(true);
        }
    };

    useEffect(() => {
        setIsInit(false);
        setPage(1);
        setHasMore(true);
        loadList(1);
    }, [activeTab]);

    const loadRecommendList = async (currentPage: number) => {
        try {
            const data = await getRecommendFollowingList(currentPage, LIMIT);
            if (data?.list) {
                setRecommendList(prev => currentPage === 1 ? data.list : [...prev, ...data.list]);
                setHasMore(data.list.length === LIMIT);
            }
        } catch (err: any) {
            console.error(err);
            setErrorMessageForRecommendList(err.message || "Unknown error occurred while loading list");
        } finally {
            setIsOnLoadingRecommendList(false);
        }
    };


    useEffect(() => {
        loadRecommendList(1);
    }, []);

    const handleFollow = async (userStudentId: number) => {
        console.log("handleFollow", userStudentId);
        if (!userStudentId) return;
        if (isOnNetworking) return;
        setIsOnNetworking(true);
        const data = await addFollow(userStudentId);
        if (data) {
            setList(list.map(item => item.userStudentInfo?.id === userStudentId ? { ...item, isFollowing: true } : item));
        }
        setIsOnNetworking(false);
    };

    const handleUnfollow = async (userStudentId: number) => {
        console.log("handleUnfollow", userStudentId);
        if (!userStudentId) return;
        if (isOnNetworking) return;
        setIsOnNetworking(true);
        const data = await cancelFollow(userStudentId);
        if (data) {
            setList(list.map(item => item.userStudentInfo?.id === userStudentId ? { ...item, isFollowing: false } : item));
        }
        setIsOnNetworking(false);
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        if (scrollHeight - scrollTop === clientHeight && !isOnLoadMore && hasMore) {
            setPage(prev => prev + 1);
            loadList(page + 1);
        }
    };

    const handleShowStudentInfoPopup = (studentId: number) => {
        console.log("handleShowStudentInfoPopup", studentId);
        showPopup(
            <StudentInfoPopupContainer
                studentId={studentId}
            />
        );
    };

    return (
        <FollowComponent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            list={list}
            isInit={isInit}
            onFollow={handleFollow}
            onUnfollow={handleUnfollow}
            onScroll={handleScroll}
            isOnLoadMore={isOnLoadMore}
            hasMore={hasMore}
            onShowStudentInfoPopup={handleShowStudentInfoPopup}
            errorMessage={errorMessage}

            recommendList={recommendList}
            isOnLoadingRecommendList={isOnLoadingRecommendList}
            errorMessageForRecommendList={errorMessageForRecommendList}
        />
    );
};

export default FollowContainer;