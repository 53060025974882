import React from "react";
import { getImageUrl } from "helpers/Image.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import LoadingComponent from "commons/loading/Loading.component";
import { Link } from "react-router-dom";
import PushCheckContainer from "pages/student/v2/my/PushCheck.container";

const IndexComponent = (props: any) => {
    const { isInit, info, onLogout, isOnUpdateBusinessCard, onUpdateBusinessCard, isApp } = props;

    return (
        <main className="bg-gray-50 min-h-screen py-6">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="fixed top-0 left-0 w-full bg-gray-50 z-10">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex items-center gap-4 py-4">
                        <button onClick={() => window.history.back()} className="text-gray-600 hover:text-gray-900">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                            </svg>
                        </button>
                        <h1 className="text-2xl font-bold text-gray-900">
                            마이페이지
                        </h1>
                    </div>
                </div>
                <div className="pt-16"></div>

                {isInit ? (
                    <>
                        {/* 계정 정보 섹션 */}
                        <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                            <h2 className="text-lg font-semibold mb-4">계정 정보</h2>
                            <div className="space-y-4">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <p className="text-gray-500 text-sm">이메일</p>
                                        <p className="font-medium">{info?.username}</p>
                                    </div>
                                </div>

                                <div>
                                    <p className="text-gray-500 text-sm">이름</p>
                                    <p className="font-medium">{info?.userAgencyInfo?.name}</p>
                                </div>

                                {/* 비밀번호 변경 */}
                                <div className="flex gap-4">
                                    <Link
                                        to="/agency/my/password"
                                        className="w-full py-2 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors text-sm text-center"
                                    >
                                        비밀번호 변경
                                    </Link>
                                </div>
                            </div>
                        </section>

                        {/* 연락처 정보 */}
                        <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                            <h2 className="text-lg font-semibold mb-4">연락처 정보</h2>
                            <div className="space-y-6">
                                <div>
                                    <p className="text-gray-500 text-sm">휴대전화</p>
                                    <p className="font-medium">{info?.userAgencyInfo?.cellphone}</p>
                                </div>

                                <div className="flex gap-4">
                                    <Link
                                        to="/agency/my/cellphone"
                                        className="w-full py-2 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors text-sm text-center"
                                    >
                                        휴대전화 정보 수정
                                    </Link>
                                </div>
                            </div>
                        </section>

                        {/* 필수 정보 섹션 */}
                        <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                            <h2 className="text-lg font-semibold mb-4">명함</h2>
                            <div className="space-y-6">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <div>
                                        <div className="flex justify-between items-center mb-2">
                                            <p className="text-gray-500 text-sm">명함 앞면</p>
                                            <div>
                                                <input
                                                    disabled={isOnUpdateBusinessCard}
                                                    id="frontCardInput"
                                                    className="hidden"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={evt => {
                                                        const file = evt.target.files?.[0];
                                                        if (file) {
                                                            onUpdateBusinessCard("front", file);
                                                        }
                                                    }}
                                                />
                                                <button
                                                    onClick={() => document.getElementById('frontCardInput').click()}
                                                    className="px-3 py-1 text-sm text-[#CF3688] hover:bg-[#CF3688] hover:text-white border border-[#CF3688] rounded-lg transition-colors"
                                                >
                                                    변경
                                                </button>
                                            </div>
                                        </div>
                                        <div className="aspect-[1.6/1] bg-gray-100 rounded-lg overflow-hidden">
                                            <img
                                                src={getImageUrl(info?.userAgencyInfo?.businessCardFrontImageInfo)}
                                                alt="명함 앞면"
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex justify-between items-center mb-2">
                                            <p className="text-gray-500 text-sm">명함 뒷면</p>
                                            <div>
                                                <input
                                                    disabled={isOnUpdateBusinessCard}
                                                    id="backCardInput"
                                                    className="hidden"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={evt => {
                                                        const file = evt.target.files?.[0];
                                                        if (file) {
                                                            onUpdateBusinessCard("back", file);
                                                        }
                                                    }}
                                                />
                                                <button
                                                    onClick={() => document.getElementById('backCardInput').click()}
                                                    className="px-3 py-1 text-sm text-[#CF3688] hover:bg-[#CF3688] hover:text-white border border-[#CF3688] rounded-lg transition-colors"
                                                >
                                                    변경
                                                </button>
                                            </div>
                                        </div>
                                        <div className="aspect-[1.6/1] bg-gray-100 rounded-lg overflow-hidden">
                                            <img
                                                src={getImageUrl(info?.userAgencyInfo?.businessCardBackImageInfo)}
                                                alt="명함 뒷면"
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* 푸시 관련 */}
                        {isApp && (
                            <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                                <h2 className="text-lg font-semibold mb-4">
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionAlarmTitle")}
                                </h2>
                                <div className="space-y-4">
                                    <PushCheckContainer />
                                </div>
                            </section>
                        )}

                        {/* 약관 및 정책 섹션 */}
                        {/* <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                            <div className="space-y-4">
                                <a href="/terms/casting-manager" target="_blank" rel="noopener noreferrer"
                                    className="block text-gray-700 hover:text-gray-900">
                                    캐스팅 매니저 약관
                                    <span className="float-right">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10 2H14V6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.66667 9.33333L14 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </a>
                                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"
                                    className="block text-gray-700 hover:text-gray-900">
                                    개인정보 처리방침
                                    <span className="float-right">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10 2H14V6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.66667 9.33333L14 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </a>
                                <a href="/notice" target="_blank" rel="noopener noreferrer"
                                    className="block text-gray-700 hover:text-gray-900">
                                    공지사항
                                    <span className="float-right">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10 2H14V6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.66667 9.33333L14 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </section> */}

                        {/* 계정 관리 섹션 */}
                        <section className="space-y-4">
                            <Link
                                to="/agency/my/withdraw"
                                className="block w-full py-3 px-4 border border-[#CF3688] text-[#CF3688] hover:bg-[#CF3688] hover:text-white rounded-lg transition-colors text-center"
                            >
                                회원 탈퇴
                            </Link>
                            <Link
                                to="/logout"
                                className="block w-full py-3 px-4 border bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors text-center"
                            >
                                로그아웃
                            </Link>
                        </section>
                    </>
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </main>
    );
};

export default IndexComponent;