import * as BaseApi from "api/Base.api";

const BASE_URL = "/agency/v2/search";

//* 검색 페이지 홈 화면 정보 요청
//  희문
//  2025. 03. 05
export const getSearchHome = async () => {
    const url = `${BASE_URL}/home`;
    return await BaseApi.get(url);
};


//* 검색 결과 조회
//  희문
//  2025. 03. 05
//* Params
//   keyword: 검색어
//   page: 페이지 번호
//   size: 페이지 크기
//   filter: 필터
interface getSearchResultFilterParams {
    countryCodes?: string;
    genderTypeCode?: string;
    minAge?: number;
    maxAge?: number;
    minHeight?: number;
    maxHeight?: number;
    minWeight?: number;
    maxWeight?: number;
}
export const getSearchResult = async (keyword: string, page: number, limit: number, filter: getSearchResultFilterParams) => {
    console.log("api/agency/v2/Search.api::getSearchResult");
    console.log({ keyword, page, limit, filter });
    const url = BASE_URL;
    return await BaseApi.get(url, { keyword, page, limit, ...filter });
};

//* 추천 지망생 조회
//  희문
//  2025. 03. 05
export const getRecommendList = async () => {
    const url = `${BASE_URL}/recommend`;
    return await BaseApi.get(url);
};
