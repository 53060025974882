import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ThirdPartyInfoComponent = (props: any) => {
    useEffect(() => {}, []);

    const navigate = useNavigate();

    const goSignupPage = () => {
        navigate("/signup/third-party");
    };

    return (
        <main className="v2-landing-main v2-landing-main-third-party">
            <div className="con">
                <section className="v2-landing-header-section v2-landing-header-section-third-party">
                    <div className="con">
                        <div className="title">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtTitle"),
                                }}></p>
                        </div>
                        <div className="body">
                            <div className="third-party-header-img-list">
                                <ul>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-1.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-2.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-3.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-4.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-5.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-6.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-7.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-8.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-9.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-10.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-11.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-12.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-13.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-14.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-15.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-16.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-17.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-18.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-19.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-20.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-21.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-22.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-23.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-24.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-25.png" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img alt="wannabe img" src="./imgs/landing/third-party-section-header-img-26.png" />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 프로필 등록 --> */}
                <section className="v2-landing-section-third-party-1">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSection101")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSection102"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="img">
                                                <img alt="search" src="./imgs/landing/third-party-section-img-1.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 강의 등록 --> */}
                <section className="v2-landing-section-third-party-2">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSection201")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSection202"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="img">
                                                <img alt="public profile" src="./imgs/landing/third-party-section-img-2.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 강의 제안 --> */}
                <section className="v2-landing-section-third-party-3">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSection301")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSection302"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="img">
                                                <img alt="profile for casting manager" src="./imgs/landing/third-party-section-img-3.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 즐겨찾기 섹션 --> */}
                <section className="v2-landing-section-third-party-4">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSection401")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSection402"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="img">
                                                <img alt="favorite" src="./imgs/landing/third-party-section-img-4.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoThirdParty.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="v2-landing-section-faq">
                    <div className="con">
                        <div className="body">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQnATitle")}</p>
                            </div>
                            <div className="faq-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="question">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQuestion1")}</p>
                                            </div>
                                            <div className="answer">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtAnswer1")}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <div className="question">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQuestion2")}</p>
                                            </div>
                                            <div className="answer">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtAnswer2")}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>
        </main>
    );
};

export default ThirdPartyInfoComponent;
