import cookie from "react-cookies";

const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;
const COOKIE_INSTANCE_PREFIX = process.env.REACT_APP_COOKIE_INSTANCE_PREFIX;

export const setCookie = (key: string, value: string, expires: Date) => {
  console.log("helpers/Cookie.helper.tsx::setCookie", key, value, expires);
  if (!expires) {
    expires = new Date();
    expires.setDate(expires.getDate() + 1);
  }

  const finalValue = process.env.REACT_APP_ENV === 'local'
    ? value
    : btoa(encodeURIComponent(value));

  cookie.save(key, finalValue, {
    expires,
    path: "/",
    domain: window.location.hostname
  });
};

export const getCookie = (key: string) => {
  const value = cookie.load(key);
  if (!value) return null;

  if (process.env.REACT_APP_ENV === 'local') {
    return value;
  }

  try {
    return decodeURIComponent(atob(value));
  } catch (e) {
    console.error('쿠키 복호화 실패:', e);
    return null;
  }
};

export const removeAllPrefixCookies = () => {
  const prefix = getCookiePrefix();
  const allCookies = document.cookie.split(';');

  allCookies.forEach(cookieString => {
    const [key] = cookieString.split('=').map(part => part.trim());
    if (key.startsWith(prefix)) {
      removeCookie(key);
    }
  });
};

export const removeAllInstancePrefixCookies = () => {
  const prefix = getCookieInstancePrefix();
  const allCookies = document.cookie.split(';');

  allCookies.forEach(cookieString => {
    const [key] = cookieString.split('=').map(part => part.trim());
    if (key.startsWith(prefix)) {
      removeCookie(key);
    }
  });
};

export const removeCookie = (key: string) => {
  return cookie.remove(key, { path: "/" });
};

export const getCookiePrefix = () => {
  return COOKIE_PREFIX;
};

export const getCookieInstancePrefix = () => {
  return COOKIE_INSTANCE_PREFIX;
};

export const getLastLoginEmailKey = () => {
  return `${getCookiePrefix()}-last-login-email`;
};
export const getLastLoginEmail = () => {
  return getCookie(getLastLoginEmailKey());
};
export const setLastLoginEmail = (email: string) => {
  setCookie(getLastLoginEmailKey(), email, new Date(Date.now() + 365 * 24 * 60 * 60 * 1000));
};


export const getJwtKey = () => {
  return `${getCookieInstancePrefix()}-jwt`;
};
export const getJwt = () => {
  return getCookie(getJwtKey());
};

export const getJwtRefreshKey = () => {
  return `${getCookieInstancePrefix()}-jwt-refresh`;
};
export const getJwtRefresh = () => {
  return getCookie(getJwtRefreshKey());
};

export const getAgencyIdKey = () => {
  return `${getCookieInstancePrefix()}-agency-id`;
};
export const getAgencyId = () => {
  return getCookie(getAgencyIdKey());
};

export const getInstallAppBannerKey = () => {
  return `${getCookieInstancePrefix()}-install-app-banner`;
};
export const getInstallAppBanner = () => {
  return getCookie(getInstallAppBannerKey());
};

export const getPopupKey = (id: string) => {
  return `${getCookieInstancePrefix()}-popup-${id}`;
};
export const getPopup = (id: string) => {
  return getCookie(getPopupKey(id));
};

export const getCookieSettingKey = () => {
  return `${getCookiePrefix()}-cookie-setting`;
};
export const getCookieSetting = () => {
  const value = getCookie(getCookieSettingKey());
  console.log("helpers/Cookie.helper.tsx::getCookieSetting", value);
  if (!value) return null;
  return value;
};
export const setCookieSetting = (analytics: boolean) => {
  const obj = {
    analytics
  }
  setCookie(getCookieSettingKey(), JSON.stringify(obj), new Date(Date.now() + 365 * 24 * 60 * 60 * 1000));
}


export const setBrowserId = () => {
  let browserId = getCookie(getBrowserIdKey());
  if (!browserId) {
    browserId = crypto.randomUUID();
    setCookie(getBrowserIdKey(), browserId, new Date(Date.now() + 365 * 24 * 60 * 60 * 1000));
  }
  console.log("browserId", browserId);
}

export const getBrowserIdKey = () => {
  return `${getCookiePrefix()}-browser-id`;
};
