import React, { useEffect, useState } from "react";
import StudentInfoPopupComponent from "./StudentInfoPopup.component";
import { getPopupStudentInfo } from "api/agency/v2/Popup.api";
import { useNavigate } from "react-router-dom";
import { addFavorite, deleteFavorite } from "api/agency/v2/Favorite.api";
const StudentInfoPopupContainer = (props: any) => {
    const navigate = useNavigate();

    const { userStudentId, onClose } = props;
    const [isInit, setIsInit] = useState<boolean>(false);
    const [isOnNetworking, setIsOnNetworking] = useState<boolean>(false);
    const [info, setInfo] = useState<any>(null);

    useEffect(() => {
        const fetchInfo = async () => {
            const data = await getPopupStudentInfo(userStudentId);
            if (data?.info) {
                setInfo(data.info);
            }
            setIsInit(true);
        };
        fetchInfo();
    }, [userStudentId]);

    const handleDetailInfo = () => {
        navigate(`/agency/student/${userStudentId}`);
        onClose();
    };

    const handleFavoriteToggle = async () => {
        if (isOnNetworking) return;
        setIsOnNetworking(true);
        console.log("pages/agency/v2/popup/StudentInfoPopup.container:handleFavoriteToggle");
        console.log({ isFavorite: info.isFavorite });
        if (info.isFavorite) {
            await deleteFavorite(userStudentId);
            setInfo({ ...info, isFavorite: false });
        } else {
            await addFavorite(userStudentId);
            setInfo({ ...info, isFavorite: true });
        }
        setIsOnNetworking(false);
    };

    return (
        <StudentInfoPopupComponent
            info={info}
            isInit={isInit}
            onClose={onClose}
            onDetailInfo={handleDetailInfo}
            onFavoriteToggle={handleFavoriteToggle}
            isOnNetworking={isOnNetworking}
        />
    );
};

export default StudentInfoPopupContainer;