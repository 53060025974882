import { useEffect, useRef, useState } from "react";
import ProfileForAgencyComponent from "./ProfileForAgency.component";
import { fetchStudentProfileForAgency, increaseStudentIntroVideoViewCount, increaseStudentProfilePhotoViewCount } from "api/agency/v2/Student.api";

import { getImagePresignedUrl, getVideoPresignedUrl } from 'api/common/Data.api';
import { useSelector } from "react-redux";
import { usePopup } from "contexts/PopupContext";
import ImageWithWatermarkForPresignedUrlPopupContainer from "pages/agency/v2/popup/ImageWithWatermarkForPresignedUrlPopup.container";

const ProfileForAgencyContainer = (props: any) => {
    const { info: myInfo } = useSelector((state: any) => state.user);
    const { showPopup } = usePopup();
    const { userStudentId } = props;
    const [isInit, setIsInit] = useState(false);
    const [info, setInfo] = useState<any>(null);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const videoRef = useRef<HTMLVideoElement>(null);


    const fetchInfo = async () => {
        try {
            const data = await fetchStudentProfileForAgency(userStudentId);
            if (data?.info) {
                let newInfo = { ...data.info };
                if (newInfo.isProfileActive) {
                    if (newInfo.profileIntroVideoInfo) {
                        const videoResult = await getVideoPresignedUrl(newInfo.profileIntroVideoInfo.videoId);
                        if (videoResult?.result) {
                            setVideoUrl(videoResult.result);
                        }
                    }
                }
                setInfo(newInfo);
            }
        } catch (error: any) {
            console.error("pages/agency/v2/student/ProfileForAgency.container::fetchInfo error", error);
            setErrorMessage(error.message || "프로필 정보를 불러오는데 실패했습니다.");
        } finally {
            setIsInit(true);
        }
    };

    useEffect(() => {
        fetchInfo();
    }, []);

    // const fetchVideoUrl = async () => {
    //     if (info?.profileIntroVideoInfo) {
    //         console.log(info.profileIntroVideoInfo.videoId);
    //         const data = await getVideoPresignedUrl(info.profileIntroVideoInfo.videoId);
    //         if (data?.result) {
    //             setVideoUrl(data.result);
    //         }
    //     }
    // };

    const fetchImageUrl = async () => {

    };

    useEffect(() => {
        // fetchVideoUrl();
    }, [info]);

    const handleVideoPlay = () => {
        console.log("비디오 재생 시작");
        // TODO: 비디오 재생 시작시 필요한 로직 추가
        increaseStudentIntroVideoViewCount(userStudentId, info?.profileIntroVideoInfo?.id);
    };

    const handleVideoError = async () => {
        console.log("pages/agency/v2/student/ProfileForAgency.container::handleVideoError");
        if (!videoRef.current) return;

        console.log({ videoId: info?.profileIntroVideoInfo?.videoId });
        if (!info?.profileIntroVideoInfo?.videoId) {
            return;
        }

        console.log("Video playback error, attempting to refresh URL");
        const currentTime = videoRef.current.currentTime;
        videoRef.current.pause();

        try {
            console.log('try');
            const data = await getVideoPresignedUrl(info?.profileIntroVideoInfo?.videoId);
            if (data?.result) {
                setVideoUrl(data.result);
            }

            // URL이 새로 설정된 후 이전 재생 시점부터 재생
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.currentTime = currentTime;
                    videoRef.current.play().catch(console.error);
                }
            }, 100);
        } catch (error) {
            console.error("Failed to refresh video URL", error);
        }
    };

    const handleClickImage = async (profileImageId: number, imageId: string) => {
        console.log("pages/agency/v2/student/ProfileForAgency.container::handleClickImage");

        showPopup(
            <ImageWithWatermarkForPresignedUrlPopupContainer
                imageId={imageId}
                watermarkText={myInfo?.agencyName + " " + myInfo?.username}
            />
        );

        await increaseStudentProfilePhotoViewCount(userStudentId, profileImageId);
    };

    return <ProfileForAgencyComponent
        isInit={isInit}
        info={info}
        videoRef={videoRef}
        videoUrl={videoUrl}
        onVideoError={handleVideoError}
        onVideoPlay={handleVideoPlay}
        myInfo={myInfo}
        onClickImage={handleClickImage}
        errorMessage={errorMessage}
    />;
};

export default ProfileForAgencyContainer;