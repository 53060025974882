import React, { useEffect } from "react";
import LayoutCompact from "./LayoutCompact.component";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LayoutCompactContainer = ({ ...props }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { backUrl } = props;

    useEffect(() => {
        console.log(location);
    }, [location]);

    return <LayoutCompact {...props} backUrl={backUrl} />;
};

export default LayoutCompactContainer;