import * as BaseApi from "api/Base.api";

const BASE_URL = "/student/v2/popup";

//* 대화 요청 상세 조회
//  2025-03-09 추가
//  희문
//* Params
//  messageRequestId : 대화 요청 아이디
export interface IFetchRequestDetailReturns {
    info: {
        id: number;
        messageStatusInfo: {
            code: string;
            names: {
                ko: string;
                en: string;
                ja: string;
                vi: string;
                id: string;
                th: string;
                zh: string;
            };
            sequence: number;
        };
        userAgencyId: number;
        userStudentId: number;
        userAgencyInfo: {
            id: number;
            agencyInfo: {
                id: number;
                nameKo: string;
                nameEn: string;
                logoImageInfo: {
                    subUrl: string;
                    width: number;
                    height: number;
                };
            };
        };
    };
}
export const fetchRequestDetail = async (messageRequestId: string): Promise<IFetchRequestDetailReturns> => {
    const url = `${BASE_URL}/chat/request/${messageRequestId}`;
    return await BaseApi.get(url);
};

//* 캐스팅 매니저의 기획사 정보 조회
//  2025-03-13 추가
//  희문
//* Params
//  channelId : 채널 아이디
export interface IFetchAgencyInfoByCastingManagerReturns {
    info: {
        id: number;
        nameKo: string;
        nameEn: string;
        logoImageInfo: {
            subUrl: string;
            width: number;
            height: number;
        };
    };
}
export const fetchAgencyInfoByCastingManager = async (userAgencyId: string): Promise<IFetchAgencyInfoByCastingManagerReturns> => {
    const url = `${BASE_URL}/casting-manager/${userAgencyId}/agency`;
    return await BaseApi.get(url);
};

//* 기획사 정보 조회
//  2025-03-24 추가
//  희문
//* Params
//  agencyId : 기획사 아이디
export interface IFetchAgencyInfoReturns {
    info: {
        id: number;
        nameKo: string;
        nameEn: string;
        logoImageInfo: {
            subUrl: string;
            width: number;
            height: number;
        };
    };
}
export const fetchAgencyInfo = async (agencyId: string): Promise<IFetchAgencyInfoReturns> => {
    const url = `${BASE_URL}/agency/${agencyId}`;
    return await BaseApi.get(url);
};

//* 팔로우 목록 조회
//  2025-03-26 추가
//  희문
export interface IFetchFollowingListReturns {
    list: Array<{
        id: number;
        createdAt: string;
        isFollowing: boolean;
        isFollower: boolean;
        userStudentInfo: {
            id: number;
            slug: string;
            nickname: string;
            profileImageInfo: {
                width: number;
                height: number;
                subUrl: string;
            };
            countryInfo: {
                code: string;
                names: {
                    ko: string;
                    en: string;
                    ja: string;
                    id: string;
                    th: string;
                    vi: string;
                    zh: string;
                };
                isUsing: number;
            };
        };
    }>;
}
export const fetchFollowingList = async (): Promise<IFetchFollowingListReturns> => {
    const url = `${BASE_URL}/follow/following`;
    return await BaseApi.get(url);
};

//* 팔로워 목록 조회
//  2025-03-26 추가
//  희문
export interface IFetchFollowerListReturns {
    list: Array<{
        id: number;
        createdAt: string;
        isFollowing: boolean;
        isFollower: boolean;
        userStudentInfo: {
            id: number;
            slug: string;
            nickname: string;
            profileImageInfo: {
                width: number;
                height: number;
                subUrl: string;
            };
            countryInfo: {
                code: string;
                names: {
                    ko: string;
                    en: string;
                    ja: string;
                    id: string;
                    th: string;
                    vi: string;
                    zh: string;
                };
                isUsing: number;
            };
        };
    }>;
}
export const fetchFollowerList = async (): Promise<IFetchFollowerListReturns> => {
    const url = `${BASE_URL}/follow/follower`;
    return await BaseApi.get(url);
};
