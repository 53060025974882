import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { Link } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";
import VideoPlayer from "commons/video/VideoPlayer.container";

const AgencyProfileIntroVideoComponent = (props: any) => {
    const {
        list,
        isLoading,
        isOnNetworking,
        hasMore,
        loadMore,
        onDelete
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <div className="flex items-center mb-6">
                    <Link
                        to="/student/profile/agency"
                        className="inline-flex items-center text-gray-600 hover:text-gray-900"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </Link>
                </div>
                <h2 className="text-3xl font-bold text-gray-900 mb-8">
                    {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfileIntroVideo.txtTitle")}
                </h2>
                <p className="text-gray-600 mb-8">{getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfileIntroVideo.txtDesc")}</p>

                {list.length > 0 &&
                    <div className="max-w-3xl mx-auto space-y-6">
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6 sm:p-8">
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
                                    {list.map((item: any, index: number) => (
                                        <div key={index} className="space-y-4">
                                            <div className="relative aspect-video rounded-xl">
                                                <VideoPlayer
                                                    videoInfo={item?.videoInfo}
                                                />
                                                <div className="absolute top-2 right-2 flex items-center space-x-2 bg-black/50 rounded-lg px-2 py-1">
                                                    <div className="flex items-center space-x-1 text-white">
                                                        <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                            <circle cx="12" cy="12" r="3"></circle>
                                                        </svg>
                                                        <span>{item.viewCount || 0}</span>
                                                    </div>
                                                </div>
                                                <div className="relative w-full text-right py-2">
                                                    <button
                                                        className="space-x-1 bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded-lg"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onDelete(item.id);
                                                        }}
                                                        disabled={isOnNetworking}>
                                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                }
                {
                    list.length == 0 && !isLoading &&
                    <div className="max-w-3xl mx-auto space-y-6">
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6 sm:p-8">
                                <div className="text-center text-gray-500">{getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfileIntroVideo.txtEmpty")}</div>
                            </div>
                        </section>
                    </div>
                }
                {isLoading && <LoadingComponent />}
                {hasMore && !isLoading && !isOnNetworking &&
                    <div className="flex justify-center mt-4">
                        <button className="bg-[#CF3688] hover:bg-[#CF3688]/80 text-white px-4 py-2 rounded-lg" onClick={loadMore}>
                            {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtMore")}
                        </button>
                    </div>
                }
            </div>
        </main>
    )
}

export default AgencyProfileIntroVideoComponent;