import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CastingManagerInfoComponent = (props: any) => {
    useEffect(() => {}, []);

    const navigate = useNavigate();

    const goSignupPage = () => {
        navigate("/signup/agency");
    };

    return (
        <main className="v2-landing-main v2-landing-main-casting-manager">
            <div className="con">
                <section className="v2-landing-header-section v2-landing-header-section-casting-manager">
                    <div className="con">
                        <div className="title">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtTitle"),
                                }}></p>
                        </div>
                        <div className="body">
                            <div className="header-img">
                                <img alt="casting manager" src="./imgs/landing/casting-manager-header-img.png" />
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 지망생 검색 섹션 --> */}
                <section className="v2-landing-section-casting-manager-1">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSection101")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSection102"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="img">
                                                <img alt="search" src="./imgs/landing/casting-manager-section-img-1.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 공개 프로필 프로필 섹션 --> */}
                <section className="v2-landing-section-casting-manager-2">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSection201")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSection202"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="img">
                                                <img alt="public profile" src="./imgs/landing/casting-manager-section-img-2.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 캐스팅 매니저 전용 프로필 섹션 --> */}
                <section className="v2-landing-section-casting-manager-3">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSection301")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSection302"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item item-1">
                                            <div className="img">
                                                <img alt="profile for casting manager" src="./imgs/landing/casting-manager-section-img-3.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 즐겨찾기 섹션 --> */}
                <section className="v2-landing-section-casting-manager-4">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSection401")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSection402"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="img">
                                                <img alt="favorite" src="./imgs/landing/casting-manager-section-img-4.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 캐스팅 채팅 --> */}
                <section className="v2-landing-section-casting-manager-5">
                    <div className="con">
                        <div className="header">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSection501")}</p>
                            </div>
                            <div className="desc">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSection502"),
                                    }}></p>
                            </div>
                        </div>
                        <div className="body">
                            <div className="img-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="img">
                                                <img alt="Profile completeness" src="./imgs/landing/casting-manager-section-img-5.png" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="control">
                                <button className="btn-signup" onClick={goSignupPage}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "infoCastingManager.txtSingup")}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="v2-landing-section-faq">
                    <div className="con">
                        <div className="body">
                            <div className="title">
                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQnATitle")}</p>
                            </div>
                            <div className="faq-list">
                                <ul>
                                    <li>
                                        <div className="item">
                                            <div className="question">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQuestion1")}</p>
                                            </div>
                                            <div className="answer">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtAnswer1")}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <div className="question">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtQuestion2")}</p>
                                            </div>
                                            <div className="answer">
                                                <p>{getLanguageValue(PAGE_TYPE.COMMON, "homeV2.txtAnswer2")}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>
        </main>
    );
};

export default CastingManagerInfoComponent;
