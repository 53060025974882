import React from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { Link } from "react-router-dom";
import { formattedDateTimeForLocale, formatTimeAgo } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getImageUrl } from "helpers/Image.helper";
import FullPageLoadingComponent from "commons/loading/FullPageLoading.component";
const HomeComponent = (props: any) => {
    const { isOnNetworking, requestList, roomList, onRequestClick } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6">
                {/* 대화 요청 목록 */}
                <div className="mb-8">
                    <h2 className="text-xl font-bold text-gray-900 mb-4">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "message.txtRequestTitle")}
                    </h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6">
                        {isOnNetworking && <LoadingComponent />}
                        {!isOnNetworking &&
                            <div className="overflow-x-auto lg:overflow-visible">
                                <div className="flex gap-4 lg:flex-wrap">
                                    {requestList?.map((item: any) => (
                                        <div
                                            onClick={() => onRequestClick(item.id)}
                                            key={item.id}
                                            className="w-[100px] sm:w-[125px] lg:w-[150px] flex-shrink-0">
                                            <div className="flex flex-col items-center">
                                                <div className="rounded-sm overflow-hidden w-14 h-14 sm:w-16 sm:h-16 flex-shrink-0 border-2 border-gray-100">
                                                    <img
                                                        src={getImageUrl(item?.userAgencyInfo?.agencyInfo?.logoImageInfo)}
                                                        alt={item?.userAgencyInfo?.agencyInfo?.nameEn}
                                                        className="w-full h-full object-contain"
                                                    />
                                                </div>
                                                <div className="mt-2 text-center w-full">
                                                    <p className="text-gray-900 text-xs truncate px-2">
                                                        {item?.userAgencyInfo?.agencyInfo?.nameEn}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {requestList?.length === 0 &&
                                        <div className="text-center py-4 text-gray-500">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "message.txtEmptyRequest")}
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {/* 대화방 목록 */}
                <div>
                    <h2 className="text-xl font-bold text-gray-900 mb-4">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "message.txtChatTitle")}
                    </h2>
                    {isOnNetworking && <LoadingComponent />}
                    {!isOnNetworking &&
                        (roomList?.length > 0 ? (
                            <div className="space-y-4">
                                {roomList.map((room: any, idx: number) => (
                                    <Link
                                        key={idx}
                                        to={`/student/casting/message/${room.channelId}/chat`}
                                        className="block bg-white p-4 rounded-xl shadow-sm hover:shadow-md transition-shadow">
                                        <div className="flex items-start gap-4 p-2 hover:bg-gray-50 transition-colors rounded-lg">
                                            {/* 왼쪽 프로필 이미지 */}
                                            <div className="w-14 h-14 flex-shrink-0 rounded-sm overflow-hidden">
                                                <img
                                                    src={getImageUrl(room?.userAgencyInfo?.agencyInfo?.logoImageInfo)}
                                                    alt={room?.userAgencyInfo?.agencyInfo?.nameEn}
                                                    className="w-full h-full  object-contain"
                                                />
                                            </div>

                                            {/* 가운데 내용 */}
                                            <div className="flex-1 min-w-0">
                                                <p className="text-sm font-medium text-gray-600 mb-1 truncate">
                                                    {room?.userAgencyInfo?.agencyInfo?.nameEn}
                                                </p>
                                                <h3 className="text-base text-gray-900 leading-snug truncate">
                                                    {room.lastMessage}
                                                </h3>
                                                <p className="text-xs text-gray-400 mt-1.5 flex items-center gap-1">
                                                    <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    {room.lastMessageAt ? formatTimeAgo(room.lastMessageAt) : ""}
                                                </p>
                                            </div>

                                            {/* 오른쪽 읽음여부 */}
                                            <div className="w-8 flex-shrink-0 flex items-start justify-end pt-1">
                                                {!room.isReadStudent && (
                                                    <span className="inline-flex items-center justify-center w-3 h-3 bg-[#CF3688] text-white text-xs rounded-full animate-pulse"></span>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        ) : (
                            <div className="text-center py-8 text-gray-500">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "message.txtEmptyChat")}
                            </div>
                        ))}
                </div>
            </div>
        </main>
    );
};

export default HomeComponent;