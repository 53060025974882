import React, { useEffect, useState } from "react";
import PublicProfileComponent from "./PublicProfile.component";
import * as PublicProfileApi from "api/student/v2/publicProfile.api";

const PublicProfileContainer = (props: any) => {
    const [isActiveOfPublicProfile, setIsActiveOfPublicProfile] = useState(false);
    const [isOnChangeActiveOfPublicProfile, setIsOnChangeActiveOfPublicProfile] = useState(false);
    const [studentInfo, setStudentInfo] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getInfo();
    }, []);

    const getInfo = async () => {
        const result = await PublicProfileApi.getHomeInfo();

        console.log('result', result);

        if (result?.info) {
            console.log('has info');
            const { info } = result;
            console.log('info', info);
            setStudentInfo(info);
            setIsActiveOfPublicProfile(info.isActiveOfPublicProfile);
            setIsLoading(false);
            console.log('studentInfo', studentInfo);
        }
    };

    const onChangeIsActiveOfPublicProfile = async (isActive: boolean) => {
        console.log("Student/Profile/Home.container::onChangeIsActive", isActive);
        if (isOnChangeActiveOfPublicProfile) {
            return;
        }

        setIsOnChangeActiveOfPublicProfile(true);

        await PublicProfileApi.updateIsActiveOfPublicProfile({ isActiveOfPublicProfile: isActive })
            .then((res) => {
                // data.isActive가 undefined일 수 있으므로 isActive 파라미터 값을 직접 사용
                console.log('res', res);
                setIsActiveOfPublicProfile(isActive);
            })
            .catch((err) => {
                console.log('err', err);
                // 에러 발생시 이전 상태로 되돌림
                setIsActiveOfPublicProfile(!isActive);
            })
            .finally(() => {
                setIsOnChangeActiveOfPublicProfile(false);
            });
    };

    return <PublicProfileComponent
        studentInfo={studentInfo}
        onChangeIsActiveOfPublicProfile={onChangeIsActiveOfPublicProfile}
        isActiveOfPublicProfile={isActiveOfPublicProfile}
        isLoading={isLoading} />;
};

export default PublicProfileContainer;
