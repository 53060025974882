import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import HeaderContainer from "./Header.container";
import MenuContainer from "./Menu.container";

const LayoutComponent = (props: any) => {
    const { needStandaloneMain } = props;

    return (
        <>
            {needStandaloneMain ? null : <HeaderContainer />}
            <Outlet />
            {needStandaloneMain ? null : <MenuContainer />}
        </>
    )
};

export default LayoutComponent;