import { useEffect } from "react";

const UsePreventZoomComponent = () => {
  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      const target = event.target as HTMLElement;
      if (target.tagName === "INPUT" || target.tagName === "TEXTAREA") {
        target.style.transform = "scale(1)"; // 확대 방지
        target.style.transition = "transform 0s"; // 애니메이션 방지
      }
    };

    document.addEventListener("focusin", handleFocus);
    return () => {
      document.removeEventListener("focusin", handleFocus);
    };
  }, []);
};

export default UsePreventZoomComponent;