import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import React from "react";
import { useNavigate } from "react-router-dom";

const CellphoneComponent = (props: any) => {
    const navigate = useNavigate();
    const {
        countryPhoneCode,
        setCountryPhoneCode,
        newCountryPhoneCode,
        setNewCountryPhoneCode,
        cellphone,
        setCellphone,
        newCellphone,
        setNewCellphone,
        verifyingNumber,
        setVerifyingNumber,
        verifyingId,
        countryPhoneCodeList,
        onSendVerifyNumber,
        onVerifyNumber,
        isOnSedingVerifyNumber,
        hasSentVerifyNumber,
        hasVerified,
        onSave
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen py-6">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center gap-4 mb-6">
                    <button onClick={() => navigate(-1)} className="text-gray-600 hover:text-gray-900">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <h1 className="text-2xl font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.txtTitle")}
                    </h1>
                </div>

                <div className="bg-white rounded-2xl p-6 shadow-sm">
                    <div className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.txtCurrentCellphone")}
                            </label>
                            <input
                                type="text"
                                disabled
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-500 text-base"
                                value={countryPhoneCode && cellphone ? `${countryPhoneCode} ${cellphone}` : "empty"}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.txtNewCellphone")}
                            </label>
                            <div className="space-y-2">
                                <div className="flex w-full gap-2 min-w-0">
                                    <select
                                        className="px-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base shrink-0"
                                        value={newCountryPhoneCode}
                                        onChange={(e) => setNewCountryPhoneCode(e.target.value)}>
                                        <option disabled value="">{getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.txtCountryPhoneCodeOption")}</option>
                                        {countryPhoneCodeList.map((item: any) => (
                                            <option key={item.phoneCode} value={item.phoneCode}>
                                                {item.phoneCode}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        disabled={hasVerified || verifyingId}
                                        type="text"
                                        className="flex-1 min-w-0 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base"
                                        placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.txtNewCellphonePlaceholder")}
                                        value={newCellphone}
                                        maxLength={14}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => setNewCellphone(e.target.value)}
                                    />
                                </div>
                                <button
                                    onClick={() => onSendVerifyNumber()}
                                    className="w-full px-4 py-2 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors"
                                    disabled={isOnSedingVerifyNumber || hasVerified || verifyingId}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.btnSendVerifyNumber")}
                                </button>
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.txtVerifyNumber")}
                            </label>
                            <div className="flex gap-2">
                                <input
                                    disabled={hasVerified}
                                    maxLength={6}
                                    type="text"
                                    className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base"
                                    placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.txtVerifyNumberPlaceholder")}
                                    value={verifyingNumber}
                                    onChange={(e) => setVerifyingNumber(e.target.value)}
                                />
                                <button
                                    disabled={isOnSedingVerifyNumber || hasVerified}
                                    onClick={() => onVerifyNumber()}
                                    className="px-4 py-2 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors">
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.btnVerify")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 space-y-4">
                    <button
                        disabled={!hasVerified}
                        onClick={() => onSave()}
                        className="w-full py-3 px-4 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors disabled:bg-gray-300"
                    >
                        {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.btnSave")}
                    </button>
                    <button
                        onClick={() => navigate(-1)}
                        className="w-full py-3 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium rounded-lg transition-colors"
                    >
                        {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeCellphone.btnCancel")}
                    </button>
                </div>
            </div>
        </main>
    );
};

export default CellphoneComponent;