import React from "react";
import { Link } from "react-router-dom";
import { getProfileImageUrl } from "helpers/Image.helper";
import { getFlagByCountryCode, getStudentName } from "helpers/String.helper";
import { getCurrentLangName } from "helpers/String.helper";

const StudentItem = ({ item, type = null, index }: { item: any, type: string | null, index: number }) => {
    return (
        <div
            key={index}
            className="flex-shrink-0 w-[160px] sm:w-[180px] md:w-auto 
            group bg-white rounded-full shadow-lg overflow-hidden 
            hover:shadow-xl transition-all duration-300 hover:-translate-y-2
            border border-gray-100">
            <div className="relative w-full pb-[100%] overflow-hidden bg-gray-50 rounded-full">
                <img
                    src={getProfileImageUrl(item.profileImage)}
                    alt={getStudentName(item)}
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-full
                    group-hover:scale-110 transition-transform duration-300"
                />
                {item.country && (
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex items-center justify-center">
                        <span className="text-4xl sm:text-4xl md:text-[3.6rem]">{getFlagByCountryCode(item.country.code)}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StudentItem;