import React from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";
import Pagination from "../../../commons/pagination/Pagination.container";
import { formatTimeAgo, getFormattedDateValue } from "helpers/Datetime.helper";

const NotificationComponent = (props: any) => {
  const {
    isLoading,
    notificationList,
    go,
    // pagination
    limit,
    page,
    setPage,
    totalCount,
    //
    onClickCheckNotification,
  } = props;

  return (
    <main className="bg-gray-50 min-h-screen">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
        {isLoading ? (
          <LoadingComponent />
        ) : notificationList && notificationList.length === 0 ? (
          <>
            <h2 className="text-3xl font-bold text-gray-900 mb-8">Notification</h2>
            <div className="flex justify-center items-center h-64">
              <p className="text-gray-500">No data</p>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-3xl font-bold text-gray-900 mb-8">Notification</h2>
            <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
              {/* PC 버전 */}
              <div className="hidden md:block">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "notification.txtContent")}
                      </th>
                      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 w-32">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "notification.txtReceivedAt")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {notificationList.map((notification: any) => (
                      <tr key={notification.id}>
                        <td className="px-4 py-3">
                          <p 
                            onClick={() => onClickCheckNotification(notification)}
                            className={`text-sm ${notification.isRead ? 'text-gray-900' : 'text-[#CF3688] font-medium'} ${(notification.action || notification.linkUrl) ? 'cursor-pointer hover:underline' : ''}`}
                          >
                            {notification.title}
                          </p>
                        </td>
                        <td className="px-4 py-3">
                          <p className="text-xs text-gray-500 whitespace-nowrap">
                            {getFormattedDateValue(notification.sendAt, "YYYY.MM.DD HH:mm")}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* 모바일 버전 */}
              <div className="md:hidden">
                <div className="divide-y divide-gray-200">
                  {notificationList.map((notification: any) => (
                    <div 
                      key={notification.id} 
                      className={`p-3 ${notification.isRead ? '' : 'bg-pink-50'}`}
                      onClick={() => onClickCheckNotification(notification)}
                    >
                      <p className={`text-sm ${notification.isRead ? 'text-gray-900' : 'text-[#CF3688] font-medium'} mb-1`}>
                        {notification.title}
                      </p>
                      <p className="text-xs text-gray-500">
                        {getFormattedDateValue(notification.sendAt, "YYYY.MM.DD HH:mm")}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="px-4 py-3 border-t border-gray-200">
                <Pagination
                  url="/student/notification"
                  totalCount={totalCount}
                  currentPage={page}
                  pageSize={limit}
                  pageGroupSize={10}
                  setPage={setPage}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default NotificationComponent;
