import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import * as ImageHelper from "helpers/Image.helper";
import * as StudentApi from "api/student/Student.api";
import * as DataApi from "api/common/Data.api";
import * as PublicProfileApi from "api/student/v2/publicProfile.api";

import PublicProfileEditComponent from "./PublicProfileEssentialInfoEdit.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const PublicProfileEssentialInfoEditContainer = (props: any) => {
    const navigate = useNavigate();

    const [studentInfo, setStudentInfo] = useState<any>({});

    const [imageInfo, setImageInfo] = useState<any>({});

    const [profileParams, setProfileParams] = useState<any>({});

    const [isOnNetworking, setIsOnNetworking] = useState(false);

    useEffect(() => {
        getInfo();
    }, []);

    const getInfo = async () => {
        const result = await PublicProfileApi.getEssentialInfo();

        if (result?.info) {
            const { info } = result;
            setStudentInfo(info);
        }
    };

    const onChangeInput = (componentName: string, value: any) => {
        console.log("UpdateMyContainer:onChangeInput", componentName, value);

        if (componentName === "countryCode") {
            setProfileParams({ ...profileParams, countryCode: value });
        } else if (componentName === "firstName") {
            setProfileParams({ ...profileParams, firstName: value });
        } else if (componentName === "lastName") {
            setProfileParams({ ...profileParams, lastName: value });
        } else if (componentName === "birthday") {
            setProfileParams({ ...profileParams, birthday: value });
        } else if (componentName === "genderTypeCode") {
            setProfileParams({ ...profileParams, genderTypeCode: value });
        } else if (componentName === "nickname") {
            setProfileParams({ ...profileParams, nickname: value });
        }
    };

    const isDisabled = async () => {
        if (!profileParams.countryCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errCountrySelection"));
            return true;
        } else if (!profileParams.firstName) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errFirstName"));
            return true;
        } else if (!profileParams.lastName) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errLastName"));
            return true;
        } else if (!profileParams.birthday) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errBirthdaySelection"));
            return true;
        } else if (!imageInfo?.id) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errProfileImageSelection"));
            return true;
        } else if (!profileParams.genderTypeCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errGenderSelection"));
            return true;
        } else {
            return false;
        }
    };

    const updateInfo = async () => {
        if (!(await isDisabled())) {
            setIsOnNetworking(true);
            profileParams.profileImageId = imageInfo?.id || null;

            await PublicProfileApi.updateEssentialInfo(profileParams)
                .then((res: any) => {
                    if (res) {
                        navigate("/student/profile/public");
                    }
                })
                .catch((err: any) => {
                    console.log("UpdateMyContainer:updateInfo", err);
                    alert("Error Occurred");
                })
                .finally(() => {
                    setIsOnNetworking(false);
                });
        }
    };

    const uploadImage = async (type: string, evt: any) => {
        setIsOnNetworking(true);

        if (!evt) {
            return false;
        }

        const file = evt.target.files[0];
        const imageInfo = await ImageHelper.getImageInfo(file);
        if (!imageInfo) {
            alert("Please choose image file");
            return false;
        }

        const result = await DataApi.uploadImage(file);

        if (type === "profile") {
            setImageInfo({ id: result.id, url: imageInfo.url });
        }

        setIsOnNetworking(false);
    };

    useEffect(() => {
        if (studentInfo?.id) {
            setImageInfo(studentInfo.profileImageInfo);

            setProfileParams({
                countryCode: studentInfo.countryCodeInfo.code,
                firstName: studentInfo.firstName,
                lastName: studentInfo.lastName,
                birthday: studentInfo.birthday,
                genderTypeCode: studentInfo.genderTypeCodeInfo.code,
                nickname: studentInfo.nickname,
            });
        }
    }, [studentInfo]);

    return (
        <PublicProfileEditComponent
            id={studentInfo?.id}
            profileParams={profileParams}
            imageInfo={imageInfo}
            setImageInfo={setImageInfo}
            isOnNetworking={isOnNetworking}
            uploadImage={uploadImage}
            onChangeInput={onChangeInput}
            onClickBtnUpdateInfo={updateInfo}
        />
    );
};

export default PublicProfileEssentialInfoEditContainer;
