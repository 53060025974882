import React, { useEffect, useState } from "react";
import RequestChatPopupComponent from "./RequestChatPopup.component";
import { fetchRequestDetail } from "api/agency/v2/Popup.api";
import { cancelChatRequest } from "api/agency/v2/Chat.api";
import { useNavigate } from "react-router-dom";

const RequestChatPopupContainer = (props: any) => {
    const { requestId, onClose, onCloseCallback } = props;
    const navigate = useNavigate();
    const [isInit, setIsInit] = useState(false);
    const [info, setInfo] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const init = async () => {
        try {
            const data = await fetchRequestDetail(requestId);
            if (data?.info) {
                setInfo(data.info);
            }
        } catch (err: any) {
            console.error(err);
            setErrorMessage(err.message || "대화 요청 정보를 불러오는데 실패했습니다.");
        } finally {
            setIsInit(true);
        }
    }

    useEffect(() => {
        init();
    }, [requestId]);

    const handleCancelRequest = async () => {
        console.log("handleCancelRequest");
        console.log(info)
        if (window.confirm("대화 요청을 취소하시겠습니까?")) {
            await cancelChatRequest(info?.id)
                .then((data: any) => {
                    if (data?.result) {
                        onClose();
                        onCloseCallback(true);
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                    alert("대화 요청 취소에 실패했습니다.");
                });
        }
    }

    const handleStartChat = () => {
        if (info?.channelId) {
            navigate(`/agency/message/${info?.channelId}/chat`);
            onClose();
        } else {
            alert("채널 아이디가 없습니다.");
        }
    }

    const handleDetailInfo = () => {
        navigate(`/agency/student/${info?.userStudentId}`);
        onClose();
    }

    return <RequestChatPopupComponent
        isInit={isInit}
        info={info}
        onClose={onClose}
        onCancelRequest={handleCancelRequest}
        onStartChat={handleStartChat}
        onDetailInfo={handleDetailInfo}
        errorMessage={errorMessage}
    />;
};

export default RequestChatPopupContainer;