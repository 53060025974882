import React from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import { getCommonCodeInfo } from "data/common.data";
import PushCheckCoponent from "commons/my/PushCheck.component";

const MyComponent = (props: any) => {
    const navigate = useNavigate();
    const {
        email,
        studentInfo,
        setTermId,
        marketingTermList,
        termList,
        setUpdateType,
        parentEmail,
        setParentEmail,
        isDisabled,
        isActive,
        updateMarketingTermList,
        updateActive,
        sendVerifyEmail,
        sendVerifyParentEmail,
        logout,
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <h2 className="text-3xl font-bold text-gray-900 mb-8">My Profile</h2>

                {studentInfo?.id ? (
                    <div className="space-y-6">
                        {/* 계정 정보 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6 border-b border-gray-100">
                                <h2 className="text-xl font-bold text-gray-900">Account Information</h2>
                            </div>
                            <div className="p-6">
                                <div className="mb-6">
                                    <h3 className="text-sm font-medium text-gray-500 mb-2">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtEmail")}
                                    </h3>
                                    <p className="text-base text-gray-900">{email}</p>
                                </div>
                                <div className="flex gap-4">
                                    <button
                                        onClick={() => navigate("/student/my/update-email")}
                                        className="px-4 py-2 text-sm font-medium text-white bg-[#CF3688] rounded-lg hover:bg-[#B82F77] transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnUpdateEmail")}
                                    </button>
                                    <button
                                        onClick={() => navigate("/student/my/update-password")}
                                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnUpdatePassword")}
                                    </button>
                                </div>
                            </div>
                        </section>

                        {/* 비상 연락처 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6 border-b border-gray-100">
                                <h2 className="text-xl font-bold text-gray-900">Emergency Contact</h2>
                            </div>
                            <div className="p-6">
                                {studentInfo.contactTypeCode && (
                                    <div className="mb-4">
                                        <h3 className="text-sm font-medium text-gray-500 mb-1">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtContactType")}
                                        </h3>
                                        <p className="text-base text-gray-900">
                                            {getCurrentLangName(getCommonCodeInfo(studentInfo.contactTypeCode)?.names)}
                                        </p>
                                    </div>
                                )}
                                {studentInfo.contact && (
                                    <div className="mb-6">
                                        <h3 className="text-sm font-medium text-gray-500 mb-1">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtContactDetail")}
                                        </h3>
                                        <p className="text-base text-gray-900">{studentInfo.contact}</p>
                                    </div>
                                )}
                                <button
                                    onClick={() => {
                                        navigate("/student/my/update-contact");
                                        setUpdateType("contact");
                                    }}
                                    className="w-full px-4 py-2 text-sm font-medium text-[#CF3688] bg-white border border-[#CF3688] rounded-lg hover:bg-[#CF3688] hover:text-white transition-colors">
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnContactProfile")}
                                </button>
                            </div>
                        </section>

                        {/* 약관 동의 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6 border-b border-gray-100">
                                <h2 className="text-xl font-bold text-gray-900">Terms & Conditions</h2>
                            </div>
                            <div className="p-6">
                                <div className="space-y-4">
                                    {marketingTermList?.map((item: any, idx: number) => (
                                        <label key={`term-${idx}`} className="flex items-start space-x-3 cursor-pointer">
                                            <input
                                                type="checkbox"
                                                className="mt-1 h-4 w-4 text-[#CF3688] rounded border-gray-300 focus:ring-[#CF3688]"
                                                onChange={(evt) => {
                                                    if (!item.disabled) {
                                                        setTermId(item.id);
                                                        updateMarketingTermList(idx, evt.target.checked);
                                                    }
                                                }}
                                                checked={item.hasAgreed}
                                                disabled={item.disabled}
                                            />
                                            <span className="text-sm text-gray-700">
                                                {`(${getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtOptional")}) ${item.title}`}
                                            </span>
                                        </label>
                                    ))}
                                </div>
                                <div className="mt-6 flex flex-wrap gap-4">
                                    {termList.map((item: any, idx: number) => (
                                        <button
                                            key={`term2-${idx}`}
                                            onClick={() => window.open(`/term/common/${item.id}`)}
                                            className="text-sm text-gray-600 hover:text-[#CF3688] transition-colors">
                                            {item.title}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </section>

                        {/* 기타 버튼들 */}
                        <div className="space-y-4">
                            <button
                                onClick={() => navigate("/student/notice")}
                                className="w-full px-4 py-3 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-xl hover:bg-gray-50 transition-colors">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnNotice")}
                            </button>

                            {!studentInfo.hasVerifiedEmail && (
                                <button
                                    onClick={() => sendVerifyEmail()}
                                    className="w-full px-4 py-3 text-sm font-medium text-[#CF3688] bg-white border border-[#CF3688] rounded-xl hover:bg-[#CF3688] hover:text-white transition-colors">
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnSendVerifiedEmail")}
                                </button>
                            )}

                            <PushCheckCoponent />

                            <button
                                onClick={() => navigate("/student/my/delete-account")}
                                className="w-full px-4 py-3 text-sm font-medium text-red-600 bg-white border border-red-200 rounded-xl hover:bg-red-50 transition-colors">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnDeleteAccount")}
                            </button>

                            <button
                                onClick={() => logout()}
                                className="w-full px-4 py-3 text-sm font-medium text-gray-700 bg-gray-100 rounded-xl hover:bg-gray-200 transition-colors">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnLogout")}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center items-center h-64">
                        <LoadingComponent />
                    </div>
                )}
            </div>
        </main>
    );
};
export default MyComponent;
