import React from "react";
import { getImageUrl, getProfileImageUrl } from "helpers/Image.helper";
import { formatTimeAgo } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

interface PostItemComponentProps {
    post: any;
    setOpenMenuId: (id: number) => void;
    openMenuId: number;
    onTranslateComment: (id: number) => void;
    translatedComments: any;
    onDeletePost: (id: number) => void;
    onToggleLike: (id: number) => void;
    setCurrentPost: (post: any) => void;
    isOnTranslating: boolean;
    onCommentPopup: () => void;
    onShowStudentInfoPopup: (studentId: number) => void;
    onShowLikePopup: () => void;
    onShowReportPopup: () => void;
    onShowImagePopup: (index: number) => void;
}

const PostItemComponent = (props: PostItemComponentProps) => {
    const {
        post,
        setOpenMenuId, openMenuId,
        onTranslateComment, translatedComments,
        onDeletePost, onToggleLike,
        setCurrentPost,
        isOnTranslating,
        onCommentPopup,
        onShowStudentInfoPopup,
        onShowLikePopup,
        onShowReportPopup,
        onShowImagePopup
    } = props;
    return (
        <>
            <div className="relative py-4">
                <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-3">
                        <div className="w-10 h-10 rounded-full bg-gray-200 overflow-hidden"
                            onClick={() => onShowStudentInfoPopup(post.userStudentInfo?.id)}
                        >
                            <img src={getProfileImageUrl(post.userStudentInfo?.profileImageInfo, 40, 40)} alt="" className="w-full h-full object-cover rounded-full" />
                        </div>
                        <div>
                            <p className="font-medium">{post.userStudentInfo?.nickname}</p>
                            <p className="text-sm text-gray-500">{formatTimeAgo(post.createdAt)}</p>
                        </div>
                    </div>

                    <div className="relative">
                        <button
                            onClick={() => setOpenMenuId(openMenuId === post.id ? null : post.id)}
                            className="p-1 hover:bg-gray-100 rounded-full transition-colors">
                            <svg className="w-6 h-6 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                            </svg>
                        </button>

                        {openMenuId === post.id && (
                            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-10 py-1">
                                {post.isMine ? (
                                    <>
                                        {/* <button
                                                                            onClick={() => {
                                                                                onEditPost(post.id);
                                                                                setOpenMenuId(null);
                                                                            }}
                                                                            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnEdit")}
                                                                        </button> */}
                                        <button
                                            onClick={() => {
                                                onDeletePost(post.id);
                                                setOpenMenuId(null);
                                            }}
                                            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnDelete")}
                                        </button>
                                        <div className="w-full h-[1px] bg-gray-200 my-1"></div>
                                        <button
                                            onClick={() => setOpenMenuId(null)}
                                            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnClose")}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            onClick={() => {
                                                // 신고 로직
                                                onShowReportPopup();
                                            }}
                                            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnReport")}
                                        </button>
                                        <div className="w-full h-[1px] bg-gray-200 my-1"></div>
                                        <button
                                            onClick={() => setOpenMenuId(null)}
                                            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnClose")}
                                        </button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {/* 포스트 내용 */}
                <div className="text-gray-900 mb-4 whitespace-pre-wrap break-words">
                    {post.text.split('\n').length > 5 ? (
                        <>
                            <p className={`${!post.isExpanded ? 'line-clamp-5' : ''}`}>
                                {post.text}
                            </p>
                            <div className="flex justify-end">
                                <button
                                    className="text-gray-600 text-xs mt-1 hover:text-gray-800 self-end"
                                    onClick={() => {
                                        setCurrentPost({ ...post, isExpanded: !post.isExpanded });
                                    }}
                                >
                                    {!post.isExpanded ? getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnExpand") : getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.btnCollapse")}
                                </button>
                            </div>
                        </>
                    ) : (
                        <p>{post.text}</p>
                    )}
                    {/* 번역 영역 */}
                    <div className="flex flex-col space-y-2">
                        {!translatedComments[post.id] && (
                            <button
                                disabled={isOnTranslating}
                                onClick={() => onTranslateComment(post.id)}
                                className={`text-sm ${isOnTranslating ? 'text-gray-400 cursor-not-allowed' : 'text-[#CF3688] hover:text-[#B32E74]'} transition-colors self-start`}>
                                {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtTranslate")}
                            </button>
                        )}

                        {translatedComments[post.id] && (
                            <div className="mt-2 p-3 bg-gray-100 border-l-4 rounded">
                                <p className="text-xs text-gray-600">{translatedComments[post.id]}</p>
                            </div>
                        )}
                    </div>
                </div>

                {/* 포스트 이미지 */}
                {post.imageList?.length > 0 && (
                    <div className="relative px-2 sm:px-4 md:px-6 pb-4">
                        <div className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide">
                            {post.imageList.map((image: any, index: number) => (
                                <div key={image.id} className="flex-none w-36 sm:w-40 md:w-48 mr-2 sm:mr-3 md:mr-4 snap-start">
                                    <div className="relative aspect-square bg-gray-100 rounded-xl overflow-hidden"
                                        onClick={evt => onShowImagePopup(index)}>
                                        <img src={getImageUrl(image.imageInfo)} alt="" className="w-full h-full object-cover" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* 포스트 메타 정보 영역 */}
                <div className="flex flex-col gap-3 border border-b border-black/50">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-6">
                            <div className="flex items-center space-x-2 text-gray-500">
                                <button
                                    className={`hover:text-[#CF3688] transition-colors ${post.isLiked ? 'text-[#CF3688]' : ''}`}
                                    onClick={() => onToggleLike(post.id)}>
                                    {post.isLiked ? (
                                        <svg className="w-5 h-5" fill="#CF3688" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                        </svg>
                                    ) : (
                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                        </svg>
                                    )}
                                </button>
                                <span className="text-sm"
                                    onClick={onShowLikePopup}>
                                    {post.likeCount}
                                </span>
                            </div>
                            <div className="flex items-center space-x-2 text-gray-500">
                                <button className="hover:text-[#CF3688] transition-colors"
                                    onClick={onCommentPopup}>
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                    </svg>
                                </button>
                                <span className="text-sm"
                                    onClick={onCommentPopup}>
                                    {post.commentCount}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PostItemComponent;