import React, { useEffect, useState } from "react";
import IndexComponent from "./Index.component";
import { getCastingSummary } from "api/student/v2/casting.api";

const IndexContainer = () => {

    interface ICastingSummary {
        totalFavoriteCount: number;

        totalChattingCount: number;
        waitingChattingCount: number;
        canceledChattingCount: number;
        deniedChattingCount: number;
        onChattingCount: number;
        closedChattingCount: number;

        totalViewCount: number;
        todayViewCount: number;
        weeklyViewCount: number;
        monthlyViewCount: number;        
    }

    //* 캐스팅 요약페이지 조회
    // const [castingSummary, setCastingSummary] = useState
    const [isLoading, setIsLoading] = useState(true);
    const [info, setInfo] = useState<ICastingSummary | null>(null);

    useEffect(() => {
        //* 캐스팅 요약페이지 조회
        const fetchCastingSummary = async () => {
            const data = await getCastingSummary();
            if (data?.info) {
                setInfo(data.info);
                setIsLoading(false);
            }
        }
        fetchCastingSummary();
    }, []);

    return <IndexComponent
        info={info}
        isLoading={isLoading}
    />;
};

export default IndexContainer;