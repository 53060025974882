import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import { getCommonCodeInfo } from "data/common.data";
import PushCheckContainer from "./PushCheck.container";

const MyComponent = (props: any) => {
    const navigate = useNavigate();
    const {
        isInit,
        isApp,
        email,
        info,
        sendVerifyEmail,
        sendVerifyParentEmail,
        logout,
        isOnSendingEmail,
        isOnToggle,
        onToggleActivateAccount,
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen py-6">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="fixed top-0 left-0 w-full bg-gray-50 z-10">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex items-center gap-4 py-4">
                        <button onClick={() => navigate('/student')} className="text-gray-600 hover:text-gray-900">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                            </svg>
                        </button>
                        <h1 className="text-2xl font-bold text-gray-900">
                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtTitle")}
                        </h1>
                    </div>
                </div>
                <div className="pt-16"></div>

                {!isInit ? (
                    <LoadingComponent />
                ) : (
                    <>
                        {info ? (
                            <>
                                {/* 계정 정보 섹션 */}
                                <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                                    <h2 className="text-lg font-semibold mb-4">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionAccountTitle")}
                                    </h2>
                                    <div className="space-y-4">
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <p className="text-gray-500 text-sm">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionAccountLabelEmail")}
                                                </p>
                                                <p className="font-medium">{email}</p>
                                            </div>
                                        </div>

                                        {/* 이메일 인증 */}
                                        {!info.hasVerifiedEmail && (
                                            <>
                                                <div className="text-amber-600 bg-amber-50 p-3 rounded-lg text-sm">
                                                    <p>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionAccountVerifyEmailDesc")}
                                                    </p>
                                                </div>
                                                <div className="flex gap-4">
                                                    <button
                                                        onClick={() => sendVerifyEmail()}
                                                        disabled={isOnSendingEmail}
                                                        className="w-full py-2 px-4 bg-[#CF3688] hover:bg-[#B82F77] text-white rounded-lg transition-colors text-sm text-center"
                                                    >
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionAccountVerifyEmailBtn")}
                                                    </button>
                                                </div>
                                            </>
                                        )}

                                        {/* 이메일/비밀번호 변경 */}
                                        <div className="flex gap-4">
                                            <button
                                                onClick={() => navigate("/student/my/password")}
                                                className="w-full py-2 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors text-sm text-center">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionAccountBtnChangePassword")}
                                            </button>
                                        </div>
                                    </div>
                                </section>

                                {/* 부모님 동의 섹션 */}
                                {(info.userStudentInfo.under14) && (
                                    <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                                        <h2 className="text-lg font-semibold mb-4">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionParentConfirmTitle")}
                                        </h2>
                                        <div className="space-y-4">
                                            <div>
                                                <p className="text-gray-500 text-sm">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionParentLabelEmail")}
                                                </p>
                                                <p className="font-medium">{info.userStudentInfo.parentEmail || '등록된 이메일이 없습니다'}</p>
                                            </div>

                                            {!info.userStudentInfo.isParentConfirm &&
                                                <>
                                                    <div className="text-amber-600 bg-amber-50 p-3 rounded-lg text-sm">
                                                        <p>
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionParentConfirmDesc")}
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-4">
                                                        <button
                                                            onClick={() => navigate("/student/my/parent-email")}
                                                            className="w-full py-2 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors text-sm text-center"
                                                        >
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnSectionParentChangeParentEmail")}
                                                        </button>

                                                        <button
                                                            onClick={() => sendVerifyParentEmail()}
                                                            className="w-full py-2 px-4 bg-[#CF3688] hover:bg-[#B82F77] text-white rounded-lg transition-colors text-sm text-center"
                                                        >
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnSectionParentConfirm")}
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </section>
                                )}

                                {/* 보조 연락처 정보 */}
                                {/* 휴대전화 정보 섹션 */}
                                <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                                    <h2 className="text-lg font-semibold mb-4">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionCellphoneTitle")}
                                    </h2>
                                    <div className="space-y-4">
                                        {info.userStudentInfo.countryPhoneCode &&
                                            info.userStudentInfo.cellphone ? (
                                            <div>
                                                <p className="text-gray-500 text-sm">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionCellphoneLabel")}
                                                </p>
                                                <p className="font-medium">{info.userStudentInfo.countryPhoneCode.code} {info.userStudentInfo.cellphone}</p>
                                            </div>
                                        ) : (
                                            <div>
                                                <p className="text-gray-500 text-sm">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionCellphoneEmpty")}
                                                </p>
                                            </div>
                                        )}

                                        <div className="flex gap-4">
                                            <button
                                                onClick={() => {
                                                    navigate("/student/my/cellphone");
                                                }}
                                                className="w-full py-2 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors text-sm text-center"
                                            >
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionCellphoneBtnChange")}
                                            </button>
                                        </div>
                                    </div>
                                </section>

                                {/* 연락처 정보 */}
                                <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                                    <h2 className="text-lg font-semibold mb-4">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionContactTitle")}
                                    </h2>
                                    <div className="space-y-4">
                                        {info.userStudentInfo.contactTypeInfo &&
                                            info.userStudentInfo.contact ? (
                                            <>
                                                <div>
                                                    <p className="text-gray-500 text-sm">
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionContactTypeLabel")}
                                                    </p>
                                                    <p className="font-medium">
                                                        {getCurrentLangName(info.userStudentInfo.contactTypeInfo?.names)}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-gray-500 text-sm">
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionContactLabel")}
                                                    </p>
                                                    <p className="font-medium">{info.userStudentInfo.contact}</p>
                                                </div>
                                            </>
                                        ) : (
                                            <div>
                                                <p className="text-gray-500 text-sm">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionContactEmpty")}
                                                </p>
                                            </div>
                                        )
                                        }

                                        <div className="flex gap-4">
                                            <button
                                                onClick={() => {
                                                    navigate("/student/my/contact");
                                                }}
                                                className="w-full py-2 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors text-sm text-center"
                                            >
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionContactBtnChange")}
                                            </button>
                                        </div>
                                    </div>
                                </section>

                                {/* 계정 활성화 섹션 */}
                                <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                                    <div className="flex items-center justify-between mb-4">
                                        <h2 className="text-lg font-semibold">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionActivateTitle")}
                                        </h2>
                                        <div className="relative inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                checked={info.userStudentInfo.isActive}
                                                onChange={(evt) => { onToggleActivateAccount(); }}
                                                disabled={isOnToggle
                                                    || !info.hasVerifiedEmail
                                                    || (info.userStudentInfo.under14 && !info.userStudentInfo.isParentConfirm)}
                                                className="sr-only peer"
                                                id="toggle-active"
                                            />
                                            <label
                                                htmlFor="toggle-active"
                                                className="flex items-center w-14 h-7 bg-gray-200 rounded-full cursor-pointer 
                                                         peer-checked:bg-[#CF3688] peer-focus:ring-2 peer-focus:ring-[#CF3688]/20 
                                                         after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                                                         after:bg-white after:rounded-full after:h-6 after:w-6 after:transition-all
                                                         after:shadow-sm peer-checked:after:translate-x-7"
                                            >
                                                <span className="sr-only">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionActivateTitle")}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        {!info.hasVerifiedEmail &&
                                            <div className="text-[#cf3688] text-sm">
                                                <p className="mb-2">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionActivateDescNeedVerifyEmail")}
                                                </p>
                                            </div>
                                        }
                                        {(info.userStudentInfo.under14 && !info.userStudentInfo.isParentConfirm) &&
                                            <div className="text-[#cf3688] text-sm">
                                                <p className="mb-2">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionActivateDescNeedParentConfirm")}
                                                </p>
                                            </div>
                                        }
                                        {info.hasVerifiedEmail &&
                                            (!info.userStudentInfo.under14 ||
                                                (info.userStudentInfo.under14 && info.userStudentInfo.isParentConfirm))
                                            &&
                                            <div className="text-[#cf3688] text-sm">
                                                <p className="mb-2">
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionActivateDescTitle")}
                                                </p>
                                                <ul className="space-y-1.5">
                                                    <li className="flex items-start">
                                                        <span className="mr-2">•</span>
                                                        <span>
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionActivateDesc1")}
                                                        </span>
                                                    </li>
                                                    <li className="flex items-start">
                                                        <span className="mr-2">•</span>
                                                        <span>
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionActivateDesc2")}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </section>

                                {/* 푸시 관련 */}
                                {isApp && (
                                    <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                                        <h2 className="text-lg font-semibold mb-4">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSectionAlarmTitle")}
                                        </h2>
                                        <div className="space-y-4">
                                            <PushCheckContainer />
                                        </div>
                                    </section>
                                )}

                                {/* 약관 및 정책 섹션 */}
                                {/* <section className="bg-white rounded-2xl p-6 shadow-sm mb-6">
                            <h2 className="text-lg font-semibold mb-4">약관 및 정책</h2>
                            <div className="space-y-4">
                                {marketingTermList?.map((item: any, idx: number) => (
                                    <label key={`term-${idx}`} className="flex items-start space-x-3 cursor-pointer">
                                        <input
                                            type="checkbox"
                                            className="mt-1 h-4 w-4 text-[#CF3688] rounded border-gray-300 focus:ring-[#CF3688]"
                                            onChange={(evt) => {
                                                if (!item.disabled) {
                                                    setTermId(item.id);
                                                    updateMarketingTermList(idx, evt.target.checked);
                                                }
                                            }}
                                            checked={item.hasAgreed}
                                            disabled={item.disabled}
                                        />
                                        <span className="text-sm text-gray-700">
                                            {`(${getLanguageValue(PAGE_TYPE.STUDENT, "my.txtOptional")}) ${item.title}`}
                                        </span>
                                    </label>
                                ))}

                                <div className="pt-4 space-y-4">
                                    {termList.map((item: any, idx: number) => (
                                        <a 
                                            key={`term2-${idx}`}
                                            href={`/term/common/${item.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="block text-gray-700 hover:text-gray-900"
                                        >
                                            {item.title}
                                            <span className="float-right">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10 2H14V6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.66667 9.33333L14 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </section> */}

                                {/* 계정 관리 섹션 */}
                                <section className="space-y-4">
                                    <button
                                        onClick={() => navigate("/student/my/delete-account")}
                                        className="w-full py-3 px-4 border border-[#CF3688] text-[#CF3688] hover:bg-[#CF3688] hover:text-white rounded-lg transition-colors"
                                    >
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnWithdraw")}
                                    </button>

                                    <Link
                                        to="/logout"
                                        className="block w-full py-3 px-4 border bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors text-center"
                                    >
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnLogout")}
                                    </Link>
                                </section>
                            </>
                        ) : (
                            <div className="flex flex-col items-center justify-center py-12">
                                <p className="text-gray-500 text-lg">
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtErrorToReadInformation")}
                                </p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </main>
    );
};
export default MyComponent;
