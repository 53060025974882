import React, { useEffect, useState } from "react";
import HeaderComponent from "./Header.component";
import { useSelector } from "react-redux";
import * as AlarmApi from "api/agency/v2/alarm.api";

const HeaderContainer = () => {
  const { info: myInfo } = useSelector((state: any) => state.user);
  const [showAlarmTooltip, setShowAlarmTooltip] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isMobileWeb, setIsMobileWeb] = useState(false);

  useEffect(() => {
    console.log("myInfo", myInfo);
  }, [myInfo]);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await AlarmApi.getUnreadAlarmCount();
        setUnreadCount(response.count);
      } catch (error) {
        console.error("읽지 않은 알람 수 조회 실패:", error);
      }
    };

    // 초기 실행
    fetchUnreadCount();

    // 10초마다 실행
    const interval = setInterval(() => {
      // fetchUnreadCount();
    }, 10000);

    // 모바일 웹 확인
    const checkMobileWeb = () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const isSmallScreen = window.innerWidth < 768;
      setIsMobileWeb(isMobile && isSmallScreen);
    };

    // 초기 체크
    checkMobileWeb();

    // 화면 크기 변경 시 체크
    window.addEventListener('resize', checkMobileWeb);

    // 컴포넌트 언마운트 시 인터벌 정리
    return () => clearInterval(interval);
  }, []);

  const handleAlarmClick = () => {
    setShowAlarmTooltip(!showAlarmTooltip);
    setUnreadCount(0);
  };

  const handleCloseAlarmTooltip = () => {
    setShowAlarmTooltip(false);
  };

  return <HeaderComponent
    userInfo={myInfo}
    showAlarmTooltip={showAlarmTooltip}
    onAlarmClick={handleAlarmClick}
    onCloseAlarmTooltip={handleCloseAlarmTooltip}
    unreadCount={unreadCount}
    isMobileWeb={isMobileWeb}
  />;
};

export default HeaderContainer;