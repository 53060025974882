import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setInfo as setUserInfo, setIsLogin } from "reducers/User";
import { removeAuthTokens } from "helpers/Auth.helper";

import * as AuthApi from "api/common/Auth.api";

import DeleteAccountComponent from "./DeleteAccount.component";
import { removeAppAccessToken } from "helpers/appLocalStorageHelper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const DeleteAccountContainer = (props: any) => {
  const { info: myInfo } = useSelector((state: any) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [withdrawalReasonCode, setWithdrawalReasonCode] = useState("");
  const [withdrawalOtherReason, setWithdrawalOtherReason] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [password, setPassword] = useState("");

  const deleteAccount = async () => {
    if (window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtConfirm"))) {
      setIsButtonDisabled(true)
      const result = await AuthApi.deleteAccount({
        accountTypeCode: myInfo.accountTypeCode,
        // withdrawalReasonCode,
        withdrawalReasonCode: "withdrawalReason:etc",
        withdrawalOtherReason,
        password,
      });

      if (result) {
        dispatch(setUserInfo({}));
        dispatch(setIsLogin(false));
        removeAuthTokens();
        navigate("/");
        await removeAppAccessToken();
      }
      setIsButtonDisabled(false)
    }
  };

  return (
    <DeleteAccountComponent
      onClickBtnDeleteAccount={deleteAccount}
      setWithdrawalReasonCode={setWithdrawalReasonCode}
      setWithdrawalOtherReason={setWithdrawalOtherReason}
      isButtonDisabled={isButtonDisabled}
      password={password}
      setPassword={setPassword}
    />
  );
};

export default DeleteAccountContainer;
