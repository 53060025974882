import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuComponent = (props: any) => {
    const { pathname } = useLocation();
    const { messageRequestCount } = props;

    const getIconSrc = (path: string, icon: string) => {
        return path === '/student'
            ? pathname === path ? `./imgs/icons/${icon}-active.svg` : `./imgs/icons/${icon}.svg`
            : pathname.startsWith(path) ? `./imgs/icons/${icon}-active.svg` : `./imgs/icons/${icon}.svg`;
    };

    return (
        <div className="fixed bottom-0 left-0 right-0 flex justify-center pb-2">
            {/* 메인 메뉴 */}
            <div className={`bg-white/80 backdrop-blur-md rounded-2xl shadow-sm px-1 py-1 transition-all duration-300 ${pathname != '/student' ? 'opacity-0 translate-y-full invisible' : 'opacity-100 translate-y-0 visible'}`}>
                <div className="flex gap-2 sm:gap-4 md:gap-6">
                    {/* 홈 */}
                    <Link to="/student" className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                            <img src={getIconSrc('/student', 'icon-home')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtHome")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname === '/student' ? 'text-[#CF3688]' : 'text-gray-700'} text-center`}>
                                {getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtHome")}
                            </p>
                        </div>
                    </Link>

                    {/* 프로필 */}
                    <Link to="/student/profile" className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                            <img src={getIconSrc('/student/profile', 'icon-profile')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtProfile")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname.startsWith('/student/profile') ? 'text-[#CF3688]' : 'text-gray-700'} text-center`}>
                                {getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtProfile")}
                            </p>
                        </div>
                    </Link>

                    {/* 통계 */}
                    {/* <Link to="/student/statistics" className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                            <img src={getIconSrc('/student/statistics', 'icon-statistics')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtStatistics")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname.startsWith('/student/statistics') ? 'text-[#CF3688]' : 'text-gray-700'} text-center`}>
                                {getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtStatistics")}
                            </p>
                        </div>
                    </Link> */}

                    {/* 팬페이지 */}
                    <Link to="/student/fan-page" className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                            <img src={getIconSrc('/student/fan-page', 'icon-fan-page')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtFanPage")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname.startsWith('/student/fan-page') ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                {getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtFanPage")}
                            </p>
                        </div>
                    </Link>

                    {/* 캐스팅 */}
                    <Link to="/student/casting" className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50  relative">
                            <img src={getIconSrc('/student/casting', 'icon-casting')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtCasting")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname.startsWith('/student/casting') ? 'text-[#CF3688]' : 'text-gray-700'} text-center`}>
                                {getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtCasting")}
                            </p>
                            {messageRequestCount > 0 && (
                                <div className="absolute top-1 right-3">
                                    <span className="block text-xs text-white bg-[#cf3688] rounded-full w-2 h-2"></span>
                                </div>
                            )}
                        </div>
                    </Link>

                    {/* 커뮤니티 */}
                    {/* <Link to="/student/hub" className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                            <img src={getIconSrc('/student/hub', 'icon-community')} alt={getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtHub")} className="w-5 h-5 sm:w-6 sm:h-6 mx-auto mb-1" />
                            <p className={`text-[10px] sm:text-xs font-medium ${pathname.startsWith('/student/hub') ? 'text-[#CF3688]' : 'text-gray-700'} text-center`}>
                                {getLanguageValue(PAGE_TYPE.STUDENT, "mainMenu.txtHub")}
                            </p>
                        </div>
                    </Link> */}
                </div>
            </div>

            {/* 프로필 메뉴 */}
            <div className={`absolute pb-2 bottom-0 left-0 right-0 flex justify-center transition-all duration-300 ${pathname.startsWith('/student/profile') ? 'opacity-100 translate-y-0 visible' : 'opacity-0 -translate-y-full invisible'}`}>
                <div className="bg-white/80 backdrop-blur-md rounded-2xl shadow-sm px-1 py-1">
                    <div className="flex flex-wrap sm:flex-nowrap gap-2 sm:gap-4 items-center">
                        <Link to="/student" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <img src="./imgs/icons/icon-arrow-left-1.svg" alt="이전" className="w-4 h-4 sm:w-5 sm:h-5 mx-auto" />
                            </div>
                        </Link>
                        <div className="hidden sm:block h-5 w-[1px] bg-gray-200"></div>
                        <Link to="/student/profile" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname === '/student/profile' ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profileMenu.txtSummary")}
                                </p>
                            </div>
                        </Link>
                        <Link to="/student/profile/public" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname.startsWith('/student/profile/public') ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profileMenu.txtPublicProfile")}
                                </p>
                            </div>
                        </Link>
                        <Link to="/student/profile/agency" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname.startsWith('/student/profile/agency') ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profileMenu.txtProfileForAgency")}
                                </p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            {/* 팬페이지 메뉴 */}
            <div className={`absolute pb-2 bottom-0 left-0 right-0 flex justify-center transition-all duration-300 ${pathname.startsWith('/student/fan-page') ? 'opacity-100 translate-y-0 visible' : 'opacity-0 -translate-y-full invisible'}`}>
                <div className="bg-white/80 backdrop-blur-md rounded-2xl shadow-sm px-1 py-1">
                    <div className="flex flex-wrap sm:flex-nowrap gap-2 sm:gap-4 items-center">
                        <Link to="/student" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <img src="./imgs/icons/icon-arrow-left-1.svg" alt="Previous" className="w-4 h-4 sm:w-5 sm:h-5 mx-auto" />
                            </div>
                        </Link>
                        <div className="hidden sm:block h-5 w-[1px] bg-gray-200"></div>
                        <Link to="/student/fan-page" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname === '/student/fan-page' ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageMenu.txtSummary")}
                                </p>
                            </div>
                        </Link>
                        <Link to="/student/fan-page/photos" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname.startsWith('/student/fan-page/photos') ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageMenu.txtPhotos")}
                                </p>
                            </div>
                        </Link>
                        <Link to="/student/fan-page/qna" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname.startsWith('/student/fan-page/qna') ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageMenu.txtQna")}
                                </p>
                            </div>
                        </Link>
                        <Link to="/student/fan-page/post" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname.startsWith('/student/fan-page/post') ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageMenu.txtPost")}
                                </p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            {/* 캐스팅 메뉴 */}
            <div className={`absolute pb-2 bottom-0 left-0 right-0 flex justify-center transition-all duration-300 ${pathname.startsWith('/student/casting') ? 'opacity-100 translate-y-0 visible' : 'opacity-0 -translate-y-full invisible'}`}>
                <div className="bg-white/80 backdrop-blur-md rounded-2xl shadow-sm px-1 py-1">
                    <div className="flex flex-wrap sm:flex-nowrap gap-2 sm:gap-4 items-center">
                        <Link to="/student" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <img src="./imgs/icons/icon-arrow-left-1.svg" alt="Previous" className="w-4 h-4 sm:w-5 sm:h-5 mx-auto" />
                            </div>
                        </Link>
                        <div className="hidden sm:block h-5 w-[1px] bg-gray-200"></div>
                        <Link to="/student/casting/" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname === '/student/casting' ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "castingMenu.txtSummary")}
                                </p>
                            </div>
                        </Link>
                        <Link to="/student/casting/message" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname === '/student/casting/message' ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "castingMenu.txtMessage")}
                                </p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            {/* 커뮤니티 메뉴 */}
            {/* <div className={`absolute pb-2 bottom-0 left-0 right-0 flex justify-center transition-all duration-300 ${pathname.startsWith('/student/hub') ? 'opacity-100 translate-y-0 visible' : 'opacity-0 -translate-y-full invisible'}`}>
                <div className="bg-white/80 backdrop-blur-md rounded-2xl shadow-sm px-1 py-1">
                    <div className="flex flex-wrap sm:flex-nowrap gap-2 sm:gap-4 items-center">
                        <Link to="/student" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <img src="./imgs/icons/icon-arrow-left-1.svg" alt="Previous" className="w-4 h-4 sm:w-5 sm:h-5 mx-auto" />
                            </div>
                        </Link>
                        <div className="hidden sm:block h-5 w-[1px] bg-gray-200"></div>
                        <Link to="/student/hub/" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname === '/student/hub' ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "hubMenu.txtSummary")}
                                </p>
                            </div>
                        </Link>
                        <Link to="/student/hub/community" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname === '/student/hub/community' ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "hubMenu.txtCommunity")}
                                </p>
                            </div>
                        </Link>
                        <Link to="/student/hub/follow" className="group flex flex-col items-center transition-all hover:scale-110">
                            <div className="p-2 sm:p-3 rounded-xl group-hover:bg-gray-50">
                                <p className={`text-xs sm:text-sm font-medium ${pathname === '/student/hub/follow' ? 'text-[#CF3688]' : 'text-gray-700'} text-center whitespace-nowrap`}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "hubMenu.txtFollow")}
                                </p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div> */}

            {/* 프로필 서브 메뉴 */}
            {/* <div className="bg-white/80 backdrop-blur-md rounded-2xl shadow-sm px-1 py-1">
                <div className="flex gap-6">
                    <Link to="/student/public-profile" className="group flex flex-col items-center transition-all hover:scale-110">
                        <div className="p-3 rounded-xl">
                            <img src={getIconSrc('/student/public-profile', 'icon-profile')} alt="프로필 수정" />
                        </div>
                    </Link>
                </div>
            </div> */}
        </div>
    );
};

export default MenuComponent;