import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import * as StudentApi from "api/student/Student.api";

import UpdateContactComponent from "./UpdateMyContact.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { updateStudentContactInfo } from "api/common/Auth.api";
import { getContactInfo, updateContactInfo } from "api/student/v2/my.api";

const UpdateContactContainer = (props: any) => {
    const navigate = useNavigate();
    const [isInit, setIsInit] = useState<boolean>(false);

    const [contact, setContact] = useState<any>(null);
    const [contactTypeCode, setContactTypeCode] = useState<string>("");
    const [contactTypeInfo, setContactTypeInfo] = useState<any>(null);

    const [isOnNetworking, setIsOnNetworking] = useState<boolean>(false);

    const handleChangeContactTypeCode = (code: string) => {
        setContactTypeCode(code);
    };

    const handleChangeContact = (value: string) => {
        setContact(value);
    };

    const handleCancelUpdate = () => {
        navigate("/student/my");
    };

    const handleUpdateContact = async () => {
        setIsOnNetworking(true);
        await updateContactInfo(contactTypeCode, contact);
        setIsOnNetworking(false);
    };

    useEffect(() => {
        const init = async () => {
            const data = await getContactInfo();
            if (data?.info) {
                setContact(data.info.contact);
                setContactTypeInfo(data.info.contactTypeInfo);
                setContactTypeCode(data.info.contactTypeInfo.code);
            }
            setIsInit(true);
        };
        init();
    }, []);


    return (
        <UpdateContactComponent
            isInit={isInit}
            contact={contact}
            contactTypeCode={contactTypeCode}
            onContactTypeChange={handleChangeContactTypeCode}
            onContactChange={handleChangeContact}
            onCancelUpdate={handleCancelUpdate}
            onUpdateContact={handleUpdateContact}
            isOnNetworking={isOnNetworking}
        />
    );
};

export default UpdateContactContainer;
