import { useEffect, useState } from "react";
import InstallAppBannerComponent from "./InstallAppBanner.component";
import { getInstallAppBannerKey, setCookie, getCookie } from "helpers/Cookie.helper";
import { useSelector } from "react-redux";

const APP_INSTALL_URL = process.env.REACT_APP_APP_INSTALL_URL;
const InstallAppBannerContainer = () => {

    const [isShowInstallAppBanner, setIsShowInstallAppBanner] = useState(false);
    const { isApp } = useSelector((state: any) => state.common);

    const cookieKey = getInstallAppBannerKey();

    useEffect(() => {
        if (isApp) {
            return;
        }

        const cookie = getCookie(cookieKey);
        if (cookie && cookie.trim() === 'y') {
            setIsShowInstallAppBanner(false);
            return;
        }
        const checkMobileWeb = () => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            const isSmallScreen = window.innerWidth < 768;
            setIsShowInstallAppBanner(isMobile && isSmallScreen);
        };

        checkMobileWeb();
        window.addEventListener('resize', checkMobileWeb);
    }, []);

    const handleClickClose = () => {
        const date = new Date();
        date.setDate(date.getDate() + 7); // 7일간 유지
        setCookie(cookieKey, "y", date);
        setIsShowInstallAppBanner(false);
    }

    return <InstallAppBannerComponent
        isShowInstallAppBanner={isShowInstallAppBanner}
        appInstallUrl={APP_INSTALL_URL}
        onClickClose={handleClickClose}
    />;
};

export default InstallAppBannerContainer;