import * as BaseApi from "../../Base.api";
const BASE_URL = "/student/v2/agency-profile";

//* 기획사용 프로필 활성화 여부
// 2025.02.13
// 희문
//* Params
//  isProfileActive: boolean - 기획사용 프로필 활성화 여부
export const updateIsActiveOfAgencyProfile = async (params: any) => {
    const url = `${BASE_URL}/info/is-profile-active`;
    return await BaseApi.patch(url, params);
};

// 기획사용 프로필 조회
// 2025.02.13
// 희문
// 학생 화면에서 기획사용 프로필화면 구성시 사용
export const getHomeInfo = async () => {
    const url = `${BASE_URL}/home`;
    return await BaseApi.get(url);
};

//* 소개 영상 관련
//* 기획사용 소개 영상 생성
// 2025.03.09
// 희문
//* Params
//  videoId: string - 영상 ID
export const createIntroVideo = async (params: any) => {
    const url = `${BASE_URL}/info/intro-video`;
    return await BaseApi.post(url, params);
};

//* 기획사용 소개 영상 목록 조회
// 2025.03.09
// 희문
//* Params
//  page: number - 페이지 번호
//  limit: number - 페이지 당 조회 개수
export const getListIntroVideo = async (page: number, limit: number) => {
    const url = `${BASE_URL}/info/intro-video`;
    const queryString = { page, limit }
    return await BaseApi.get(url, queryString);
};

//* 기획사용 소개 영상 삭제
// 2025.03.09
// 희문
export const deleteIntroVideo = async (id: number) => {
    const url = `${BASE_URL}/info/intro-video/${id}`;
    return await BaseApi.remove(url);
};



//* 이미지 관련

//* 기획사용 이미지 생성
// 2025.03.09
// 희문
//* Params
//  imageId: string - 이미지 ID
//  profileImageCategoryCode: string - 이미지 카테고리 코드
//  profileImageTypeCode: string - 이미지 타입 코드
export const createProfileImage = async (params: any) => {
    const url = `${BASE_URL}/info/profile-image`;
    return await BaseApi.post(url, params);
};

// 기획사용 프로필 사진 목록 조회
// 2025.02.13
// 희문
// 기획사용 프로필 화면에서 조회 시 사용
export const getAgencyProfilePhoto = async (category: string, type: string, page: number, limit: number) => {
    const url = `${BASE_URL}/info/profile-image?category=${category}&type=${type}&page=${page}&limit=${limit}`;
    return await BaseApi.get(url);
};

// 기획사용 프로필 사진 삭제
// 2025.02.13
// 희문
// 기획사용 프로필 화면에서 사진 삭제 시 사용
export const deleteAgencyProfilePhoto = async (id: number): Promise<any> => {
    const url = `${BASE_URL}/info/profile-image/${id}`;
    return await BaseApi.remove(url)
        .then((response: any) => {
            console.log(response);
            if (response.status == 200) {
                return response.data.data;
            } else {
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};



//* 연습 영상 관련

//* 연습 영상 목록 조회
// 2025.03.09
// 희문
export const getListPractice = async (params: any) => {
    const url = `${BASE_URL}/info/practice`;
    return await BaseApi.get(url, params);
};

//* 연습 영상 상세 조회
// 2025.03.09
// 희문
export const getInfoPractice = async (id: number) => {
    const url = `${BASE_URL}/info/practice/${id}`;
    return await BaseApi.get(url);
};

//* 연습 영상 생성
// 2025.03.09
// 희문
export const createPractice = async (params: any) => {
    const url = `${BASE_URL}/info/practice`;
    return await BaseApi.post(url, params);
};

//* 연습 영상 수정
// 2025.03.09
// 희문
export const updatePracticeVideo = async (id: number, params: any) => {
    const url = `${BASE_URL}/info/practice/${id}`;
    return await BaseApi.patch(url, params);
};

//* 연습 영상 삭제
// 2025.03.09
// 희문
export const deletePracticeVideo = async (id: number) => {
    const url = `${BASE_URL}/info/practice/${id}`;
    return await BaseApi.remove(url);
};

//* 연습 영상 조회 기록 목록 조회
// 2025.03.09
// 희문
export const getListPraciceViewedAgency = async (params: any) => {
    const url = `${BASE_URL}/info/practice/agency`;
    return await BaseApi.get(url, params);
};

//* 연습 영상 공개 여부 수정
// 2025.03.09
// 희문
export const updatePracticeVideoVisibility = async (
    id: number,
    params: any
) => {
    const url = `${BASE_URL}/info/practice/visibility-code/${id}`;
    return await BaseApi.patch(url, params);
};
