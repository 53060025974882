import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import PostInfoComponent from "./PostInfo.component";
import { getPostInfo, getCommentList, createPostComment, deletePostComment } from "api/student/v2/FanPage.api";
import { doTranslate } from "api/Translate.api";
import { getLanguageValue } from "locales/i18n";
import { getCurrentLanguage } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const COMMENT_LIMIT = 10;

const PostInfoContainer = () => {
    const { id } = useParams<any>();

    const [post, setPost] = useState<any>(null);
    const [comments, setComments] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingComments, setIsLoadingComments] = useState(false);
    const [commentPage, setCommentPage] = useState(1);
    const [hasMoreComments, setHasMoreComments] = useState(false);
    const [translatedComments, setTranslatedComments] = useState<{ [key: string]: string }>({});
    const [commentText, setCommentText] = useState("");
    const [isPostingComment, setIsPostingComment] = useState(false);

    // 포스트 상세 정보 조회
    const fetchPostDetail = useCallback(async () => {
        if (!id) return;

        try {
            setIsLoading(true);
            const data = await getPostInfo(Number(id));
            if (data.info) {
                setPost(data.info);
            }
        } catch (error) {
            console.error('Failed to fetch post:', error);
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    // 댓글 목록 조회
    const fetchComments = useCallback(async () => {
        if (!id) return;

        try {
            setIsLoadingComments(true);
            const data = await getCommentList(Number(id), commentPage, COMMENT_LIMIT);
            if (data?.list) {
                setComments(prev => commentPage === 1 ? data.list : [...prev, ...data.list]);
                setHasMoreComments(data.list.length === COMMENT_LIMIT);
            }
        } catch (error) {
            console.error('Failed to fetch comments:', error);
        } finally {
            setIsLoadingComments(false);
        }
    }, [id, commentPage]);

    // 초기 데이터 로딩
    useEffect(() => {
        fetchPostDetail();
        fetchComments();
    }, [fetchPostDetail, fetchComments]);

    // 댓글 더보기
    const handleLoadMoreComments = useCallback(() => {
        setCommentPage(prev => prev + 1);
    }, []);

    // 댓글 번역 처리
    const handleTranslateComment = useCallback(async (commentId: number, text: string) => {
        try {
            const response = await doTranslate({ text });
            console.log(response);
            if (response?.data?.translatedText) {
                setTranslatedComments(prev => ({
                    ...prev,
                    [commentId]: response.data.translatedText
                }));
            }
        } catch (error) {
            console.error('Failed to translate comment:', error);
            alert('번역에 실패했습니다. 다시 시도해주세요.');
        }
    }, []);

    // 댓글 입력 처리
    const handleCommentChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!value.startsWith(' ')) { // 공백으로 시작하지 않도록
            setCommentText(value);
        }
    }, []);

    // 댓글 등록
    const handleCreateComment = useCallback(async () => {
        if (!id || !commentText.trim() || isPostingComment) return;

        let text = commentText.trim();

        try {
            setIsPostingComment(true);
            const data = await createPostComment(Number(id), { text });

            if (data?.info) {
                // 댓글 목록 첫 번째에 새 댓글 추가
                setComments(prev => [data.info, ...prev]);
                // 입력창 초기화
                setCommentText("");
            }
            if (data?.totalCommentCount) {
                setPost((prev: any) => ({ ...prev, commentCount: data.totalCommentCount }));
            }
        } catch (error) {
            console.error('Failed to create comment:', error);
            alert('댓글 등록에 실패했습니다. 다시 시도해주세요.');
        } finally {
            setIsPostingComment(false);
        }
    }, [id, commentText, isPostingComment]);

    // 댓글 삭제
    const handleDeleteComment = useCallback(async (commentId: number) => {
        const message = getLanguageValue(PAGE_TYPE.COMMON, "btn.txtDeleteConfirmation");
        if (!window.confirm(message)) return;

        try {
            await deletePostComment(Number(id), commentId);
            // 삭제된 댓글을 목록에서 제거
            setComments(prev => prev.filter(comment => comment.id !== commentId));
            // 댓글 수 업데이트
            setPost((prev: any) => ({
                ...prev,
                commentCount: (prev.commentCount || 0) - 1
            }));
        } catch (error) {
            console.error('Failed to delete comment:', error);
            alert('댓글 삭제에 실패했습니다. 다시 시도해주세요.');
        }
    }, []);

    return (
        <PostInfoComponent
            post={post}
            comments={comments}
            commentText={commentText}
            isPostingComment={isPostingComment}
            translatedComments={translatedComments}
            isLoading={isLoading}
            isLoadingComments={isLoadingComments}
            hasMoreComments={hasMoreComments}
            onLoadMoreComments={handleLoadMoreComments}
            onTranslateComment={handleTranslateComment}
            onCommentChange={handleCommentChange}
            onCreateComment={handleCreateComment}
            onDeleteComment={handleDeleteComment}
        />
    );
};

export default PostInfoContainer;