import React, { useCallback, useEffect, useState } from "react";
import CommentPopupComponent from "./CommentPopupForCommunity.component";
import { createComment, deleteComment, getCommentList, toggleLikeComment, doTranslateCommentText } from "api/student/Community.api";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import StudentInfoPopupContainer from "commons/student/popup/StudentInfoPopup.container";
import { usePopup } from "contexts/PopupContext";
import ReportPopupForCommunityCommentContainer from "./ReportPopupForCommunityComment.container";
import LikePopupForCommunityCommentContainer from "./LikePopupForCommunityComment.container";

const COMMENT_LIMIT = 10;
const CommentPopupContainer = (props: any) => {
    const { targetId } = props;
    const { showPopup } = usePopup();

    const [isInit, setIsInit] = useState(false);

    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [commentList, setCommentList] = useState<any[]>([]);
    const [text, setText] = useState("");
    const [isPosting, setIsPosting] = useState(false);
    const [openMenuId, setOpenMenuId] = useState<number | null>(null);
    const [isOnTranslating, setIsOnTranslating] = useState(false);
    const [translatedComments, setTranslatedComments] = useState<{ [key: string]: string }>({});
    const fetchCommentList = useCallback(async () => {
        try {
            setIsLoading(true);
            const res = await getCommentList(targetId, page, COMMENT_LIMIT);
            if (res?.list) {
                setCommentList(res.list);
            }
        } catch (error) {
            console.error('Failed to fetch comment list:', error);
        } finally {
            setIsLoading(false);
            if (!isInit) {
                setIsInit(true);
            }
        }
    }, [targetId]);

    useEffect(() => {
        fetchCommentList();
    }, [fetchCommentList]);

    const handleCreateComment = useCallback(async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (isPosting) return;
        if (!text.trim()) {
            alert("댓글을 입력해주세요.");
            return;
        }

        try {
            setIsPosting(true);
            const response = await createComment(targetId, text);
            if (response) {
                setText("");
                setPage(1);
                fetchCommentList();
            }
        } catch (error) {
            console.error('Failed to create comment:', error);
        } finally {
            setIsPosting(false);
        }
    }, [text, isPosting, fetchCommentList]);

    const handleTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    }, []);


    const handleToggleLike = useCallback(async (commentId: number) => {
        try {
            const data = await toggleLikeComment(targetId, commentId);
            if (data) {
                setCommentList(prev => prev.map(comment => comment.id === commentId ? { ...comment, isLiked: data.result, likeCount: data.likeCount } : comment));
            }
        } catch (error) {
            console.error('Failed to like comment:', error);
        }
    }, [targetId]);


    const handleDeleteComment = useCallback(async (commentId: number) => {
        if (window.confirm(getLanguageValue(PAGE_TYPE.STUDENT, "commentPopup.txtDeleteConfirm"))) {
            try {
                const res = await deleteComment(targetId, commentId);
                if (res) {
                    setCommentList(prev => prev.filter(comment => comment.id !== commentId));
                }
            } catch (error) {
                console.error('Failed to delete comment:', error);
            }
        }
    }, [targetId]);

    const handleTranslateComment = useCallback(async (commentId: number) => {
        try {
            const data = await doTranslateCommentText(targetId, commentId);
            if (data) {
                setTranslatedComments(prev => ({
                    ...prev,
                    [commentId]: data.translatedText
                }));
            }
        } catch (error) {
            console.error('Failed to translate comment:', error);
        }
    }, [targetId]);

    // const handleEditComment = useCallback(async (commentId: string, text: string) => {
    //     try {
    //         const res = await editComment(commentId, text);
    //     } catch (error) {
    //         console.error('Failed to edit comment:', error);
    //     }
    // }, [targetId]);

    // const handleLikeComment = useCallback(async (commentId: string) => {
    //     try {
    //         const res = await likeComment(commentId);
    //     } catch (error) {
    //         console.error('Failed to like comment:', error);
    //     }
    // }, [targetId]);

    // const handleReportComment = useCallback(async (commentId: string) => {
    //     try {
    //         const res = await reportComment(commentId);
    //     } catch (error) {
    //         console.error('Failed to report comment:', error);
    //     }
    // }, [targetId]);

    // const handleReplyComment = useCallback(async (commentId: string, text: string) => {
    //     try {
    //         const res = await replyComment(commentId, text);
    //     } catch (error) {
    //         console.error('Failed to reply comment:', error);
    //     }
    // }, [targetId]);

    const handleLoadMoreComment = useCallback(async () => {
        try {
            setPage(prev => prev + 1);
        } catch (error) {
            console.error('Failed to load more comment:', error);
        }
    }, [targetId]);

    const handleShowStudentInfoPopup = useCallback((studentId: number) => {
        showPopup(
            <StudentInfoPopupContainer
                studentId={studentId}
            />
        );
    }, []);

    const handleShowReportPopup = useCallback((commentId:number) => {
        showPopup(
            <ReportPopupForCommunityCommentContainer
                bbsId={targetId}
                commentId={commentId}
            />
        );
    }, []);

    const handleShowLikePopup = useCallback((commentId:number) => {
        showPopup(
            <LikePopupForCommunityCommentContainer
                bbsId={targetId}
                commentId={commentId}
            />
        );
    }, []);

    return <CommentPopupComponent
        onClose={props.onClose}
        isInit={isInit}
        targetId={targetId}
        page={page}
        text={text}
        commentList={commentList}
        isLoading={isLoading}
        onTranslateComment={handleTranslateComment}
        handleLoadMoreComment={handleLoadMoreComment}
        handleCreateComment={handleCreateComment}
        handleTextChange={handleTextChange}
        onToggleLike={handleToggleLike}
        openMenuId={openMenuId}
        setOpenMenuId={setOpenMenuId}
        onDeleteComment={handleDeleteComment}
        isOnTranslating={isOnTranslating}
        setIsOnTranslating={setIsOnTranslating}
        translatedComments={translatedComments}
        setTranslatedComments={setTranslatedComments}
        onShowStudentInfoPopup={handleShowStudentInfoPopup}
        onShowReportPopup={handleShowReportPopup}
        onShowLikePopup={handleShowLikePopup}
    />;
};

export default CommentPopupContainer;