import React from "react";
import { PAGE_TYPE } from "enums";
import { getImageUrl } from "helpers/Image.helper";
import { getLanguageValue } from "locales/i18n";
import LoadingComponent from "commons/loading/Loading.component";

const LikePopupForFanPagePostComponent = (props: any) => {
    const { list, onFollow, onUnfollow, isInit, onScroll, isOnLoadMore, hasMore, onShowStudentInfoPopup } = props;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 p-4 !m-0">
            <div className="bg-white rounded-2xl p-6 w-full max-w-2xl mx-auto">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "likePopup.txtTitle")}
                    </h3>
                    <button className="text-gray-400 hover:text-gray-600" onClick={props.onClose}>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {!isInit ? (
                    <LoadingComponent />
                ) : list?.length === 0 ? (
                    <div className="flex flex-col items-center justify-center py-10 text-gray-500">
                        <svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                        <p className="text-center">
                            {getLanguageValue(PAGE_TYPE.STUDENT, "likePopup.txtEmpty")}
                        </p>
                    </div>
                ) : (
                    <div
                        className="max-h-[400px] overflow-y-auto mb-4"
                        onScroll={onScroll}
                    >
                        <div className="space-y-4">
                            {list?.map((like: any) => (
                                <div key={like.id} className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-lg">
                                    <div className="flex items-center space-x-3"
                                        onClick={() => onShowStudentInfoPopup(like.userStudentInfo?.id)}
                                    >
                                        <div className="w-10 h-10 rounded-full bg-gray-200 overflow-hidden">
                                            <img src={getImageUrl(like.userFanInfo?.profileImageInfo)} alt="" className="w-full h-full object-cover" />
                                        </div>
                                        <div>
                                            <p className="font-medium">
                                                {like.userFanInfo?.nickname ? like.userFanInfo.nickname : "Unknown"}
                                            </p>
                                        </div>
                                    </div>
                                    {/* {(!like.isMine && like.userStudentInfo) && (
                                        <button
                                            className={`px-4 py-2 rounded-lg text-sm font-medium ${like.isFollowing ? 'bg-gray-100 text-gray-700 hover:bg-gray-200' : 'bg-[#CF3688] text-white hover:bg-[#CF3688]/90'}`}
                                            onClick={() => like.isFollowing ? onUnfollow(like.userStudentInfo.id) : onFollow(like.userStudentInfo.id)}>
                                            {like.isFollowing ? '팔로잉' : '팔로우'}
                                        </button>
                                    )} */}
                                </div>
                            ))}
                            {isOnLoadMore && (
                                <LoadingComponent />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LikePopupForFanPagePostComponent;