import PopupManager from 'commons/popup/PopupManager.component';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface PopupContextType {
    showPopup: (content: ReactNode) => void;
    hidePopup: (index: number) => void;
    popups: ReactNode[];
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const usePopup = () => {
    const context = useContext(PopupContext);
    if (!context) {
        throw new Error('usePopup must be used within a PopupProvider');
    }
    return context;
};

export const PopupProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [popups, setPopups] = useState<ReactNode[]>([]);

    const showPopup = (content: ReactNode) => setPopups(prev => [...prev, content]);
    
    const hidePopup = (index: number) => setPopups(prev => prev.filter((_, i) => i !== index));

    useEffect(() => {
        if (popups.length > 0) {
            document.body.style.overflow = 'hidden'; // 드래그 비활성화
        } else {
            document.body.style.overflow = 'auto'; // 드래그 활성화
        }
    }, [popups]);

    return (
        <PopupContext.Provider value={{ showPopup, hidePopup, popups }}>
            {children}
            <PopupManager />
        </PopupContext.Provider>
    );
}; 