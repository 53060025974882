import React, { useCallback, useEffect, useRef, useState } from "react";
import HomeComponent from "./Index.component"

import * as AuthApi from "api/common/Auth.api";
import { getCommonCodeListByParent } from "data/common.data";

import * as CommunityApi from "api/student/Community.api";
import { uploadImage } from "api/common/Data.api";
import * as HomeApi from "api/student/v2/home.api";
import RequestChatPopupContainer from "../popup/RequestChatPopup.container";
import { usePopup } from "contexts/PopupContext";
import StudentInfoPopupContainer from "commons/student/popup/StudentInfoPopup.container";
import FullPageFollowPopupContainer from "../popup/FullPageFollowPopup.container";

const POST_LIMIT = 20; // 한 번에 불러올 포스트 수
const HomeContainer = (props: any) => {
    const { showPopup } = usePopup();
    // 상단 섹션 관련
    const [info, setInfo]: any = useState({});
    const evaluationCategory = getCommonCodeListByParent("evaluationCategory");

    const [isLoading, setIsLoading] = useState(true);
    const [isOnSendEmailForParentConfirmation, setIsOnSendEmailForParentConfirmation] = useState(false);
    const [isOnSendEmailForEmailVerification, setIsOnSendEmailForEmailVerification] = useState(false);

    const [requestChatList, setRequestChatList] = useState<Array<any>>([]);
    const [errorMessage, setErrorMessage] = useState<string>(null);

    useEffect(() => {
        getInfo();
    }, []);

    const handleSendEmailForParentConfirmation = async () => {
        setIsOnSendEmailForParentConfirmation(true);
        const result = await AuthApi.sendVerifyParentEmail({})
            .then((res: any) => {
                if (res) {
                    if (res.status === 200) {
                        const response = res.data;
                        const message = response.message;
                        alert(message);
                    }
                }
            }).finally(() => {
                setIsOnSendEmailForParentConfirmation(false);
            });
    };

    const handleSendEmailForEmailVerification = async () => {
        setIsOnSendEmailForEmailVerification(true);
        const result = await AuthApi.sendVerifyEmailForUser()
            .then((res: any) => {
                if (res) {
                    if (res.status === 200) {
                        const response = res.data;
                        const message = response.message;
                        alert(message);
                    }
                }
            }).catch((err: any) => {
                console.log(err);
            }).finally(() => {
                setIsOnSendEmailForEmailVerification(false);
            });
    };

    const getInfo = async () => {
        try {
            const result = await HomeApi.fetchHomeInfo();
            if (result?.info) {
                setInfo(result.info);
                setRequestChatList(result.requestChatList);
            }
        } catch (err: any) {
            console.log(err);
            setErrorMessage(err.message || "Unknown Error");
        } finally {
            setIsLoading(false);
        }
    };

    // 포스트 관련
    const [posts, setPosts] = useState<Array<any>>([]);
    const [isLoadingPosts, setIsLoadingPosts] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const [page, setPage] = useState(1);
    const [images, setImages] = useState<Array<{ id: string; url: string, file?: File }>>([]);
    const [content, setContent] = useState("");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [hasMore, setHasMore] = useState(true);

    const handleScroll = useCallback(() => {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        // 스크롤이 바닥에 도달했는지 체크
        if (scrollHeight - scrollTop <= clientHeight + 100) { // 100px 여유를 둠
            console.log('scroll to bottom')
            // 더 로드할 데이터가 있고 현재 로딩 중이 아닐 때만 다음 페이지 로드
            if (hasMore && !isLoadingPosts) {
                setPage(prevPage => prevPage + 1);
            }
        }
    }, [hasMore, isLoadingPosts]);

    useEffect(() => {
        // 스크롤 이벤트 리스너 등록
        console.log('add event listener for scroll')
        window.addEventListener('scroll', handleScroll);

        return () => {
            // 컴포넌트 언마운트 시 이벤트 리스너 제거
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const fetchPosts = useCallback(async () => {
        if (isLoadingPosts) return;

        try {
            setIsLoadingPosts(true);
            const data = await CommunityApi.getList(page, POST_LIMIT);
            if (data?.list) {
                setPosts(prev => page === 1 ? data.list : [...prev, ...data.list]);
                setHasMore(data.list.length === POST_LIMIT);
                // setPosts([data.list[0]])
            }
        } catch (error) {
            console.error('Failed to fetch posts:', error);
        } finally {
            setIsLoadingPosts(false);
        }
    }, [page]);

    useEffect(() => {
        console.log('fetchPosts')
        fetchPosts();
    }, [fetchPosts]);

    // 이미지 업로드 처리
    const handleImageUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;

        if (images.length + files.length > 10) {
            alert("이미지는 최대 10개까지만 업로드 가능합니다.");
            return;
        }

        // 이미지 파일들을 순서대로 처리하기 위해 Promise 배열 생성
        const imagePromises = Array.from(files).map(file => {
            return new Promise<{id: string, url: string, file: File}>((resolve) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target?.result) {
                        resolve({
                            id: Math.random().toString(36).substr(2, 9),
                            url: e.target.result as string,
                            file: file
                        });
                    }
                };
                reader.readAsDataURL(file);
            });
        });

        // 모든 이미지가 로드되면 순서대로 상태에 추가
        Promise.all(imagePromises).then(newImages => {
            setImages(prev => [...prev, ...newImages]);
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }, [images.length]);

    const handleRemoveImage = useCallback((id: string) => {
        setImages(prev => prev.filter(image => image.id !== id));
    }, []);

    const handleContentChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newContent = event.target.value;
        setContent(newContent);
    }, []);

    const handlePost = async () => {
        if (isPosting) return;
        if (!content.trim()) {
            alert("내용을 입력해주세요.");
            return;
        }

        try {
            setIsPosting(true);

            const uploadedImageIdList = [];
            for (const image of images) {
                if (image.file) {
                    const formData = new FormData();
                    formData.append('file', image.file);
                    const response = await uploadImage(image.file);
                    if (response?.id) {
                        uploadedImageIdList.push(response.id);
                    }
                }
            }

            const result = await CommunityApi.createPost(content.trim(), uploadedImageIdList) as any
            if (result?.data?.data?.info) {
                setContent("");
                setImages([]);
                const info = result?.data?.data?.info;
                const newPosts = [info, ...posts];
                setPosts(newPosts);
            }
            // fetchPosts();
        } catch (error) {
            console.error('Failed to post:', error);
        } finally {
            setIsPosting(false);
        }
    };


    const handleRequestClick = useCallback((request: any) => {
        console.log(request);
        showPopup(<RequestChatPopupContainer messageRequestId={request.id} />);
    }, []);

    const handleShowStudentInfoPopup = useCallback((studentId: number) => {
        showPopup(<StudentInfoPopupContainer
            studentId={studentId} />);
    }, []);

    const handleShowFullPageFollowPopup = useCallback(() => {
        showPopup(<FullPageFollowPopupContainer   />);
    }, []);

    return (
        <HomeComponent
            info={info}
            isLoading={isLoading}
            isOnSendEmailForParentConfirmation={isOnSendEmailForParentConfirmation}
            isOnSendEmailForEmailVerification={isOnSendEmailForEmailVerification}
            handleSendEmailForParentConfirmation={handleSendEmailForParentConfirmation}
            handleSendEmailForEmailVerification={handleSendEmailForEmailVerification}

            handleShowStudentInfoPopup={handleShowStudentInfoPopup}
            handleShowFullPageFollowPopup={handleShowFullPageFollowPopup}

            requestChatList={requestChatList}
            onRequestClick={handleRequestClick}

            posts={posts}
            isLoadingPosts={isLoadingPosts}
            isPosting={isPosting}
            hasMore={hasMore}
            fileInputRef={fileInputRef}
            onImageUpload={handleImageUpload}
            onRemoveImage={handleRemoveImage}
            onContentChange={handleContentChange}
            onPost={handlePost}
            images={images}
            content={content}
            setPosts={setPosts}
            errorMessage={errorMessage}
        />
    );
};

export default HomeContainer;
