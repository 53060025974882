import React, { useEffect, useState } from "react";
import HeaderComponent from "./Header.component";
import { useSelector } from "react-redux";
import * as AlarmApi from "api/student/v2/alarm.api";
const HeaderContainer = (props: any) => {
    const { info: myInfo } = useSelector((state: any) => state.user);
    const [showAlarmTooltip, setShowAlarmTooltip] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        console.log("myInfo", myInfo);
    }, [myInfo]);


    useEffect(() => {
        const fetchUnreadCount = async () => {
            try {
                const response = await AlarmApi.getUnreadAlarmCount();
                setUnreadCount(response.count);
            } catch (error) {
                console.error("읽지 않은 알람 수 조회 실패:", error);
            }
        };

        // 초기 실행
        fetchUnreadCount();

        // 10초마다 실행
        const interval = setInterval(() => {
            fetchUnreadCount();
        }, 10000);

        // 컴포넌트 언마운트 시 인터벌 정리
        return () => clearInterval(interval);
    }, []);

    const handleAlarmClick = () => {
        setShowAlarmTooltip(!showAlarmTooltip);
        setUnreadCount(0);
    };

    const handleCloseAlarmTooltip = () => {
        setShowAlarmTooltip(false);
    };

    return (
        <HeaderComponent 
            userInfo={myInfo} 
            showAlarmTooltip={showAlarmTooltip}
            onAlarmClick={handleAlarmClick}
            onCloseAlarmTooltip={handleCloseAlarmTooltip}
            unreadCount={unreadCount}
        />
    );
};

export default HeaderContainer;