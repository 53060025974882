import React, { useEffect, useState } from "react";
import LikePopupForFanPagePostComponent from "./LikePopupForFanPagePost.component";
import { addFollow, cancelFollow } from "api/student/Follow.api";
import StudentInfoPopupContainer from "commons/student/popup/StudentInfoPopup.container";
import { usePopup } from "contexts/PopupContext";
import { getLikeListForFanPagePost } from "api/student/v2/FanPage.api";

const LIMIT = 10;
const LikePopupForFanPagePostContainer = (props: any) => {
    const { postId } = props;
    const { showPopup } = usePopup();
    const [page, setPage] = useState<number>(1);
    const [list, setList] = useState<any[]>([]);
    const [isInit, setIsInit] = useState<boolean>(false);
    const [isOnNetworking, setIsOnNetworking] = useState<boolean>(false);
    const [isOnLoadMore, setIsOnLoadMore] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const loadLikeList = async () => {
        if (isOnLoadMore) return;

        setIsOnLoadMore(true);
        const data = await getLikeListForFanPagePost(postId, page, LIMIT);
        if (data?.list) {
            setList(prev => page === 1 ? data.list : [...prev, ...data.list]);
            setHasMore(data.list.length === LIMIT);
        }
        setIsOnLoadMore(false);
        setIsInit(true);
    };

    useEffect(() => {
        loadLikeList();
    }, []);

    useEffect(() => {
        loadLikeList();
    }, [page]);

    const handleFollow = async (userStudentId: number) => {
        if (isOnNetworking) return;
        setIsOnNetworking(true);
        const data = await addFollow(userStudentId);
        if (data) {
            setList(list.map(item => item.userStudentInfo.id === userStudentId ? { ...item, isFollowing: true } : item));
        }
        setIsOnNetworking(false);
    };

    const handleUnfollow = async (userStudentId: number) => {
        if (isOnNetworking) return;
        setIsOnNetworking(true);
        const data = await cancelFollow(userStudentId);
        if (data) {
            setList(list.map(item => item.userStudentInfo.id === userStudentId ? { ...item, isFollowing: false } : item));
        }
        setIsOnNetworking(false);
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        if (scrollHeight - scrollTop === clientHeight && !isOnLoadMore && hasMore) {
            setPage(page + 1);
        }
    };

    const handleShowStudentInfoPopup = (studentId: number) => {
        showPopup(
            <StudentInfoPopupContainer
                studentId={studentId}
            />
        );
    };

    return <LikePopupForFanPagePostComponent
        list={list}
        onClose={props.onClose}
        onFollow={handleFollow}
        onUnfollow={handleUnfollow}
        isInit={isInit}
        onScroll={handleScroll}
        isOnLoadMore={isOnLoadMore}
        hasMore={hasMore}
        onShowStudentInfoPopup={handleShowStudentInfoPopup}
    />;
};

export default LikePopupForFanPagePostContainer;
