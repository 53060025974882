import React from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";
import Pagination from "commons/pagination/Pagination.container";
import { formatTimeAgo, getFormattedDateValue } from "helpers/Datetime.helper";
import { getUserType } from "helpers/Common.helper";
import { useSelector } from "react-redux";

const NoticeComponent = (props: any) => {
    const {
        isLoading,
        importantList,
        noticeList,
        go,
        // pagination
        limit,
        page,
        setPage,
        totalCount,
        //
        onClickCheckNotification,
    } = props;

    const navigate = useNavigate();
    const { info: userInfo } = useSelector((state: any) => state.user);

    const noticePath = `/${getUserType(userInfo?.accountTypeCode)}/notice`;
    const goDetailPage = (id: number) => {
        navigate(`${noticePath}/${id}`, {
            state: {
                page,
            },
        });
    };

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                {isLoading ? (
                    <LoadingComponent />
                ) : noticeList && noticeList.length === 0 ? (
                    <>
                        <h2 className="text-3xl font-bold text-gray-900 mb-8">{getLanguageValue(PAGE_TYPE.COMMON, "notice.txtMenuTitle")}</h2>
                        <div className="flex justify-center items-center h-64">
                            <p className="text-gray-500">No data</p>
                        </div>
                    </>
                ) : (
                    <>
                        <h2 className="text-3xl font-bold text-gray-900 mb-8">{getLanguageValue(PAGE_TYPE.COMMON, "notice.txtMenuTitle")}</h2>
                        <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            {/* PC 버전 */}
                            <div className="hidden md:block">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                                                {getLanguageValue(PAGE_TYPE.COMMON, "notice.txtTitle")}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-500 w-48">
                                                {getLanguageValue(PAGE_TYPE.COMMON, "notice.txtCreatedAt")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {importantList.map((notice: any) => (
                                            <tr key={notice.id} className="bg-pink-50">
                                                <td className="px-6 py-4">
                                                    <p onClick={() => goDetailPage(notice.id)} className="text-[#CF3688] font-medium cursor-pointer hover:underline">
                                                        {notice.title}
                                                    </p>
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    {getFormattedDateValue(notice.createdAt, "YYYY.MM.DD HH:mm")}
                                                </td>
                                            </tr>
                                        ))}
                                        {noticeList.map((notice: any) => (
                                            <tr key={notice.id}>
                                                <td className="px-6 py-4">
                                                    <p onClick={() => goDetailPage(notice.id)} className="text-gray-900 cursor-pointer hover:text-[#CF3688]">
                                                        {notice.title}
                                                    </p>
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500">
                                                    {getFormattedDateValue(notice.createdAt, "YYYY.MM.DD HH:mm")}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* 모바일 버전 */}
                            <div className="md:hidden">
                                <div className="divide-y divide-gray-200">
                                    {importantList.map((notice: any) => (
                                        <div key={notice.id} className="p-4 bg-pink-50" onClick={() => goDetailPage(notice.id)}>
                                            <p className="text-[#CF3688] font-medium mb-2">{notice.title}</p>
                                            <p className="text-sm text-gray-500">
                                                {getFormattedDateValue(notice.createdAt, "YYYY.MM.DD HH:mm")}
                                            </p>
                                        </div>
                                    ))}
                                    {noticeList.map((notice: any) => (
                                        <div key={notice.id} className="p-4" onClick={() => goDetailPage(notice.id)}>
                                            <p className="text-gray-900 mb-2">{notice.title}</p>
                                            <p className="text-sm text-gray-500">
                                                {getFormattedDateValue(notice.createdAt, "YYYY.MM.DD HH:mm")}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="p-4">
                                <Pagination 
                                    url={noticePath} 
                                    totalCount={totalCount} 
                                    currentPage={page} 
                                    pageSize={limit} 
                                    pageGroupSize={10} 
                                    setPage={setPage} 
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </main>
    );
};

export default NoticeComponent;
