import axios from "axios";
import { getAccessToken, getNewAccessTokenValue } from "helpers/Auth.helper";
import { getDeviceId, getPushToken } from "helpers/InApp.helper";
import { platform } from "os";

const AUTH_API_URL = process.env.REACT_APP_AUTH_URL;
const languageCode = navigator.language;

const getAxios = () => {
    const headers: any = {
        accesskey: process.env.REACT_APP_AUTH_ACCESS_KEY,
        secretkey: process.env.REACT_APP_AUTH_SECRET_KEY,

        "language-code": languageCode,
    };

    const accessToken = getAccessToken();
    if (accessToken) {
        headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return axios.create({ headers });
};

// 공통코드 요청
export const getAllCodeList = async () => {
    const url = "/app/code";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const getTermList = async (params?: any) => {
    const url = "/app/term";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const getAgencyList = async (params?: any) => {
    const url = "/app/agency";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const getHomeTermList = async () => {
    const url = "/app/term/home";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const getTermInfo = async (id: number) => {
    const url = `/app/term/info/${id}`;

    return getAxios()
        .get(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const getTermListByType = async (params?: any) => {
    const url = "/app/term/signup";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const signupByEmail = async (params: any) => {
    const url = "/auth/email/signup";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const schoolSignupByEmail = async (params: any) => {
    const url = "/auth/email/school/signup";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const createInfo = async (accountType: string, params: any) => {
    const url = `/my-info/${accountType}`;

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const createStudentInfoBySchool = async (accountType: string, params: any) => {
    const url = `/my-info/school/${accountType}`;

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const login = async (params: any) => {
    const url = "/auth/email/login";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const getMyInfo = async () => {
    const url = "/my-info";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const checkPassword = async (params: any) => {
    const url = "/my-info/check-password";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const updatePassword = async (params: any) => {
    const url = "/my-info/password";

    return getAxios()
        .put(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const updateEmail = async (params: any) => {
    const url = "/my-info/username";

    return getAxios()
        .put(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const deleteAccount = async (params: any) => {
    const url = "/my-info/withdrawal";

    return getAxios()
        .delete(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const deleteAccountBySchool = async (params: any) => {
    const url = "/auth/email/withdrawal";

    return getAxios()
        .delete(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const checkExistsSlug = async (params: any) => {
    const url = `/my-info/student/slug/exists`;

    return getAxios()
        .get(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const verifyEmail = async (code: string) => {
    const url = "/verification/email/signup";

    return getAxios()
        .get(`${AUTH_API_URL}${url}/${code}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const verifyParentEmail = async (code: string) => {
    const url = "/verification/email/parent";

    return getAxios()
        .get(`${AUTH_API_URL}${url}/${code}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const verifyParentEmailAndSaveParentInfo = async (code: string, params: any) => {
    const url = "/verification/email/parent";

    return getAxios()
        .put(`${AUTH_API_URL}${url}/${code}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

//* 이메일 인증 메일 발송
export const sendVerifyEmail = async (params: any) => {
    const url = "/auth/email/send/email-signup";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

//* 회원 이메일 인증 메일 발송
//* 
export const sendVerifyEmailForUser = async () => {
    const url = "/verification/email/verify";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};
export const sendVerifyParentEmail = async (data: any) => {
    const url = "/auth/email/send/email-parent";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, data)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const sendEmailSignUp = async (params: any) => {
    const url = "/auth/email/send/signup";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const sendEmailForEmailUpdate = async (params: any) => {
    const url = "/auth/email/send/email-update";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const createFcmTokenInfo = async (params: any) => {
    const url = "/auth/token/notification";
    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const deleteFcmTokenInfo = async (params: any) => {
    const url = "/auth/token/notification";
    console.log(params);
    return getAxios()
        .delete(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const sendEmailForResetPassword = async (params: any) => {
    const url = "/verification/email/reset-password";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const verifyResetPasswordEmail = async (token: string) => {
    const url = "/verification/email/reset-password";

    return getAxios()
        .get(`${AUTH_API_URL}${url}/${token}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const verifyResetPassword = async (params: any) => {
    const url = "/verification/email/reset-password";

    return getAxios()
        .put(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const updateStudentContactInfo = async (params: any) => {
    const url = "/my-info/student/contact";

    return getAxios()
        .put(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const saveAppPushInfo = async () => {
    const deviceUuid = await getDeviceId();
    const token = await getPushToken();

    await createFcmTokenInfo({ deviceUuid, token, platform: "app" });
};

// push 활성화 여부 확인
export const getAppPushActivatedInfo = async () => {
    console.log("Auth.service:getAppPushActivatedInfo");
    const deviceUuid = await getDeviceId();
    const url = `/auth/token/notification/is-activated/${deviceUuid}`;

    return getAxios()
        .get(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

// push 활성화 업데이트
export const setPushActivate = async (params: any) => {
    console.log("Auth.service:setPushActivate");
    const deviceUuid = await getDeviceId();
    const url = `/auth/token/notification/is-activated/${deviceUuid}`;

    return getAxios()
        .patch(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const sendEmailForAgencyVeification = async (params: any) => {
    const url = "/verification/email/agency";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const verifyAgencyEmail = async (params: any) => {
    const url = "/verification/email/agency";

    return getAxios()
        .put(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const getNewAccessToken = async (params: any) => {
    const url = "/auth/email/refresh-token";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const checkExistsUsername = async (email: string) => {
    const url = "/auth/email/exists/username";

    const data = {
        email
    }
    return getAxios()
        .post(`${AUTH_API_URL}${url}`, data)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

//* 휴대전화 번호 인증 번호 발송
// 2025-02-28
// 희문
//* Params
//  countryPhoneCode 국가 번호
//  cellphone 휴대전화 번호
export const sendSmsVerifyingNumber = async (countryPhoneCode: string, cellphone: string) => {
    console.log("api/common/Auth.api.tsx:sendSmsVerifyingNumber");
    console.log({ countryPhoneCode, cellphone });

    const url = `${AUTH_API_URL}/verification/sms`;
    console.log(url);

    return getAxios()
        .post(url, { countryPhoneCode, cellphone })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
            return null;
        })
        .catch(errorHandler);
};

//* 휴대전화 번호 인증 번호 검증
// 2025-02-28
// 희문
//* Params
//  id 휴대전화 번호 인증 번호 발송 아이디
//  verifyingNumber 휴대전화 번호 인증 번호
export const verifySmsVerifyingNumber = async (id: string, verifyingNumber: string) => {
    console.log("api/common/Auth.api.tsx:verifySmsVerifyingNumber");
    console.log({ id, verifyingNumber });

    const url = `${AUTH_API_URL}/verification/sms/${id}/verify`;

    return getAxios()
        .post(url, { verifyingNumber })
        .then(({ status, data }) => {
            console.log({ status, data });
            if (status === 200) {
                return data.data;
            }
            return null;
        })
        .catch(errorHandler);
}

//* 지망생 휴대전화 번호 수정
//  2025.03.04
//  희문
//* Params
//  smsVerificationTokenId 휴대전화 번호 인증 번호 발송 아이디
//  countryPhoneCode 국가 번호
//  cellphone 휴대전화 번호
export const updateStudentCellphone = async (smsVerificationTokenId: string, countryPhoneCode: string, cellphone: string) => {
    console.log("api/common/Auth.api.tsx:updateStudentCellphone");
    console.log({ smsVerificationTokenId, countryPhoneCode, cellphone });

    const url = `${AUTH_API_URL}/my-info/student/cellphone`;

    return getAxios()
        .put(url, { smsVerificationTokenId, countryPhoneCode, cellphone })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
            return null;
        })
        .catch(errorHandler);
}

//* 캐스팅 매니저 기획사 휴대전화 번호 수정
//  2025.03.04
//  희문
//* Params
//  smsVerificationTokenId 휴대전화 번호 인증 번호 발송 아이디
//  countryPhoneCode 국가 번호
//  cellphone 휴대전화 번호
export const updateAgencyCellphone = async (smsVerificationTokenId: string, countryPhoneCode: string, cellphone: string) => {
    console.log("api/common/Auth.api.tsx:updateAgencyCellphone");
    console.log({ smsVerificationTokenId, countryPhoneCode, cellphone });

    const url = `${AUTH_API_URL}/my-info/agency/cellphone`;

    return getAxios()
        .put(url, { smsVerificationTokenId, countryPhoneCode, cellphone })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
            return null;
        })
        .catch(errorHandler);
}

//* 캐스팅 매니저 명함 앞면 수정
// 2025-03-01
// 희문
//* Params
//  businessCardFrontImageId 명함 앞면 이미지 아이디
export const updateBusinessCardFrontImageId = async (businessCardFrontImageId: string) => {

    const url = `${AUTH_API_URL}/my-info/agency/business-card-front-image-id`;

    return getAxios()
        .put(url, { businessCardFrontImageId })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
            return null;
        })
        .catch(errorHandler);
};

//* 캐스팅 매니저 명함 뒷면 수정
//  2025-03-01
//  희문
//* Params
//  businessCardBackImageId 명함 뒷면 이미지 아이디
export const updateBusinessCardBackImageId = async (businessCardBackImageId: string) => {

    const url = `${AUTH_API_URL}/my-info/agency/business-card-back-image-id`;

    return getAxios()
        .put(url, { businessCardBackImageId })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
            return null;
        })
        .catch(errorHandler);
};

//* 지망생 회원 탈퇴
//  2025-03-15
//  희문
//* Params
//  password 비밀번호
//  withdrawalReasonCode 탈퇴 사유 코드
//  withdrawalOtherReason 탈퇴 사유 기타
export const deleteStudentAccount = async (password: string, withdrawalReasonCode: string, withdrawalOtherReason: string) => {
    const url = `${AUTH_API_URL}/my-info/student`;

    const data = {
        password,
        withdrawalReasonCode,
        withdrawalOtherReason
    }
    return getAxios()
        .delete(url, { data })
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
}

//* 캐스팅 매니저 회원 탈퇴
//  2025-03-26
//  희문
//* Params
//  password 비밀번호
//  withdrawalReasonCode 탈퇴 사유 코드
//  withdrawalOtherReason 탈퇴 사유 기타
export const deleteAgencyAccount = async (password: string, withdrawalReasonCode: string, withdrawalOtherReason: string) => {
    const url = `${AUTH_API_URL}/my-info/agency`;

    const data = {
        password,
        withdrawalReasonCode,
        withdrawalOtherReason
    }
    return getAxios()
        .delete(url, { data })
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
}


const errorHandler = async ({ response }: any) => {
    console.log(response);
    if (!response) {
        alert("Unknown Error Occurred");
    } else if (response?.status === 401) {
        try {
            // 토큰 갱신
            const newAccessToken = await getNewAccessTokenValue();

            if (newAccessToken) {
                // 기존 요청 정보 가져오기
                const config = response.config;
                config.headers["Authorization"] = `Bearer ${newAccessToken}`;
                config.headers["Accesskey"] = process.env.REACT_APP_AUTH_ACCESS_KEY;
                config.headers["Secretkey"] = process.env.REACT_APP_AUTH_SECRET_KEY;
                config.headers["Language-Code"] = languageCode;

                const _axios = getAxios()

                const retry = await _axios(config);

                // 갱신된 토큰으로 API 재호출
                if (config.method === 'get') {
                    return retry.data.data;
                }

                return _axios(config);
            }
        } catch (refreshError) {
            console.error("Error during token refresh:", refreshError);
            throw refreshError; // Refresh 실패 시 오류 전달
        }
    } else if (response?.status !== 200) {
        alert(response?.data?.message);
    } else if (response?.status === 500) {
        alert(response?.data?.message);
        window.location.href = "/error/500";
    } else {
        alert(response?.data?.message);
    }
};