import React from "react";
import { getImageUrl } from "helpers/Image.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import FullPageLoadingComponent from "commons/loading/FullPageLoading.component";

const PhotosComponent = (props: any) => {
    const { isOnLoading, photosList, page, limit, setPage, setLimit, loadPhotosList, selectedPhoto, setSelectedPhoto, hasMorePage, handleDelete, isOnNetworking, handleUploadPhoto, handleShowPhotosPopup } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            {isOnNetworking && <FullPageLoadingComponent />}
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6">
                <h1 className="text-3xl font-bold text-gray-900 mb-6">
                    {getLanguageValue(PAGE_TYPE.STUDENT, "photos.txtTitle")}
                </h1>
                <p className="text-gray-500 mb-6">
                    {getLanguageValue(PAGE_TYPE.STUDENT, "photos.txtDesc")}
                </p>
            </div>
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                {/* 상단 버튼 영역 추가 */}
                <div className="flex justify-end items-center mb-6">
                    <button
                        onClick={() => document.getElementById("photo-upload").click()}
                        className="px-4 py-2 text-sm font-medium text-white bg-[#CF3688] rounded-lg hover:bg-[#B82F77] transition-colors flex items-center gap-2"
                    >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        {getLanguageValue(PAGE_TYPE.STUDENT, "photos.btnUploadPhoto")}
                    </button>
                    <input
                        type="file"
                        id="photo-upload"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => handleUploadPhoto(e)}
                        multiple={true}
                        max={10}
                    />
                </div>

                <>
                    <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-2 sm:gap-3 md:gap-4">
                        <>
                            {photosList.map((photo: any, index: number) => (
                                <div key={index} className="relative group w-full pb-[100%]">
                                    <div className="absolute inset-0">
                                        <div
                                            className="w-full h-full rounded-lg bg-gray-200 overflow-hidden cursor-pointer"
                                            onClick={() => handleShowPhotosPopup(photo)}
                                        >
                                            <img
                                                src={getImageUrl(photo.imageInfo)}
                                                alt={photo.title}
                                                className="w-full h-full object-cover group-hover:opacity-75"
                                            />
                                            <div className="absolute bottom-2 right-2 bg-black bg-opacity-70 text-white text-xs px-2 py-1 rounded-full flex items-center">
                                                <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                                {photo.viewCount || 0}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    </div>

                    {!isOnLoading && photosList.length === 0 && (
                        <div className="text-center text-gray-500 p-4 sm:p-6 md:p-8 text-sm sm:text-base md:text-lg">
                            {getLanguageValue(PAGE_TYPE.STUDENT, "photos.txtEmpty")}
                        </div>
                    )}


                    {/* 더보기 버튼 영역 추가 */}
                    {(!isOnLoading && hasMorePage) && (
                        <div className="mt-8 flex justify-center w-full">
                            <button
                                onClick={() => loadPhotosList(page + 1, limit)}
                                className="w-full max-w-lg mx-4 py-3 text-base font-medium text-[#CF3688] border-2 border-[#CF3688] rounded-lg hover:bg-[#CF3688] hover:text-white transition-colors shadow-sm outline outline-2 outline-[#CF3688]"
                            >
                                {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtMore")}
                            </button>
                        </div>
                    )}

                    {isOnLoading && (
                        <FullPageLoadingComponent />
                    )}
                </>
            </div>
        </main>
    );
};

export default PhotosComponent;
