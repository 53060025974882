import * as BaseApi from "../Base.api";

export const getInfoForHome = async () => {
  const url = "/student/profile/home";
  return await BaseApi.get(url);
};

export const getInfoForStudent = async () => {
  const url = "/student/profile";
  return await BaseApi.get(url);
};

export const createIntroVideo = async (params: any) => {
  const url = "/student/profile/intro-video";
  return await BaseApi.post(url, params);
};

export const createProfileImage = async (params: any) => {
  const url = "/student/profile/profile-image";
  return await BaseApi.post(url, params);
};

export const updateActiveProfile = async (params: any) => {
  const url = `/student/profile/active`;
  return await BaseApi.patch(url, params);
};

export const getListIntroVideo = async () => {
  const url = "/student/profile/intro-video";
  return await BaseApi.get(url);
};

export const deleteIntroVideo = async (id: number) => {
  const url = `/student/profile/intro-video/${id}`;
  return await BaseApi.remove(url);
};

export const getListProfileImage = async (params: any) => {
  const url = "/student/profile/profile-image";
  return await BaseApi.get(url, params);
};

export const deleteProfileImage = async (id: number) => {
  const url = `/student/profile/profile-image/${id}`;
  return await BaseApi.remove(url);
};

export const getListViewedAgency = async (params: any) => {
  const url = "/student/profile/agency";
  return await BaseApi.get(url, params);
};

export const getListPractice = async (params: any) => {
  const url = "/student/profile/practice";
  return await BaseApi.get(url, params);
};

export const getInfoPractice = async (id: number) => {
  const url = `/student/profile/practice/info/${id}`;
  return await BaseApi.get(url);
};

export const createPractice = async (params: any) => {
  const url = "/student/profile/practice";
  return await BaseApi.post(url, params);
};

export const updatePracticeVideo = async (id: number, params: any) => {
  const url = `/student/profile/practice/${id}`;
  return await BaseApi.patch(url, params);
};

export const deletePracticeVideo = async (id: number) => {
  const url = `/student/profile/practice/${id}`;
  return await BaseApi.remove(url);
};

export const getListPraciceViewedAgency = async (params: any) => {
  const url = "/student/profile/practice/agency";
  return await BaseApi.get(url, params);
};

export const updatePracticeVideoVisibility = async (
  id: number,
  params: any
) => {
  const url = `/student/profile/practice/visibility-code/${id}`;
  return await BaseApi.patch(url, params);
};


//* 프로필 - Summary 데이터 요청 API
// 2025.03.24
// 희문
// 프로필 요약 데이터 요청 API
export const getProfileSummary = async () => {
  const url = "/student/v2/profile/summary";
  return await BaseApi.get(url);
};


//* 프로필 - 홈 화면용 데이터 요청 API
// 2025.02.13
// 희문
// 학생 화면에서 프로필 화면 구성시 사용
export const getProfileInfoForHome = async () => {
  const url = "/student/info/profile/home";
  return await BaseApi.get(url);
};

// 기획사용 프로필 조회
// 2025.02.13
// 희문
// 학생 화면에서 기획사용 프로필화면 구성시 사용
export const getAgencyProfileInfo = async () => {
  const url = "/student/info/profile/agency";
  return await BaseApi.get(url);
};

// 기획사용 프로필 사진 목록 조회
// 2025.02.13
// 희문
// 기획사용 프로필 화면에서 조회 시 사용
export const getAgencyProfilePhoto = async (category: string, type: string, page: number, limit: number) => {
  const url = `/student/info/profile/agency/photo?category=${category}&type=${type}&page=${page}&limit=${limit}`;
  return await BaseApi.get(url);
};

// 기획사용 프로필 사진 삭제
// 2025.02.13
// 희문
// 기획사용 프로필 화면에서 사진 삭제 시 사용
export const deleteAgencyProfilePhoto = async (id: number): Promise<any> => {
  const url = `/student/info/profile/agency/photo/${id}`;
  return await BaseApi.remove(url)
    .then((response: any) => {
      console.log(response);
      if (response.status == 200) {
        return response.data.data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
