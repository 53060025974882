import React from 'react';
import ImageWithWatermarkForPresignedUrlContainer from "commons/image/ImageWithWatermarkForPresignedUrl.container";
import LoadingComponent from 'commons/loading/Loading.component';

const ProfileForAgencyComponent = (props: any) => {
  const { isInit, info, videoRef, onVideoPlay, onVideoError, videoUrl, myInfo, onClickImage, errorMessage } = props;
  return (
    <>
      {!isInit ?
        <LoadingComponent />
        :
        <>
          {errorMessage ?
            <div className="w-full text-center py-8 text-red-500 bg-red-100 border border-red-400 rounded">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z" />
              </svg>
              {errorMessage}
            </div>
            :
            <>
              {/* 섹션 1: 소개 비디오 */}
              <section className="bg-white rounded-lg shadow-sm p-6 mb-6">
                <h3 className="text-lg font-medium text-gray-500 mb-3">소개 비디오</h3>
                {videoUrl ? (
                  <video
                    ref={videoRef}
                    className="w-full rounded-lg"
                    src={videoUrl}
                    controls
                    controlsList="nodownload"
                    onPlay={onVideoPlay}
                    onContextMenu={(e) => e.preventDefault()}
                    onStalled={onVideoError}
                    onError={onVideoError}
                    onTimeUpdate={(e) => {
                      const video = e.target as HTMLVideoElement;
                      if (video.networkState === video.NETWORK_NO_SOURCE) {
                        onVideoError();
                      }
                    }}
                  />
                ) : (
                  <div className="text-gray-500">
                    등록된 비디오가 없네요...
                  </div>
                )}
              </section>

              {/* 섹션 2: 얼굴 사진 */}
              <section className="bg-white rounded-lg shadow-sm p-6 mb-6">
                <h3 className="text-lg font-medium text-gray-500 mb-3">얼굴 사진</h3>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {/* 정면 */}
                  <div>
                    <h4 className="text-sm text-gray-400 mb-2 flex justify-between items-center">
                      <span>정면</span>
                      {info?.faceFrontImageInfo && (
                        <span className="flex items-center">
                          <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                          <span className="ml-1">{info?.faceFrontImageInfo?.viewCount || 0}</span>
                        </span>
                      )}
                    </h4>
                    {info?.faceFrontImageInfo ? (
                      <div onClick={() => onClickImage(info?.faceFrontImageInfo?.id, info?.faceFrontImageInfo?.imageId)}>
                        <ImageWithWatermarkForPresignedUrlContainer
                          imageId={info?.faceFrontImageInfo?.imageId}
                          watermarkText={myInfo?.agencyName + " " + myInfo?.username}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
                        <svg className="w-12 h-12 text-gray-300 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p className="text-gray-400 text-sm text-center">등록된 사진이 없습니다</p>
                      </div>
                    )}
                  </div>
                  {/* 좌측면 */}
                  <div>
                    <h4 className="text-sm text-gray-400 mb-2 flex justify-between items-center">
                      <span>좌측면</span>
                      {info?.faceLeftImageInfo && (
                        <span className="flex items-center">
                          <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                          <span className="ml-1">{info?.faceLeftImageInfo?.viewCount || 0}</span>
                        </span>
                      )}
                    </h4>
                    {info?.faceLeftImageInfo ? (
                      <div onClick={() => onClickImage(info?.faceLeftImageInfo?.id, info?.faceLeftImageInfo?.imageId)}>
                        <ImageWithWatermarkForPresignedUrlContainer
                          imageId={info?.faceLeftImageInfo?.imageId}
                          watermarkText={myInfo?.agencyName + " " + myInfo?.username}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
                        <svg className="w-12 h-12 text-gray-300 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p className="text-gray-400 text-sm text-center">등록된 사진이 없습니다</p>
                      </div>
                    )}
                  </div>
                  {/* 우측면 */}
                  <div>
                    <h4 className="text-sm text-gray-400 mb-2 flex justify-between items-center">
                      <span>우측면</span>
                      {info?.faceRightImageInfo && (
                        <span className="flex items-center">
                          <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                          <span className="ml-1">{info?.faceRightImageInfo?.viewCount || 0}</span>
                        </span>
                      )}
                    </h4>
                    {info?.faceRightImageInfo ? (
                      <div onClick={() => onClickImage(info?.faceRightImageInfo?.id, info?.faceRightImageInfo?.imageId)}>
                        <ImageWithWatermarkForPresignedUrlContainer
                          imageId={info?.faceRightImageInfo?.imageId}
                          watermarkText={myInfo?.agencyName + " " + myInfo?.username}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
                        <svg className="w-12 h-12 text-gray-300 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p className="text-gray-400 text-sm text-center">등록된 사진이 없습니다</p>
                      </div>
                    )}
                  </div>
                </div>
              </section>

              {/* 섹션 3: 전신 사진 */}
              <section className="bg-white rounded-lg shadow-sm p-6">
                <h3 className="text-lg font-medium text-gray-500 mb-3">전신 사진</h3>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {/* 정면 */}
                  <div>
                    <h4 className="text-sm text-gray-400 mb-2 flex justify-between items-center">
                      <span>정면</span>
                      {info?.fullBodyFrontImageInfo && (
                        <span className="flex items-center">
                          <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                          <span className="ml-1">{info?.fullBodyFrontImageInfo?.viewCount || 0}</span>
                        </span>
                      )}
                    </h4>
                    {info?.fullBodyFrontImageInfo ? (
                      <div onClick={() => onClickImage(info?.fullBodyFrontImageInfo?.id, info?.fullBodyFrontImageInfo?.imageId)}>
                        <ImageWithWatermarkForPresignedUrlContainer
                          imageId={info?.fullBodyFrontImageInfo?.imageId}
                          watermarkText={myInfo?.agencyName + " " + myInfo?.username}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
                        <svg className="w-12 h-12 text-gray-300 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p className="text-gray-400 text-sm text-center">등록된 사진이 없습니다</p>
                      </div>
                    )}
                  </div>
                  {/* 좌측면 */}
                  <div>
                    <h4 className="text-sm text-gray-400 mb-2 flex justify-between items-center">
                      <span>좌측면</span>
                      {info?.fullBodyLeftImageInfo && (
                        <span className="flex items-center">
                          <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                          <span className="ml-1">{info?.fullBodyLeftImageInfo?.viewCount || 0}</span>
                        </span>
                      )}
                    </h4>
                    {info?.fullBodyLeftImageInfo ? (
                      <div onClick={() => onClickImage(info?.fullBodyLeftImageInfo?.id, info?.fullBodyLeftImageInfo?.imageId)}>
                        <ImageWithWatermarkForPresignedUrlContainer
                          imageId={info?.fullBodyLeftImageInfo?.imageId}
                          watermarkText={myInfo?.agencyName + " " + myInfo?.username}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
                        <svg className="w-12 h-12 text-gray-300 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p className="text-gray-400 text-sm text-center">등록된 사진이 없습니다</p>
                      </div>
                    )}
                  </div>
                  {/* 우측면 */}
                  <div>
                    <h4 className="text-sm text-gray-400 mb-2 flex justify-between items-center">
                      <span>우측면</span>
                      {info?.fullBodyRightImageInfo && (
                        <span className="flex items-center">
                          <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                          <span className="ml-1">{info?.fullBodyRightImageInfo?.viewCount || 0}</span>
                        </span>
                      )}
                    </h4>
                    {info?.fullBodyRightImageInfo ? (
                      <div onClick={() => onClickImage(info?.fullBodyRightImageInfo?.id, info?.fullBodyRightImageInfo?.imageId)}>
                        <ImageWithWatermarkForPresignedUrlContainer
                          imageId={info?.fullBodyRightImageInfo?.imageId}
                          watermarkText={myInfo?.agencyName + " " + myInfo?.username}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
                        <svg className="w-12 h-12 text-gray-300 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p className="text-gray-400 text-sm text-center">등록된 사진이 없습니다</p>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </>
          }
        </>
      }
    </>
  );
};

export default ProfileForAgencyComponent;