import LoadingComponent from "commons/loading/Loading.component";
import React from "react";

const AgencyProfilePhotoModalPopupComponent = ({ ...props }) => {

    const { onClose, selectedPopupImageUrl, handleClosePopup, isLoading, hasError, imageUrl } = props;

    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
                <div className="bg-white rounded-lg p-4 relative">
                    {isLoading &&
                        <div className="flex items-center justify-center w-full h-full">
                            <LoadingComponent />
                        </div>
                    }
                    {hasError && <div>Error</div>}
                    {imageUrl && (
                        <>
                            <button
                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                                onClick={onClose}
                            >
                                <svg className="w-6 h-6" fill="none" stroke="black" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <img src={selectedPopupImageUrl} alt="Profile photo" className="max-w-[80vw] max-h-[80vh] object-contain" />
                        </>
                    )}
                    {/*  */}
                    {/* <div className="absolute bottom-2 left-2 flex items-center space-x-2 bg-black/50 rounded-lg px-2 py-1">
                                    <div className="flex items-center space-x-1 text-white">
                                        <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg>
                                        <div className="flex flex-col">
                                            {item.viewUsers?.map((user: any, idx: number) => (
                                                <span key={idx} className="text-xs">{user.name}</span>
                                            ))}
                                        </div>
                                    </div>
                                </div> */}
                </div>
            </div>
        </>
    );
};

export default AgencyProfilePhotoModalPopupComponent;