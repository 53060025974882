import React, { useCallback, useState, useEffect } from "react";
import IndexComponent from "./Index.component";
import { getAgencyChannels } from "api/common/Chat.api";
import { getChatList, getRequestList } from "api/agency/v2/Chat.api";
import { usePopup } from "contexts/PopupContext";
import RequestChatPopupContainer from "../popup/RequestChatPopup.container";
const IndexContainer = () => {
    const { showPopup } = usePopup();

    const [isLoading, setIsLoading] = useState(true);
    const [requestList, setRequestList] = useState([]);
    const [roomList, setRoomList] = useState([]);

    // 홈 정보 조회
    const fetchHomeInfo = useCallback(async () => {
        try {
            setIsLoading(true);

            // 요청 목록 설정
            const requestData = await getRequestList();
            console.log("requestData", requestData);
            if (requestData?.list) {
                setRequestList(requestData.list || []);
            }

            // 채팅 목록 설정
            const channelData = await getChatList("messageType:accept");
            if (channelData?.list) {
                setRoomList(channelData.list || []);
            }
        } catch (error) {
            console.error('Failed to fetch home info:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleRequestClick = (requestId: number) => {
        showPopup(<RequestChatPopupContainer
            requestId={requestId}
            onCloseCallback={(needRefresh: boolean) => {
                console.log("handleRequestClick", needRefresh);
                if (needRefresh) {
                    fetchHomeInfo();
                }
            }}
        />);
    };

    useEffect(() => {
        fetchHomeInfo();
    }, [fetchHomeInfo]);

    return <IndexComponent
        isLoading={isLoading}
        requestList={requestList}
        roomList={roomList}
        onRequestClick={handleRequestClick}
    />
};

export default IndexContainer;