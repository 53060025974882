import React, { useEffect, useState } from "react";
import ChangeParentEmailComponent from "./ChangeParentEmail.component";
import { getParentEmail, updateParentEmail } from "api/student/v2/my.api";
import { sendVerifyParentEmail } from "api/common/Auth.api";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const ChangeParentEmailContainer = () => {
    const [currentEmail, setCurrentEmail] = useState<string>("");
    const [newEmail, setNewEmail] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchParentEmail = async () => {
            setIsLoading(true);
            try {
                const data = await getParentEmail();
                console.log({ data });
                if (data?.parentEmail) {
                    setCurrentEmail(data.parentEmail);
                }
            } catch (error) {
                console.error("Failed to fetch parent email:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchParentEmail();
    }, []);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewEmail(e.target.value);
    };

    const handleSubmit = async () => {
        if (!validateEmail(newEmail)) {
            alert(getLanguageValue(PAGE_TYPE.STUDENT, "myChangeParentEmail.txtInvalidEmail"));
            return;
        }

        setIsLoading(true);

        try {
            await updateParentEmail(newEmail);
            setCurrentEmail(newEmail);
            const confirmSend = window.confirm(getLanguageValue(PAGE_TYPE.STUDENT, "myChangeParentEmail.txtSuccessAndAskSendEmail"));
            if (confirmSend) {
                const result = await sendVerifyParentEmail({ parentEmail: newEmail });
                if (result.status === 200) {
                    alert(getLanguageValue(PAGE_TYPE.STUDENT, "myChangeParentEmail.txtSuccessSentEmail"));
                }
            }
        } catch (error: any) {
            console.error("Failed to update parent email:", error);
            alert(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleCancel = () => {
        navigate("/student/my");
    };

    return (
        <ChangeParentEmailComponent
            currentEmail={currentEmail}
            newEmail={newEmail}
            onEmailChange={handleEmailChange}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            onCancel={handleCancel}
        />
    );
};

export default ChangeParentEmailContainer;