import React, { useEffect, useRef, useState } from "react";
import ContactComponent from "./Contact.component";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { checkIsEmail } from "helpers/Reg.helper";
import { PAGE_TYPE } from "enums";
import { saveContactInfo } from "api/landing/Landing.api";

const ContactContainer = (props: any) => {
  const titleRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLTextAreaElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const save = async () => {
    const title: string = titleRef?.current?.value.trim() || "";
    const content: string = contentRef?.current?.value.trim() || "";
    const email: string = emailRef?.current?.value.trim() || "";

    if (!(title && content && email)) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "contact.txtEmptyItemAlert"));
      return;
    }
    if (!checkIsEmail(email)) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "contact.txtEmailFormatCheck"));
      return;
    }

    setIsButtonDisabled(true)
    const result: any = await saveContactInfo({ title, content, email });

    if (result.status === 200) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "contact.txtSaveSuccess"));
    } else {
      alert(result.data.message);
    }
    setIsButtonDisabled(false)
  };

  useEffect(() => { }, []);

  return (
    <ContactComponent
      {...props}
      titleRef={titleRef}
      contentRef={contentRef}
      emailRef={emailRef}
      isButtonDisable={isButtonDisabled}
      save={save}
    />
  );
};

export default ContactContainer;
