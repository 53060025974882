//* 캐스팅 페이지 관련 API
import * as BaseApi from "api/Base.api";
const BASE_URL = "/student/v2/casting";

//* 캐스팅 요약페이지 조회  
//  2025.03.08
//  희문
export const getCastingSummary = async () => {
    const result = await BaseApi.get(BASE_URL + "/summary");
    return result;
};