import * as BaseApi from "../Base.api";

export const getStudentList = async (params: any) => {
  const url = "/home/student";
  return await BaseApi.get(url, params);
};

export const getStudentInfo = async (slug: string, params: any) => {
  const url = `/home/student/${slug}`;
  return await BaseApi.get(url, params);
};

export const getWannabeAuditionList = async (params: any) => {
  const url = `/home/wannabes-audition`;
  return await BaseApi.get(url, params);
};

export const getAgencyAuditionList = async (params: any) => {
  const url = `/home/agency-audition`;
  return await BaseApi.get(url, params);
};

export const getAuditionInfo = async (id: number, params: any) => {
  const url = `/home/audition/${id}`;
  return await BaseApi.get(url, params);
};

export const updateUnSubscribedEmailInfo = async (id: number, params: any) => {
  const url = `/home/has-unsubscribed-email/${id}`;
  return await BaseApi.patch(url, params);
};

export const getPopularStudentList = async () => {
  const url = `/home/popular-student`;
  return await BaseApi.get(url);
};

export const getNewStudentList = async () => {
  const url = `/home/new-student`;
  return await BaseApi.get(url);
};

export const getTopFanCountStudentList = async () => {
  const url = `/home/top-fan-count-student`;
  return await BaseApi.get(url);
};

export const getSearchStudentList = async (keyword: string, page: number, limit: number) => {
  const url = `/home/search/${keyword}`;
  return await BaseApi.get(url, { page, limit });
};

//* 홈 화면 정보 요청
export const fetchHomeInfo = async () => {
  const url = `/home`;
  return await BaseApi.get(url);
};
