import { getCurrentLangName, getFanPageUrl, getFlagByCountryCode, getStudentNickname } from "helpers/String.helper";
import React from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getImageUrl } from "helpers/Image.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { useNavigate } from "react-router-dom";

interface StudentInfoPopupProps {
    onClose: () => void;
    info: any;
    isLoading: boolean;
    errorMessage: string;
    onFollow: (studentId: number) => Promise<void>;
    onUnfollow: (studentId: number) => Promise<void>;
    isOnNetworking: boolean;
}

const StudentInfoPopupComponent: React.FC<StudentInfoPopupProps> = ({ onClose, info, isLoading, errorMessage, onFollow, onUnfollow, isOnNetworking }) => {
    const navigate = useNavigate();
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 p-4 !m-0">
            <div className="bg-white rounded-2xl p-6 w-full max-w-2xl mx-auto">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "studentInfoPopup.txtTitle")}
                    </h3>
                    <button className="text-gray-400 hover:text-gray-600" onClick={onClose}>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {isLoading ? (
                    <LoadingComponent />
                ) : errorMessage ? (
                    <div className="flex flex-col items-center justify-center py-10 text-gray-500">
                        <p className="text-center">{errorMessage}</p>
                    </div>
                ) : (
                    <div className="flex flex-col items-center">
                        <div className="w-32 h-32 rounded-full bg-gray-200 overflow-hidden mb-4">
                            <img
                                src={getImageUrl(info?.profileImageInfo)}
                                alt={info?.nickname}
                                className="w-full h-full object-cover"
                            />
                        </div>

                        <h2 className="mb-2 text-center">
                            <span className="text-xl font-bold">
                                {getStudentNickname(info)}
                            </span>
                            <br />
                            <span className="text-sm text-gray-600"> {info?.birthYear ? `(${info?.birthYear})` : ""}</span>
                        </h2>

                        <p className="text-gray-600 mb-4 text-center">
                            {getFlagByCountryCode(info?.countryInfo?.code)}
                            {getCurrentLangName(info?.countryInfo?.names)}
                        </p>

                        {info?.isActiveOfPublicProfile &&
                            <div className="flex flex-col gap-4 mb-8 w-full">
                                <div className="flex justify-center gap-8">
                                    <div className="text-center">
                                        <div className="font-bold">{info?.followingCount || 0}</div>
                                        <div className="text-gray-600 text-sm">{getLanguageValue(PAGE_TYPE.STUDENT, "studentInfoPopup.txtFollowing")}</div>
                                    </div>
                                    <div className="text-center">
                                        <div className="font-bold">{info?.followerCount || 0}</div>
                                        <div className="text-gray-600 text-sm">{getLanguageValue(PAGE_TYPE.STUDENT, "studentInfoPopup.txtFollower")}</div>
                                    </div>
                                    <div className="text-center">
                                        <div className="font-bold">{info?.favoriteByAgencyCount || 0}</div>
                                        <div className="text-gray-600 text-sm">{getLanguageValue(PAGE_TYPE.STUDENT, "studentInfoPopup.txtFavoriteByAgency")}</div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="flex flex-row gap-4 w-full">
                            {info && !info.isMine && (
                                <>
                                    {info?.isActiveOfPublicProfile &&
                                        <button
                                            className="px-4 py-2 rounded-lg w-full text-sm font-medium bg-gray-100 text-gray-700 hover:bg-gray-200"
                                            onClick={() => {
                                                onClose();
                                                navigate(`/student/wannabe/${info.id}`);
                                            }}
                                        >
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "studentInfoPopup.txtShowDetail")}
                                        </button>
                                    }
                                    <button
                                        className={`px-4 py-2 rounded-lg text-sm font-medium w-full
                                        ${info.isFollowing
                                                ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                                : 'bg-[#CF3688] text-white hover:bg-[#CF3688]/90'}`}
                                        onClick={() => info.isFollowing ? onUnfollow(info.id) : onFollow(info.id)}
                                        disabled={isOnNetworking}
                                    >
                                        {!info.isFollowing && info.isFollower
                                            ? getLanguageValue(PAGE_TYPE.STUDENT, "studentInfoPopup.txtFollowBack")
                                            : info.isFollowing
                                                ? getLanguageValue(PAGE_TYPE.STUDENT, "studentInfoPopup.txtUnfollow")
                                                : getLanguageValue(PAGE_TYPE.STUDENT, "studentInfoPopup.txtFollow")}
                                    </button>
                                </>
                            )}
                        </div>

                        {/* <div className="text-center">
                            <a
                                href={getFanPageUrl(info)}
                                className="!text-[#CF3688] hover:underline text-sm"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {getLanguageValue(PAGE_TYPE.STUDENT, "studentInfoPopup.txtFanPageVisit")}
                            </a>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default StudentInfoPopupComponent;