import LoadingComponent from 'commons/loading/Loading.component';
import { PAGE_TYPE } from 'enums';
import { getLanguageValue } from 'locales/i18n';
import StudentItem from 'commons/student/StudentItem.component';
import { Link } from 'react-router-dom';
import ContactContainer from '../contact/Contact.container';
import { getImageUrl } from 'helpers/Image.helper';
import CountUp from 'react-countup';
import React, { useEffect, useRef } from "react";

const Home = ({ ...props }) => {
    const { popularStudentList, userAgencyCount, userStudentCount, messageRequestCount,
        appAgencyLogoList, isOnLoadingCountData } = props;
    const sectionRef = useRef<HTMLDivElement>(null);

    return (
        <main className="!w-full !max-w-full mx-auto !p-0 bg-[#272b36]">

            {/* WANNABES 소개 섹션 */}
            <section className="flex flex-col items-center pt-20 pb-10 sm:pt-50 sm:pb-30 lg:pt-60 lg:pb-40 z-10 min-h-[100dvh]">
                <div className="relative w-[90vw] max-w-lg mx-auto py-8 px-4 sm:py-12 sm:px-6 md:py-16 md:px-8 lg:py-20 lg:px-10 text-center bg-[#FFD700] rounded-lg shadow-lg">
                    <div className="flex justify-center items-center mb-4">
                        <img alt="Wannabes word mark logo" src="/imgs/common/logo/img-logo/logo-t2-500.png" className="w-1/2 transform transition-transform duration-500 hover:scale-110" />
                    </div>
                    <h4 className="text-xl sm:text-2xl lg:text-3xl text-black font-extrabold mb-4 leading-tight">
                        {getLanguageValue(PAGE_TYPE.COMMON, "home.txtHeaderMessage")}
                    </h4>

                    <div className="flex flex-col md:flex-row items-center w-full py-4 sm:py-6 md:py-8">
                        <div className="flex flex-row justify-around w-full max-w-md mx-auto gap-4 sm:gap-6 md:gap-8">
                            <div className="flex flex-col items-center">
                                <p className="text-gray-600 text-sm mb-4">
                                    {getLanguageValue(PAGE_TYPE.COMMON, "home.txtHeaderLabelCastingManager")}
                                </p>

                                {isOnLoadingCountData ?
                                    <p className="text-4xl sm:text-6xl font-extrabold text-[#3E4352]">
                                        ...
                                    </p>
                                    :
                                    <p className="text-4xl sm:text-6xl font-extrabold bg-gradient-to-r from-[#FF7A7A] to-[#695EE6] text-transparent bg-clip-text">
                                        <CountUp start={0} end={Math.round(userAgencyCount / 10) * 10} duration={2.75} />+
                                    </p>
                                }
                            </div>
                            <div className="flex flex-col items-center">
                                <p className="text-gray-600 text-sm mb-4">
                                    {getLanguageValue(PAGE_TYPE.COMMON, "home.txtHeaderLabelCastingChat")}
                                </p>
                                {isOnLoadingCountData ?
                                    <p className="text-4xl sm:text-6xl font-extrabold text-[#3E4352]">
                                        ...
                                    </p>
                                    :
                                    <p className="text-4xl sm:text-6xl font-extrabold bg-gradient-to-r from-[#FF7A7A] to-[#695EE6] text-transparent bg-clip-text">
                                        <CountUp start={0} end={Math.round(messageRequestCount / 10) * 10} duration={2.75} />+
                                    </p>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 mb-8 sm:mt-10 sm:mb-10 md:mt-12 md:mb-12 lg:mt-14 lg:mb-14 animate-bounce-slow">
                        <Link to="/signup"
                            className="relative inline-block font-extrabold bg-[#3E4352] !text-[#FFD700] px-10 py-5 rounded-full tracking-[0.2em]
                                hover:scale-150 transition-transform duration-300">
                            {getLanguageValue(PAGE_TYPE.COMMON, "home.btnStart")}
                        </Link>
                    </div>

                    <div className="animate-bounce flex justify-center  ">
                        <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#fff" />
                            <path d="M12 16L8 12H16L12 16Z" fill="#272b36" />
                        </svg>
                    </div>
                </div>
            </section>

            {/* 프로필 생성 섹션 */}
            <section ref={sectionRef} className="flex items-center mb-0 sm:mb-0 lg:mb-0 bg-gradient-to-br from-blue-100 via-blue-50 to-purple-100 py-16 sm:py-20 lg:py-24" style={{ minHeight: '600px' }}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                        <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8 flex flex-col items-center md:items-start">
                            <h2 className="text-4xl sm:text-5xl font-extrabold text-blue-900 text-center md:text-left mb-8">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection1Title")}
                            </h2>
                            <p className="text-lg sm:text-xl text-blue-900 text-center md:text-left mb-16">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection1Desc")}
                            </p>
                            <Link to="/signup"
                                className="relative inline-block 
                                bg-[#FFD700] text-black px-10 py-5 rounded-full font-extrabold
                                hover:bg-[#FFD700] transition-all duration-300 
                                hover:scale-105 shadow-lg 
                                hover:shadow-black/50
                                after:content-[''] after:absolute after:inset-0 
                                after:rounded-full after:border-2 after:border-black 
                                after:scale-110 after:opacity-0 
                                hover:after:scale-100 hover:after:opacity-100 
                                after:transition-all after:duration-300">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.btnCreateProfile")}
                            </Link>
                        </div>
                        <div className="md:w-1/2">
                            <div className="bg-white rounded-xl shadow-lg p-6 relative animate-card-tilt w-full max-w-xs mx-auto">
                                {/* 프로필 카드 헤더 */}
                                <div className="flex flex-col items-center mb-4">
                                    <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-gray-200 mb-3">
                                        <img
                                            src="/imgs/v2/boy-1.jpg"
                                            alt="Yuhyun 프로필"
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <h3 className="text-xl font-bold">Yuhyun</h3>
                                    <p className="text-gray-600 text-sm">한국</p>
                                    <p className="text-gray-500 text-xs mt-1">2007년생</p>
                                </div>

                                {/* 특기 */}
                                <div className="mb-4">
                                    <p className="text-sm text-gray-700 font-medium mb-2">특기</p>
                                    <div className="flex flex-wrap gap-2">
                                        <span className="bg-gray-100 text-gray-800 px-2 py-1 rounded-full text-xs">랩</span>
                                        <span className="bg-gray-100 text-gray-800 px-2 py-1 rounded-full text-xs">보컬</span>
                                        <span className="bg-gray-100 text-gray-800 px-2 py-1 rounded-full text-xs">댄스</span>
                                    </div>
                                </div>

                                {/* 추가 사진들 */}
                                <div className="grid grid-cols-3 gap-2">
                                    <img
                                        src="/imgs/v2/boy-2.jpg"
                                        alt="Yuhyun 추가 사진"
                                        className="rounded-lg h-20 w-full object-cover"
                                    />
                                    <img
                                        src="/imgs/v2/boy-3.jpg"
                                        alt="Yuhyun 추가 사진"
                                        className="rounded-lg h-20 w-full object-cover"
                                    />
                                    <img
                                        src="/imgs/v2/boy-4.jpg"
                                        alt="Yuhyun 추가 사진"
                                        className="rounded-lg h-20 w-full object-cover"
                                    />
                                </div>
                                <style>
                                    {`
                                @keyframes cardTilt {
                                    0% { transform: perspective(1000px) rotateY(0deg); }
                                    25% { transform: perspective(1000px) rotateY(15deg); }
                                    75% { transform: perspective(1000px) rotateY(-15deg); }
                                    100% { transform: perspective(1000px) rotateY(0deg); }
                                }
                                .animate-card-tilt {
                                    animation: cardTilt 6s ease-in-out infinite;
                                    transform-style: preserve-3d;
                                }
                                `}
                                </style>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 캐스팅 매니저 프로필 확인 섹션 */}
            <section className="flex items-center mb-0 sm:mb-0 lg:mb-0 bg-gradient-to-r from-emerald-100 via-emerald-50 to-teal-100 py-16 sm:py-20 lg:py-24" style={{ minHeight: '600px' }}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                    <div className="text-center mb-8 sm:mb-12">
                        <h2 className="text-4xl sm:text-5xl font-extrabold text-teal-900 mb-8">
                            {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection2Title")}
                        </h2>
                        <p className="text-lg sm:text-xl text-teal-900 max-w-2xl mx-auto mb-8">
                            {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection2Desc")}
                        </p>
                    </div>

                    <div className="grid grid-cols-1 gap-8 max-w-[600px] mx-auto">
                        {/* 신규 지망생 예시 */}
                        <div className="bg-white rounded-lg shadow-md overflow-hidden border border-green-200 relative">
                            <div className="p-6">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <img
                                            src="/imgs/v2/boy-1.jpg"
                                            alt="Yuhyun"
                                            className="w-16 h-16 rounded-full object-cover mr-4"
                                        />
                                        <div>
                                            <h3 className="font-bold text-lg">Yuhyun</h3>
                                            <p className="text-gray-600 text-sm">1분전 업데이트</p>
                                        </div>
                                    </div>
                                    <div className="animate-pulse-dot w-4 h-4 rounded-full bg-[#CF3688]"></div>
                                </div>
                            </div>
                        </div>

                        {/* 업데이트된 지망생 예시 */}
                        <div className="bg-white rounded-lg shadow-md overflow-hidden border border-blue-200 relative mb-8">
                            <div className="p-6">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <img
                                            src="/imgs/v2/girl-1.jpg"
                                            alt="업데이트된 지망생"
                                            className="w-16 h-16 rounded-full object-cover mr-4"
                                        />
                                        <div>
                                            <h3 className="font-bold text-lg">Angela</h3>
                                            <p className="text-gray-600 text-sm">신규 가입</p>
                                        </div>
                                    </div>
                                    <div className="animate-pulse-dot w-4 h-4 rounded-full bg-[#CF3688]"></div>
                                </div>
                            </div>
                        </div>
                        <style>
                            {`
                                @keyframes pulseDot {
                                    0% { opacity: 1; }
                                    50% { opacity: 0.4; }
                                    100% { opacity: 1; }
                                }
                                .animate-pulse-dot {
                                    animation: pulseDot 1.5s ease-in-out infinite;
                                }
                                `}
                        </style>
                    </div>

                    <div className="mt-8 text-center">
                        <Link to="/signup"
                            className="relative inline-block 
                                bg-[#FFD700] text-black px-10 py-5 rounded-full font-extrabold
                                hover:bg-[#FFD700] transition-all duration-300 
                                hover:scale-105 shadow-lg 
                                hover:shadow-black/50
                                after:content-[''] after:absolute after:inset-0 
                                after:rounded-full after:border-2 after:border-black 
                                after:scale-110 after:opacity-0 
                                hover:after:scale-100 hover:after:opacity-100 
                                after:transition-all after:duration-300">
                            {getLanguageValue(PAGE_TYPE.COMMON, "home.btnCreateProfile")}
                        </Link>
                    </div>
                </div>
            </section>

            {/* 프로필 즐겨찾기 섹션 */}
            <section className="flex items-center mb-0 sm:mb-0 lg:mb-0 bg-gradient-to-br from-amber-100 via-amber-50 to-orange-100 py-16 sm:py-20 lg:py-24" style={{ minHeight: '600px' }}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                        {/* 예시 영역 */}
                        <div className="md:w-1/2  mb-8 md:mb-0 md:pr-8">
                            {/* 예시 카드 - Yuhyun 프로필 */}
                            <div className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 relative max-w-xs mx-auto">
                                <div className="relative">
                                    <img
                                        src="/imgs/v2/boy-1.jpg"
                                        alt="Yuhyun 프로필"
                                        className="w-full h-64 object-cover"
                                    />
                                    <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-4">
                                        <h3 className="text-white font-bold text-lg">Yuhyun</h3>
                                    </div>
                                </div>
                                <div className="p-4 relative">
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="text-sm text-gray-600">2007년생 / 남자</span>
                                        <div>
                                            <div className="bookmark-animation-container">
                                                <img
                                                    src="/imgs/icons/icon-bookmark.svg"
                                                    alt="즐겨찾기"
                                                    className="w-6 h-6 bookmark-icon"
                                                />
                                            </div>
                                            <style>
                                                {`
                                                @keyframes bookmarkPulse {
                                                    0% { transform: scale(1); }
                                                    10% { transform: scale(2); }
                                                    20% { transform: scale(1); }
                                                    100% { transform: scale(1); }
                                                }
                                                .animate-bookmark-pulse {
                                                    animation: bookmarkPulse 2s infinite;
                                                }
                                                .bookmark-animation-container {
                                                    position: relative;
                                                    width: 24px;
                                                    height: 24px;
                                                }
                                                .bookmark-icon {
                                                    animation: bookmarkSequence 3s infinite;
                                                }
                                                @keyframes bookmarkSequence {
                                                    0% { content: url('/imgs/icons/icon-bookmark.svg'); }
                                                    5% { content: url('/imgs/icons/icon-bookmark.svg'); transform: scale(1); }
                                                    10% { content: url('/imgs/icons/icon-bookmark.svg'); transform: scale(2); }
                                                    20% { content: url('/imgs/icons/icon-bookmark-active.svg'); transform: scale(1); }
                                                    50% { content: url('/imgs/icons/icon-bookmark-active.svg'); }
                                                    100% { content: url('/imgs/icons/icon-bookmark-active.svg'); }
                                                }
                                            `}</style>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span className="bg-amber-100 text-black text-xs px-2 py-1 rounded-full">
                                            랩, 보컬, 댄스
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 설명 영역 */}
                        <div className="md:w-1/2 text-center md:text-left">
                            <h2 className="text-4xl sm:text-5xl font-extrabold text-orange-900 mb-8">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection3Title")}
                            </h2>
                            <p className="text-lg sm:text-xl text-orange-900 mb-16">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection3Desc")}
                            </p>
                            <div className="mt-8">
                                <Link to="/signup"
                                    className="relative inline-block bg-[#FFD700] text-black px-10 py-5 rounded-full font-extrabold
                                        hover:bg-[#FFD700] transition-all duration-300 
                                        hover:scale-105 shadow-lg 
                                        hover:shadow-black/50
                                        after:content-[''] after:absolute after:inset-0 
                                        after:rounded-full after:border-2 after:border-black 
                                        after:scale-110 after:opacity-0 
                                        hover:after:scale-100 hover:after:opacity-100 
                                        after:transition-all after:duration-300">
                                    {getLanguageValue(PAGE_TYPE.COMMON, "home.btnCreateProfile")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 프로필 업데이트 알림 섹션 */}
            <section className="flex items-center mb-0 sm:mb-0 lg:mb-0 bg-gradient-to-r from-indigo-100 via-indigo-50 to-blue-100 py-16 sm:py-20 lg:py-24" style={{ minHeight: '600px' }}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                    <div className="flex justify-center mb-8 sm:mb-12 overflow-hidden py-2">
                        {/* 노티 알람 */}
                        <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-4 animate-notification">
                            <div className="flex items-start">
                                {/* 로고 */}
                                <div className="mr-3">
                                    <div className="w-12 h-12 bg-white rounded-lg flex items-center justify-center">
                                        <img
                                            src="/imgs/v2/logo.png"
                                            alt="WANNABES 로고"
                                            className="w-10 h-10 object-contain"
                                        />
                                    </div>
                                </div>

                                <div className="flex-1">
                                    <div className="flex items-center mb-1">
                                        <span className="font-bold text-lg">WANNABES</span>
                                        <span className="ml-auto text-xs text-gray-500">지금</span>
                                    </div>
                                    <p className="text-sm text-gray-700">
                                        {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection4NotiMessage")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <style>{`
                            @keyframes notificationSlide {
                                0% { transform: translateY(-140%); }
                                20% { transform: translateY(0); }
                                80% { transform: translateY(0); }
                                100% { transform: translateY(-140%); }
                            }
                            .animate-notification {
                                animation: notificationSlide 7s ease-in-out infinite;
                                animation-delay: 4s;
                                transform: translateY(-140%);
                            }
                        `}</style>
                    </div>

                    <div className="text-center">
                        <h2 className="text-4xl sm:text-5xl font-extrabold text-blue-900 mb-8">
                            {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection4Title")}
                        </h2>
                        <p className="text-lg sm:text-xl text-blue-900 max-w-2xl mx-auto mb-16">
                            {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection4Desc")}
                        </p>

                        <div className="text-center">
                            <Link to="/signup"
                                className="relative inline-block 
                                bg-[#FFD700] text-black px-10 py-5 rounded-full font-extrabold
                                hover:bg-[#FFD700] transition-all duration-300 
                                hover:scale-105 shadow-lg 
                                hover:shadow-black/50
                                after:content-[''] after:absolute after:inset-0 
                                after:rounded-full after:border-2 after:border-black 
                                after:scale-110 after:opacity-0 
                                hover:after:scale-100 hover:after:opacity-100 
                                after:transition-all after:duration-300">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.btnCreateProfile")}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* 대화창 섹션 */}
            <section className="flex items-center mb-0 sm:mb-0 lg:mb-0 bg-gradient-to-br from-rose-100 via-rose-50 to-pink-100 py-16 sm:py-20 lg:py-24" style={{ minHeight: '600px' }}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                    <div className="flex flex-col lg:flex-row items-center lg:items-start gap-8 lg:gap-12">
                        {/* 설명 영역 */}
                        <div className="text-center lg:text-left lg:w-1/2">
                            <h2 className="text-4xl sm:text-5xl font-extrabold text-pink-900 mb-8">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection5Title")}
                            </h2>
                            <p className="text-lg sm:text-xl text-pink-900 max-w-2xl mx-auto lg:mx-0 mb-16">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection5Desc")}
                            </p>
                            <div className="text-center">
                                <Link to="/signup"
                                    className="relative inline-block bg-[#FFD700] text-black px-10 py-5 rounded-full font-extrabold
                                        hover:bg-[#FFD700] transition-all duration-300 
                                        hover:scale-105 shadow-lg 
                                        hover:shadow-black/50
                                        after:content-[''] after:absolute after:inset-0 
                                        after:rounded-full after:border-2 after:border-black 
                                        after:scale-110 after:opacity-0 
                                        hover:after:scale-100 hover:after:opacity-100 
                                        after:transition-all after:duration-300">
                                    {getLanguageValue(PAGE_TYPE.COMMON, "home.btnCreateProfile")}
                                </Link>
                            </div>
                        </div>

                        {/* 예시 영역 */}
                        <div className="lg:w-1/2 w-full">
                            <div className="bg-white rounded-xl shadow-lg overflow-hidden max-w-3xl mx-auto">
                                <div className="p-4">
                                    <div className="flex flex-col space-y-3">
                                        <div className="flex items-start">
                                            <div className="bg-blue-100 rounded-lg p-3 max-w-[80%] animate-bubble-1">
                                                <p className="text-sm">
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection5Chat1")}
                                                </p>
                                                <span className="text-xs text-gray-500 mt-1 block">10:23</span>
                                            </div>
                                        </div>

                                        <div className="flex items-start justify-end">
                                            <div className="bg-blue-500 text-white rounded-lg p-3 max-w-[80%] animate-bubble-2">
                                                <p className="text-sm">
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection5Chat2")}
                                                </p>
                                                <span className="text-xs text-blue-100 mt-1 block">10:25</span>
                                            </div>
                                        </div>

                                        <div className="flex items-start">
                                            <div className="bg-blue-100 rounded-lg p-3 max-w-[80%] animate-bubble-3">
                                                <p className="text-sm">
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "home.txtSection5Chat3")}
                                                </p>
                                                <span className="text-xs text-gray-500 mt-1 block">10:28</span>
                                            </div>
                                        </div>

                                        <style>{`
                                            @keyframes bubbleAppear {
                                                0% { transform: scale(0.9); opacity: 0; }
                                                100% { transform: scale(1); opacity: 1; }
                                            }
                                            @keyframes bubbleDisappear {
                                                0% { transform: scale(1); opacity: 1; }
                                                100% { transform: scale(0.9); opacity: 0; }
                                            }
                                            @keyframes bubbleIdle {
                                                0%, 100% { transform: scale(1); opacity: 1; }
                                            }
                                            .animate-bubble-1 {
                                                animation: bubbleAppear 0.5s ease-out forwards;
                                                animation-delay: 0s;
                                                opacity: 0;
                                                animation-iteration-count: 1;
                                                animation-fill-mode: forwards;
                                            }
                                            .animate-bubble-2 {
                                                animation: bubbleAppear 0.5s ease-out forwards;
                                                animation-delay: 2s;
                                                opacity: 0;
                                                animation-iteration-count: 1;
                                                animation-fill-mode: forwards;
                                            }
                                            .animate-bubble-3 {
                                                animation: bubbleAppear 0.5s ease-out forwards;
                                                animation-delay: 4s;
                                                opacity: 0;
                                                animation-iteration-count: 1;
                                                animation-fill-mode: forwards;
                                            }
                                        `}</style>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 인기 지망생 섹션 */}
            <section className="flex items-center mb-0 sm:mb-0 lg:mb-0 bg-gradient-to-r from-violet-100 via-violet-50 to-purple-100 py-16 sm:py-20 lg:py-24" style={{ minHeight: '600px' }}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                    <div className="mb-6 sm:mb-8 text-center">
                        <h2 className="text-4xl sm:text-5xl font-extrabold text-purple-900 mb-8">
                            {getLanguageValue(PAGE_TYPE.COMMON, "home.txtPopularTitle")}
                        </h2>
                        <p className="text-lg sm:text-xl text-purple-900 mx-auto lg:mx-0 mb-8">
                            {getLanguageValue(PAGE_TYPE.COMMON, "home.txtPopularDesc")}
                        </p>
                    </div>
                    {popularStudentList ? (
                        <div className="flex md:grid overflow-x-auto scrollbar-hide 
                            md:grid-cols-3 lg:grid-cols-6 
                            gap-3 sm:gap-4 lg:gap-6 
                            px-4 sm:px-2 
                            py-1 sm:py-2
                            mb-16">
                            {popularStudentList.map((item: any, index: number) => (
                                <StudentItem key={index} item={item} type={null} index={index} />
                            ))}
                        </div>
                    ) : (
                        <LoadingComponent />
                    )}
                    <div className="text-center">
                        <Link to="/signup"
                            className="relative inline-block bg-[#FFD700] text-black px-10 py-5 rounded-full font-extrabold
                                    hover:bg-[#FFD700] transition-all duration-300 
                                    hover:scale-105 shadow-lg 
                                    hover:shadow-black/50
                                    after:content-[''] after:absolute after:inset-0 
                                    after:rounded-full after:border-2 after:border-black 
                                    after:scale-110 after:opacity-0 
                                    hover:after:scale-100 hover:after:opacity-100 
                                    after:transition-all after:duration-300">
                            {getLanguageValue(PAGE_TYPE.COMMON, "home.btnCreateProfile")}
                        </Link>
                    </div>
                </div>
            </section>

            {/* FAQ 섹션 */}
            <section className="flex items-center mb-0 sm:mb-0 lg:mb-0 rounded-b-xl py-16 sm:py-20 lg:py-24" style={{ minHeight: '600px' }}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                    <div className="mb-6 sm:mb-8 text-center">
                        <h2 className="text-4xl sm:text-5xl font-extrabold text-white mb-1 sm:mb-2">
                            {getLanguageValue(PAGE_TYPE.COMMON, "home.txtFaqTitle")}
                        </h2>
                    </div>

                    <div className="space-y-4 sm:space-y-6">
                        <div className="bg-white/80 backdrop-blur-sm rounded-lg p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqQ1")}
                            </h3>
                            <p className="text-gray-700">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqA1")}
                            </p>
                        </div>

                        <div className="bg-white/80 backdrop-blur-sm rounded-lg p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqQ2")}
                            </h3>
                            <p className="text-gray-700">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqA2")}
                            </p>
                        </div>

                        <div className="bg-white/80 backdrop-blur-sm rounded-lg p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqQ3")}
                            </h3>
                            <p className="text-gray-700">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqA3")}
                            </p>
                        </div>

                        <div className="bg-white/80 backdrop-blur-sm rounded-lg p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqQ4")}
                            </h3>
                            <p className="text-gray-700">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqA4")}
                            </p>
                        </div>

                        <div className="bg-white/80 backdrop-blur-sm rounded-lg p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqQ5")}
                            </h3>
                            <p className="text-gray-700">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqA5")}
                            </p>
                            <ul className="list-disc pl-5 mt-2 text-gray-700">
                                <li>{getLanguageValue(PAGE_TYPE.COMMON, "home.faqA5-1")}</li>
                                <li>{getLanguageValue(PAGE_TYPE.COMMON, "home.faqA5-2")}</li>
                                <li>{getLanguageValue(PAGE_TYPE.COMMON, "home.faqA5-3")}</li>
                                <li>{getLanguageValue(PAGE_TYPE.COMMON, "home.faqA5-4")}</li>
                            </ul>
                        </div>

                        <div className="bg-white/80 backdrop-blur-sm rounded-lg p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqQ6")}
                            </h3>
                            <p className="text-gray-700">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqA6")}
                            </p>
                        </div>

                        <div className="bg-white/80 backdrop-blur-sm rounded-lg p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqQ7")}
                            </h3>
                            <p className="text-gray-700">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqA7")}
                            </p>
                            <ol className="list-decimal pl-5 mt-2 text-gray-700">
                                <li>{getLanguageValue(PAGE_TYPE.COMMON, "home.faqA7-1")}</li>
                                <li>{getLanguageValue(PAGE_TYPE.COMMON, "home.faqA7-2")}</li>
                                <li>{getLanguageValue(PAGE_TYPE.COMMON, "home.faqA7-3")}</li>
                                <li>{getLanguageValue(PAGE_TYPE.COMMON, "home.faqA7-4")}</li>
                            </ol>
                        </div>

                        <div className="bg-white/80 backdrop-blur-sm rounded-lg p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqQ8")}
                            </h3>
                            <p className="text-gray-700">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqA8")}
                            </p>
                        </div>

                        <div className="bg-white/80 backdrop-blur-sm rounded-lg p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqQ9")}
                            </h3>
                            <p className="text-gray-700">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqA9")}
                            </p>
                        </div>

                        <div className="bg-white/80 backdrop-blur-sm rounded-lg p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqQ11")}
                            </h3>
                            <p className="text-gray-700">
                                {getLanguageValue(PAGE_TYPE.COMMON, "home.faqA11")}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ContactContainer />
        </main>
    );
};

export default Home;