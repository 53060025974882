import React, { useEffect, useState } from "react";
import ReportPopup from "./ReportPopup.component";
import { getReportTypeCodeList } from "data/common.data";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { doReportComment } from "api/student/Community.api";
const ReportPopupForCommunityCommentContainer = (props: any) => {
    const { bbsId, commentId, isShowReportPopup, onClose } = props;
    const [reportTypeCodeList, setReportTypeCodeList] = useState<any[]>([]);
    const [reportTypeCode, setReportTypeCode] = useState<string>("");
    const [etcReason, setEtcReason] = useState<string>("");

    useEffect(() => {
        if (isShowReportPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isShowReportPopup]);

    useEffect(() => {
        console.log("bbsId", bbsId);
        const codeList = getReportTypeCodeList();
        console.log({ codeList });
        setReportTypeCodeList(codeList);
    }, [bbsId]);

    const handleReport = async () => {
        console.log("reportTypeCode", reportTypeCode);
        console.log("etcReason", etcReason);

        if (!reportTypeCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.warningChooseReportType"));
            return;
        }

        if (!window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.confirmationReport"))) {
            return
        }
        console.log("reportTypeCode", reportTypeCode);
        console.log("etcReason", etcReason);
        try {
            await doReportComment(bbsId, commentId, reportTypeCode, etcReason);
            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return <ReportPopup
        bbsId={bbsId}
        onClose={onClose}
        reportTypeCodeList={reportTypeCodeList}
        reportTypeCode={reportTypeCode}
        etcReason={etcReason}
        setReportTypeCode={setReportTypeCode}
        setEtcReason={setEtcReason}
        onReport={handleReport} />;
};

export default ReportPopupForCommunityCommentContainer;    