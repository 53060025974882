import React, { useEffect, useState } from "react";
import PostImagePopupComponent from "./PostImagePopup.component";

const PostImagePopupContainer = (props: any) => {
    const { onClose, imageInfoList, initialSlideIdx } = props;
    const [slideIdx, setSlideIdx] = useState(initialSlideIdx);

    return <PostImagePopupComponent
        imageInfoList={imageInfoList}
        slideIdx={slideIdx}
        setSlideIdx={setSlideIdx}
        onClose={onClose} />;
};

export default PostImagePopupContainer;
