import React, { useCallback, useEffect, useState } from "react";
import PostItemComponent from "./PostItem.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { deletePost, doTranslatePostText, toggleLike } from "api/student/Community.api";
import { doTranslate } from "api/Translate.api";
import CommentPopupContainer from "commons/popup/CommentPopupForCommunity.container";
import { usePopup } from "contexts/PopupContext";
import StudentInfoPopupContainer from "commons/student/popup/StudentInfoPopup.container";
import LikePopupForCommunityContainer from "commons/popup/LikePopupForCommunity.container";
import ReportPopupContainer from "commons/popup/ReportPopupForCommunity.container";
import PostImagePopupContainer from "pages/student/v2/popup/PostImagePopup.container";
    const PostItemContainer = (props: any) => {
    const { post, posts, setPosts } = props;
    const { showPopup, hidePopup } = usePopup();

    const [currentPost, setCurrentPost] = useState<any>(post);
    const [openMenuId, setOpenMenuId] = useState<number | null>(null);
    const [translatedComments, setTranslatedComments] = useState<{ [key: string]: string }>({});
    const [isShowReportPopup, setIsShowReportPopup] = useState<boolean>(false);
    const [isOnTranslating, setIsOnTranslating] = useState<boolean>(false);
    const [isShowLikePopup, setIsShowLikePopup] = useState<boolean>(false);

    const handleCommentPopup = () => {
        console.log("handleCommentPopup");
        showPopup(
            <CommentPopupContainer
                targetId={post.id}
            />
        );
    };

    useEffect(() => {
        console.log('postItemContainer : ', posts);
    }, [posts]);

    const handleEditPost = useCallback(async (bbsId: number) => {
        try {
            // const post = posts.find(post => post.id === bbsId);
            // if (post) {
            //     setContent(post.text);
            //     setImages(post.imageList.map(image => ({
            //         id: image.id,
            //         url: image.url,
            //         file: null
            //     })));
            // }
        } catch (error) {
            console.error('Failed to edit post:', error);
        }
    }, []);

    const handleDeletePost = useCallback(async (bbsId: number) => {
        try {
            if (window.confirm(getLanguageValue(PAGE_TYPE.STUDENT, "hubCommunity.txtDeleteConfirm"))) {
                const result = await deletePost(bbsId);
                if (result) {
                    let newPosts = posts.filter((post: any) => post.id !== bbsId);
                    setPosts(newPosts);
                }
            }
        } catch (error) {
            console.error('Failed to delete post:', error);
        }
    }, []);

    const handleToggleLike = useCallback(async (bbsId: number) => {
        try {
            const data = await toggleLike(bbsId);
            if (data) {
                setCurrentPost({ ...currentPost, isLiked: data.result, likeCount: data.likeCount });
            }
        } catch (error) {
            console.error('Failed to toggle like:', error);
        }
    }, []);

    const handleTranslateComment = useCallback(async (postId: number) => {
        if (isOnTranslating) {
            return;
        }

        setIsOnTranslating(true);

        try {
            const data = await doTranslatePostText(postId);
            if (data) {
                setTranslatedComments(prev => ({
                    ...prev,
                    [postId]: data.translatedText
                }));
            }
        } catch (error) {
            console.error('Failed to translate comment:', error);
            alert('번역에 실패했습니다. 다시 시도해주세요.');
        } finally {
            setIsOnTranslating(false);
        }
    }, []);

    const handleShowStudentInfoPopup = (studentId: number) => {
        showPopup(
            <StudentInfoPopupContainer
                studentId={studentId}
            />
        );
    };

    const handleShowLikePopup = () => {
        showPopup(
            <LikePopupForCommunityContainer
                bbsId={post.id}
            />
        );
    };

    const handleShowReportPopup = () => {
        setOpenMenuId(null);
        showPopup(
            <ReportPopupContainer
                targetId={post.id}
                targetType="community"
            />
        );
    };

    const handleShowImagePopup = (index: number) => {
        const imageInfoList = post.imageList.map((image: any) => image.imageInfo);
        console.log({ imageInfoList });

        showPopup(
            <PostImagePopupContainer
                imageInfoList={imageInfoList}
                initialSlideIdx={index}
            />
        );
    };

    return <PostItemComponent
        post={currentPost}
        setCurrentPost={setCurrentPost}
        openMenuId={openMenuId}
        translatedComments={translatedComments}
        onCommentPopup={handleCommentPopup}
        onDeletePost={handleDeletePost}
        onToggleLike={handleToggleLike}
        onTranslateComment={handleTranslateComment}
        setOpenMenuId={setOpenMenuId}
        isOnTranslating={isOnTranslating}
        onShowStudentInfoPopup={handleShowStudentInfoPopup}
        onShowLikePopup={handleShowLikePopup}
        onShowReportPopup={handleShowReportPopup}
        onShowImagePopup={handleShowImagePopup}
    />;
};

export default PostItemContainer;
