import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React from "react";
import Header from "./Header.component";

const StudentSignupPreComponent = (props: any) => {
  const {
    schoolId,
    schoolList,
    schoolInfo,
    selectedInfo,
    onSchoolChange,
    next,
    backUrl,
  } = props;

  return (
    <main className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <Header backUrl={backUrl} />
      <div className="max-w-4xl mx-auto">
        <h2 className="md:text-4xl text-2xl font-bold text-center text-gray-900 mb-8 md:mb-12">
          {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupPre.txtPreTitle")}
        </h2>
        {schoolId ? (
          <div className="bg-white rounded-lg shadow-lg p-4 md:p-8 mb-6 md:mb-8">
            <h3 className="text-xl md:text-2xl font-semibold text-center text-gray-800 mb-4 md:mb-6">
              {schoolInfo ? schoolInfo.schoolName : null}
            </h3>

            <div
              className="w-32 h-32 md:w-48 md:h-48 mx-auto mb-6 md:mb-8 bg-center bg-no-repeat bg-contain rounded-full border-2 border-gray-200"
              style={{
                backgroundImage: schoolInfo
                  ? schoolInfo.logoImageInfo
                    ? `url("${schoolInfo.logoImageInfo.url}?w=400&h=400")`
                    : `url("./imgs/common/logo/empty-school-logo-2.png")`
                  : null,
              }}></div>

            <div className="text-center">
              <p className="text-gray-600 text-base md:text-lg">
                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupPre.txtPreDesc")}
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="bg-white rounded-lg shadow-lg p-4 md:p-8 mb-6 md:mb-8">
              <h3 className="text-xl md:text-2xl font-semibold text-center text-gray-800 mb-4 md:mb-6">
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "studentSignupPre.txtPreChooseSchool"
                )}
              </h3>

              <div
                className="w-32 h-32 md:w-48 md:h-48 mx-auto mb-6 md:mb-8 bg-center bg-no-repeat bg-cover rounded-md border-2 border-gray-200"
                style={{
                  backgroundImage:
                    selectedInfo && selectedInfo.logoImageInfo
                      ? `url("${selectedInfo.logoImageInfo.url}?w=400")`
                      : `url("./imgs/common/logo/empty-school-logo-2.png")`,
                  backgroundSize: 'contain'
                }}>

              </div>

              <div className="max-w-md mx-auto">
                <select
                  onChange={(e) => onSchoolChange(e.target.value)}
                  className="w-full p-2 md:p-3 text-sm md:text-base border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-[#CF3688] focus:border-[#CF3688] mb-4"
                >
                  {!schoolList &&
                    <option value="">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignupPre.txtPreLoadingSchool")}</option>
                  }
                  {schoolList && (
                    <>
                      <option value="">
                        {getLanguageValue(
                          PAGE_TYPE.COMMON,
                          "studentSignupPre.txtPreNoSchool"
                        )}
                      </option>
                      {schoolList.map((item: any, idx: number) => (
                        <option key={idx} value={item.id}>
                          {item.schoolName}
                        </option>
                      ))}
                    </>
                  )}
                </select>
                <div className="text-center mb-4 md:mb-6">
                  <p className="text-gray-600 text-base md:text-lg leading-relaxed whitespace-pre-line bg-gray-50 p-4 rounded-lg border border-gray-200">
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "studentSignupPre.txtPreNoSchoolDesc"
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-8 flex flex-col sm:flex-row gap-4 sm:gap-6 justify-center">
              <button
                className="bg-[#CF3688] text-white py-2 md:py-3 px-6 md:px-8 text-sm md:text-base rounded-md hover:bg-[#CF3688] transition-all duration-200 transform hover:scale-105"
                onClick={next}
              >
                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignupPre.btnNext")}
              </button>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default StudentSignupPreComponent;
