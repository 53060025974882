import React, { useEffect, useState } from "react";
import StudentImagePopup from "./StudentImageSlidePopup.component";
import { increaseStudentPhotoViewCount } from "api/agency/v2/Student.api";

const StudentImageSlidePopupContainer = (props: any) => {
    const { profileImageInfo, userStudentImageList, initialSlideIdx, onClose } = props;
    const [isInit, setIsInit] = useState(false);
    const [slideIdx, setSlideIdx] = useState(-1);
    const [imageList, setImageList] = useState([]);

    useEffect(() => {
        console.log("agency/v2/popup/StudentImageSlidePopup.container:useEffect");
        console.log({ initialSlideIdx });
        console.log({ profileImageInfo });
        console.log({ userStudentImageList });

        const newImageList = [];
        if (profileImageInfo) {
            newImageList.push({ imageInfo: profileImageInfo });
        }
        if (userStudentImageList?.length > 0) {
            newImageList.push(...userStudentImageList);
        }
        setImageList(newImageList);
    }, []);

    useEffect(() => {
        console.log('imageList', imageList);
        setSlideIdx(initialSlideIdx);
        setIsInit(true);
    }, [imageList]);

    useEffect(() => {
        console.log('slideIdx', slideIdx);
        if (slideIdx >= 0) {
            increasePhotoViewCount();
        }
    }, [slideIdx]);

    const increasePhotoViewCount = async () => {
        console.log('increasePhotoViewCount');
        console.log({ slideIdx });
        console.log({ imageList });
        const imageInfo = imageList[slideIdx];
        console.log('imageInfo', imageInfo);
        if (!imageInfo) {
            return;
        } else if (!imageInfo?.id) {
            return;
        }

        try {
            const userStudentImageId = imageInfo?.id;
            await increaseStudentPhotoViewCount(userStudentImageId);
        } catch (error) {
            console.error('Failed to increase photo view count:', error);
        }
    }

    return <StudentImagePopup
        imageList={imageList}
        slideIdx={slideIdx}
        setSlideIdx={setSlideIdx}
        onClose={onClose}
        isInit={isInit}
    />;
};

export default StudentImageSlidePopupContainer;
