import * as BaseApi from "../../Base.api";

const BASE_URL = "/student/fan-page";

export const getFanPageSummary = async () => {
  const url = `${BASE_URL}/summary`;
  return await BaseApi.get(url);
};

export const getPostList = async (page: number, limit: number) => {
  const url = `${BASE_URL}/post`;
  return await BaseApi.get(url, { page, limit });
};

export const getPostInfo = async (id: number) => {
  const url = `${BASE_URL}/post/${id}`;
  return await BaseApi.get(url);
};

export const createPost = async (text: string, imageIds: string[]) => {
  const url = `${BASE_URL}/post`;
  return await BaseApi.post(url, { text, imageIds });
};

export const updatePost = async (id: number, text: string, existingPostImageIds: any[], newImageIds: any[]) => {
  const url = `${BASE_URL}/post/${id}`;
  return await BaseApi.put(url, { text, existingPostImageIds, newImageIds });
};


export const getCommentList = async (postId: number, page: number, limit: number) => {
  const url = `${BASE_URL}/post/${postId}/comment`;
  return await BaseApi.get(url, { page, limit });
};

export const createPostComment = async (postId: number, params: any) => {
  const url = `${BASE_URL}/post/${postId}/comment`;
  return await BaseApi.post(url, params)
    .then((result: any) => {
      if (result?.status === 200) {
        return result.data.data;
      }
      throw new Error(result.response.data.message);
    })
};

export const updatePostComment = async (postId: number, commentId: number, params: any) => {
  const url = `${BASE_URL}/post/${postId}/comment/${commentId}`;
  return await BaseApi.patch(url, params)
    .then((result: any) => {
      if (result?.status === 200) {
        return result.data.data;
      }
      throw new Error(result.response.data.message);
    })
};

export const deletePostComment = async (postId: number, commentId: number) => {
  const url = `${BASE_URL}/post/${postId}/comment/${commentId}`;
  return await BaseApi.remove(url)
    .then((result: any) => {
      if (result?.status === 200) {
        return result.data.data;
      }
      throw new Error(result.response.data.message);
    })
};

export const getPhotosList = async (page: number, limit: number) => {
  const url = `${BASE_URL}/photos`;
  return await BaseApi.get(url, { page, limit });
};

export const getPhotoInfo = async (photoId: string) => {
  const url = `${BASE_URL}/photos/${photoId}`;
  return await BaseApi.get(url);
};

export const createPhoto = async (imageId: string) => {
  const url = `${BASE_URL}/photos`;
  return await BaseApi.post(url, { imageId })
    .then((result: any) => {
      if (result?.status === 200) {
        return result.data.data;
      }
      throw new Error(result.response.data.message);
    })
};

export const deletePhoto = async (photoId: string) => {
  const url = `${BASE_URL}/photos/${photoId}`;
  return await BaseApi.remove(url)
    .then((result: any) => {
      if (result?.status === 200) {
        return result.data.data;
      }
      throw new Error(result.response.data.message);
    })
};

export const getQnAList = async (page: number, limit: number) => {
  const url = `${BASE_URL}/qna`;
  return await BaseApi.get(url, { page, limit });
};

export const createQuestion = async(question: string) => {
  const url = `${BASE_URL}/qna`;
  return await BaseApi.post(url, { question });
};

export const updateAnswer = async (id: number, answer: string) => {
  const url = `${BASE_URL}/qna/${id}/answer`;
  return await BaseApi.put(url, { answer });
};

export const deleteQnA = async (id: number) => {
  const url = `${BASE_URL}/qna/${id}`;
  return await BaseApi.remove(url);
};

export const getLikeListForFanPagePost = async (postId: number, page: number, limit: number) => {
  const url = `${BASE_URL}/post/${postId}/like`;
  return await BaseApi.get(url, { page, limit });
};