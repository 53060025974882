import React, { useCallback, useEffect, useRef, useState } from "react";
import ChatComponent from "./Chat.component";
import { useParams } from "react-router-dom";
import { getAgencyChannel, getChannelMessages } from "api/common/Chat.api";
import { uploadImage, uploadVideo, } from "api/common/Data.api";
import { WebSocketSubject } from "rxjs/webSocket";
import { useSelector } from "react-redux";
import { usePopup } from "contexts/PopupContext";
import ReportChatPopupContainer from "pages/agency/v2/popup/ReportChatPopup.container";
import StudentInfoPopupContainer from "pages/agency/v2/popup/StudentInfoPopup.container";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
const LIMIT = 30;
const ORDER = "desc";

const ChatContainer = (props: any) => {
    const { channelId } = useParams();
    const { info: userInfo } = useSelector((state: any) => state.user);
    const { showPopup } = usePopup();
    const chatRef = useRef(null);
    const fileInputRef = useRef(null);

    const [error, setError] = useState<any>(null);
    const [isInit, setIsInit] = useState(false);
    const [channelInfo, setChannelInfo] = useState<any>(null);

    const [myText, setMyText] = useState("");
    const [messages, setMessages] = useState([]);
    const prevMessagesRef = useRef<any[]>(messages);

    // 대화창의 상태 코드
    const [isOnLoading, setIsOnLoading] = useState(false);
    const [webSocket, setWebSocket] = useState<WebSocketSubject<any>>();
    const [socketStatus, setSocketStatus] = useState<string>("");

    const [isOnLoadPrev, setIsOnLoadPrev] = useState(false);
    const [hasPrev, setHasPrev] = useState(true);
    const [lastCreatedAt, setLastCreatedAt] = useState<string>("");
    const [isFileUploadAreaVisible, setIsFileUploadAreaVisible] = useState(false);

    const [isOpenTooltip, setIsOpenTooltip] = useState(false);
    const [isOnBottom, setIsOnBottom] = useState(false);
    const [newMessageTip, setNewMessageTip] = useState(null);

    // 메시지 수신 처리 함수
    const messageHandler = (message: any) => {
        console.log("messageHandler");
        console.log({ message });
        if (message?.messageType === 'close') {
            // setIsOnQuitChat(true);
            setChannelInfo({
                ...channelInfo,
                messageStatusCode: 'messageType:close',
            });
        } else if (message?.messageType !== "ping") {
            setMessages((prevMessages: any) => [...prevMessages, message]);
            setTimeout(() => {
                if (isOnBottom) {
                    scrollToBottom();
                } else {
                    setNewMessageTip("new Message");
                }
            }, 100);
        }
    }

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop } = event.currentTarget;
        if (scrollTop === 0) {
            loadMoreMessages();
        }
    };

    const scrollToBottom = () => {
        if (chatRef.current) {
            const lastMessage = chatRef.current.lastElementChild;

            setTimeout(() => {
                chatRef.current.scrollTop = lastMessage ? chatRef.current.scrollHeight + lastMessage.offsetHeight : chatRef.current.scrollHeight;
                setIsOnBottom(true);
                setNewMessageTip(null);
            }, 100);
        }
    };

    // 사용자가 직접 스크롤할 때만 스크롤 위치 감지
    const handleUserScroll = useCallback((event: Event) => {
        console.log('handleUserScroll');
        // 사용자 상호작용으로 인한 스크롤인지 확인
        if (event.isTrusted && chatRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatRef.current;
            const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 10;

            console.log("사용자 스크롤:", Math.abs(scrollHeight - scrollTop - clientHeight));

            // 스크롤이 바닥이 아니면 isOnBottom을 false로 설정
            if (!isAtBottom) {
                setIsOnBottom(false);
            } else {
                setIsOnBottom(true);
                setNewMessageTip(null);
            }
        } else if (!event.isTrusted && isOnBottom && chatRef.current) {
            // 사용자 상호작용이 아닌 스크롤이고 isOnBottom이 true인 경우 scrollToBottom 호출
            // scrollToBottom();
        }
    }, [isOnBottom]);

    // 스크롤 이벤트 리스너 등록 - 사용자 상호작용 플래그 추가
    useEffect(() => {
        const chatElement = chatRef.current;
        if (chatElement) {
            // wheel 이벤트와 touchmove 이벤트로 사용자 직접 스크롤만 감지
            chatElement.addEventListener('wheel', handleUserScroll, { passive: true });
            chatElement.addEventListener('touchmove', handleUserScroll, { passive: true });

            return () => {
                chatElement.removeEventListener('wheel', handleUserScroll);
                chatElement.removeEventListener('touchmove', handleUserScroll);
            };
        }
    }, [handleUserScroll]);

    const keepScrollPosition = (timeout = 0) => {
        const scrollElement = chatRef.current;
        const prevScrollHeight = scrollElement.scrollHeight;
        const prevScrollTop = scrollElement.scrollTop;

        setTimeout(() => {
            const newScrollHeight = scrollElement.scrollHeight;
            const scrollDiff = newScrollHeight - prevScrollHeight;
            scrollElement.scrollTop = prevScrollTop + scrollDiff;
        }, timeout);
    }

    const handleFileChange = async (e: any) => {
        console.log("handleFileChange");
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                sendMyImageMessage(file);
            } else if (file.type.startsWith('video/')) {
                sendMyVideoMessage(file);
            } else {
                console.log('지원하지 않는 파일 형식입니다');
                return;
            }
        }
    }

    const sendMyVideoMessage = async (file: File) => {
        console.log("sendMyVideoMessage");
        const videoData = await uploadVideo(file, false);
        console.log({ videoData });
        if (!videoData) {
            return;
        }
        const fileInfo = {
            id: videoData.id,
        }

        sendMyMessage(channelId, "video", "", fileInfo, null);
        setMyText("");
    }

    const sendMyImageMessage = async (file: File) => {
        console.log("sendMyImageMessage");
        const imageData = await uploadImage(file, false);
        console.log({ imageData });
        if (!imageData) {
            return;
        }
        const fileInfo = {
            id: imageData.id,
        }

        sendMyMessage(channelId, "image", "", fileInfo, null);
        setMyText("");
    }

    const sendMyTextMessage = (id: string, message: string) => {
        console.log("sendMyTextMessage");
        console.log({ id, message });
        sendMyMessage(id, "text", message, null, null);
        setMyText("");
        scrollToBottom();
    }

    const sendMyMessage = (id: string, messageType: string, message: string, fileInfo: any = null, instantInfo: any = null) => {
        console.log("sendMyMessage");
        console.log({ id, messageType, message, fileInfo });
        console.log({ webSocket });
        if (!webSocket?.closed) {
            let messageObj = {
                metaData: {
                    version: 1,
                },
                channelId: id,
                userId: userInfo.userAgencyId,
                userType: userInfo.accountTypeCode,
                messageType: messageType,
                message: message,
                fileInfo: fileInfo,
                instantInfo: instantInfo,
                createdAt: new Date().toISOString(),
            }

            webSocket.next(messageObj);
        }
    }

    const quitChannel = async (needConfirm = true) => {
        console.log("quitChannel");
        setIsOpenTooltip(false);

        if (needConfirm && !window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "chat.txtQuitConfirmation"))) {
            return;
        }

        sendMyMessage(channelId, "close", "", null, null);
    }

    const openProfilePopup = () => {
        console.log("openProfilePopup");
        showPopup(<StudentInfoPopupContainer
            userStudentId={channelInfo.userStudentId}
        />);
        setIsOpenTooltip(false);
    }

    const openReportPopup = () => {
        console.log("openReportPopup");
        showPopup(<ReportChatPopupContainer
            channelId={channelId}
        />);
        setIsOpenTooltip(false);
    }

    const fetchChannelInfo = async () => {
        console.log("pages/agency/v2/message/chat.container:fetchChannelInfo");
        const data = await getAgencyChannel(channelId);
        console.log({ data });

        if (!data) {
            setError(true);
        } else if (data?.requestInfo) {
            console.log("init");
            let newChannelInfo = data.requestInfo;
            setChannelInfo(newChannelInfo);
            setIsInit(true);

            if (newChannelInfo.messageStatusCode === 'messageType:close') {
                initMessages();
            }
        }
    }

    useEffect(() => {
        const init = async () => {
            await fetchChannelInfo();
        }
        init();
    }, []);

    useEffect(() => {
        console.log({ socketStatus });

        if (socketStatus === "reconnecting" || socketStatus === "connecting") {
            setIsOnLoading(true);
        } else {
            setIsOnLoading(false);
        }

        if (socketStatus === 'connected') {
            initMessages();
        }
    }, [socketStatus]);

    const initMessages = async () => {
        console.log("initMessages");
        setIsOnLoadPrev(true);
        try {
            const params = {
                limit: LIMIT,
                order: ORDER,
                createdAt: '',
            }
            const result = await getChannelMessages(channelId, params);
            if (result?.list) {
                if (result.list.length > 0) {
                    setLastCreatedAt(result.list[0].createdAt);
                    setMessages(result.list);
                    setTimeout(() => {
                        scrollToBottom();
                    }, 100);
                } else {
                    setHasPrev(false);
                }
            }
        } catch (error) {
            console.error("Error fetching channel messages:", error);
        } finally {
            setIsOnLoadPrev(false);
        }
    }

    const loadMoreMessages = async () => {
        console.log("loadMoreMessages");
        if (isOnLoadPrev || !hasPrev) {
            return;
        }
        setIsOnLoadPrev(true);
        try {
            const params = {
                limit: LIMIT,
                order: ORDER,
                createdAt: lastCreatedAt,
            }
            const result = await getChannelMessages(channelId, params);
            if (result?.list) {
                if (result.list.length > 0) {
                    setLastCreatedAt(result.list[0].createdAt);
                    addMessages(result.list);
                    keepScrollPosition();
                } else {
                    setHasPrev(false);
                }
            }
        } catch (error) {
            console.error("Error fetching channel messages:", error);
        } finally {
            setIsOnLoadPrev(false);
        }
    }

    const addMessages = useCallback((newMessages: any[]) => {
        setMessages((prev) => {
            const newUniqueMessages = newMessages.filter(msg => !prev.some(p => p.messageId === msg.messageId));
            const mergedMessages = [...newUniqueMessages, ...prev];
            prevMessagesRef.current = mergedMessages;
            return mergedMessages;
        });
    }, []);

    const onMessageAdded = () => {
        if (isOnBottom) {
            scrollToBottom();
        }
    }

    // const handleMessageLongPress = (message: any) => {
    //     console.log("Long press detected for message:", message);
    //     // 여기에 길게 눌렀을 때의 로직 추가
    //     // 예: 메시지 복사, 삭제, 신고 등의 옵션을 보여주는 모달 표시
    // };

    return <ChatComponent
        channelId={channelId}
        userInfo={userInfo}

        isInit={isInit}
        channelInfo={channelInfo}

        openProfilePopup={openProfilePopup}

        isError={error}

        chatRef={chatRef}
        messages={messages}
        quitChannel={quitChannel}
        openReportPopup={openReportPopup}

        isOnLoading={isOnLoading}
        fileInputRef={fileInputRef}
        handleFileChange={handleFileChange}
        handleScroll={handleScroll}

        sendMyTextMessage={sendMyTextMessage}
        setMyText={setMyText}
        myText={myText}

        isOnLoadPrev={isOnLoadPrev}
        hasPrev={hasPrev}

        messageHandler={messageHandler}

        setWebSocket={setWebSocket}

        setSocketStatus={setSocketStatus}
        socketStatus={socketStatus}

        isFileUploadAreaVisible={isFileUploadAreaVisible}
        setIsFileUploadAreaVisible={setIsFileUploadAreaVisible}

        isOpenTooltip={isOpenTooltip}
        setIsOpenTooltip={setIsOpenTooltip}

        isOnBottom={isOnBottom}

        scrollToBottom={scrollToBottom}
        onMessageAdded={onMessageAdded}
        newMessageTip={newMessageTip}
    />;
};

export default ChatContainer;