//* 학생관련 API
import * as BaseApi from "api/Base.api";

const BASE_URL = "/agency/v2/student";

//* 학생 홈 정보 조회
//  2025.03.12
//  희문
//* Params
//  userStudentId: 학생 아이디
export const fetchStudentHomeInfo = async (userStudentId: string) => {
    console.log("fetchStudentHomeInfo");
    console.log({ userStudentId });
    const url = `${BASE_URL}/${userStudentId}/home`;
    return await BaseApi.get(url);
};

//* 학생 기본정보 조회
//  2025.03.10
//  희문
//* Params
//  userStudentId: 학생 아이디
export const fetchStudentBasicInfo = async (userStudentId: string) => {
    console.log("fetchStudentBasicInfo");
    console.log({ userStudentId });
    const url = `${BASE_URL}/${userStudentId}/basic-info`;
    return await BaseApi.get(url);
};

//* 학생 사진첩 조회
//  2025.03.10
//  희문
//* Params
//  userStudentId: 학생 아이디
//  page: 페이지 번호
//  limit: 페이지 당 데이터 개수
export const fetchStudentPhotoList = async (userStudentId: string, page: number, limit: number) => {
    console.log("fetchStudentPhotoList");
    console.log({ userStudentId, page, limit });
    const url = `${BASE_URL}/info/${userStudentId}/photo`;
    return await BaseApi.get(url, { page, limit });
};

//* 기획사용 프로필 조회
//  2025.03.10
//  희문
//* Params
//  userStudentId: 학생 아이디
export const fetchStudentProfileForAgency = async (userStudentId: string) => {
    console.log("fetchStudentProfileForAgency");
    console.log({ userStudentId });
    const url = `${BASE_URL}/info/${userStudentId}/profile-for-agency`;
    return await BaseApi.get(url);
};

//* 학생 성장 정보 조회
//  2025.03.11
//  희문
//* Params
//  userStudentId: 학생 아이디
export const fetchStudentGrowthInfo = async (userStudentId: string) => {
    console.log("fetchStudentGrowthInfo");
    console.log({ userStudentId });
    const url = `${BASE_URL}/info/${userStudentId}/growth`;
    return await BaseApi.get(url);
};

//* 기획사용 정보의 프로필 사진 뷰 카운트 증가
//  2025.03.11
//  희문
//* Params
//  userStudentId: 학생 아이디
//  profileImageId: 프로필 사진 아이디
export const increaseStudentProfilePhotoViewCount = async (userStudentId: number, profileImageId: number) => {
    console.log("increaseStudentProfilePhotoViewCount");
    console.log({ userStudentId, profileImageId });
    const url = `${BASE_URL}/info/${userStudentId}/profile-for-agency/profile-image/${profileImageId}/view-count/increase`;
    return await BaseApi.patch(url, null);
};

//* 기획사용 정보의 자기소개 영상 뷰 카운트 증가
//  2025.03.11
//  희문
//* Params
//  userStudentId: 학생 아이디
//  profileIntroVideoId: 자기소개 영상 아이디
export const increaseStudentIntroVideoViewCount = async (userStudentId: number, profileIntroVideoId: number) => {
    console.log("increaseStudentIntroVideoViewCount");
    console.log({ userStudentId, profileIntroVideoId });
    const url = `${BASE_URL}/info/${userStudentId}/profile-for-agency/intro-video/${profileIntroVideoId}/view-count/increase`;
    return await BaseApi.patch(url, null);
};

//* 학생 프로필 사진 뷰 카운트 증가
//  2025.03.16
//  희문
//* Params
//  userStudentImageId: 프로필 사진 아이디
export const increaseStudentPhotoViewCount = async (userStudentImageId: number) => {
    console.log("increaseStudentPhotoViewCount");
    console.log({ userStudentImageId });
    const url = `${BASE_URL}/photo/${userStudentImageId}/view-count/increase`;
    return await BaseApi.patch(url, null);
};




//* 학생 업데이트 로그 조회
//  2025.03.11
