import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HomeComponent from "./Home.component";
import { fetchHomeInfo, getPopularStudentList } from "api/home/Home.api";

const HomeContainer = (props: any) => {
  const navigate = useNavigate();
  const [popularStudentList, setPopularStudentList] = useState(null);
  
  const [newStudentList, setNewStudentList] = useState(null);
  const [topFanCountStudentList, setTopFanCountStudentList] = useState(null);
  const [appAgencyLogoList, setAppAgencyLogoList] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState('');

  const [isOnLoadingCountData, setIsOnLoadingCountData] = useState(true);
  const [userAgencyCount, setUserAgencyCount] = useState(0);
  const [userStudentCount, setUserStudentCount] = useState(0);
  const [messageRequestCount, setMessageRequestCount] = useState(0);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    console.log('popularStudentList', popularStudentList);
  }, [popularStudentList]);

  const init = async () => {
    await loadHomeInfo();
    await loadPopularStudentList();
  };

  const loadHomeInfo = async () => {
    try {
      const data = await fetchHomeInfo() as any;
      if (data) {
        setUserAgencyCount(data?.userAgencyCount);
        setUserStudentCount(data?.userStudentCount);
        setMessageRequestCount(data?.messageRequestCount);
        setAppAgencyLogoList(data?.appAgencyLogoList);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsOnLoadingCountData(false);
    }
  };

  const loadPopularStudentList = async () => {
    const data = await getPopularStudentList();
    if (data.list) {
      const newList = [...data.list].sort(() => Math.random() - 0.5);
      setPopularStudentList(newList);
    }
  };

  // const loadTopFanCountStudentList = async () => {
  //   const data = await getTopFanCountStudentList();
  //   if (data.list) {
  //     setTopFanCountStudentList(data.list);
  //   }
  // };

  // const loadNewStudentList = async () => {
  //   const data = await getNewStudentList();
  //   if (data.list) {
  //     setNewStudentList(data.list);
  //   }
  // };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!searchKeyword || searchKeyword.trim() === '') {
      return;
    }
    const trimmedKeyword = searchKeyword.trim();
    if (!trimmedKeyword) {
      return;
    }
    navigate(`/search/${trimmedKeyword}`);
  };

  const handleChangeSearchKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();

    setSearchKeyword(value);
  };

  return (
    <HomeComponent
      {...props}
      popularStudentList={popularStudentList}
      newStudentList={newStudentList}
      topFanCountStudentList={topFanCountStudentList}
      handleSearch={handleSearch}
      handleChangeSearchKeyword={handleChangeSearchKeyword}
      searchKeyword={searchKeyword}

      isOnLoadingCountData={isOnLoadingCountData}
      messageRequestCount={messageRequestCount}
      userAgencyCount={userAgencyCount}
      userStudentCount={userStudentCount}
      appAgencyLogoList={appAgencyLogoList}
    />
  );
};

export default HomeContainer;
