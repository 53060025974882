import React from "react";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import { formatTimeAgo } from "helpers/Datetime.helper";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const IndexComponent = (props: any) => {
  const {
    isLoading,
    list,
    onClickCheckNotification,
    limit,
    page,
    setPage,
    hasMore
  } = props;

  return (
    <main className="bg-gray-50 min-h-screen">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
        <h2 className="text-3xl font-bold text-gray-900 mb-8">
          {getLanguageValue(PAGE_TYPE.STUDENT, "alarmHome.txtTitle")}
        </h2>
        {list &&
          (list.length === 0 ? (
            <div className="flex justify-center items-center h-64">
              <p className="text-gray-500">
                {getLanguageValue(PAGE_TYPE.STUDENT, "alarmHome.txtNoAlarm")}
              </p>
            </div>
          ) : (
            <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
              <div className="space-y-4 p-4">
                {list.map((item: any, index: number) => (
                  <div key={index} className="border-b border-gray-100 pb-4 last:border-b-0 last:pb-0">
                    <p
                      onClick={() => onClickCheckNotification(item)}
                      className={`text-sm break-words mb-2 ${item.isRead ? 'text-gray-900' : 'text-[#CF3688] font-medium'} ${(item.action || item.linkUrl) ? 'cursor-pointer hover:underline' : ''}`}
                    >
                      {getCurrentLangName(item.contents)}
                    </p>
                    <p className="text-xs text-gray-500">
                      {formatTimeAgo(item.createdAt)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        {isLoading && <LoadingComponent />}
      </div>
    </main>
  );
};

export default IndexComponent;
