import { getLanguageValue } from "locales/i18n";
import ReportChatPopupComponent from "./ReportChatPopup.component";
import { PAGE_TYPE } from "enums";
import { doReport } from "api/student/v2/chat.api";
import { useState, useEffect } from "react";
import { getMessageReportReasonCodeList } from "data/common.data";

const ReportChatPopupContainer = (props: any) => {
    const { channelId, onClose } = props;
    const [messageReportReasonCodeList, setMessageReportReasonCodeList] = useState<any[]>([]);
    const [messageReportReasonCode, setMessageReportReasonCode] = useState<string>("");
    const [messageReportReason, setMessageReportReason] = useState<string>("");
    const [isReporting, setIsReporting] = useState<boolean>(false);

    useEffect(() => {
        const codeList = getMessageReportReasonCodeList();
        setMessageReportReasonCodeList(codeList);
    }, []);

    const handleReport = async () => {
        if (!messageReportReasonCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.txtReportTypeSelect"));
            return;
        }

        if (!window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.confirmationReport"))) {
            return;
        }

        try {
            setIsReporting(true);
            await doReport(channelId, messageReportReasonCode, messageReportReason);
            onClose();
        } catch (error) {
            console.error(error);
        } finally {
            setIsReporting(false);
        }
    };

    return <ReportChatPopupComponent
        channelId={channelId}
        onClose={onClose}
        reportReasonCodeList={messageReportReasonCodeList}
        reportReasonCode={messageReportReasonCode}
        setReportReasonCode={setMessageReportReasonCode}
        etcReason={messageReportReason}
        setEtcReason={setMessageReportReason}
        isReporting={isReporting}
        onReport={handleReport}
    />;
};

export default ReportChatPopupContainer;