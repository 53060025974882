import React from "react";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";

const ContactComponent = (props: any) => {
  const { titleRef, contentRef, emailRef, save, isButtonDisabled } = props;

  return (
    <section className="bg-gradient-to-br from-pink-50 to-indigo-50 py-16 sm:py-20 lg:py-24 rounded-2xl shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 tracking-wide bg-clip-text text-transparent bg-gradient-to-r from-pink-600 to-purple-600">
            {getLanguageValue(PAGE_TYPE.COMMON, "contact.txtTitle")}
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            {getLanguageValue(PAGE_TYPE.COMMON, "contact.txtDesc")}
          </p>
        </div>

        <div className="max-w-3xl mx-auto">
          <div className="bg-white/90 backdrop-blur-md shadow-xl rounded-xl p-8 border border-gray-100">
            <div className="space-y-7">
              <div>
                <label htmlFor="title" className="block text-lg font-medium text-gray-800 mb-2">
                  {getLanguageValue(PAGE_TYPE.COMMON, "contact.txtTitleInputLabel")}
                </label>
                <input
                  id="title"
                  type="text"
                  placeholder={getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "contact.txtTitlePlaceholder"
                  )}
                  ref={titleRef}
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#CF3688] focus:border-transparent transition-all duration-200"
                />
              </div>

              <div>
                <label htmlFor="content" className="block text-lg font-medium text-gray-800 mb-2">
                  {getLanguageValue(PAGE_TYPE.COMMON, "contact.txtContentInputLabel")}
                </label>
                <textarea
                  id="content"
                  placeholder={getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "contact.txtContentPlaceholder"
                  )}
                  ref={contentRef}
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#CF3688] focus:border-transparent h-40 resize-none transition-all duration-200"
                ></textarea>
              </div>

              <div>
                <label htmlFor="email" className="block text-lg font-medium text-gray-800 mb-2">
                  {getLanguageValue(PAGE_TYPE.COMMON, "contact.txtEmailInputLabel")}
                </label>
                <input
                  id="email"
                  type="email"
                  placeholder={getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "contact.txtEmailPlaceholder"
                  )}
                  ref={emailRef}
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#CF3688] focus:border-transparent transition-all duration-200"
                />
              </div>

              <button
                className={`w-full py-4 px-6 rounded-lg text-white font-medium text-lg transition-all duration-300 ${
                  isButtonDisabled
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-[#CF3688] hover:bg-[#CF3688]/90 shadow-md hover:shadow-lg transform hover:-translate-y-1'
                }`}
                onClick={save}
                disabled={isButtonDisabled}
              >
                {getLanguageValue(PAGE_TYPE.COMMON, "contact.btnSave")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactComponent;
