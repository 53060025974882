import LoadingComponent from 'commons/loading/Loading.component';
import { formatTimeAgo } from 'helpers/Datetime.helper';
import { getImageUrl } from 'helpers/Image.helper';
import React from 'react';

const PhotoComponent = (props: any) => {
    const {
        list,
        isInit,
        isOnLoadMore,
        hasMore,
        page,
        handleShowPhotoPopup,
        errorMessage
    } = props;

    return (
        <>
            {isInit &&
                <>
                    {list?.length > 0 &&
                        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                                {list && list?.map((item: any, index: number) => (
                                    <div key={index} className="relative group overflow-hidden rounded-lg shadow-md hover:shadow-xl transition-all duration-300">
                                        <div
                                            onClick={() => handleShowPhotoPopup(index)}
                                            className="aspect-w-1 aspect-h-1">
                                            <img
                                                src={getImageUrl(item?.imageInfo, 500, 500)}
                                                alt={`item ${index + 1}`}
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    {!errorMessage && list?.length === 0 &&
                        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                                <div className="text-center text-gray-500">
                                    등록된 사진이 없습니다.
                                </div>
                            </div>
                        </div>
                    }
                    {errorMessage &&
                        <div className="w-full text-center py-8 text-red-500 bg-red-100 border border-red-400 rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z" />
                            </svg>
                            {errorMessage}
                        </div>
                    }
                </>
            }
            {isOnLoadMore && (
                <LoadingComponent />
            )}
        </>
    );
};

export default PhotoComponent;