import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";
import LoadingComponent from "commons/loading/Loading.component";

const UpdatePasswordComponent = (props: any) => {
    const { isInit, contact, contactTypeCode, onContactTypeChange, onContactChange, onCancelUpdate, onUpdateContact, isOnNetworking } = props;

    return (
        <main className="bg-gray-50 min-h-screen py-6">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center gap-4 mb-6">
                    <button onClick={onCancelUpdate} className="text-gray-600 hover:text-gray-900">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <h1 className="text-2xl font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "myUpdateContact.txtTitle")}
                    </h1>
                </div>

                {isInit ? (
                    <>
                        <div className="bg-white rounded-2xl p-6 shadow-sm">
                            <div className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "myUpdateContact.txtContactType")}
                                    </label>
                                    <select
                                        value={contactTypeCode}
                                        onChange={(evt) => onContactTypeChange(evt.target.value)}
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base"
                                    >
                                        <option value="" disabled selected>
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "myUpdateContact.txtContactTypeOptional")}
                                        </option>
                                        {commonData.getCommonCodeListByParent("contactType").map((item: any, idx: number) => (
                                            <option key={idx} value={item.code}>
                                                {getCurrentLangName(item.names)}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "myUpdateContact.txtContactDetail")}
                                    </label>
                                    <input
                                        type="text"
                                        value={contact}
                                        onChange={(evt) => onContactChange(evt.target.value)}
                                        placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "myUpdateContact.txtContactDetailPlaceholder")}
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 space-y-4">
                            <button
                                onClick={onCancelUpdate}
                                className="w-full py-3 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium rounded-lg transition-colors"
                            >
                                {getLanguageValue(PAGE_TYPE.STUDENT, "myUpdateContact.btnCancel")}
                            </button>
                            <button
                                onClick={onUpdateContact}
                                disabled={isOnNetworking}
                                className="w-full py-3 px-4 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors disabled:bg-gray-300"
                            >
                                {getLanguageValue(PAGE_TYPE.STUDENT, "myUpdateContact.btnUpdate")}
                            </button>
                        </div>
                    </>
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </main>
    );
};

export default UpdatePasswordComponent;
