
import React from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const IndexComponent = (props: any) => {
    const { info, isLoading } = props;

    return (
        <>
            <main className="bg-gray-50 min-h-screen">
                <>
                    {isLoading ? (
                        <LoadingComponent />
                    ) : (
                        <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6">
                            <h2 className="text-3xl font-bold text-gray-900 mb-8">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "castingSummary.txtTitle")}
                            </h2>

                            <div className="space-y-6">
                                {/* 내 캐스팅 정보 섹션 */}
                                <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4 sm:mb-6">
                                    <div className="p-4 sm:p-6 border-b border-gray-100">
                                        <h2 className="text-lg sm:text-xl font-bold text-gray-900">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "castingSummary.txtCastingSectionTitle")}
                                        </h2>
                                    </div>

                                    <div className="p-3 sm:p-4 md:p-6">
                                        <div className="space-y-4 sm:space-y-6">
                                            {/* 대화 섹션 */}
                                            <div>
                                                <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4">
                                                    {/* 총 대화 */}
                                                    <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                    hover:bg-gray-100 transition-all duration-200 
                                                    transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                        <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                            <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                            mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "castingSummary.txtCastingSectionTotalChat")}
                                                            </h3>
                                                            <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                                {info.totalChattingCount?.toLocaleString() || "N/A"}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* 대기중인 요청 */}
                                                    <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                    hover:bg-gray-100 transition-all duration-200 
                                                    transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                        <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                            <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                            mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "castingSummary.txtCastingSectionWaitingRequest")}
                                                            </h3>
                                                            <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                                {info.waitingChattingCount?.toLocaleString() || "N/A"}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* 진행중인 대화 */}
                                                    <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                    hover:bg-gray-100 transition-all duration-200 
                                                    transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                        <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                            <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                            mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "castingSummary.txtCastingSectionChatting")}
                                                            </h3>
                                                            <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                                {info.onChattingCount?.toLocaleString() || "N/A"}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* 종료된 대화 */}
                                                    <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                    hover:bg-gray-100 transition-all duration-200 
                                                    transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                        <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                            <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                            mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "castingSummary.txtCastingSectionClosedChat")}
                                                            </h3>
                                                            <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                                {info.closedChattingCount?.toLocaleString() || "N/A"}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* 취소된 요청 */}
                                                    <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                    hover:bg-gray-100 transition-all duration-200 
                                                    transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                        <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                            <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                            mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "castingSummary.txtCastingSectionCanceledRequest")}
                                                            </h3>
                                                            <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                                {info.canceledChattingCount?.toLocaleString() || "N/A"}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* 거절한 요청 */}
                                                    <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl 
                                                    hover:bg-gray-100 transition-all duration-200 
                                                    transform hover:-translate-y-1 shadow-sm hover:shadow">
                                                        <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                                            <h3 className="text-xs sm:text-sm font-medium text-gray-500 
                                                            mb-0 sm:mb-2 mr-2 sm:mr-0">
                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "castingSummary.txtCastingSectionDeniedRequest")}
                                                            </h3>
                                                            <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                                                {info.deniedChattingCount?.toLocaleString() || "N/A"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    )}
                </>
            </main>
        </>
    );
};

export default IndexComponent;