import TagManager from "react-gtm-module";
import { getCookieSetting } from "./Cookie.helper";

export const initGtm = () => {
    if (process.env.REACT_APP_ENV !== "local") {

        const cookieSetting = getCookieSetting();
        if (cookieSetting?.analytics) {
            enableGtm();
        } else {
            disableGtm();
        }
    }
}

export const sendEvent = (event: string, data: any) => {
    if (process.env.REACT_APP_ENV !== "local") {
        TagManager.dataLayer({
            dataLayer: {
                event,
                data
            }
        });
    }
}

export const sendPageView = (path: string) => {
    if (process.env.REACT_APP_ENV !== "local") {
        sendEvent("page_view", { path_path: path });
    }
}

export const enableGtm = () => {
    // enable GTM
    TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? "" });
}

export const disableGtm = () => {
    // disable GTM
    if (process.env.REACT_APP_ENV !== "local") {
        TagManager.dataLayer({
            dataLayer: {
                event: "consent_update",
                analytics_storage: "denied"
            }
        })
    }
}

