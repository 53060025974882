import React from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const IndexComponent = (props: any) => {
    const { isInit, info } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6">
                <h1 className="text-3xl font-bold text-gray-900 mb-6">
                    {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtTitle")}
                </h1>
                <p className="text-gray-500 mb-6 italic font-semibold text-lg bg-pink-50 p-4 rounded-lg border-2 border-pink-200 shadow-sm">
                    {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtDesc2")}
                </p>
                <p className="text-gray-500 mb-6">
                    {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtDesc")}
                </p>
            </div>
            {isInit && info && (
                <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                    {/* 팬 섹션 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
                        <div className="p-4 sm:p-6 border-b border-gray-100">
                            <h2 className="text-lg sm:text-xl font-bold text-gray-900">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtFanSectionTitle")}
                            </h2>
                        </div>
                        <div className="p-3 sm:p-4 md:p-6">
                            <div className="grid grid-cols-1 gap-3 sm:gap-4">
                                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl hover:bg-gray-100 transition-all duration-200 transform hover:-translate-y-1 shadow-sm hover:shadow">
                                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                        <h3 className="text-xs sm:text-sm font-medium text-gray-500 mb-0 sm:mb-2 mr-2 sm:mr-0">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtFanSectionTotalCount")}
                                        </h3>
                                        <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                            {info.fanCount?.toLocaleString() || "N/A"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* 사진 섹션 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
                        <div className="p-4 sm:p-6 border-b border-gray-100">
                            <h2 className="text-lg sm:text-xl font-bold text-gray-900">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtPhotosSectionTitle")}
                            </h2>
                        </div>
                        <div className="p-3 sm:p-4 md:p-6">
                            <div className="grid grid-cols-1 gap-3 sm:gap-4">
                                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl hover:bg-gray-100 transition-all duration-200 transform hover:-translate-y-1 shadow-sm hover:shadow">
                                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                        <h3 className="text-xs sm:text-sm font-medium text-gray-500 mb-0 sm:mb-2 mr-2 sm:mr-0">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtPhotosSectionTotalCount")}
                                        </h3>
                                        <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                            {info.photoCount?.toLocaleString() || "N/A"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Q&A 섹션 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
                        <div className="p-4 sm:p-6 border-b border-gray-100">
                            <h2 className="text-lg sm:text-xl font-bold text-gray-900">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtQnaSectionTitle")}
                            </h2>
                        </div>
                        <div className="p-3 sm:p-4 md:p-6">
                            <div className="grid grid-cols-2 gap-3 sm:gap-4">
                                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl hover:bg-gray-100 transition-all duration-200 transform hover:-translate-y-1 shadow-sm hover:shadow">
                                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                        <h3 className="text-xs sm:text-sm font-medium text-gray-500 mb-0 sm:mb-2 mr-2 sm:mr-0">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtQnaSectionTotalCount")}
                                        </h3>
                                        <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                            {info.qnaCount?.toLocaleString() || "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl hover:bg-gray-100 transition-all duration-200 transform hover:-translate-y-1 shadow-sm hover:shadow">
                                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                        <h3 className="text-xs sm:text-sm font-medium text-gray-500 mb-0 sm:mb-2 mr-2 sm:mr-0">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtQnaSectionNoAnswerCount")}
                                        </h3>
                                        <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                            {info.unansweredCount?.toLocaleString() || "N/A"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* 포스팅 섹션 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                        <div className="p-4 sm:p-6 border-b border-gray-100">
                            <h2 className="text-lg sm:text-xl font-bold text-gray-900">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtPostSectionTitle")}
                            </h2>
                        </div>
                        <div className="p-3 sm:p-4 md:p-6">
                            <div className="grid grid-cols-1 gap-3 sm:gap-4">
                                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl hover:bg-gray-100 transition-all duration-200 transform hover:-translate-y-1 shadow-sm hover:shadow">
                                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                                        <h3 className="text-xs sm:text-sm font-medium text-gray-500 mb-0 sm:mb-2 mr-2 sm:mr-0">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "fanPageSummary.txtPostSectionTotalCount")}
                                        </h3>
                                        <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                                            {info.postCount?.toLocaleString() || "N/A"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
            {!isInit && (
                <div className="p-3 sm:p-4 md:p-6 space-y-6 sm:space-y-8">
                    <LoadingComponent />
                </div>
            )}
        </main>
    );
};

export default IndexComponent;