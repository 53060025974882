import React from "react";
import { Link, useLocation } from "react-router-dom";

const FooterV2Component = (props: any) => {
    const { termList } = props;
    return (
        <footer className="bg-gray-800 text-white py-12">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between gap-8">
                    {/* 앱스토어 다운로드 */}
                    <div className="w-full md:w-1/4 mb-6 md:mb-0">
                        <div className="mb-4">
                            <p className="text-lg font-semibold">Download</p>
                        </div>
                        <ul className="flex space-x-6">
                            <li>
                                <a href="itms-apps://itunes.apple.com/app/6677023178" target="_blank">
                                    <img className="w-auto h-8 object-contain" alt="apple app store" src="./imgs/icons/icon-app-store.png" />
                                </a>
                            </li>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.theethru.wannabes" target="_blank">
                                    <img className="w-auto h-8 object-contain" alt="google play store" src="./imgs/icons/icon-google-play.png" />
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* SNS */}
                    <div className="w-full md:w-1/4 mb-6 md:mb-0">
                        <div className="mb-4">
                            <p className="text-lg font-semibold">Follow Us</p>
                        </div>
                        <ul className="flex space-x-6">
                            <li>
                                <a target="_blank" href="https://instagram.com/wannabes.app">
                                    <img className="w-8 h-8" alt="instagram icon" src="./imgs/icons/icon-instagram-bw.png" />
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/profile.php?id=61566755867822">
                                    <img className="w-8 h-8" alt="facebook icon" src="./imgs/icons/icon-facebook-bw.png" />
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://youtube.com/@wannabes-app">
                                    <img className="w-8 h-8" alt="youtube icon" src="./imgs/icons/icon-youtube-bw.png" />
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* 회사 */}
                    <div className="w-full md:w-1/4 mb-6 md:mb-0">
                        <div className="mb-4">
                            <p className="text-lg font-semibold">Company</p>
                        </div>
                        <ul>
                            <li>
                                <a target="_blank" href="https://www.theethru.com" className="!text-white hover:opacity-80">
                                    Homepage
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between mt-12">
                    <div className="w-full">
                        {/* 약관 */}
                        <div className="mb-4">
                            <p className="text-lg font-semibold">Terms</p>
                        </div>
                        <ul className="flex space-x-4">
                            {termList
                                ? termList.map((item: any, index: number) => (
                                    <li key={`term-${index}`}>
                                        <Link to={`/term/common/${item?.id}`} className="!text-white hover:opacity-80">{item?.title}</Link>
                                    </li>
                                ))
                                : null}
                        </ul>
                    </div>
                </div>
                
                {/* 로고 및 카피라이트 */}
                <div className="w-full mt-12 flex flex-col md:flex-row md:justify-between items-end md:items-center">
                    <div className="mb-4 md:mb-0">
                        <img alt="Wannabes word mark logo" src="./imgs/common/footer-logo.png" className="w-48" />
                    </div>
                    <p className="text-sm">&copy; 2025 theethru. All rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};
export default FooterV2Component;
