import React from "react";

const FullPageLoadingComponent = (props: any) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-75">
            <div className="flex flex-col items-center gap-2">
                <div className="animate-spin h-8 w-8">
                    <img src="/imgs/icons/icon-star-fill.svg" alt="Loading..."
                        className="w-8 h-8" />
                </div>
            </div>
        </div>
    );
};

export default FullPageLoadingComponent;
