//* 공개 프로필 관련 API
// 2025.03.08
// 희문

import * as BaseApi from "api/Base.api";
const BASE_URL = "/student/v2/public-profile";

//* 공개 프로필 홈 화면 조회
// 2025.03.08
// 희문
// 공개 프로필 홈 화면 조회 시 사용
export const getHomeInfo = async () => {
  const url = `${BASE_URL}/home`;
  return await BaseApi.get(url);
};

//* 공개프로필 활성화 여부
// 2025.02.13
// 희문
// 공개 프로필 활성화 여부 수정 시 사용
export const updateIsActiveOfPublicProfile = async (params: any) => {
  const url = `${BASE_URL}/info/is-active-of-public-profile`;
  return await BaseApi.patch(url, params);
};

//* 필수 정보 요청
// 2025.03.08
// 희문
// 필수 정보 요청 시 사용
export const getEssentialInfo = async () => {
  const url = `${BASE_URL}/info/essential`;
  return await BaseApi.get(url);
};

//* 필수 정보 수정
// 2025.03.08
// 희문
// 필수 정보 수정 시 사용
export const updateEssentialInfo = async (params: any) => {
  const url = `${BASE_URL}/info/essential`;
  return await BaseApi.put(url, params);
};

//* 신체정보 관련

// 특정 정보 공개 여부 수정
// 2025.02.13
// 희문
// 공개 프로필 화면에서 특정 정보 공개 여부 수정 시 사용
export const updateBodyInfoIsPublic = async (type: string, isPublic: boolean) => {
  const url = `${BASE_URL}/info/body-info/${type}/is-public`;
  return await BaseApi.patch(url, { isPublic });
};

// 특정 신체 정보 조회
// 2025.02.13
// 희문 
// 공개 프로필 화면에서 특정 신체 정보 조회 시 사용
export const getBodyInfo = async (type: string) => {
  const url = `${BASE_URL}/info/body-info/${type}`;
  return await BaseApi.get(url);
};

// 특정 신체정보 등록
// 2025.02.13
// 공개 프로필 화면에서 특정 신체 정보 등록 시 사용
export const createBodyInfo = async (type: string, value: number) => {
  const url = `${BASE_URL}/info/body-info/${type}`;
  return await BaseApi.post(url, { value });
};

// 특정 신체 정보 수정
// 2025.02.13
// 희문 
// 공개 프로필 화면에서 특정 신체 정보 수정 시 사용
export const updateBodyInfo = async (type: string, id: number, value: number) => {
  const url = `${BASE_URL}/info/body-info/${type}/history/${id}`;
  return await BaseApi.put(url, { value });
};

// 특정 신체 정보 삭제
// 2025.02.13
// 희문 
// 공개 프로필 화면에서 특정 신체 정보 삭제 시 사용
export const deleteBodyInfo = async (type: string, id: number) => {
  const url = `${BASE_URL}/info/body-info/${type}/history/${id}`;
  return await BaseApi.remove(url);
};

//* 추가정보 관련

//* 추가 정보 요청
// 2025.03.08
// 희문
// 추가 정보 요청 시 사용
export const getAdditionalInfo = async () => {
  const url = `${BASE_URL}/info/additional`;
  return await BaseApi.get(url);
};

//* 추가 정보 수정
// 2025.03.08
// 희문
// 추가 정보 수정 시 사용
export const updateAdditionalInfo = async (params: any) => {
  const url = `${BASE_URL}/info/additional`;
  return await BaseApi.put(url, params);
};


