import React from "react";
import LayoutComponent from "./Layout.component";
import { useLocation } from "react-router-dom";

const STANDALONE_MAIN_PATH = ["/chat", "/my", "/student"];

const LayoutContainer = () => {
  const location = useLocation();
  const needStandaloneMain = STANDALONE_MAIN_PATH.some(path => location.pathname.includes(path));

  return <LayoutComponent needStandaloneMain={needStandaloneMain} />;
};

export default LayoutContainer;