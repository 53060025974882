import axios from "axios";
import { getAccessToken } from "helpers/Auth.helper";

const DATA_API_URL = process.env.REACT_APP_DATA_URL;

const FILE_BASE_URL = "/file";
const IMAGE_BASE_URL = "/image";
const VIDEO_BASE_URL = "/video";

const getAxios = () => {
    const headers: any = {
        accesskey: process.env.REACT_APP_DATA_ACCESS_KEY,
        secretkey: process.env.REACT_APP_DATA_SECRET_KEY,
    };

    const accessToken = getAccessToken();
    if (accessToken) {
        headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return axios.create({ headers });
};

// 공통 파일 업로드
export const uploadFile = async (file: File, isPublic = true) => {
    console.log("Data.service:uploadFile");
    const params = {
        isPublic,
        name: file.name,
        size: file.size,
    };

    return await upload(params, file, false, false);
};

// 공통 이미지 업로드
export const uploadImage = async (file: File, isPublic = true) => {
    console.log("Data.service:uploadImage");
    const params: any = await getImageFileInfo(file);

    params["isPublic"] = isPublic;

    return await upload(params, file, true, false);
};

// 공통 비디오 업로드
export const uploadVideo = async (file: File, isPublic = true) => {
    console.log("Data.service:uploadVideo");
    const params: any = await getVideoFileInfo(file);

    params["isPublic"] = isPublic;

    return await upload(params, file, false, true);
};

const upload = async (params: any, file: File, isImage: boolean, isVideo: boolean) => {
    console.log("Data.service:upload");
    const url = isImage ? IMAGE_BASE_URL : isVideo ? VIDEO_BASE_URL : FILE_BASE_URL;

    const axios = getAxios();
    const infoResult = await axios.post(`${DATA_API_URL}${url}`, params);
    if (infoResult.status !== 200) {
        alert("파일 업로드 실패하였습니다.(0)");
        return;
    }
    const uploadResultStatus = await putFile(infoResult.data.data.uploadUrl, file);
    if (!uploadResultStatus) {
        alert("파일 업로드 실패하였습니다.(1)");
        return;
    }
    return infoResult.data.data;
};

// get image info
export const getImageInfo = async (id: string) => await getDateInfo(id, true);

// get file info
export const getFileInfo = async (id: string) => await getDateInfo(id, false);

const getDateInfo = async (id: string, isImage: boolean) => {
    const url = `${isImage ? IMAGE_BASE_URL : FILE_BASE_URL}/${id}`;
    return getAxios()
        .get(`${DATA_API_URL}${url}`)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

// make image activate
export const makeImageActivate = async (id: string) => await makeDataActivate(id, true);

// make file activate
export const makeFileActivate = async (id: string) => await makeDataActivate(id, false);

// make data activate
const makeDataActivate = async (id: string, isImage: boolean) => {
    const url = `${isImage ? IMAGE_BASE_URL : FILE_BASE_URL}/${id}/activate`;

    return getAxios()
        .patch(`${DATA_API_URL}${url}`)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const makeImageCopy = async (params: any) => {
    const url = `${IMAGE_BASE_URL}/copy`;

    const axios = getAxios();
    const infoResult = await axios.post(`${DATA_API_URL}${url}`, params);
    if (infoResult.status !== 200) {
        alert(infoResult.data.message);
        return;
    }

    return infoResult.data.data;
};

// get image presgined url
export const getImagePresignedUrl = async (id: string) => await getDataPresignedUrl(id, true, false);

// get file presgined url
export const getFilePresignedUrl = async (id: string) => await getDataPresignedUrl(id, false, false);

// get file presgined url
export const getVideoPresignedUrl = async (id: string) => await getDataPresignedUrl(id, false, true);

// get data presgined url
export const getDataPresignedUrl = async (id: string, isImage: boolean, isVideo: boolean) => {
    //const url = `${isImage ? IMAGE_BASE_URL : FILE_BASE_URL}/${id}/presigned-url`;
    const url = `${isImage ? IMAGE_BASE_URL : isVideo ? VIDEO_BASE_URL : FILE_BASE_URL}/${id}/presigned-url`;

    return getAxios()
        .get(`${DATA_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

// 파일 다운로드
export const fileDownload = async (url: string, fileName = "") => {
    console.log(url, fileName);
    const fileUrl = url;

    const _axios = axios.create();
    _axios // AxiosInstance
        .get(fileUrl, { responseType: "blob" })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName || fileUrl.split("/").pop() || "download";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
        .catch((error) => {
            console.error("파일 다운로드 오류:", error);
        });
};

export const getWaterMarkImage = async (id: string) => {
    const url = `${IMAGE_BASE_URL}/${id}/water-mark`;

    return getAxios()
        .get(`${DATA_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

const getImageFileInfo: any = async (file: File) => {
    const imageUrl = URL.createObjectURL(file);
    const { width, height } = await addImageProcess(imageUrl);

    return {
        name: file.name,
        size: file.size,
        width: width,
        height: height,
    };
};

const getVideoFileInfo: any = async (file: File) => {
    return {
        name: file.name,
        size: file.size,
    };
};
const addImageProcess: any = (src: string) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve({ width: img.width, height: img.height });
        img.onerror = reject;
        img.src = src;
    });
};

// S3 put file
const putFile = async (signedUrl: string, file: File) => {
    const options = {
        headers: {
            "Content-Type": file.type,
        },
    };

    const _axios = axios.create();
    return _axios
        .put(signedUrl, file, options)
        .then(({ status, data }) => {
            console.log(status);
            return status;
        })
        .catch(errorHandler);
};

const errorHandler = ({ response }: any) => {
    console.log(response);
    if (response?.status === 400) {
        throw new Error("Bad request");
    }
    
    if (response?.status === 401) {
        throw new Error("Authentication failed");
    }

    if (response?.status === 403) {
        throw new Error("Access denied");
    }

    if (response?.status === 404) {
        throw new Error("Resource not found");
    }

    if (response?.status >= 500) {
        throw new Error("Server error occurred");
    }

    throw new Error("Unknown error occurred");
};
