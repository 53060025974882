import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import React from "react";

const InstallAppBanner = (props: any) => {
    const { isShowInstallAppBanner, appInstallUrl, onClickClose } = props;
    return (
        isShowInstallAppBanner && (
            <div className="bg-[#ffd700] border-t py-2 px-4 flex justify-between items-center">
                <div className="flex items-center gap-2">
                    <button className="rounded-full" onClick={onClickClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div className="text-xs font-medium">
                        {getLanguageValue(PAGE_TYPE.COMMON, "installAppBanner.txtInstallAppDesc")}
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <a
                        href={appInstallUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center bg-white px-3 py-1.5 rounded-md text-sm shadow-sm"
                    >
                        <div className="text-sm font-bold">
                            {getLanguageValue(PAGE_TYPE.COMMON, "installAppBanner.txtInstallApp")}
                        </div>
                    </a>

                </div>
            </div>
        )
    );
};

export default InstallAppBanner;