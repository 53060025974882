import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import * as PublicProfileApi from "api/student/v2/publicProfile.api";
import PublicProfileAdditionalInfoEditComponent from "./PublicProfileAdditionalInfoEdit.component";
import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

const PublicProfileAdditionalInfoEditContainer = (props: any) => {
    const navigate = useNavigate();
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [hasChecked, setHasChecked] = useState({
        dance: false,
        vocal: false,
        rap: false,
    });
    const [bloodTypeCodeList, setBloodTypeCodeList] = useState<any>([]);
    const [mbtiCodeList, setMbtiCodeList] = useState<any>([]);
    const [specialtyCodeList, setSpecialtyCodeList] = useState<any>([]);
    const [profileParams, setProfileParams] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isOnNetworking, setIsOnNetworking] = useState(false);

    useEffect(() => {
        setBloodTypeCodeList(commonData.getBloodTypeCodeList());
        setMbtiCodeList(commonData.getMbtiCodeList());
        setSpecialtyCodeList(commonData.getSpecialtyCodeList());
        loadInfo();
    }, []);


    const loadInfo = async () => {
        setIsLoading(true);

        const result = await PublicProfileApi.getAdditionalInfo();

        if (result?.info) {
            const { info } = result;
            setStudentInfo(info);

            if (info.specialtyCodeList?.length > 0) {
                let specialty = "";

                info.specialtyCodeList.map((code: any, idx: number) => {
                    const codeInfo = commonData.getCommonCodeListByParent("specialty").find((i: any) => i.code === code);

                    specialty =
                        idx + 1 < info.specialtyCodeList?.length
                            ? `${specialty}${getCurrentLangName(codeInfo.names)}, `
                            : `${specialty}${getCurrentLangName(codeInfo.names)}`;

                    info.specialty = specialty;
                });
            }
        }

        setIsLoading(false);
    };

    const onChangeInput = (componentName: string, value: any) => {
        console.log("UpdateMyContainer:onChangeInput", componentName, value);

        if (componentName === "mbtiCode") {
            setProfileParams({ ...profileParams, mbtiCode: value });
        } else if (componentName === "bloodTypeCode") {
            setProfileParams({ ...profileParams, bloodTypeCode: value });
        }
    };

    const onChangeCheckbox = (componentName: string, evt: any) => {
        if (componentName === "dance") {
            setHasChecked({ ...hasChecked, dance: evt.target.checked });
        } else if (componentName === "vocal") {
            setHasChecked({ ...hasChecked, vocal: evt.target.checked });
        } else if (componentName === "rap") {
            setHasChecked({ ...hasChecked, rap: evt.target.checked });
        }
    };

    // const setSpecialtyCodeList = () => {
    //     const specialtyCodeList = [];
    //     if (hasChecked.dance) {
    //         specialtyCodeList.push("specialty:dance");
    //     }
    //     if (hasChecked.vocal) {
    //         specialtyCodeList.push("specialty:vocal");
    //     }
    //     if (hasChecked.rap) {
    //         specialtyCodeList.push("specialty:rap");
    //     }

    //     return specialtyCodeList;
    // };

    const updateInfo = async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        const params = {
            ...profileParams,
        }
        await PublicProfileApi.updateAdditionalInfo(params)
            .then((res: any) => {
                if (res) {
                    navigate("/student/profile/public");
                }
            })
            .catch((err: any) => {
                console.log("updateInfo error", err);
            }).finally(() => {
                setIsLoading(false);
            });
    };

    const handleChangeSpecialty = (code: string, checked: boolean) => {
        console.log("handleChangeSpecialty", code, checked);

        if (checked) {
            setProfileParams({ ...profileParams, specialtyCodeList: [...profileParams.specialtyCodeList, code] });
        } else {
            setProfileParams({ ...profileParams, specialtyCodeList: profileParams.specialtyCodeList.filter((item: any) => item !== code) });
        }
    };

    useEffect(() => {
        if (studentInfo?.id) {
            setProfileParams({
                mbtiCode: studentInfo.mbtiCode,
                specialtyCodeList: studentInfo.specialtyCodeList,
                bloodTypeCode: studentInfo.bloodTypeCodeInfo?.code || null,
            });

            setHasChecked({
                dance: studentInfo.specialtyCodeList?.includes("specialty:dance"),
                vocal: studentInfo.specialtyCodeList?.includes("specialty:vocal"),
                rap: studentInfo.specialtyCodeList?.includes("specialty:rap"),
            });
        }
    }, [studentInfo]);

    return (
        <PublicProfileAdditionalInfoEditComponent
            id={studentInfo?.id}
            profileParams={profileParams}
            isOnNetworking={isOnNetworking}
            hasChecked={hasChecked}
            onChangeInput={onChangeInput}
            onChangeCheckbox={onChangeCheckbox}
            onClickBtnUpdateInfo={updateInfo}
            bloodTypeCodeList={bloodTypeCodeList}
            mbtiCodeList={mbtiCodeList}
            specialtyCodeList={specialtyCodeList}
            handleChangeSpecialty={handleChangeSpecialty}
        />
    );
};

export default PublicProfileAdditionalInfoEditContainer;
