import React from "react";
import { getProfileImageUrl } from "helpers/Image.helper";
import { getStudentNickname, getFlagByCountryCode, getStudentName } from "helpers/String.helper";
import LoadingComponent from "commons/loading/Loading.component";

const WELCOME_MESSAGE_LIST = [
  "최고의 캐스팅 매니저가 될 수 있도록 도와드릴게요!",
  "캐스팅 매니저님의 관심이 지망생들의 꿈을 이뤄줄 거예요!",
  "오늘도 즐거운 캐스팅 될 수 있게 도와드릴게요!",
];

const IndexComponent = (props: any) => {
  const { isInit, recentlySignupStudentList, updatedFavoriteList,
    onClickStudent, favoriteCount, todayViewCount, chatRequestCount, chattingCount,
    handleClickFavoriteCount, handleClickTodayViewCount, handleClickChatRequestCount, handleClickChattingCount,
    showStudentInfoPopup } = props;

  return (
    <main className="bg-gray-50 min-h-screen">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6">
        <h2 className="text-3xl font-bold text-gray-900 mb-8">대시보드</h2>
        <p className="text-sm text-gray-500 mb-8">
          {WELCOME_MESSAGE_LIST[Math.floor(Math.random() * WELCOME_MESSAGE_LIST.length)]}
        </p>
        {!isInit &&
          <LoadingComponent />
        }
        {isInit &&
          <div className="space-y-6">
            {/* 캐스팅 현황 섹션 */}
            <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4 sm:mb-6">
              <div className="p-4 sm:p-6 border-b border-gray-100">
                <h2 className="text-lg sm:text-xl font-bold text-gray-900">캐스팅 현황</h2>
              </div>

              <div className="p-3 sm:p-4 md:p-6">
                <div className="space-y-4 sm:space-y-6">
                  <div>
                    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4">
                      {/* 즐겨찾기한 지망생 */}
                      <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl hover:bg-gray-100 transition-all duration-200 transform hover:-translate-y-1 shadow-sm hover:shadow">
                        <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                          <h3 className="text-xs sm:text-sm font-medium text-gray-500 mb-0 sm:mb-2 mr-2 sm:mr-0">
                            즐겨찾기 지망생
                          </h3>
                          <p onClick={handleClickFavoriteCount} className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688] cursor-pointer">
                            {favoriteCount}
                          </p>
                        </div>
                      </div>

                      {/* 오늘 조회한 지망생 */}
                      <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl hover:bg-gray-100 transition-all duration-200 transform hover:-translate-y-1 shadow-sm hover:shadow">
                        <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                          <h3 className="text-xs sm:text-sm font-medium text-gray-500 mb-0 sm:mb-2 mr-2 sm:mr-0">
                            오늘 조회한 지망생
                          </h3>
                          <p onClick={handleClickTodayViewCount} className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688] cursor-pointer">
                            {todayViewCount}
                          </p>
                        </div>
                      </div>

                      {/* 대화 요청 */}
                      <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl hover:bg-gray-100 transition-all duration-200 transform hover:-translate-y-1 shadow-sm hover:shadow">
                        <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                          <h3 className="text-xs sm:text-sm font-medium text-gray-500 mb-0 sm:mb-2 mr-2 sm:mr-0">
                            대화 요청
                          </h3>
                          <p onClick={handleClickChatRequestCount} className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688] cursor-pointer">
                            {chatRequestCount}
                          </p>
                        </div>
                      </div>

                      {/* 진행중인 대화 */}
                      <div className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl hover:bg-gray-100 transition-all duration-200 transform hover:-translate-y-1 shadow-sm hover:shadow">
                        <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                          <h3 className="text-xs sm:text-sm font-medium text-gray-500 mb-0 sm:mb-2 mr-2 sm:mr-0">
                            진행중인 대화
                          </h3>
                          <p onClick={handleClickChattingCount} className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688] cursor-pointer">
                            {chattingCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* 신규회원 목록 섹션 */}
            <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4 sm:mb-6">
              <div className="p-4 sm:p-6 border-b border-gray-100">
                <h2 className="text-lg sm:text-xl font-bold text-gray-900">신규 지망생</h2>
              </div>
              <div className="p-3 sm:p-4 md:p-6">
                {recentlySignupStudentList.length === 0 && (
                  <div className="flex-shrink-0 w-auto text-center px-2 py-4">
                    <p className="text-sm text-gray-500">
                      신규 지망생이 없어요... <br />
                      하지만 WANNABES가 더 노력할게요!
                    </p>
                  </div>
                )}

                <div className="flex md:grid overflow-x-auto scrollbar-hide md:grid-cols-4 lg:grid-cols-6 gap-4">
                  {recentlySignupStudentList.map((item: any, index: number) => (
                    <div key={index} className="flex-shrink-0 w-auto text-center px-2" onClick={() => showStudentInfoPopup(item.id)}>
                      <div className="relative inline-block mb-2">
                        <div className="w-24 h-24 sm:w-28 sm:h-28 rounded-full overflow-hidden mx-auto">
                          <img
                            src={getProfileImageUrl(item.profileImageInfo, 200)}
                            alt={getStudentNickname(item)}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <span className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 text-2xl sm:text-3xl">
                          {getFlagByCountryCode(item.countryInfo?.code)}
                        </span>
                      </div>
                      <p className="font-medium text-sm">{getStudentName(item)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            {/* 업데이트된 즐겨찾기 목록 섹션 */}

            <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4 sm:mb-6">
              <div className="p-4 sm:p-6 border-b border-gray-100">
                <h2 className="text-lg sm:text-xl font-bold text-gray-900">프로필이 업데이트된 즐겨찾기</h2>
              </div>
              <div className="p-3 sm:p-4 md:p-6">
                {updatedFavoriteList.length === 0 && (
                  <div className="flex-shrink-0 w-auto text-center px-2 py-4">
                    <p className="text-sm text-gray-500">정보가 업데이트된 지망생이 없어요</p>
                  </div>
                )}

                <div className="flex md:grid overflow-x-auto scrollbar-hide md:grid-cols-4 lg:grid-cols-6 gap-4">
                  {updatedFavoriteList.map((item: any, index: number) => (
                    <div key={index} className="flex-shrink-0 w-auto text-center px-2" onClick={() => showStudentInfoPopup(item.id)}>
                      <div className="relative inline-block mb-2">
                        <div className="w-24 h-24 sm:w-28 sm:h-28 rounded-full overflow-hidden mx-auto">
                          <img
                            src={getProfileImageUrl(item.profileImageInfo, 200)}
                            alt={getStudentNickname(item)}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <span className="absolute top-0 right-0">
                          {item.isUpdatedProfile && (
                            <div className="w-4 h-4 bg-[#CF3688] rounded-full"></div>
                          )}
                        </span>
                        <span className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 text-2xl sm:text-3xl">
                          {getFlagByCountryCode(item.countryInfo?.code)}
                        </span>
                      </div>
                      <p className="font-medium text-sm">{getStudentName(item)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </section>


            {/* 기획사 정보 섹션 */}
            {/* <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
            <div className="p-4 sm:p-6 border-b border-gray-100">
              <h2 className="text-lg sm:text-xl font-bold text-gray-900">기획사 정보</h2>
            </div>

            <div className="p-3 sm:p-4 md:p-6 space-y-6 sm:space-y-8">
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 sm:gap-4">
                {[
                  { title: "소속 연습생", value: 127 },
                  { title: "진행중인 오디션", value: 5 },
                  { title: "완료된 오디션", value: 32 },
                  { title: "총 조회수", value: 15234 },
                  { title: "이번달 조회수", value: 1234 },
                  { title: "오늘 조회수", value: 89 }
                ].map((item, index) => (
                  <div key={index} className="bg-gray-50 p-3 sm:p-4 rounded-lg sm:rounded-xl hover:bg-gray-100 transition-all duration-200 transform hover:-translate-y-1 shadow-sm hover:shadow">
                    <div className="flex flex-row sm:flex-col h-full justify-between sm:justify-start">
                      <h3 className="text-xs sm:text-sm font-medium text-gray-500 mb-0 sm:mb-2 mr-2 sm:mr-0">
                        {item.title}
                      </h3>
                      <p className="text-lg sm:text-xl lg:text-2xl font-bold text-[#CF3688]">
                        {item.value.toLocaleString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section> */}
          </div>
        }
      </div>
    </main>
  );
};

export default IndexComponent;