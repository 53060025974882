import React, { useEffect, useState } from "react";
import MenuComponent from "./Menu.component";
import { fetchMessageRequestCount } from "api/student/v2/menu.api";
import { useLocation } from "react-router-dom";
const MenuContainer = () => {
    const location = useLocation();
    const [messageRequestCount, setMessageRequestCount] = useState(0);

    useEffect(() => {
        if (location.pathname == '/student') {
            getMessageRequestCount();
            const interval = setInterval(() => {
                getMessageRequestCount();
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [location]);

    const getMessageRequestCount = async () => {
        try {
            const data = await fetchMessageRequestCount();
            if (data?.messageRequestCount) {
                setMessageRequestCount(data.messageRequestCount);
            } else {
                setMessageRequestCount(0);
            }
        } catch (error) {
            console.error("채팅 요청 수 조회 실패:", error);
            setMessageRequestCount(0);
        }
    };


    return <MenuComponent messageRequestCount={messageRequestCount} />;
};

export default MenuContainer;