import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { Link } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";

const PublicProfileBodyInfoEditComponent = (props: any) => {
    const { type, list, isLoading, handleRemoveLog, handleEditLog, isOnNetworking, editingItemIdx, handleEditInputLog, editedValue, handleEditInputChange, editedValueLog, handleEditInputChangeLog, inputStep, inputMin, inputMax, handleSave, isPublic, handleIsPublicChange } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <div className="flex items-center mb-6">
                    <Link
                        to="/student/profile/public"
                        className="inline-flex items-center text-gray-600 hover:text-gray-900"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </Link>
                </div>
                <div className="flex items-center justify-between mb-8">
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">
                        {type === 'height' && getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtTitleForHeight")}
                        {type === 'weight' && getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtTitleForWeight")}
                        {type === 'shoe-size' && getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtTitleForShoeSize")}
                    </h2>
                </div>

                {isLoading && (
                    <LoadingComponent />
                )}
                {!isLoading && (
                    <div className="max-w-2xl mx-auto">
                        <div className="space-y-6">
                            <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-6">
                                <div className="p-4 sm:p-6 lg:p-8">
                                    <div className="flex items-center justify-between">
                                        <h3 className="text-lg font-medium text-gray-900">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtIsPublicTitle")}
                                        </h3>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input type="checkbox" className="sr-only peer"
                                                checked={isPublic}
                                                onChange={(e) => handleIsPublicChange(e.target.checked)}
                                            />
                                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#CF3688]/20 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#CF3688]"></div>
                                        </label>
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtIsPublicDesc")}
                                    </p>
                                </div>
                            </section>

                            <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                                <div className="p-4 sm:p-6 lg:p-8">
                                    <div className="space-y-8">
                                        {/* 입력 폼 */}
                                        <div className="flex flex-col gap-6">
                                            {list.length > 0 &&
                                                <>
                                                    {/* 현재 값 표시 */}
                                                    <div className="p-4 sm:p-6 rounded-xl">
                                                        <p className="text-2xl sm:text-3xl font-bold text-gray-900 text-center">
                                                            {list[0]?.value || '-'}
                                                            {type === 'height' && 'cm'}
                                                            {type === 'weight' && 'kg'}
                                                            {type === 'shoe-size' && 'mm'}
                                                        </p>
                                                    </div>

                                                    {/* 화살표 아이콘 */}
                                                    <div className="flex justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                                                        </svg>
                                                    </div>
                                                </>
                                            }

                                            {/* 입력 필드 */}
                                            <div className="relative">
                                                <input
                                                    type="number"
                                                    min={inputMin}
                                                    max={inputMax}
                                                    step={inputStep}
                                                    onKeyDown={(e) => {
                                                        if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    className="block w-full px-4 py-3 sm:py-4 border-2 border-gray-200 rounded-xl
                                                        focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                        text-base sm:text-lg transition-colors pr-16"
                                                    placeholder={
                                                        type === 'height' ? getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtPlaceholderForHeight") :
                                                            type === 'weight' ? getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtPlaceholderForWeight") :
                                                                getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtPlaceholderForShoeSize")
                                                    }
                                                    value={editedValue}
                                                    onChange={(e) => handleEditInputChange(e.target.value)}
                                                />
                                                <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 text-base sm:text-lg">
                                                    {type === 'height' && 'cm'}
                                                    {type === 'weight' && 'kg'}
                                                    {type === 'shoe-size' && 'mm'}
                                                </span>
                                            </div>
                                        </div>

                                        {/* 버튼 그룹 */}
                                        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
                                            <Link
                                                to="/student/profile/public"
                                                className="w-full sm:w-1/2 px-4 py-3 sm:py-4 text-base font-medium text-gray-700 bg-gray-50 border-2 border-gray-200 rounded-xl
                                                    hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200
                                                    text-center transition-colors">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.btnCancel")}
                                            </Link>
                                            <button
                                                className="w-full sm:w-1/2 px-4 py-3 sm:py-4 text-base font-medium text-white bg-[#CF3688] rounded-xl
                                                    hover:bg-[#B82F77] focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                                    transition-colors"
                                                disabled={isOnNetworking}
                                                onClick={handleSave}>
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.btnSave")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* 히스토리 섹션 */}
                            {list.length > 0 && (
                                <section className="bg-white rounded-2xl shadow-sm overflow-hidden mt-8">
                                    <div className="p-4 sm:p-6 lg:p-8">
                                        <h3 className="text-lg font-semibold text-gray-900 mb-4">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtHistoryTitle")}
                                        </h3>
                                        <div className="space-y-4">
                                            {list.map((item: any, index: number) => (
                                                <div className="bg-gray-50 rounded-xl" key={index}>
                                                    <div className="flex flex-col sm:flex-row sm:items-center justify-between p-4 gap-4">
                                                        <div>
                                                            <div className="text-base sm:text-lg font-medium text-gray-900">
                                                                {item.value}{type === 'height' ? 'cm' : type === 'weight' ? 'kg' : 'mm'}
                                                            </div>
                                                            <div className="text-sm text-gray-500 mt-1">
                                                                {new Date(item.createdAt).toLocaleDateString()}
                                                            </div>
                                                        </div>
                                                        <div className="flex gap-2 sm:gap-3">
                                                            <button className="flex-1 sm:flex-none px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50"
                                                                onClick={() => handleEditInputLog(index)}
                                                                disabled={isOnNetworking}>
                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.btnEdit")}
                                                            </button>
                                                            <button className="flex-1 sm:flex-none px-4 py-2 text-sm font-medium text-red-600 bg-white border border-red-200 rounded-lg hover:bg-red-50"
                                                                onClick={() => handleRemoveLog(item.id)} disabled={isOnNetworking}>
                                                                {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.btnDelete")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {editingItemIdx === index && (
                                                        <div className="p-4">
                                                            <div className="flex gap-2">
                                                                <div className="relative flex-1">
                                                                    <input
                                                                        type="number"
                                                                        min={inputMin}
                                                                        max={inputMax}
                                                                        step={inputStep}
                                                                        className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl
                                                                            focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] 
                                                                            text-base transition-colors pr-16"
                                                                        placeholder={
                                                                            type === 'height' ? getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtPlaceholderForHeight") :
                                                                                type === 'weight' ? getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtPlaceholderForWeight") :
                                                                                    getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.txtPlaceholderForShoeSize")
                                                                        }
                                                                        value={editedValueLog}
                                                                        onChange={(e) => handleEditInputChangeLog(e.target.value)}
                                                                    />
                                                                    <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 text-base">
                                                                        {type === 'height' && 'cm'}
                                                                        {type === 'weight' && 'kg'}
                                                                        {type === 'shoe-size' && 'mm'}
                                                                    </span>
                                                                </div>
                                                                <button className="px-4 py-2 text-sm font-medium text-white bg-[#CF3688] rounded-lg hover:bg-[#B82F77]"
                                                                    onClick={() => handleEditLog(item.id)}
                                                                    disabled={isOnNetworking}>
                                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfileBodyInfoEdit.btnUpdate")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
};

export default PublicProfileBodyInfoEditComponent;