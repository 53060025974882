import React, { useEffect, useState } from "react";
import ReportPopup from "./ReportPopup.component";
import { getReportTypeCodeList } from "data/common.data";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { doReport } from "api/student/Community.api";

const ReportPopupForCommunityContainer = (props: any) => {
    const { bbsId } = props;
    const [reportTypeCodeList, setReportTypeCodeList] = useState<any[]>([]);
    const [reportTypeCode, setReportTypeCode] = useState<string>("");
    const [etcReason, setEtcReason] = useState<string>("");
    const [isReporting, setIsReporting] = useState<boolean>(false);

    useEffect(() => {
        console.log("bbsId", bbsId);
        const codeList = getReportTypeCodeList();
        console.log({ codeList });
        setReportTypeCodeList(codeList);
    }, [bbsId]);

    const handleReport = async () => {
        if (!reportTypeCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.warningChooseReportType"));
            return;
        }

        if (!window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "reportPopup.confirmationReport"))) {
            return;
        }

        try {
            setIsReporting(true);
            await doReport(bbsId, reportTypeCode, etcReason);
            props.onClose();
        } catch (error) {
            console.error(error);
        } finally {
            setIsReporting(false);
        }
    };

    return (
        <ReportPopup
            bbsId={bbsId}
            onClose={props.onClose}
            reportTypeCodeList={reportTypeCodeList}
            reportTypeCode={reportTypeCode}
            etcReason={etcReason}
            setReportTypeCode={setReportTypeCode}
            setEtcReason={setEtcReason}
            onReport={handleReport}
            isReporting={isReporting}
        />
    );
};

export default ReportPopupForCommunityContainer;    