import React from "react";
import { Link, Outlet } from "react-router-dom";

const LayoutCompact = (props: any) => {
    const { backUrl } = props;

    return (
        <>
            <Outlet />
        </>
    );
};

export default LayoutCompact;