import LoadingComponent from "commons/loading/Loading.component";
import { PAGE_TYPE } from "enums";
import { getUserType } from "helpers/Common.helper";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const NoticeDetailComponent = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { info, isLoading } = props;
    const { info: userInfo } = useSelector((state: any) => state.user);

    const noticePath = `/${getUserType(userInfo?.accountTypeCode)}/notice`;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                {isLoading ? (
                    <LoadingComponent />
                ) : (
                    <div className="space-y-6">
                        <div className="flex items-center">
                            <button
                                onClick={() => {
                                    navigate(noticePath, {
                                        state: {
                                            page: location?.state?.page || 1,
                                        },
                                    });
                                }}
                                className="flex items-center text-gray-500 hover:text-[#CF3688] transition-colors"
                            >
                                <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                                {getLanguageValue(PAGE_TYPE.COMMON, "notice.btnToList")}
                            </button>
                        </div>

                        <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6 border-b border-gray-100">
                                <h2 className="text-2xl font-bold text-gray-900 mb-2">{info.title}</h2>
                                <p className="text-sm text-gray-500">
                                    {getFormattedDateValue(info.createdAt, "YYYY.MM.DD HH:mm")}
                                </p>
                            </div>

                            <div className="p-6">
                                {info.attachedPcImageUrl && (
                                    <div className="hidden md:block mb-6">
                                        <img src={info.attachedPcImageUrl} alt={info.title} className="max-w-full h-auto rounded-lg" />
                                    </div>
                                )}

                                {info.attachedMobileImageUrl && (
                                    <div className="md:hidden mb-6">
                                        <img src={info.attachedMobileImageUrl} alt={info.title} className="max-w-full h-auto rounded-lg" />
                                    </div>
                                )}

                                <div className="prose max-w-none">
                                    {info.content.split("\n").map((line: any, index: any) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>

                            <div className="p-6 border-t border-gray-100">
                                <button
                                    onClick={() => {
                                        navigate(noticePath, {
                                            state: {
                                                page: location?.state?.page || 1,
                                            },
                                        });
                                    }}
                                    className="w-full sm:w-auto px-4 py-2 text-sm font-medium text-white bg-[#CF3688] rounded-lg hover:bg-[#B82F77] transition-colors"
                                >
                                    {getLanguageValue(PAGE_TYPE.COMMON, "notice.btnToList")}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
};
export default NoticeDetailComponent;
