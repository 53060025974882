import { getProfileImageUrl } from "helpers/Image.helper";
import { getFlagByCountryCode, getCurrentLangName, getStudentName } from "helpers/String.helper";
import React from "react";
import LoadingComponent from "commons/loading/Loading.component";

const RequestChatPopupComponent = (props: any) => {
    const { isInit, info, onClose, onCancelRequest, onStartChat, onDetailInfo, errorMessage } = props;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 p-4 !m-0">
            <div className="bg-white rounded-2xl p-6 w-full max-w-2xl mx-auto">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-bold text-gray-900">대화 요청</h3>
                    <button
                        type="button"
                        className="text-gray-400 hover:text-gray-600"
                        onClick={onClose}>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {isInit ? (
                    <>
                        {info &&
                            <div className="flex flex-col items-center">
                                <div className="w-32 h-32 rounded-full overflow-hidden mb-4">
                                    <img
                                        src={getProfileImageUrl(info.userStudentInfo.profileImageInfo, 200)}
                                        alt="프로필 이미지"
                                        className="w-full h-full object-cover"
                                    />
                                </div>

                                <div className="text-center mb-4">
                                    <h4 className="text-xl font-bold mb-2">
                                        {getStudentName(info?.userStudentInfo)}
                                    </h4>
                                    <p className="text-gray-600 mb-2">{info.userStudentInfo.gender}</p>
                                    <div className="flex items-center justify-center gap-2">
                                        <span className="text-2xl">
                                            {getFlagByCountryCode(info.userStudentInfo.countryInfo?.code)}
                                        </span>
                                        <span className="text-gray-700">
                                            {getCurrentLangName(info.userStudentInfo.countryInfo?.names)}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex gap-4 w-full">
                                    <button
                                        onClick={onDetailInfo}
                                        className="w-full px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 border-2 border-gray-200 rounded-xl
                                    hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200
                                    transition-colors">
                                        상세 정보
                                    </button>
                                    {info.messageStatusInfo?.code === 'messageType:request' &&
                                        <button
                                            onClick={onCancelRequest}
                                            className="w-full px-4 py-3 text-sm font-medium text-white bg-[#CF3688] rounded-xl
                                    hover:bg-[#B82F77] focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                    transition-colors">
                                            요청 취소
                                        </button>
                                    }
                                    {(info.messageStatusInfo?.code === 'messageType:accept') &&
                                        <button
                                            onClick={onStartChat}
                                            className="w-full px-4 py-3 text-sm font-medium text-white bg-[#CF3688] rounded-xl
                                    hover:bg-[#B82F77] focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                    transition-colors">
                                            대화 시작
                                        </button>
                                    }
                                    {info.messageStatusInfo?.code === 'messageType:close' &&
                                        <button
                                            onClick={onStartChat}
                                            className="w-full px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 border-2 border-gray-200 rounded-xl
                                    hover:bg-gray-80 focus:outline-none focus:ring-2 focus:ring-gray-200
                                    transition-colors">
                                            대화방 입장
                                        </button>
                                    }
                                </div>
                            </div>
                        }
                        {errorMessage &&
                            <div className="w-full text-center py-8 text-red-500 bg-red-100 border border-red-400 rounded">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z" />
                                </svg>
                                {errorMessage}
                            </div>
                        }
                    </>)
                    : (
                        <LoadingComponent />
                    )}
            </div>
        </div>
    );
};

export default RequestChatPopupComponent;