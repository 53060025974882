import React, { useEffect, useState } from "react";
import AlarmTooltipComponent from "./AlarmTooltip.component";
import { getAlarmList } from "api/student/v2/alarm.api";

const AlarmTooltipContainer = (props: any) => {
    const { onClose } = props;
    const [isInit, setIsInit] = useState(false);
    const [list, setList] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        const fetchAlarmList = async () => {
            try {
                const data = await getAlarmList(page, limit, true);
                if (data.list) {
                    setList(data.list);
                    setIsInit(true);
                }
            } catch (error) {
                console.error("알림 목록 가져오기 실패:", error);
            }
        };
        fetchAlarmList();
    }, []);

    return <AlarmTooltipComponent list={list} onClose={onClose} isInit={isInit} />;
};

export default AlarmTooltipContainer;