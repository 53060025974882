import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import AuthHeader from "../component/AuthHeader.component";
const PasswordResetComponent = (props: any) => {
  const { email, setEmail, resetPassword, btnRef } = props;

  return (
    <main className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <AuthHeader backUrl="/login" />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
        <div className="flex flex-col items-center max-w-md mx-auto w-full">
          <div className="text-center mb-8">
            <h2 className="text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">
              {getLanguageValue(PAGE_TYPE.COMMON, "passwordReset.txtFindPassword")}
            </h2>
          </div>
          <div className="w-full space-y-6">
            <div className="space-y-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 focus:border-[#CF3688] focus:ring-2 focus:ring-[#CF3688]/20 text-gray-900 transition-all duration-200 text-base sm:text-lg"
                />
              </div>
              <p className="text-sm text-gray-500">
                * {getLanguageValue(PAGE_TYPE.COMMON, "passwordReset.txtPasswordResetLink")}
              </p>
            </div>

            <div>
              <button
                ref={btnRef}
                onClick={resetPassword}
                className="w-full py-3 sm:py-4 px-6 text-base sm:text-lg font-semibold text-white bg-[#CF3688] hover:bg-[#A62B6D] rounded-lg transition-all duration-200 transform hover:scale-[1.02] active:scale-[0.98] shadow-lg hover:shadow-xl"
              >
                {getLanguageValue(PAGE_TYPE.COMMON, "passwordReset.btnPasswordResetEmailSent")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default PasswordResetComponent;
