import React, { useEffect, useState } from "react";
import LikePopupComponent from "./LikePopup.component";
import { getLikeListForComment } from "api/student/Community.api";
import { addFollow, cancelFollow } from "api/student/Follow.api";

const LIMIT = 30;
const LikePopupForCommunityCommentContainer = (props: any) => {
    const { bbsId, commentId, onClose } = props;

    const [page, setPage] = useState<number>(1);
    const [list, setList] = useState<any[]>([]);
    const [isInit, setIsInit] = useState<boolean>(false);
    const [isOnNetworking, setIsOnNetworking] = useState<boolean>(false);
    const [isOnLoadMore, setIsOnLoadMore] = useState<boolean>(false);

    const loadLikeList = async () => {
        setIsInit(false);
        const data = await getLikeListForComment(bbsId, commentId, page, LIMIT);
        if (data?.list) {
            setList(data.list);
        }
        setIsInit(true);
    };

    useEffect(() => {
        loadLikeList();
    }, []);

    const handleFollow = async (userStudentId: number) => {
        if (isOnNetworking) return;
        setIsOnNetworking(true);
        const data = await addFollow(userStudentId);
        if (data) {
            setList(list.map(item => item.userStudentInfo.id === userStudentId ? { ...item, isFollowing: true } : item));
        }
        setIsOnNetworking(false);
    };

    const handleUnfollow = async (userStudentId: number) => {
        if (isOnNetworking) return;
        setIsOnNetworking(true);
        const data = await cancelFollow(userStudentId);
        if (data) {
            setList(list.map(item => item.userStudentInfo.id === userStudentId ? { ...item, isFollowing: false } : item));
        }
        setIsOnNetworking(false);
    };

    const handleLoadMore = () => {
        setPage(page + 1);
        loadLikeList();
    };

    return <LikePopupComponent
        list={list}
        onClose={onClose}
        onFollow={handleFollow}
        onUnfollow={handleUnfollow}
        isInit={isInit}
        onLoadMore={handleLoadMore}
        isOnLoadMore={isOnLoadMore} />;
};

export default LikePopupForCommunityCommentContainer;
