import React from "react";
import "./error.css";
import Error400Component from "./400.component";

const Error400Container = () => {
    return (
        <Error400Component />
    )
};

export default Error400Container;
