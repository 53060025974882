import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getAccessToken } from "helpers/Auth.helper";
import { WebSocketSubject } from "rxjs/webSocket";
import { useSelector } from "react-redux";
import SocketWrapComponent from "./SocketWrap.component";
import { throttleTime } from "rxjs/operators";

const SocketWrapContainer = (props: any) => {
    const { channelId, userId, accountTypeCode } = props;
    const { messageHandler, setWebSocket, setSocketStatusFromParent } = props;

    const RECONNECT_DELAY = 1000;
    const MAX_RECONNECT_COUNT = 5;
    const PING_INTERVAL = 30000; // 30초
    const PROTOCOL = ["myprotocol", process.env.REACT_APP_WS_ACCESS_KEY ?? "", process.env.REACT_APP_WS_SECRET_KEY ?? "", getAccessToken()];
    const WEBSOCKET_URL = `${process.env.REACT_APP_WS_URL}?channelId=${channelId}&userId=${userId}&userType=${accountTypeCode}`;

    const wsSubject = useRef<WebSocketSubject<any>>();
    const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const [socketStatus, setSocketStatus] = useState<'connecting' | 'connected' | 'reconnecting' | 'disconnected' | 'error' | 'gone'>('disconnected');
    const retryCount = useRef(0);

    useEffect(() => {
        if (setSocketStatusFromParent) {
            setSocketStatusFromParent(socketStatus);
        }
    }, [socketStatus]);

    const createWebSocket = useCallback(() => {
        console.log("pages/student/casting/MessageChat.container.tsx/createWebSocket");

        console.log('wsSubject.current.closed', wsSubject?.current?.closed);
        console.log('wsSubject.current.readyState', wsSubject?.current?.unsubscribe);

        if (wsSubject?.current) {
            if (wsSubject.current.closed) {
                console.log("pages/student/casting/MessageChat.container.tsx/createWebSocket/wsSubject.current.closed");
                return;
            }
        }
        setSocketStatus('connecting');

        wsSubject.current = new WebSocketSubject({
            url: WEBSOCKET_URL,
            protocol: PROTOCOL,
            // 연결 성공시
            openObserver: {
                // 연결 성공 직후
                next: async () => {
                    console.log("pages/student/casting/MessageChat.container.tsx/handleWebSocketConnect.openObserver.next");
                    retryCount.current = 0;
                    // 연결 성공시 ping 인터벌 시작
                    startPingInterval();
                    // loadRecentlyMessages();
                    setSocketStatus('connected');
                },
                // 연결 실패시
                error: () => {
                    console.log("pages/student/casting/MessageChat.container.tsx/handleWebSocketConnect.openObserver.error");
                    clearPingInterval();
                    if (retryCount.current < MAX_RECONNECT_COUNT) {
                        retryConnectWebSocket();
                    } else {
                        setSocketStatus('gone');
                    }
                },
                complete: () => {
                    console.log("pages/student/casting/MessageChat.container.tsx/handleWebSocketConnect.openObserver.complete");
                }
            },
            // 연결 끊김시
            closeObserver: {
                // 연결 끊김 직후
                next: () => {
                    console.log("pages/student/casting/MessageChat.container.tsx/handleWebSocketConnect.closeObserver.next");
                    clearPingInterval();
                    setSocketStatus('disconnected');
                },
                // 연결 끊김 실패시
                error: () => {
                    console.log("pages/student/casting/MessageChat.container.tsx/handleWebSocketConnect.closeObserver.error");
                    clearPingInterval();
                    if (retryCount.current < MAX_RECONNECT_COUNT) {
                        retryConnectWebSocket();
                    } else {
                        setSocketStatus('gone');
                    }
                },
                // 연결 끊김 완료시
                complete: () => {
                    console.log("pages/student/casting/MessageChat.container.tsx/handleWebSocketConnect.closeObserver.complete");
                    clearPingInterval();
                    setSocketStatus('disconnected');
                }
            }
        });

        wsSubject.current
            .pipe(throttleTime(100))
            .subscribe({
            next: socketMessageHandler,
            error: socketErrorHandler,
            complete: socketCompleteHandler
        });

        
        if (setWebSocket) {
            setWebSocket(wsSubject.current);
        }
    }, []);

    useEffect(() => {
        createWebSocket();
        return () => {
            handleWebSocketDisconnect();
        };
    }, [createWebSocket]);

    const handleWebSocketDisconnect = async () => {
        console.log("pages/student/casting/MessageChat.container.tsx/handleWebSocketDisconnect");
        clearPingInterval();
        if (wsSubject.current) {
            wsSubject.current.unsubscribe();
            wsSubject.current = null;
        }
    }

    // 메시지 수신 함수
    const socketMessageHandler = (message: any) => {
        console.log("pages/student/MessageChat.container.tsx/socketMessageHandler");
        console.log({ message });
        messageHandler(message);
    }

    // 오류 수신 함수
    const socketErrorHandler = function (error: any) {
        console.log("============================================================================")
        console.log("MessageChat.container:socketErrorHandler");
        console.log("============================================================================")
        console.log(arguments)
        console.error(error);

        if (retryCount.current < MAX_RECONNECT_COUNT) {
            retryConnectWebSocket();
        } else {
            console.log({ error });
            if (error.code === 1000) {
                console.log("========================================");
                console.log("서버에서 종료");
                console.log("========================================");
                setSocketStatus('gone');
            } else if (error.code === 1001) {
                console.log("========================================");
                console.log("서버 다운 or 클라이언트 떠남");
                console.log("========================================");
                setSocketStatus('gone');
            } else if (error.code === 1006) {
                console.log("========================================");
                console.log("서버 재부팅 or 네트워크 문제 발생");
                console.log("========================================");
                setSocketStatus('gone');
            } else {
                console.log("========================================");
                console.log("알 수 없는 오류");
                console.log("========================================");
                setSocketStatus('error');
            }
        }
    }
    // 소켓 종료시 수신 함수
    const socketCompleteHandler = () => {
        console.log("pages/student/casting/MessageChat.container.tsx/socketCompleteHandler");
    }

    const retryConnectWebSocket = () => {
        console.log("retry createWebSocket");
        console.log("retryCount.current", retryCount.current);
        retryCount.current++;
        setTimeout(() => {
            createWebSocket();
        }, RECONNECT_DELAY);
    }

    const startPingInterval = () => {
        // 기존 인터벌이 있다면 제거
        clearPingInterval();

        // 새로운 ping 인터벌 시작
        pingIntervalRef.current = setInterval(() => {
            if (wsSubject.current) {
                const jsonMessage = {
                    channelId: channelId,
                    messageType: "ping",
                }
                wsSubject.current.next(jsonMessage);
                console.log('Ping sent');
            }
        }, PING_INTERVAL);
    };

    const clearPingInterval = () => {
        if (pingIntervalRef.current) {
            clearInterval(pingIntervalRef.current);
            pingIntervalRef.current = null;
        }
    };

    const handleReconnect = () => {
        console.log("pages/student/casting/SocketWrap.container.tsx/handleReconnect");
        retryCount.current = 0;
        createWebSocket();
    }

    return (
        <SocketWrapComponent
            socketStatus={socketStatus}
            retryCount={retryCount}
            handleReconnect={handleReconnect}
            MAX_RECONNECT_COUNT={MAX_RECONNECT_COUNT}
        />
    )
};

export default SocketWrapContainer;