import * as BaseApi from "api/Base.api";

const BASE_URL = "/agency/v2/popup";

//* 전체 팝업용 지망생 정보 요청
//  2025-02-27 추가
//  희문
//* Params
//  userStudentId : 지망생 아이디
export const getFullPopupStudentInfo = async (userStudentId: number) => {
    const url = `${BASE_URL}/student/${userStudentId}/full`;
    return await BaseApi.get(url);
};

//* 팝업용 지망생 정보 요청
//  2025-03-05 추가
//  희문
//* Params
//  userStudentId : 지망생 아이디
export const getPopupStudentInfo = async (userStudentId: number) => {
    const url = `${BASE_URL}/student/${userStudentId}`;
    return await BaseApi.get(url);
};

//* 대화 요청 상세 조회
//  2025-03-09 추가
//  희문
//* Params
//  requestId : 대화 요청 아이디
export const fetchRequestDetail = async (requestId: number) => {
    const url = `${BASE_URL}/chat/request/${requestId}`;
    return await BaseApi.get(url);
};

//* 팝업용 학생 신체정보 요청
//  2025-03-11 추가
//  희문
//* Params
//  userStudentId : 지망생 아이디
//  bodyInfoTypeCode : 신체정보 타입 코드
export const fetchStudentGrowthHistory = async (userStudentId: number, bodyInfoTypeCode: string) => {
    const url = `${BASE_URL}/student/${userStudentId}/growth-history/${bodyInfoTypeCode}`;
    return await BaseApi.get(url);
};