import { useEffect, useState } from "react";
import IndexComponent from "./Index.component";
import { useParams, useNavigate } from "react-router-dom";
import { fetchStudentHomeInfo } from "api/agency/v2/Student.api";
import { requestChat, cancelChatRequest } from "api/agency/v2/Chat.api";
import FullPageStudentInfoPopupContainer from "../popup/FullPageStudentInfoPopup.container";
import { usePopup } from "contexts/PopupContext";
const IndexContainer = (props: any) => {
    const { showPopup } = usePopup();
    const { userStudentId } = useParams();
    const navigate = useNavigate();
    const [tabIdx, setTabIdx] = useState(0);

    const [isInit, setIsInit] = useState(false);
    const [homeInfo, setHomeInfo] = useState<any>(null);
    const [isOnNetworking, setIsOnNetworking] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const buttonInit = async () => {
        try {
            const data = await fetchStudentHomeInfo(userStudentId);
            if (data?.info) {
                setHomeInfo(data.info);
            }
        } catch (error: any) {
            console.error("Failed to fetch home info:", error);
            setErrorMessage(error.message);
        } finally {
            setIsInit(true);
        }
    }

    const handleRequestChat = async () => {
        console.log("pages/agency/v2/student/Index.container::handleRequestChat");

        if (isOnNetworking) return;

        setIsOnNetworking(true);
        try {
            const data = await requestChat(userStudentId);
            if (data?.messageStatusCode) {
                const newHomeInfo = { ...homeInfo, messageInfo: data.messageInfo };
                setHomeInfo(newHomeInfo);
            }
        } catch (error: any) {
            console.error("pages/agency/v2/student/Index.container::handleRequestChat", error);
            alert(error.message || "대화 요청에 실패했어요. 다시 시도해주세요.");
        } finally {
            setIsOnNetworking(false);
        }
    }

    const handleCancelChat = async () => {
        console.log("pages/agency/v2/student/Index.container::handleCancelChat");
        console.log({ homeInfo });

        if (isOnNetworking) return;

        if (!homeInfo?.messageInfo.id) return;

        if (window.confirm("대화 요청을 취소하시겠어요?")) {
            setIsOnNetworking(true);
            const data = await cancelChatRequest(homeInfo.messageInfo.id);
            console.log(data);
            if (data?.result) {
                const newHomeInfo = { ...homeInfo, messageInfo: null };
                setHomeInfo(newHomeInfo);
            }

        }
        setIsOnNetworking(false);
    }

    const showBasicInfoPopup = () => {
        console.log("pages/agency/v2/student/Index.container::showBasicInfoPopup");
        showPopup(<FullPageStudentInfoPopupContainer userStudentId={userStudentId} />)
    }

    const onClickBack = () => {
        navigate("/agency");
    }

    useEffect(() => {
        buttonInit();
    }, []);

    return <IndexComponent
        isOnNetworking={isOnNetworking}
        tabIdx={tabIdx}
        setTabIdx={setTabIdx}
        isInit={isInit}
        userStudentId={userStudentId}
        homeInfo={homeInfo}
        onRequestChat={handleRequestChat}
        onCancelChat={handleCancelChat}
        showBasicInfoPopup={showBasicInfoPopup}
        onClickBack={onClickBack}
        errorMessage={errorMessage}
    />;
};

export default IndexContainer;