import React, { useEffect, useState } from "react";
import StudentInfoComponent from "./StudentInfo.component";
import { getStudentInfo, getStudentPhotos } from "api/student/v2/student.api";
import { useParams } from "react-router-dom";
import { addFollow, cancelFollow } from "api/student/Follow.api";
const PHOTOS_LIMIT = 10;
const StudentInfoContainer = () => {
    const { studentId } = useParams();
    const [isInit, setIsInit] = useState(false);
    const [info, setInfo] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState("");

    const [isOnLoading, setIsOnLoading] = useState(false);
    const [photos, setPhotos] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [errorMessageForPhotos, setErrorMessageForPhotos] = useState("");
    const [isOnNetworking, setIsOnNetworking] = useState(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const data = await getStudentInfo(studentId);
            if (data?.info) {
                setInfo(data.info);
            }
        } catch (error: any) {
            console.error(error);
            setErrorMessage(error.message || "Unknown error occurred while loading student info");
        } finally {
            setIsInit(true);
        }
    };

    useEffect(() => {
        if (info?.isActiveOfPublicProfile) {
            loadPhotos();
        }
    }, [info]);

    const loadPhotos = async (page: number = 1) => {
        setIsOnLoading(true);
        try {
            const data = await getStudentPhotos(studentId, page, PHOTOS_LIMIT);
            if (data?.list) {
                setPhotos([...photos, ...data.list]);
                setPage(page);
                setHasMore(data.list.length === PHOTOS_LIMIT);
            }
        } catch (error: any) {
            console.error(error);
            setErrorMessageForPhotos(error.message || "Unknown error occurred while loading photos");
            setHasMore(false);
        } finally {
            setIsOnLoading(false);
        }
    };

    const loadMorePhotos = () => {
        loadPhotos(page + 1);
    };

    const onFollow = async (studentId: number) => {
        console.log("onFollow", studentId);
        if (isOnNetworking) return;
        setIsOnNetworking(true);
        try {
            const data = await addFollow(studentId);
            if (data) {
                setInfo((prev: any) => prev ? { ...prev, isFollowing: true } : null);
            }
        } catch (error: any) {
            console.error(error);
        } finally {
            setIsOnNetworking(false);
        }
    };

    const onUnfollow = async (studentId: number) => {
        console.log("onUnfollow", studentId);
        if (isOnNetworking) return;
        setIsOnNetworking(true);
        try {
            const data = await cancelFollow(studentId);
            if (data) {
                setInfo((prev: any) => prev ? { ...prev, isFollowing: false } : null);
            }
        } catch (error: any) {
            console.error(error);
        } finally {
            setIsOnNetworking(false);
        }
    };

    return <StudentInfoComponent
        isInit={isInit}
        info={info}
        errorMessage={errorMessage}

        isOnLoading={isOnLoading}
        photos={photos}
        page={page}
        errorMessageForPhotos={errorMessageForPhotos}
        hasMore={hasMore}
        loadMorePhotos={loadMorePhotos}
        handleFollow={onFollow}
        handleUnfollow={onUnfollow}
        isOnNetworking={isOnNetworking}
    />;
};

export default StudentInfoContainer;