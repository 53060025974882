import { getProfileImageUrl } from "helpers/Image.helper";
import React from "react";
import { getImageUrl } from "helpers/Image.helper";
import { getCurrentLangName, getFlagByCountryCode } from "helpers/String.helper";
import LoadingComponent from "commons/loading/Loading.component";

const StudentInfoPopupComponent = (props: any) => {
    const { info, isInit, onClose, onDetailInfo, isOnNetworking, onFavoriteToggle } = props;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 p-4 !m-0">
            <div className="bg-white rounded-2xl p-6 w-full max-w-2xl mx-auto">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-bold text-gray-900">지망생 정보</h3>
                    <button className="text-gray-400 hover:text-gray-600" onClick={onClose}>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {isInit ? (
                    <>
                        <div className="flex flex-col items-center">
                            <>
                                <div className="w-32 h-32 rounded-full overflow-hidden mb-4">
                                    <img
                                        src={getProfileImageUrl(info.userStudentInfo.profileImageInfo, 200)}
                                        alt="프로필 이미지"
                                        className="w-full h-full object-cover"
                                    />
                                </div>

                                <div className="text-center mb-4">
                                    <h4 className="text-xl font-bold mb-2">
                                        {info.userStudentInfo.firstName} {info.userStudentInfo.lastName}
                                    </h4>
                                    <p className="text-gray-600 mb-2">{info.userStudentInfo.gender}</p>
                                    <div className="flex items-center justify-center gap-2">
                                        <span className="text-2xl">
                                            {getFlagByCountryCode(info.userStudentInfo.countryInfo?.code)}
                                        </span>
                                        <span className="text-gray-700">
                                            {getCurrentLangName(info.userStudentInfo.countryInfo?.names)}
                                        </span>
                                    </div>
                                </div>

                                {info.userStudentInfo.specialtyInfoList?.length > 0 && (
                                    <div className="w-full mb-4">
                                        <div className="flex flex-wrap gap-2 justify-center">
                                            {info.userStudentInfo.specialtyInfoList.map((item: any, index: number) => (
                                                <span
                                                    key={index}
                                                    className="px-3 py-1 bg-gray-100 rounded-full text-sm"
                                                >
                                                    {getCurrentLangName(item.names)}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {/* 추가이미지  */}
                                {info.userStudentInfo.userStudentImageInfoList?.length > 0 && (
                                    <div className="w-full mb-4">
                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 flex overflow-x-auto pb-2 gap-2">
                                            {info.userStudentInfo.userStudentImageInfoList.map((item: any, index: number) => (
                                                <img
                                                    key={index}
                                                    src={getImageUrl(item.imageInfo, 200)}
                                                    alt="추가이미지"
                                                    className="w-24 h-24 rounded-lg object-cover flex-shrink-0 sm:w-full sm:h-auto sm:aspect-square"
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="flex gap-4 w-full">
                                {info.isFavorite ?
                                    <button
                                        onClick={onFavoriteToggle}
                                        disabled={isOnNetworking}
                                        className="w-1/2 px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 border-2 border-gray-200 rounded-xl
                                hover:bg-gray-80 focus:outline-none focus:ring-2 focus:ring-gray-200
                                transition-colors">
                                        <div className="flex items-center justify-center gap-2">
                                            <img src="imgs/icons/icon-bookmark-active.svg" alt="즐겨찾기" className="w-4 h-4" />
                                            <span className="text-sm font-medium text-gray-700">즐겨찾기 해제</span>
                                        </div>
                                    </button>
                                    :
                                    <button
                                        onClick={onFavoriteToggle}
                                        disabled={isOnNetworking}
                                        className="w-1/2 px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 border-2 border-gray-200 rounded-xl
                                hover:bg-gray-80 focus:outline-none focus:ring-2 focus:ring-gray-200
                                transition-colors">
                                        <div className="flex items-center justify-center gap-2">
                                            <img src="imgs/icons/icon-bookmark.svg" alt="즐겨찾기" className="w-4 h-4" />
                                            <span className="text-sm font-medium text-gray-700">즐겨찾기 추가</span>
                                        </div>
                                    </button>
                                }
                                <button
                                    onClick={onDetailInfo}
                                    className="w-1/2 px-4 py-3 text-sm font-medium text-white bg-[#CF3688] rounded-xl
                                hover:bg-[#B82F77] focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                transition-colors">
                                    상세보기
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </div>
    )
};

export default StudentInfoPopupComponent;