import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";

const VerifyEmailComponent = (props: any) => {
  const { isVerify } = props;

  return (
    <main className="flex min-h-screen items-center justify-center p-4">
      {typeof isVerify !== "boolean" ? (
        <LoadingComponent />
      ) : (
        <div className="text-center">
          {isVerify ? (
            <div className="space-y-4">
              <strong className="block text-xl text-[#CF3688]">
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "verifyEmail.txtSuccessful"
                )}
              </strong>
            </div>
          ) : (
            <div className="space-y-4">
              <strong className="block text-xl text-red-600">
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "verifyEmail.txtFailed1"
                )}
              </strong>
              <p className="text-gray-600">
                {getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtFailed2")}
              </p>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default VerifyEmailComponent;
