import React from "react";

const SocketWrapComponent = (props: any) => {
    const { socketStatus, retryCount, handleReconnect, MAX_RECONNECT_COUNT } = props;
    return (
        <>
            {
                (socketStatus !== 'connected') && (
                    <div className="px-3 sm:px-4 py-1.5 sm:py-2 text-center text-xs sm:text-sm bg-yellow-50 text-yellow-700">
                        {socketStatus === 'connecting' && ("연결 중...  ")}
                        {socketStatus === 'reconnecting' && ("연결 재시도 중...")}
                        {(socketStatus === 'disconnected' && retryCount.current < MAX_RECONNECT_COUNT) && ("연결 끊김")}
                        {(socketStatus === 'disconnected' && retryCount.current === MAX_RECONNECT_COUNT) && ("채널 종료")}
                        {socketStatus === 'error' && ("오류 발생")}
                        {socketStatus === 'gone' && ("채널 종료")}
                    </div>)
            }
            {
                (socketStatus === 'gone' ||
                    (socketStatus === 'disconnected' && retryCount.current === MAX_RECONNECT_COUNT)) && (
                    <div className="flex justify-center px-3 sm:px-4 py-1.5 sm:py-2">
                        <button
                            onClick={handleReconnect}
                            className="w-32 bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium py-1.5 px-3 rounded-lg transition-colors text-sm">
                            채널 재연결
                        </button>
                    </div>
                )
            }

            {/* <button
                onClick={() => createWebSocket()}
                className="w-[48%] bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-lg transition-colors"
            >
                연결
            </button>
            <button
                onClick={handleWebSocketDisconnect}
                className="w-[48%] bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-lg transition-colors"
            >
                연결 해제
            </button> */}

            {/* <button onClick={() => sendMessage("test" + Math.random())}>sendMessage</button><br /> */}
        </>
    );
};

export default SocketWrapComponent;