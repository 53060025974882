import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getCurrentLangName } from "helpers/String.helper";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { Link, useNavigate } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";

const PublicProfileComponent = (props: any) => {
    const {
        studentInfo,
        isActiveOfPublicProfile,
        onChangeIsActiveOfPublicProfile,
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <h2 className="text-3xl font-bold text-gray-900 mb-8">{getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtTitle")}</h2>
                {studentInfo ? (
                    <div className="max-w-3xl mx-auto space-y-6">
                        {/* 프로필 이미지 섹션 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6 sm:p-8">
                                <div className="flex flex-col items-center">
                                    <div className="w-48 h-48 rounded-full overflow-hidden mb-6">
                                        <img
                                            src={
                                                studentInfo.profileImageInfo?.url
                                                    ? `${studentInfo.profileImageInfo?.url}?w=192&h=192`
                                                    : "./imgs/dummy/basic-img-2.png"
                                            }
                                            alt="profile image"
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <h3 className="text-2xl font-bold text-gray-900">{`${studentInfo.firstName} ${studentInfo.lastName}`}</h3>
                                    {studentInfo?.nickname && (
                                        <p className="text-gray-500 mt-2">{studentInfo.nickname}</p>
                                    )}
                                </div>
                                <div className="space-y-4">
                                    <div>
                                        <h4 className="text-sm font-medium text-gray-500 mb-2">{getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtCountry")}</h4>
                                        <p className="text-base font-medium text-gray-900">{getCurrentLangName(studentInfo.countryCodeInfo.names)}</p>
                                    </div>
                                    <div>
                                        <h4 className="text-sm font-medium text-gray-500 mb-2">{getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtBirthday")}</h4>
                                        <p className="text-base font-medium text-gray-900">{getFormattedDateValue(studentInfo.birthday, "YYYY.MM.DD")}</p>
                                    </div>
                                    <div>
                                        <h4 className="text-sm font-medium text-gray-500 mb-2">{getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtGender")}</h4>
                                        <p className="text-base font-medium text-gray-900">{getCurrentLangName(studentInfo.genderTypeCodeInfo.names)}</p>
                                    </div>
                                </div>
                                {/* 수정 버튼 */}
                                <div className="mt-4">
                                    <Link
                                        to="/student/profile/public/essential-info/edit"
                                        className="block w-full px-4 py-3 text-sm font-medium text-center !text-white bg-[#CF3688] rounded-xl hover:bg-[#B82F77] transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.btnEditEssentialInfo")}
                                    </Link>
                                </div>
                            </div>
                        </section>

                        {/* 신체 정보 섹션 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6 sm:p-8">
                                <h3 className="text-lg font-semibold text-gray-900 mb-6">{getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtSectionBodyInfo")}</h3>
                                <div className="space-y-4">

                                    <Link
                                        to={`/student/profile/public/body-info/height/edit`}
                                        className="flex items-center justify-between">
                                        <div>
                                            <h4 className="text-sm font-medium text-gray-500 mb-2">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtHeight")}</h4>
                                            <p className="text-base font-medium text-gray-900">
                                                {studentInfo.height} cm
                                                {!studentInfo.isPublicHeight && `(${getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtHidden")})`}
                                            </p>
                                        </div>
                                        <div className="text-sm text-gray-500 hover:text-gray-700">
                                            <svg className="w-5 h-5 text-[#CF3688]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </Link>

                                    <Link
                                        to={`/student/profile/public/body-info/weight/edit`}
                                        className="flex items-center justify-between">
                                        <div>
                                            <h4 className="text-sm font-medium text-gray-500 mb-2">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtWeight")}</h4>
                                            <p className="text-base font-medium text-gray-900">
                                                {studentInfo.weight} kg
                                                {!studentInfo.isPublicWeight && `(${getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtHidden")})`}
                                            </p>
                                        </div>
                                        <div className="text-sm text-gray-500 hover:text-gray-700">
                                            <svg className="w-5 h-5 text-[#CF3688]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </Link>


                                    <Link
                                        to={`/student/profile/public/body-info/shoe-size/edit`}
                                        className="flex items-center justify-between">
                                        <div>
                                            <h4 className="text-sm font-medium text-gray-500 mb-2">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtShoeSize")}</h4>
                                            <p className="text-base font-medium text-gray-900">
                                                {studentInfo.shoeSize} mm
                                                {!studentInfo.isPublicShoeSize && `(${getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtHidden")})`}
                                            </p>
                                        </div>
                                        <div className="text-sm text-gray-500 hover:text-gray-700">
                                            <svg className="w-5 h-5 text-[#CF3688]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </section>

                        {/* 추가 정보 섹션 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6 sm:p-8">
                                <h3 className="text-lg font-semibold text-gray-900 mb-6">{getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtSectionAdditionalInfo")}</h3>
                                <div className="space-y-4">

                                    <div>
                                        <h4 className="text-sm font-medium text-gray-500 mb-2">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtBloodType")}
                                        </h4>
                                        <p className="text-base font-medium text-gray-900">
                                            {
                                                studentInfo.bloodTypeCodeInfo
                                                    ?
                                                    getCurrentLangName(studentInfo.bloodTypeCodeInfo.names)
                                                    :
                                                    getLanguageValue(PAGE_TYPE.COMMON, "txt.txtKeepPrivate")
                                            }
                                        </p>
                                    </div>
                                    <div>
                                        <h4 className="text-sm font-medium text-gray-500 mb-2">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtMBTI")}
                                        </h4>
                                        <p className="text-base font-medium text-gray-900">
                                            {
                                                studentInfo.mbtiCode
                                                    ?
                                                    studentInfo.mbtiCode
                                                    :
                                                    getLanguageValue(PAGE_TYPE.COMMON, "txt.txtKeepPrivate")
                                            }
                                        </p>
                                    </div>
                                    <div>
                                        <h4 className="text-sm font-medium text-gray-500 mb-2">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtSpecialty")}
                                        </h4>
                                        <p className="text-base font-medium text-gray-900">
                                            {
                                                studentInfo?.specialtyCodeInfoList.length > 0
                                                    ?
                                                    studentInfo?.specialtyCodeInfoList.map((code: any) => getCurrentLangName(code.names)).join(", ")
                                                    :
                                                    getLanguageValue(PAGE_TYPE.COMMON, "txt.txtDidntChoose")
                                            }
                                        </p>
                                    </div>

                                </div>
                                {/* 수정 버튼 */}
                                <div className="mt-4">
                                    <Link
                                        to="/student/profile/public/additional-info/edit"
                                        className="block w-full px-4 py-3 text-sm font-medium text-center !text-white bg-[#CF3688] rounded-xl hover:bg-[#B82F77] transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.btnEditAdditionalInfo")}
                                    </Link>
                                </div>
                            </div>
                        </section>
                        {/* 공개 프로필 활성화 여부 섹션 */}
                        <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                            <div className="p-6 sm:p-8">
                                <div className="flex items-center justify-between">
                                    <div>
                                        {/* <h3 className="text-lg font-semibold text-gray-900">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtPublicProfile")}
                                        </h3> */}
                                        <p className="text-sm text-gray-500 mt-2">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtPublicProfileDesc")}
                                        </p>
                                    </div>
                                    <div className="relative inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={isActiveOfPublicProfile ?? false}
                                            onChange={(e) => onChangeIsActiveOfPublicProfile(e.target.checked)}
                                            className="sr-only peer"
                                            id="toggle-active"
                                        />
                                        <label
                                            htmlFor="toggle-active"
                                            className="flex items-center w-14 h-7 bg-gray-200 rounded-full cursor-pointer 
                                                             peer-checked:bg-[#CF3688] peer-focus:ring-2 peer-focus:ring-[#CF3688]/20 
                                                             after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                                                             after:bg-white after:rounded-full after:h-6 after:w-6 after:transition-all
                                                             after:shadow-sm peer-checked:after:translate-x-7"
                                        >
                                            <span className="sr-only">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtPublicProfileActivate")}
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="mt-4 bg-gray-50 rounded-lg p-4 border border-gray-100">
                                    <h4 className="text-sm font-medium text-gray-700 mb-3">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtPublicProfileExplainTitle")}
                                    </h4>
                                    <ul className="space-y-2">
                                        <li className="flex items-start text-sm text-gray-600">
                                            <svg className="w-5 h-5 text-gray-400 mr-2 mt-0.5 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtPublicProfileExplainDesc1")}
                                        </li>
                                        <li className="flex items-start text-sm text-gray-600">
                                            <svg className="w-5 h-5 text-gray-400 mr-2 mt-0.5 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtPublicProfileExplainDesc2")}
                                        </li>
                                    </ul>
                                    <div className="mt-4 flex items-start">
                                        <svg className="w-5 h-5 text-[#CF3688] mr-2 mt-0.5 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <p className="text-sm text-[#CF3688] font-medium">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtPublicProfileExplainDesc3")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                ) : (
                    <div className="flex justify-center items-center h-64">
                        <LoadingComponent />
                    </div>
                )}
            </div>
        </main>
    );
};

export default PublicProfileComponent;
