import React, { useEffect, useState } from "react";
import AgencyProfilePhotoComponent from "./AgencyProfilePhoto.component";
import { useParams } from "react-router-dom";
import * as ProfileApi from "api/student/Profile.api";
import * as DataApi from "api/common/Data.api";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { usePopup } from "contexts/PopupContext";
import AgencyProfilePhotoModalPopupContainer from "./AgencyProfilePhotoModalPopup.container";
const AgencyProfilePhotoContainer = () => {
    const { category, type } = useParams();
    const { showPopup } = usePopup();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(6);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isOnNetworking, setIsOnNetworking] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedPopupImageInfo, setSelectedPopupImageInfo] = useState(null);

    useEffect(() => {
        fetchList(page, limit);
    }, []);

    const fetchList = async (page: number, limit: number, needInit: boolean = false) => {
        if (isLoading) return;

        setIsLoading(true);

        const data = await ProfileApi.getAgencyProfilePhoto(category, type, page, limit)

        if (data?.list) {
            setPage(page);
            setLimit(limit);
            for (const item of data.list) {
                const data = await DataApi.getImagePresignedUrl(item.imageId)

                if (data.result) {
                    item.url = data.result;
                }
            }
            if (needInit) {
                setList(data.list);
            } else {
                setList([...list, ...data.list]);
            }
            setHasMore(data.list.length === limit);
        } else {

        }

        setIsLoading(false);
    }

    const handleDelete = async (id: number) => {
        console.log("student/profile/AgencyProfilePhoto.container::handleDelete", id);
        if (isLoading) return;

        if (!window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "btn.txtDeleteConfirmation"))) return;

        setIsOnNetworking(true);
        const data = await ProfileApi.deleteAgencyProfilePhoto(id);
        setIsOnNetworking(false);

        console.log(data);
        if (data.result) {
            console.log(list);
            const newList = [...list];
            newList.splice(newList.findIndex((item: any) => item.id === id), 1);
            console.log(newList);
            setList([...newList]);
        }
    }

    const loadMore = () => {
        if (hasMore) {
            fetchList(page + 1, limit);
        }
    }

    const showImageModelPopup = async (imageInfo: any) => {
        console.log("student/profile/AgencyProfilePhoto.container::showImageModelPopup", imageInfo);

        showPopup(
            <AgencyProfilePhotoModalPopupContainer
                imageInfo={imageInfo}
                handleClosePopup={handleClosePopup} />
        )
    }

    const handleClosePopup = (evt: any) => {
        evt.preventDefault();
        console.log("student/profile/AgencyProfilePhoto.container::handleClosePopup");
        setIsPopupOpen(false);
    }

    return (
        <AgencyProfilePhotoComponent
            list={list}
            isLoading={isLoading}
            handleDelete={handleDelete}
            isOnNetworking={isOnNetworking}
            hasMore={hasMore}
            loadMore={loadMore}
            category={category}
            type={type}
            isPopupOpen={isPopupOpen}
            showImageModelPopup={showImageModelPopup}
            handleClosePopup={handleClosePopup}
            selectedPopupImageInfo={selectedPopupImageInfo}
        />
    )
}

export default AgencyProfilePhotoContainer;