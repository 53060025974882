import * as BaseApi from "api/Base.api";

const BASE_URL = "/agency/v2/my";

//* 마이페이지 정보 조회
export const getMyPageInfo = async () => {
    const url = `${BASE_URL}/`;
    return await BaseApi.get(url);
};

//* 비밀번호 변경
export const changePassword = async (params: any) => {
    const url = `${BASE_URL}/password`;
    return await BaseApi.put(url, params);
};

//* 휴대전화 번호 조회
export const getCellphone = async () => {
    const url = `${BASE_URL}/cellphone`;
    return await BaseApi.get(url);
};