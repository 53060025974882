import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";


const CookieSettingPopup = (props: any) => {
    const {
        handleSave,
        isCheckedAnalytics,
        setIsCheckedAnalytics
    } = props;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 p-4 !m-0">
            <div className="bg-white rounded-2xl p-6 w-full h-[90vh] max-h-[800px] max-w-2xl mx-auto flex flex-col">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-xl font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtTitle")}
                    </h3>
                </div>

                <div className="space-y-4 mb-6 flex-1 overflow-y-auto">
                    <p className="text-gray-600 text-base">
                        {getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtDescription")}
                    </p>

                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                                <div className="flex items-center justify-between">
                                    <div className="flex-1">
                                        <p className="font-bold text-base text-gray-900 mb-2">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtNecessaryCookies")}
                                        </p>
                                        <div className="bg-white rounded-sm p-4">
                                            <p className="text-sm font-medium mb-2">
                                                {getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtNecessaryCookiesDetail")}
                                            </p>
                                            <ul className="text-sm list-disc ml-5 space-y-2">
                                                <li className="text-sm text-gray-600">{getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtNecessaryCookiesDetailDesc1")}</li>
                                                <li className="text-sm text-gray-600">{getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtNecessaryCookiesDetailDesc2")}</li>
                                                <li className="text-sm text-gray-600">{getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtNecessaryCookiesDetailDesc3")}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="flex items-center ml-4">
                                        <input type="checkbox" checked disabled className="w-6 h-6 rounded border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                                <div className="flex items-center justify-between">
                                    <div className="flex-1">
                                        <p className="font-semibold text-base text-gray-900 mb-2">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtAnalyticsCookies")}
                                        </p>
                                        <div className="bg-white rounded-sm p-4">
                                            <p className="text-sm font-medium mb-2">
                                                {getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtAnalyticsCookiesDetail")}
                                            </p>
                                            <ul className="text-sm list-disc ml-5 space-y-2">
                                                <li className="text-sm text-gray-600">{getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtAnalyticsCookiesDetailDesc1")}</li>
                                                <li className="text-sm text-gray-600">{getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtAnalyticsCookiesDetailDesc2")}</li>
                                                <li className="text-sm text-gray-600">{getLanguageValue(PAGE_TYPE.COMMON, "cookieSettingPopup.txtAnalyticsCookiesDetailDesc3")}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="flex items-center ml-4">
                                        <input type="checkbox" checked={isCheckedAnalytics} onChange={() => setIsCheckedAnalytics(!isCheckedAnalytics)} className="w-6 h-6 rounded border-gray-300 cursor-pointer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end gap-3 mt-auto">
                    <button
                        onClick={handleSave}
                        className="px-6 py-2.5 bg-[#CF3688] text-white rounded-lg hover:bg-[#CF3688]/90 text-base font-medium">
                        {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtSave")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookieSettingPopup;