import { useEffect, useState } from "react";
import StudentGrowthHistoryPopupComponent from "./StudentGrowthHistoryPopup.component";
import { fetchStudentGrowthHistory } from "api/agency/v2/Popup.api";

const StudentGrowthHistoryPopup = (props: any) => {
    const {
        userStudentId,
        bodyInfoTypeCode,
        onClose
    } = props;

    const [isInit, setIsInit] = useState(false);
    const [list, setList] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");
    useEffect(() => {
        fetchInfo();
    }, [userStudentId, bodyInfoTypeCode]);

    const fetchInfo = async () => {
        try {
            const data = await fetchStudentGrowthHistory(userStudentId, bodyInfoTypeCode);
            if (data?.list) {
                setList(data?.list);
            }
        } catch (error: any) {
            setErrorMessage(error.message || "성장 정보를 불러오는데 실패했습니다.");
        } finally {
            setIsInit(true);
        }
    }

    return <StudentGrowthHistoryPopupComponent
        isInit={isInit}
        list={list}
        bodyInfoTypeCode={bodyInfoTypeCode}
        onClose={onClose}
        errorMessage={errorMessage}
    />;
};

export default StudentGrowthHistoryPopup;