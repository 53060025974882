import { usePopup } from "contexts/PopupContext";
import PostItemComponent from "./PostItem.component";
import LikePopupForFanPagePostContainer from "commons/popup/LikePopupForFanPagePost.container";
const PostItemContainer = (props: any) => {
    const { post, posts, setPosts } = props;
    const { showPopup } = usePopup();
    const handleShowLikePopup = (postId: number) => {
        showPopup(
            <LikePopupForFanPagePostContainer postId={postId} />
        );
    };
    return <PostItemComponent post={post} posts={posts} setPosts={setPosts} onShowLikePopup={handleShowLikePopup} />;
};

export default PostItemContainer;