import React, { useEffect } from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { Link, useNavigate } from "react-router-dom";

const AgencyProfileComponent = (props: any) => {
    const navigate = useNavigate();

    const {
        profile,
        toggleActiveProfile,
        inputRef,
        videoInfo,
        faceFrontImageInfo,
        faceLeftImageInfo,
        faceRightImageInfo,
        fullBodyFrontImageInfo,
        fullBodyLeftImageInfo,
        fullBodyRightImageInfo,
        uploadVideo,
        videoRef,
        uploadImage,
        openViewPopup,
        handleEnded,
        handleSeeked,
        handlePause,
        handlePlay,

        isProfileActive,
        onChangeIsProfileActive,
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                <h2 className="text-3xl font-bold text-gray-900 mb-8">{getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtTitle")}</h2>
                <p className="text-gray-600 mb-8">{getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtDesc")}</p>

                <div className="max-w-3xl mx-auto space-y-6">
                    {/* Introduction Video 섹션 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                        <div className="p-6 sm:p-8">
                            <h3 className="text-lg font-semibold text-gray-900 mb-6">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtIntroVideoTitle")}
                            </h3>
                            <p className="text-sm text-gray-600 mb-4">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtIntroVideoDesc")}
                            </p>
                            <div className="aspect-video rounded-xl overflow-hidden bg-gray-100">
                                <video
                                    controls
                                    ref={videoRef}
                                    muted
                                    playsInline
                                    className="w-full h-full object-cover"
                                    src={videoInfo?.url || ''}
                                    crossOrigin="anonymous"
                                    onEnded={handleEnded}
                                    onSeeked={handleSeeked}
                                    onPause={handlePause}
                                    onPlay={handlePlay}
                                >
                                </video>
                            </div>
                            <div className="mt-4 flex justify-end items-center">
                                <Link
                                    to="/student/profile/agency/intro-video"
                                    className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors">
                                    <svg className="w-5 h-5" fill="currentColor" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="4" y="4" width="12" height="12" strokeWidth={2} />
                                        <rect x="8" y="8" width="12" height="12" strokeWidth={2} />
                                    </svg>
                                    <span className="text-sm">{videoInfo?.videoCount || 0}</span>
                                </Link>
                                <button
                                    // onClick={() => openViewPopup(videoInfo?.id, "introVideo")}
                                    className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>
                                    <span className="font-medium">{videoInfo?.viewCount || 0}</span>
                                </button>
                            </div>
                            <div className="mt-4">
                                <input
                                    type="file"
                                    id="input-file-video"
                                    onChange={uploadVideo}
                                    accept="video/*"
                                    className="hidden"
                                />
                                <button
                                    onClick={() => document.getElementById("input-file-video").click()}
                                    className="block w-full px-4 py-3 text-sm font-medium text-center !text-white bg-[#CF3688] rounded-xl hover:bg-[#B82F77] transition-colors">
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.btnUpload")}
                                </button>
                            </div>
                        </div>
                    </section>

                    {/* Face Photo 섹션 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                        <div className="p-6 sm:p-8">
                            <h3 className="text-lg font-semibold text-gray-900 mb-6">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtFacePhotoTitle")}
                            </h3>
                            <p className="text-sm text-gray-600 mb-4">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtFacePhotoDesc")}
                            </p>
                            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-6">
                                {/* Front Photo */}
                                <div className="space-y-4">
                                    <div className="relative aspect-square rounded-xl overflow-hidden bg-gray-100">
                                        <img
                                            src={faceFrontImageInfo?.url || "./imgs/dummy/basic-img-3.png"}
                                            alt="Front photo"
                                            className="w-full h-full object-cover"
                                        />
                                        <div className="absolute top-2 right-2 flex items-center space-x-2 bg-black/50 rounded-lg px-2 py-1">
                                            <button
                                                // onClick={() => openViewPopup(faceFrontImageInfo?.id, "profileImage")}
                                                className="flex items-center space-x-1 text-white hover:text-gray-200">
                                                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                                <span>{faceFrontImageInfo?.viewCount || 0}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium text-gray-900">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtFront")}
                                            </p>
                                        </div>
                                        <Link
                                            to={`/student/profile/agency/photo/face/front`}
                                            className="flex items-center space-x-2 text-gray-600 hover:text-gray-800">
                                            <svg className="w-5 h-5" fill="white" stroke="currentColor" viewBox="0 0 24 24">
                                                <rect x="4" y="4" width="12" height="12" strokeWidth={2} />
                                                <rect x="8" y="8" width="12" height="12" strokeWidth={2} />
                                            </svg>
                                            <span className="text-sm">{faceFrontImageInfo?.imageCount || 0}</span>

                                        </Link>
                                    </div>

                                    <input
                                        type="file"
                                        id="input-file-image-face-front"
                                        onChange={(evt) => uploadImage(evt, "profileImageCategory:face", "profileImageType:front")}
                                        accept="image/*"
                                        className="hidden"
                                    />
                                    <button
                                        onClick={() => document.getElementById("input-file-image-face-front").click()}
                                        className="block w-full px-4 py-3 text-sm font-medium text-center !text-white bg-[#CF3688] rounded-xl hover:bg-[#B82F77] transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.btnUpload")}
                                    </button>
                                </div>

                                {/* Left Photo */}
                                <div className="space-y-4">
                                    <div className="relative aspect-square rounded-xl overflow-hidden bg-gray-100">
                                        <img
                                            src={faceLeftImageInfo?.url || "./imgs/dummy/basic-img-3.png"}
                                            alt="Left photo"
                                            className="w-full h-full object-cover"
                                        />
                                        <div className="absolute top-2 right-2 flex items-center space-x-2 bg-black/50 rounded-lg px-2 py-1">
                                            <button
                                                // onClick={() => openViewPopup(faceLeftImageInfo?.id, "profileImage")}
                                                className="flex items-center space-x-1 text-white hover:text-gray-200">
                                                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                                <span>{faceLeftImageInfo?.viewCount || 0}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium text-gray-900">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtLeft")}
                                            </p>
                                        </div>
                                        <Link
                                            to={`/student/profile/agency/photo/face/left`}
                                            className="flex items-center space-x-2 text-gray-600 hover:text-gray-800">
                                            <svg className="w-5 h-5" fill="white" stroke="currentColor" viewBox="0 0 24 24">
                                                <rect x="4" y="4" width="12" height="12" strokeWidth={2} />
                                                <rect x="8" y="8" width="12" height="12" strokeWidth={2} />
                                            </svg>
                                            <span className="text-sm">{faceLeftImageInfo?.imageCount || 0}</span>

                                        </Link>
                                    </div>
                                    <input
                                        type="file"
                                        id="input-file-image-face-left"
                                        onChange={(evt) => uploadImage(evt, "profileImageCategory:face", "profileImageType:left")}
                                        accept="image/*"
                                        className="hidden"
                                    />
                                    <button
                                        onClick={() => document.getElementById("input-file-image-face-left").click()}
                                        className="block w-full px-4 py-3 text-sm font-medium text-center !text-white bg-[#CF3688] rounded-xl hover:bg-[#B82F77] transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.btnUpload")}
                                    </button>
                                </div>

                                {/* Right Photo */}
                                <div className="space-y-4">
                                    <div className="relative aspect-square rounded-xl overflow-hidden bg-gray-100">
                                        <img
                                            src={faceRightImageInfo?.url || "./imgs/dummy/basic-img-3.png"}
                                            alt="Right photo"
                                            className="w-full h-full object-cover"
                                        />
                                        <div className="absolute top-2 right-2 flex items-center space-x-2 bg-black/50 rounded-lg px-2 py-1">
                                            <button
                                                // onClick={() => openViewPopup(faceRightImageInfo?.id, "profileImage")}
                                                className="flex items-center space-x-1 text-white hover:text-gray-200">
                                                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                                <span>{faceRightImageInfo?.viewCount || 0}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium text-gray-900">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtRight")}
                                            </p>
                                        </div>
                                        <Link
                                            to={`/student/profile/agency/photo/face/right`}
                                            className="flex items-center space-x-2 text-gray-600 hover:text-gray-800">
                                            <svg className="w-5 h-5" fill="white" stroke="currentColor" viewBox="0 0 24 24">
                                                <rect x="4" y="4" width="12" height="12" strokeWidth={2} />
                                                <rect x="8" y="8" width="12" height="12" strokeWidth={2} />
                                            </svg>
                                            <span className="text-sm">{faceRightImageInfo?.imageCount || 0}</span>
                                        </Link>
                                    </div>
                                    <input
                                        type="file"
                                        id="input-file-image-face-right"
                                        onChange={(evt) => uploadImage(evt, "profileImageCategory:face", "profileImageType:right")}
                                        accept="image/*"
                                        className="hidden"
                                    />
                                    <button
                                        onClick={() => document.getElementById("input-file-image-face-right").click()}
                                        className="block w-full px-4 py-3 text-sm font-medium text-center !text-white bg-[#CF3688] rounded-xl hover:bg-[#B82F77] transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.btnUpload")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Full Body Photo 섹션 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                        <div className="p-6 sm:p-8">
                            <h3 className="text-lg font-semibold text-gray-900 mb-6">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtFullBodyPhotoTitle")}
                            </h3>
                            <p className="text-sm text-gray-600 mb-4">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtFullBodyPhotoDesc")}
                            </p>
                            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-6">
                                {/* Front Photo */}
                                <div className="space-y-4">
                                    <div className="aspect-square rounded-xl overflow-hidden bg-gray-100 relative">
                                        <img
                                            src={fullBodyFrontImageInfo?.url || "./imgs/dummy/basic-img-3.png"}
                                            alt="Front photo"
                                            className="w-full h-full object-cover"
                                        />
                                        <div className="absolute top-2 right-2 flex items-center space-x-2 bg-black/50 rounded-lg px-2 py-1">
                                            <button
                                                // onClick={() => openViewPopup(fullBodyFrontImageInfo?.id, "profileImage")}
                                                className="flex items-center space-x-1 text-white hover:text-gray-200">
                                                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                                <span>{fullBodyFrontImageInfo?.viewCount || 0}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium text-gray-900">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtFront")}
                                            </p>
                                        </div>
                                        <Link
                                            to={`/student/profile/agency/photo/fullBody/front`}
                                            className="flex items-center space-x-2 text-gray-600 hover:text-gray-800">
                                            <svg className="w-5 h-5" fill="white" stroke="currentColor" viewBox="0 0 24 24">
                                                <rect x="4" y="4" width="12" height="12" strokeWidth={2} />
                                                <rect x="8" y="8" width="12" height="12" strokeWidth={2} />
                                            </svg>
                                            <span className="text-sm">{fullBodyFrontImageInfo?.imageCount || 0}</span>
                                        </Link>
                                    </div>
                                    <input
                                        type="file"
                                        id="input-file-image-body-front"
                                        onChange={(evt) => uploadImage(evt, "profileImageCategory:fullBody", "profileImageType:front")}
                                        accept="image/*"
                                        className="hidden"
                                    />
                                    <button
                                        onClick={() => document.getElementById("input-file-image-body-front").click()}
                                        className="block w-full px-4 py-3 text-sm font-medium text-center !text-white bg-[#CF3688] rounded-xl hover:bg-[#B82F77] transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.btnUpload")}
                                    </button>
                                </div>

                                {/* Left Photo */}
                                <div className="space-y-4">
                                    <div className="aspect-square rounded-xl overflow-hidden bg-gray-100 relative">
                                        <img
                                            src={fullBodyLeftImageInfo?.url || "./imgs/dummy/basic-img-3.png"}
                                            alt="Left photo"
                                            className="w-full h-full object-cover"
                                        />
                                        <div className="absolute top-2 right-2 flex items-center space-x-2 bg-black/50 rounded-lg px-2 py-1">
                                            <button
                                                // onClick={() => openViewPopup(fullBodyLeftImageInfo?.id, "profileImage")}
                                                className="flex items-center space-x-1 text-white hover:text-gray-200">
                                                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                                <span>{fullBodyLeftImageInfo?.viewCount || 0}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium text-gray-900">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtLeft")}
                                            </p>
                                        </div>
                                        <Link
                                            to={`/student/profile/agency/photo/fullBody/left`}
                                            className="flex items-center space-x-2 text-gray-600 hover:text-gray-800">
                                            <svg className="w-5 h-5" fill="white" stroke="currentColor" viewBox="0 0 24 24">
                                                <rect x="4" y="4" width="12" height="12" strokeWidth={2} />
                                                <rect x="8" y="8" width="12" height="12" strokeWidth={2} />
                                            </svg>
                                            <span className="text-sm">{fullBodyLeftImageInfo?.imageCount || 0}</span>
                                        </Link>
                                    </div>
                                    <input
                                        type="file"
                                        id="input-file-image-body-left"
                                        onChange={(evt) => uploadImage(evt, "profileImageCategory:fullBody", "profileImageType:left")}
                                        accept="image/*"
                                        className="hidden"
                                    />
                                    <button
                                        onClick={() => document.getElementById("input-file-image-body-left").click()}
                                        className="block w-full px-4 py-3 text-sm font-medium text-center !text-white bg-[#CF3688] rounded-xl hover:bg-[#B82F77] transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.btnUpload")}
                                    </button>
                                </div>

                                {/* Right Photo */}
                                <div className="space-y-4">
                                    <div className="aspect-square rounded-xl overflow-hidden bg-gray-100 relative">
                                        <img
                                            src={fullBodyRightImageInfo?.url || "./imgs/dummy/basic-img-3.png"}
                                            alt="Right photo"
                                            className="w-full h-full object-cover"
                                        />
                                        <div className="absolute top-2 right-2 flex items-center space-x-2 bg-black/50 rounded-lg px-2 py-1">
                                            <button
                                                // onClick={() => openViewPopup(fullBodyRightImageInfo?.id, "profileImage")}
                                                className="flex items-center space-x-1 text-white hover:text-gray-200">
                                                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                                <span>{fullBodyRightImageInfo?.viewCount || 0}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium text-gray-900">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.txtRight")}
                                            </p>
                                        </div>
                                        <Link
                                            to={`/student/profile/agency/photo/fullBody/right`}
                                            className="flex items-center space-x-2 text-gray-600 hover:text-gray-800">
                                            <svg className="w-5 h-5" fill="white" stroke="currentColor" viewBox="0 0 24 24">
                                                <rect x="4" y="4" width="12" height="12" strokeWidth={2} />
                                                <rect x="8" y="8" width="12" height="12" strokeWidth={2} />
                                            </svg>
                                            <span className="text-sm">{fullBodyRightImageInfo?.imageCount || 0}</span>
                                        </Link>
                                    </div>
                                    <input
                                        type="file"
                                        id="input-file-image-body-right"
                                        onChange={(evt) => uploadImage(evt, "profileImageCategory:fullBody", "profileImageType:right")}
                                        accept="image/*"
                                        className="hidden"
                                    />
                                    <button
                                        onClick={() => document.getElementById("input-file-image-body-right").click()}
                                        className="block w-full px-4 py-3 text-sm font-medium text-center !text-white bg-[#CF3688] rounded-xl hover:bg-[#B82F77] transition-colors">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "agencyProfile.btnUpload")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* 기획사용 프로필 활성화 여부 섹션 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden">
                        <div className="p-6 sm:p-8">
                            <div className="flex justify-between items-center mb-6">
                                <div>
                                    {/* <h3 className="text-lg font-semibold text-gray-900">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtAgencyProfile")}
                                    </h3> */}
                                    <p className="mt-1 text-sm text-gray-600">
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtAgencyProfileDesc")}
                                    </p>
                                </div>
                                <div className="relative inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={isProfileActive ?? false}
                                        onChange={(e) => onChangeIsProfileActive(e.target.checked)}
                                        className="sr-only peer"
                                        id="toggle-agency-active"
                                    />
                                    <label
                                        htmlFor="toggle-agency-active"
                                        className="flex items-center w-14 h-7 bg-gray-200 rounded-full cursor-pointer 
                                                             peer-checked:bg-[#CF3688] peer-focus:ring-2 peer-focus:ring-[#CF3688]/20 
                                                             after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                                                             after:bg-white after:rounded-full after:h-6 after:w-6 after:transition-all
                                                             after:shadow-sm peer-checked:after:translate-x-7"
                                    >
                                        <span className="sr-only">
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtAgencyProfileActivate")}
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <div className="mt-4 bg-gray-50 rounded-lg p-4 border border-gray-100">
                                <h4 className="text-sm font-medium text-gray-700 mb-3">
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtAgencyProfileExplainTitle")}
                                </h4>
                                <ul className="space-y-2">
                                    <li className="flex items-start text-sm text-gray-600">
                                        <svg className="w-5 h-5 text-gray-400 mr-2 mt-0.5 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtAgencyProfileExplainDesc1")}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
};
export default AgencyProfileComponent;
