import React, { useRef, useEffect, useState } from "react";

const ImageWithWatermarkComponent = (props: any) => {
    const { isInit, imageId, canvasRef, onClickImage } = props;

    return (
        <canvas
            className="cursor-pointer"
            ref={canvasRef}
            style={{
                maxWidth: '100%',
                height: 'auto'
            }}
        />
    );
};

export default ImageWithWatermarkComponent;
