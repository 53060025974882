import React, { useEffect, useState, useRef, memo } from 'react';
import ChatItemComponent from './ChatItem.component';
import { getImagePresignedUrl, getVideoPresignedUrl } from 'api/common/Data.api';

const ChatItemContainer = memo(({ message, userInfo, id, messageDate, onMessageAdded }: {
    message: any,
    userInfo: any,
    id: number,
    messageDate: string,
    onMessageAdded: (message: any) => void
}) => {
    const [status, setStatus] = useState<string>('');
    const [url, setUrl] = useState<string>(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const prevMessageRef = useRef<any>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const tooltipTimeoutRef = useRef<NodeJS.Timeout>();

    const handleLongPress = () => {
        // setShowTooltip(true);
    };

    const handleTooltipClose = () => {
        setShowTooltip(false);
    };

    const handleCopyMessage = () => {
        if (message.message) {
            navigator.clipboard.writeText(message.message);
        }
        setShowTooltip(false);
    };

    const handleDeleteMessage = () => {
        // TODO: 메시지 삭제 API 호출
        console.log('Delete message:', message.messageId);
        setShowTooltip(false);
    };

    const handleReportMessage = () => {
        // TODO: 메시지 신고 API 호출
        console.log('Report message:', message.messageId);
        setShowTooltip(false);
    };

    useEffect(() => {
        console.log('message', message);
        if (message.messageType === 'text') {
            onMessageAdded(message);
        }
    }, [message]);

    useEffect(() => {
        // 이미 URL이 있거나 상태가 설정된 경우 스킵
        if (prevMessageRef.current?.messageId === message.messageId && (url || status)) {
            return;
        }

        async function fetchPresignedUrl() {
            if (message.messageType === 'image' && message.fileInfo?.id && !url) {
                setStatus('loading');
                getImagePresignedUrl(message.fileInfo.id)
                    .then(data => {
                        if (data?.result) {
                            setUrl(data.result);
                            setStatus('done');
                        }
                    })
                    .catch(error => {
                        console.error("Failed to fetch presigned URL", error);
                        setStatus('error');
                    });
            }
        }

        fetchPresignedUrl();
        prevMessageRef.current = message;
    }, [message.messageId]); // message 전체 대신 messageId만 의존성으로 사용

    const handleVideoPlay = async () => {
        setStatus('loading');
        try {
            const data = await getVideoPresignedUrl(message.fileInfo?.id);
            setUrl(data.result);
            setStatus('done');
        } catch (error) {
            console.error("Failed to fetch presigned URL", error);
            setStatus('error');
        }
    };

    const handleVideoError = async () => {
        if (!videoRef.current) return;

        if (status === 'loading') {
            return;
        }

        console.log("Video playback error, attempting to refresh URL");
        const currentTime = videoRef.current.currentTime;
        videoRef.current.pause();

        setStatus('loading');
        try {
            const data = await getVideoPresignedUrl(message.fileInfo?.id);
            setUrl(data.result);
            setStatus('done');

            // URL이 새로 설정된 후 이전 재생 시점부터 재생
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.currentTime = currentTime;
                    videoRef.current.play().catch(console.error);
                }
            }, 100);
        } catch (error) {
            console.error("Failed to refresh video URL", error);
            setStatus('error');
        }
    };

    const onLoadedImage = () => {
        console.log("onLoadedImage");
        onMessageAdded(message);
    }

    const onLoadedVideo = () => {
        console.log("onLoadedVideo");
        onMessageAdded(message);
    }



    return (
        <ChatItemComponent
            message={message}
            status={status}
            url={url}
            userInfo={userInfo}
            messageDate={messageDate}
            handleVideoPlay={handleVideoPlay}
            handleVideoError={handleVideoError}
            videoRef={videoRef}
            showTooltip={showTooltip}
            handleLongPress={handleLongPress}
            handleTooltipClose={handleTooltipClose}
            handleCopyMessage={handleCopyMessage}
            handleDeleteMessage={handleDeleteMessage}
            handleReportMessage={handleReportMessage}

            onLoadedImage={onLoadedImage}
            onLoadedVideo={onLoadedVideo}
        />
    );
});

export default ChatItemContainer;