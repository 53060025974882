import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import * as AuthApi from "api/common/Auth.api";

import VerifyParentEmailComponent from "./VerifyParentEmail.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const VerifyParentEmailContainer = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [params, setParams] = useState<any>({
        countryCode: "",
        firstName: "",
        lastName: "",
        phoneCode: "",
        phoneNumber: "",
        isAgreeTerm: false,
    });
    const btnRef = useRef<any>();

    const { token } = useParams();

    const verifyEmail = async () => {
        await AuthApi.verifyParentEmail(token)
            .then((data: any) => {
                console.log("VerifyParentEmailContainer:verifyEmail", data);
                if (data?.info) {
                    setUserInfo(data.info);
                } else {
                    setUserInfo(false);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onChangeInput = (componentName: string, value: any) => {
        console.log("VerifyParentEmailContainer:onChangeInput", componentName, value);

        if (componentName === "countryCode") {
            setParams({ ...params, countryCode: value });
        } else if (componentName === "firstName") {
            setParams({ ...params, firstName: value });
        } else if (componentName === "lastName") {
            setParams({ ...params, lastName: value });
        } else if (componentName === "phoneCode") {
            setParams({ ...params, phoneCode: value });
        } else if (componentName === "phoneNumber") {
            setParams({ ...params, phoneNumber: value });
        } else if (componentName === "isAgreeTerm") {
            setParams({ ...params, isAgreeTerm: value });
        }
    };

    const isDisabled = () => {
        if (!params.countryCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.errCountryCode"));
            return true;
        } else if (!params.firstName) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.errFirstName"));
            return true;
        } else if (!params.lastName) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.errLastName"));
            return true;
        } else if (!params.phoneCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.errPhoneCode"));
            return true;
        } else if (!params.phoneNumber) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.errPhoneNumber"));
            return true;
        } else if (!params.isAgreeTerm) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.errIsAgree"));
            return true;
        } else {
            return false;
        }
    };

    const saveInfo = async () => {
        if (!isDisabled()) {
            btnRef.current.disabled = true;
            const result = await AuthApi.verifyParentEmailAndSaveParentInfo(token, params);
            btnRef.current.disabled = false;

            if (result?.result) {
                alert(getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtSuccessful"));
                navigate(`/`);
            }
        }
    };

    useEffect(() => {
        if (!token) {
            navigate(`/`);
            return;
        }

        verifyEmail();
    }, []);

    return (
        <VerifyParentEmailComponent
            isLoading={isLoading}
            userInfo={userInfo}
            params={params}
            onChangeInput={onChangeInput}
            saveInfo={saveInfo}
            btnRef={btnRef}
        />
    );
};

export default VerifyParentEmailContainer;
