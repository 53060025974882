import React, { useEffect } from "react";
import AgencyInfoPopupComponent from "./AgencyInfoPopup.component";
import { useNetwork } from "contexts/NetworkContext";
import { useState } from "react";
import { fetchAgencyInfo } from "api/student/v2/popup.api";

const AgencyInfoPopupContainer = (props: any) => {
    const { agencyId, onClose } = props;
    const [isInit, setIsInit] = useState(false);
    const [info, setInfo]: any = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const data = await fetchAgencyInfo(agencyId);
            if (data?.info) {
                setInfo(data?.info);
            }
        } catch (error: any) {
            console.error('Failed to fetch agency info:', error);
            setErrorMessage(error.message || "Unknown error occurred");
        } finally {
            setIsInit(true);
        }
    }


    return <AgencyInfoPopupComponent
        isInit={isInit}
        info={info}
        errorMessage={errorMessage}
        onClose={onClose}
    />;
};

export default AgencyInfoPopupContainer;  