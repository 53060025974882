import * as BaseApi from "./Base.api";

export const doTranslate = async (param: any): Promise<any> => {
    const url = "/translate/do";
    return await BaseApi.post(url, param)
        .then((response: any) => {
            if (response?.status === 200) {
                return response.data;
            } else {
                throw new Error(response.message);
            }
        });
};

export const getTranslateText = async (param: any) => {
    const url = "/translate";
    return await BaseApi.post(url, param);
};

export const getIsNeedTranslate = async (params: any) => {
    const url = "/translate/is-needed";
    return await BaseApi.post(url, params);
};
