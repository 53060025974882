import React, { useState, useRef, useCallback, useEffect } from "react";
import PostComponent from "./Post.component";
import { getPostList, createPost } from "api/student/v2/FanPage.api";
import { uploadImage } from "api/common/Data.api";
const POST_LIMIT = 10; // 한 번에 불러올 포스트 수

const PostContainer = () => {
    const [images, setImages] = useState<Array<{ id: string; url: string; file?: File }>>([]);
    const [content, setContent] = useState("");
    const [posts, setPosts] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isPosting, setIsPosting] = useState(false);
    const [page, setPage] = useState(1);
    const fileInputRef = useRef<HTMLInputElement>(null);

    // 포스트 목록 불러오기
    const fetchPosts = useCallback(async () => {
        try {
            setIsLoading(true);
            const data = await getPostList(page, POST_LIMIT);
            if (data?.list) {
                setPosts(prev => page === 1 ? data.list : [...prev, ...data.list]);
            }
        } catch (error) {
            console.error('Failed to fetch posts:', error);
        } finally {
            setIsLoading(false);
        }
    }, [page]);

    // 초기 로딩 및 페이지 변경시 포스트 불러오기
    useEffect(() => {
        fetchPosts();
    }, [fetchPosts]);

    // 더보기 버튼 클릭 처리
    const handleLoadMore = useCallback(() => {
        setPage(prev => prev + 1);
    }, []);

    // 본문 입력 처리
    const handleContentChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newContent = event.target.value;
        setContent(newContent);
    }, []);

    // 이미지 업로드 처리
    const handleImageUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;

        if (images.length + files.length > 10) {
            alert("이미지는 최대 10개까지만 업로드 가능합니다.");
            return;
        }

        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target?.result) {
                    setImages(prev => [...prev, {
                        id: Math.random().toString(36).substr(2, 9),
                        url: e.target.result as string,
                        file: file // 파일 객체 저장
                    }]);
                }
            };
            reader.readAsDataURL(file);
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }, [images.length]);

    // 이미지 제거 처리
    const handleRemoveImage = useCallback((id: string) => {
        setImages(prev => prev.filter(image => image.id !== id));
    }, []);

    // 게시물 등록 처리
    const handlePost = useCallback(async () => {
        if (isPosting) return; // 이미 게시 중이면 중복 실행 방지
        if (!content.trim()) {
            alert("내용을 입력해주세요.");
            return;
        }

        try {
            setIsPosting(true);

            // 이미지 업로드
            const uploadedImages = [];
            for (const image of images) {
                if (image.file) {
                    const formData = new FormData();
                    formData.append('file', image.file);
                    const response = await uploadImage(image.file);
                    if (response?.id) {
                        uploadedImages.push(response.id);
                    }
                }
            }

            // 게시글 생성
            await createPost(content.trim(), uploadedImages);

            // 초기화
            setContent("");
            setImages([]);

            // 포스트 목록 새로고침
            setPage(1);
            fetchPosts();

        } catch (error) {
            console.error('Failed to create post:', error);
            alert("게시물 등록에 실패했습니다. 다시 시도해주세요.");
        } finally {
            setIsPosting(false);
        }
    }, [content, images, isPosting, fetchPosts]);

    return (
        <PostComponent
            images={images}
            content={content}
            posts={posts}
            isLoading={isLoading}
            isPosting={isPosting}
            hasMore={posts.length > 0 && posts.length % POST_LIMIT === 0}
            fileInputRef={fileInputRef}
            onImageUpload={handleImageUpload}
            onRemoveImage={handleRemoveImage}
            onContentChange={handleContentChange}
            onPost={handlePost}
            onLoadMore={handleLoadMore}
            setPosts={setPosts}
        />
    );
};

export default PostContainer;