export const isInApp = () => {
    return (window as any).flutter_inappwebview || false;
}

export const getOsType = async () => {
    const OSType = await (window as any).flutter_inappwebview.callHandler("getOSType") || "web";
    return OSType;
}

export const getIsIos = async () => {
    const osType = await getOsType();
    return (osType === "iOS");
}

export const getAppVersion = async () => {
    const appVersion = await (window as any).flutter_inappwebview.callHandler("getAppVersion") ;
    return appVersion;
}

export const callAppStore = async (isIos: boolean) => {
    const iOSLink = "itms-apps://itunes.apple.com/app/6677023178";
    const androidLink = "https://play.google.com/store/apps/details?id=com.theethru.wannabes";
    await (window as any).flutter_inappwebview
        .callHandler(
            "executeExternalLink",
            isIos ? iOSLink : androidLink
        )(window as any)
        .flutter_inappwebview.callHandler("exitApp");
}

export const setNotiFilterByPath = async (path: string) => {
    if(path.includes("chat")) {
        await setNotiFilterByType("chat");
    } else {
        await setNotiFilterByType("");
    }
}

export const setNotiFilterByType = async (type: string) => {
    await (window as any).flutter_inappwebview.callHandler("setNotiFilter", type);
}

export const getDeviceId = async () => {
    return await (window as any).flutter_inappwebview.callHandler("getDeviceId");
}

export const getPushToken = async () => {
    return await (window as any).flutter_inappwebview.callHandler("getPushToken");
}

export const setBadgeCount = (count: number) => {
    (window as any).flutter_inappwebview.callHandler("setBadgeCount", `${count}`);
}

export const clearBadgeCount = () => {
    (window as any).flutter_inappwebview.callHandler("clearBadgeCount");
}

export const checkHasPushPermission = async () => {
    return await (window as any).flutter_inappwebview.callHandler("hasPushPermission");
}

export const openAppSetting = async () => {
    await (window as any).flutter_inappwebview.callHandler("openAppSetting");
}

export const setData = async (key: string, value: string) => {
    await (window as any).flutter_inappwebview.callHandler("setData", key, value);
}

export const getData = async (key: string) => {
    return await (window as any).flutter_inappwebview.callHandler("getData", key);
}

export const removeData = async (key: string) => {
    await (window as any).flutter_inappwebview.callHandler("removeData", key);
}

export const hideSplash = async () => {
    await (window as any).flutter_inappwebview.callHandler("hideSplash");
}
