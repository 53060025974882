import React, { useEffect, useRef, useState } from "react";
import AgencyProfileComponent from "./AgencyProfile.component";
import { useLocation, useNavigate } from "react-router-dom";

import * as AgencyProfileApi from "api/student/v2/agencyProfile.api";
import * as ImageHelper from "helpers/Image.helper";
import * as VideoHelper from "helpers/Video.helper";
import * as DataApi from "api/common/Data.api";
import * as AgencyApi from "api/agency/Agency.api";
import { increaseStudentIntroVideoViewCount } from "api/agency/v2/Student.api";

const AgencyProfileContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState(location.state?.tab || "profile");
  const [profileSubTab, setProfileSubTab] = useState(
    location.state?.profileSubTab || "main"
  );

  const [profile, setProfile] = useState(null);
  const [isProfileActive, setIsProfileActive] = useState(false);
  const [isOnChangeProfileActive, setIsOnChangeProfileActive] = useState(false);

  const [videoInfo, setVideoInfo] = useState(null);
  const [faceFrontImageInfo, setFaceFrontImageInfo] = useState(null);
  const [faceLeftImageInfo, setFaceLeftImageInfo] = useState(null);
  const [faceRightImageInfo, setFaceRightImageInfo] = useState(null);

  const [fullBodyFrontImageInfo, setFullBodyFrontImageInfo] = useState(null);
  const [fullBodyLeftImageInfo, setFullBodyLeftImageInfo] = useState(null);
  const [fullBodyRightImageInfo, setFullBodyRightImageInfo] = useState(null);


  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [practiceList, setPracticeList] = useState(null);
  const [isPracticeLoading, setIsPracticeLoading] = useState(true);
  const inputRef = useRef<any>();

  const [isOnNetworking, setIsOnNetworking] = useState(false);
  const videoRef = useRef(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [initFlag, setInitFlag] = useState(false);

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupList, setPopupList] = useState([]);
  const [profileMediaType, setProfileMediaType] = useState("");
  const [isAgencyPopup, setIsAgencyPopup] = useState(false);
  const [agencyInfo, setAgencyInfo] = useState(null);

  const searchParams = new URLSearchParams(location.search);
  const limit = 8;
  const [page, setPage] = useState(
    location.state?.page || +searchParams.get("page") || 1
  );

  const [totalCount, setTotalcount] = useState(0);

  useEffect(() => {
    fetchHomeInfo();
  }, []);

  useEffect(() => {
    const updateInfo = async () => {
      if (profile.videoInfo) {
        const data = await DataApi.getVideoPresignedUrl(profile.videoInfo.videoId);
        if (data?.result) {
          setVideoInfo({ ...profile.videoInfo, url: data.result });
          console.log("videoUrl : ", data.result)
          // captureThumbnail(data.result, 0);
        }
      }

      if (profile.faceFrontImageInfo) {
        if (profile.faceFrontImageInfo.imageId) {
          const frontInfo = await DataApi.getImagePresignedUrl(profile.faceFrontImageInfo.imageId);
          if (frontInfo?.result) {
            setFaceFrontImageInfo({ ...profile.faceFrontImageInfo, url: frontInfo.result });
          }
        }
      }

      if (profile.faceLeftImageInfo) {
        if (profile.faceLeftImageInfo.imageId) {
          const leftInfo = await DataApi.getImagePresignedUrl(profile.faceLeftImageInfo.imageId);
          if (leftInfo?.result) {
            setFaceLeftImageInfo({ ...profile.faceLeftImageInfo, url: leftInfo.result });
          }
        }
      }

      if (profile.faceRightImageInfo) {
        if (profile.faceRightImageInfo.imageId) {
          const rightInfo = await DataApi.getImagePresignedUrl(profile.faceRightImageInfo.imageId);
          if (rightInfo?.result) {
            setFaceRightImageInfo({ ...profile.faceRightImageInfo, url: rightInfo.result });
          }
        }
      }

      if (profile.fullBodyFrontImageInfo) {
        if (profile.fullBodyFrontImageInfo.imageId) {
          const frontInfo = await DataApi.getImagePresignedUrl(profile.fullBodyFrontImageInfo.imageId);
          if (frontInfo?.result) {
            setFullBodyFrontImageInfo({ ...profile.fullBodyFrontImageInfo, url: frontInfo.result });
          }
        }
      }

      if (profile.fullBodyLeftImageInfo) {
        if (profile.fullBodyLeftImageInfo.imageId) {
          const leftInfo = await DataApi.getImagePresignedUrl(profile.fullBodyLeftImageInfo.imageId);
          if (leftInfo?.result) {
            setFullBodyLeftImageInfo({ ...profile.fullBodyLeftImageInfo, url: leftInfo.result });
          }
        }
      }

      if (profile.fullBodyRightImageInfo) {
        if (profile.fullBodyRightImageInfo.imageId) {
          const rightInfo = await DataApi.getImagePresignedUrl(profile.fullBodyRightImageInfo.imageId);
          if (rightInfo?.result) {
            setFullBodyRightImageInfo({ ...profile.fullBodyRightImageInfo, url: rightInfo.result });
          }
        }
      }
    }

    if (profile) {
      updateInfo();
    }

  }, [profile])

  const fetchHomeInfo = async () => {
    const result = await AgencyProfileApi.getHomeInfo()
      .then((data: any) => {
        if (data?.info) {
          setProfile(data.info);
          setIsProfileActive(data.info.isProfileActive);
        }
      })
      .catch((err: any): null => {
        console.log(err);
        return null;
      });
  };

  const getPractice = async () => {
    setIsPracticeLoading(true);
    const result = await AgencyProfileApi.getListPractice({ page, limit });

    if (result?.list) {
      const updatedList = await Promise.all(
        result.list.map(async (video: any) => {
          const videoInfo = await DataApi.getVideoPresignedUrl(video.videoId);
          if (videoInfo) {
            return {
              ...video,
              url: videoInfo.result,
            };
          }
          return video;
        })
      );

      setPracticeList(updatedList);
      setTotalcount(result.totalCount);
    }
    setIsPracticeLoading(false);
  };

  const captureThumbnail = (videoUrl: string, idx: number) => {
    const videoElement = document.createElement("video");
    videoElement.src = videoUrl;
    videoElement.crossOrigin = "anonymous";
    videoElement.muted = true;
    videoElement.playsInline = true;

    videoElement.addEventListener("loadeddata", () => {
      videoElement.currentTime = 0;
    });

    videoElement.addEventListener("seeked", () => {
      const canvas = document.getElementById(
        `canvas-${idx}`
      ) as HTMLCanvasElement;

      if (canvas) {
        const context = canvas.getContext("2d");

        if (context) {
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;

          context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

          const imgElement = document.getElementById(
            `thumbnail-${idx}`
          ) as HTMLImageElement;
          imgElement.src = canvas.toDataURL("image/png");
          imgElement.style.visibility = "visible";
        }
      }
    });
    videoElement.load();
  };

  const uploadImage = async (evt: any, category: string, type: string) => {
    if (!isOnNetworking) {
      setIsOnNetworking(true);

      if (!evt) {
        return false;
      }

      const file = evt.target.files[0];
      const imageInfo = await ImageHelper.getImageInfo(file);
      if (!imageInfo) {
        alert("Please choose image file");
        return false;
      }

      const result = await DataApi.uploadImage(file, false);
      imageInfo.id = result.id;

      const upload = await AgencyProfileApi.createProfileImage({
        imageId: result.id,
        profileImageCategoryCode: category,
        profileImageTypeCode: type,
      });

      if (upload && upload.status === 200) {
        await fetchHomeInfo();
      }

      setIsOnNetworking(false);
    }
  };

  const uploadVideo = async (evt: any) => {
    if (!isOnNetworking) {
      setIsOnNetworking(true);

      if (!evt) {
        return false;
      }

      const file = evt.target.files[0];
      const videoInfo = await VideoHelper.getVideoInfo(file);
      if (!videoInfo) {
        alert("Please choose video file");
        return false;
      }

      const result = await DataApi.uploadVideo(file, false);
      videoInfo.id = result.id;

      const upload = await AgencyProfileApi.createIntroVideo({ videoId: result.id });
      if (upload && upload.status === 200) {
        await fetchHomeInfo();
      }

      setIsOnNetworking(false);
    }
  };

  const getVideoUrl = async (id: string) => {
    if (id) {
      const videoInfo = await DataApi.getVideoPresignedUrl(id);
      if (videoInfo) {
        const url = videoInfo.result;
        return url;
      }
    }
  };

  const handleEnded = () => {
    console.log('ended')
    setCurrentTime(0);
  };

  const handleSeeked = () => {
    console.log('seek')
    const video = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
      video.play();
    }
  };

  const handlePlay = () => {
    console.log('play')
    setInitFlag(false);
  };

  const handlePause = () => {
    console.log('pause')
    const video = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
    }
    setInitFlag(true);
  };

  const openViewPopup = async (
    profileMediaId: string,
    profileMediaType: string
  ) => {
    if (profileMediaId) {
      const result = await AgencyProfileApi.getListPraciceViewedAgency({
        profileMediaId: profileMediaId,
        profileMediaType: profileMediaType,
      });

      if (result.list) {
        setPopupList(result.list);
      }
      setProfileMediaType(profileMediaType);
      setIsShowPopup(true);
    }
  };

  const openAgencyPopup = async (id: number) => {
    const result = await AgencyApi.getInfo(id);
    if (result.info) {
      setAgencyInfo(result.info);
    }
    setIsAgencyPopup(true);
  };

  useEffect(() => {
    const handleInitFlagChange = async () => {
      if (!initFlag) {
        const url = await getVideoUrl(profile?.introVideoInfo?.videoId);
        setSelectedVideoUrl(url);
        setInitFlag(false);
      }
    };

    handleInitFlagChange();
  }, [initFlag]);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.src = selectedVideoUrl;
      video.muted = true;
      video.playsInline = true;
      video.currentTime = currentTime;
      video.load();
      const handleLoadedData = () => {
        //video.play().catch((e: any) => console.log(e));
      };

      const handleError = () => {
        console.log(45454545)
      }

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
      video.addEventListener("ended", handleEnded);
      video.addEventListener("seeked", handleSeeked);
      video.addEventListener("onerror", handleError);

      return () => {
        video.removeEventListener("loadeddata", handleLoadedData);
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        video.removeEventListener("ended", handleEnded);
        video.removeEventListener("seeked", handleSeeked);
      };
    }
  }, [selectedVideoUrl]);

  const practiceInit = async () => {
    await getPractice();
  };

  const handleChangeIsProfileActive = async (checked: boolean) => {

    if (isOnChangeProfileActive) {
      return;
    }

    setIsOnChangeProfileActive(true);

    await AgencyProfileApi.updateIsActiveOfAgencyProfile({ isProfileActive: checked })
      .then((res: any) => {
        console.log(res)
        console.log({ checked });
        setIsProfileActive(checked);
      }).catch((err: any) => {
        console.log(err)
        console.log({ checked });
        setIsProfileActive(!checked);
      }).finally(() => {
        setIsOnChangeProfileActive(false);
      })
  };

  return (
    <AgencyProfileComponent
      {...props}
      tab={tab}
      setTab={setTab}
      profileSubTab={profileSubTab}
      setProfileSubTab={setProfileSubTab}

      videoInfo={videoInfo}
      faceFrontImageInfo={faceFrontImageInfo}
      faceLeftImageInfo={faceLeftImageInfo}
      faceRightImageInfo={faceRightImageInfo}
      fullBodyFrontImageInfo={fullBodyFrontImageInfo}
      fullBodyLeftImageInfo={fullBodyLeftImageInfo}
      fullBodyRightImageInfo={fullBodyRightImageInfo}

      //
      profile={profile}
      isProfileLoading={isProfileLoading}
      setIsProfileLoading={setIsProfileLoading}
      practiceList={practiceList}
      isPracticeLoading={isPracticeLoading}
      //upload
      videoRef={videoRef}
      uploadVideo={uploadVideo}
      uploadImage={uploadImage}
      //
      isShowPopup={isShowPopup}
      setIsShowPopup={setIsShowPopup}
      popupList={popupList}
      openViewPopup={openViewPopup}
      profileMediaType={profileMediaType}
      //
      isAgencyPopup={isAgencyPopup}
      setIsAgencyPopup={setIsAgencyPopup}
      agencyInfo={agencyInfo}
      openAgencyPopup={openAgencyPopup}
      // pagination
      limit={limit}
      page={page}
      setPage={setPage}
      totalCount={totalCount}

      // 기획사용 프로필 활성화 여부
      isProfileActive={isProfileActive}
      onChangeIsProfileActive={handleChangeIsProfileActive}
    />
  );
};

export default AgencyProfileContainer;
