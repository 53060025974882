import React from "react";
import { getProfileImageUrl } from "helpers/Image.helper";
import { getStudentNickname, getFlagByCountryCode, getStudentName, getCurrentLangName } from "helpers/String.helper";
import { getCountryCodeList } from "data/common.data";
import { getLanguageValue } from "locales/i18n";
import LoadingComponent from "commons/loading/Loading.component";

const IndexComponent = (props: any) => {
    const { keyword, setKeyword, list, isLoading, onSearch, hasSearch, updatedProfileList, recentlyViewedProfileList, showModal, setShowModal, searchFilter, onSearchFilter, onDeleteCountryCode, onClickStudent, isInit } = props;

    return (
        <main className="flex flex-col min-h-screen bg-gray-50">
            <div className="container mx-auto px-4 py-6">
                {/* 검색 영역 */}
                <div className="mb-6 sm:mb-8 lg:mb-12">
                    <div className="max-w-2xl mx-auto px-2 sm:px-4">
                        <div className="relative">
                            <input
                                type="text"
                                value={keyword}
                                onClick={() => setShowModal(true)}
                                className="w-full px-4 py-2.5 sm:py-3 rounded-full border border-gray-300 focus:outline-none focus:border-[#cf3688] shadow-sm"
                                placeholder="검색어를 입력하세요"
                                readOnly
                            />
                        </div>
                    </div>
                </div>

                {/* 검색 필터 모달 */}
                {showModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 p-4 !m-0">
                        <div className="bg-white rounded-2xl p-6 w-full max-w-2xl mx-auto">
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="text-lg font-bold text-gray-900">검색 필터</h3>
                                <button className="text-gray-400 hover:text-gray-600" onClick={() => setShowModal(false)}>
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>

                            <div className="mb-6">
                                <input
                                    type="text"
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    className="w-full px-4 py-2 text-base rounded-full border border-gray-300 focus:outline-none focus:border-[#cf3688] shadow-sm"
                                    placeholder="검색어를 입력하세요"
                                />
                            </div>

                            <div className="mb-4 flex items-center">
                                <label className="w-20">국가 선택</label>
                                <select className="flex-1 p-2 border rounded bg-white border-gray-300 focus:outline-none focus:border-[#cf3688] shadow-sm"
                                    onChange={(e) => onSearchFilter("countryCodeList", e.target.value)}
                                    value={""}>
                                    <option value="">국가 선택</option>
                                    {/* 국가 옵션들 */}
                                    {getCountryCodeList().map((item: any) => (
                                        <option key={item.code} value={item.code}>
                                            {getFlagByCountryCode(item.code)}
                                            {getCurrentLangName(item.names)}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* 선택된 국가 목록 표시 */}
                            <div className="mb-4">
                                {searchFilter.countryCodeList.length > 0 ? (
                                    <div className="mt-2 w-full">
                                        <div className="overflow-x-auto md:overflow-x-visible">
                                            <div className="flex md:grid md:grid-cols-4 gap-2 min-w-max md:min-w-0">
                                                {searchFilter.countryCodeList.map((code: string) => (
                                                    <div key={code} className="relative flex items-center p-2 rounded border bg-white border-gray-300 focus:outline-none focus:border-[#cf3688] shadow-sm">
                                                        <span className="mr-2">{getFlagByCountryCode(code)}</span>
                                                        <span className="text-sm">
                                                            {getCurrentLangName(getCountryCodeList().find(item => item.code === code)?.names)}</span>
                                                        <button
                                                            className="absolute -top-1 -right-1 bg-gray-200 rounded-full p-0.5 hover:bg-gray-300"
                                                            onClick={() => onDeleteCountryCode(code)}
                                                        >
                                                            <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mt-2 text-gray-500 text-sm">선택된 국가가 없습니다</div>
                                )}
                            </div>

                            <div className="mb-4 flex items-center">
                                <label className="w-20">성별</label>
                                <div className="flex flex-1 gap-4">
                                    <label className="flex-1 flex items-center justify-center border rounded py-2">
                                        <input
                                            className="mr-2 accent-[#CF3688]"
                                            onChange={(e) => onSearchFilter("genderTypeCode", e.target.value)} type="radio" name="gender" value=""
                                            checked={searchFilter.genderTypeCode === ""}
                                        />
                                        무관
                                    </label>
                                    <label className="flex-1 flex items-center justify-center border rounded py-2">
                                        <input
                                            className="mr-2 accent-[#CF3688]"
                                            onChange={(e) => onSearchFilter("genderTypeCode", e.target.value)} type="radio" name="gender" value="genderType:boy"
                                            checked={searchFilter.genderTypeCode === "genderType:boy"}
                                        />
                                        남성
                                    </label>
                                    <label className="flex-1 flex items-center justify-center border rounded py-2">
                                        <input
                                            className="mr-2 accent-[#CF3688]"
                                            onChange={(e) => onSearchFilter("genderTypeCode", e.target.value)} type="radio" name="gender" value="genderType:girl"
                                            checked={searchFilter.genderTypeCode === "genderType:girl"}
                                        />
                                        여성
                                    </label>
                                </div>
                            </div>

                            <div className="mb-4 flex items-center">
                                <label className="w-20">나이</label>
                                <select className="flex-1 p-2 border rounded bg-white border-gray-300 focus:outline-none focus:border-[#cf3688] shadow-sm"
                                    onChange={(e) => onSearchFilter("minAge", e.target.value)}
                                    value={searchFilter.minAge}
                                >
                                    <option value="" selected={searchFilter.minAge === 0}>무관</option>
                                    {Array.from({ length: 23 }, (_, i) => i + 8).map(age => (
                                        <option key={age} value={age} selected={searchFilter.minAge === age}>{age}세</option>
                                    ))}
                                </select>
                                <span className="w-8 text-center text-gray-500">~</span>
                                <select className="flex-1 p-2 border rounded bg-white border-gray-300 focus:outline-none focus:border-[#cf3688] shadow-sm"
                                    onChange={(e) => onSearchFilter("maxAge", e.target.value)}
                                    value={searchFilter.maxAge}
                                >
                                    <option value="" selected={searchFilter.maxAge === 99}>무관</option>
                                    {Array.from({ length: 23 }, (_, i) => i + 8).map(age => (
                                        <option key={age} value={age} selected={searchFilter.maxAge === age}>{age}세</option>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-4 flex items-center">
                                <label className="w-20">몸무게</label>
                                <select className="flex-1 p-2 border rounded bg-white border-gray-300 focus:outline-none focus:border-[#cf3688] shadow-sm"
                                    onChange={(e) => onSearchFilter("minWeight", e.target.value)}
                                    value={searchFilter.minWeight}
                                >
                                    <option value="" selected={searchFilter.minWeight === 0}>무관</option>
                                    {Array.from({ length: 11 }, (_, i) => 20 + i * 5).map(weight => (
                                        <option key={weight} value={weight} selected={searchFilter.minWeight === weight}>{weight}kg</option>
                                    ))}
                                </select>
                                <span className="w-8 text-center text-gray-500">~</span>
                                <select className="flex-1 p-2 border rounded bg-white border-gray-300 focus:outline-none focus:border-[#cf3688] shadow-sm"
                                    onChange={(e) => onSearchFilter("maxWeight", e.target.value)}
                                    value={searchFilter.maxWeight}
                                >
                                    <option value="" selected={searchFilter.maxWeight === 999}>무관</option>
                                    {Array.from({ length: 11 }, (_, i) => 20 + i * 5).map(weight => (
                                        <option key={weight} value={weight} selected={searchFilter.maxWeight === weight}>{weight}kg</option>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-4 flex items-center">
                                <label className="w-20">키</label>
                                <select className="flex-1 p-2 border rounded bg-white border-gray-300 focus:outline-none focus:border-[#cf3688] shadow-sm"
                                    onChange={(e) => onSearchFilter("minHeight", e.target.value)}
                                    value={searchFilter.minHeight}
                                >
                                    <option value="" selected={searchFilter.minHeight === 0}>무관</option>
                                    {Array.from({ length: 21 }, (_, i) => 100 + i * 5).map(height => (
                                        <option key={height} value={height} selected={searchFilter.minHeight === height}>{height}cm</option>
                                    ))}
                                </select>
                                <span className="w-8 text-center text-gray-500">~</span>
                                <select className="flex-1 p-2 border rounded bg-white border-gray-300 focus:outline-none focus:border-[#cf3688] shadow-sm"
                                    onChange={(e) => onSearchFilter("maxHeight", e.target.value)}
                                    value={searchFilter.maxHeight}
                                >
                                    <option value="" selected={searchFilter.maxHeight === 999}>무관</option>
                                    {Array.from({ length: 21 }, (_, i) => 100 + i * 5).map(height => (
                                        <option key={height} value={height} selected={searchFilter.maxHeight === height}>{height}cm</option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex gap-4 w-full">
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="w-1/2 px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 border-2 border-gray-200 rounded-xl
                                        hover:bg-gray-80 focus:outline-none focus:ring-2 focus:ring-gray-200
                                        transition-colors"
                                >
                                    취소
                                </button>
                                <button
                                    onClick={() => {
                                        onSearch(1);
                                        setShowModal(false);
                                    }}
                                    className="w-1/2 px-4 py-3 text-sm font-medium text-white bg-[#CF3688] rounded-xl
                                        hover:bg-[#B82F77] focus:outline-none focus:ring-2 focus:ring-[#CF3688]/20
                                        transition-colors"
                                >
                                    검색
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {!isInit && (
                    <div className="mb-6">
                        <LoadingComponent />
                    </div>
                )}

                {!hasSearch && (
                    <>
                        {/* 최근 업데이트된 프로필 영역 */}
                        {updatedProfileList.length > 0 && (
                            <div className="mb-6">
                                <h2 className="text-xl font-bold mb-3">
                                    최근 업데이트된 프로필
                                </h2>
                                <div className="flex md:grid overflow-x-auto scrollbar-hide md:grid-cols-3 lg:grid-cols-4 gap-2">
                                    {updatedProfileList.map((item: any, index: number) => (
                                        <div key={index} className="flex-shrink-0 w-auto text-center px-2" onClick={() => onClickStudent(item.id)}>
                                            <div className="relative inline-block mb-2">
                                                <div className="w-24 h-24 sm:w-28 sm:h-28 rounded-full overflow-hidden mx-auto">
                                                    <img src={getProfileImageUrl(item.profileImageInfo, 200)} alt={getStudentNickname(item)} className="w-full h-full object-cover" />
                                                </div>
                                                <span className="absolute top-0 right-0">
                                                    <div className="w-4 h-4 bg-[#CF3688] rounded-full"></div>
                                                </span>
                                                <span className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 text-2xl sm:text-3xl">
                                                    {getFlagByCountryCode(item.countryInfo?.code)}
                                                </span>
                                            </div>
                                            <p className="font-medium text-sm">{getStudentName(item)}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* 최근 조회된 프로필 영역 */}
                        {recentlyViewedProfileList.length > 0 && (
                            <div>
                                <h2 className="text-xl font-bold mb-4">
                                    최근 조회한 프로필
                                </h2>
                                {recentlyViewedProfileList.length === 0 ? (
                                    <p>최근 조회한 프로필이 없습니다.</p>
                                ) : (
                                    <div className="flex md:grid overflow-x-auto scrollbar-hide md:grid-cols-3 lg:grid-cols-4 gap-2">
                                        {recentlyViewedProfileList.map((item: any, index: number) => (
                                            <div key={index} className="flex-shrink-0 w-auto text-center px-2" onClick={() => onClickStudent(item.id)}>
                                                <div className="relative inline-block mb-2">
                                                    <div className="w-24 h-24 sm:w-28 sm:h-28 rounded-full overflow-hidden mx-auto">
                                                        <img src={getProfileImageUrl(item.profileImageInfo, 200)} alt={getStudentNickname(item)} className="w-full h-full object-cover" />
                                                    </div>
                                                    {item.isUpdatedProfile && (
                                                        <span className="absolute top-0 right-0">
                                                            <div className="w-4 h-4 bg-[#CF3688] rounded-full"></div>
                                                        </span>
                                                    )}
                                                    <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 text-2xl sm:text-3xl">
                                                        {getFlagByCountryCode(item.countryInfo?.code)}
                                                    </span>
                                                </div>
                                                <p className="font-medium text-sm sm:text-base">{getStudentName(item)}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

                {/* 검색 결과 영역 */}
                {hasSearch &&
                    <div>
                        {/* <h2 className="text-xl font-bold mb-4">검색 결과</h2> */}
                        {!isLoading && list.length === 0 ? (
                            <p className="p-y-10 text-center">검색 결과가 없습니다.</p>
                        ) : (
                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3 sm:gap-4">
                                {list.map((item: any, index: number) => (
                                    <div key={index} className="text-center" onClick={() => onClickStudent(item.id)}>
                                        <div className="relative inline-block">
                                            <div className="w-32 h-32 sm:w-36 sm:h-36 md:w-40 md:h-40 rounded-full overflow-hidden mx-auto mb-2">
                                                <img src={getProfileImageUrl(item.profileImageInfo, 200)} alt={getStudentNickname(item)} className="w-full h-full object-cover" />
                                            </div>
                                            {item.isUpdatedProfile && (
                                                <span className="absolute top-0 right-0">
                                                    <div className="w-4 h-4 bg-[#CF3688] rounded-full"></div>
                                                </span>
                                            )}
                                            <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 text-2xl sm:text-3xl">
                                                {getFlagByCountryCode(item.countryInfo?.code)}
                                            </span>
                                        </div>
                                        <p className="font-medium text-sm sm:text-base">
                                            {item.lastName} {item.firstName}
                                            <br />
                                            {item.nickname && `(${item.nickname})`}

                                            {item.isUpdatedProfile && (
                                                <span className="absolute top-0 right-0">
                                                    <div className="w-4 h-4 bg-[#CF3688] rounded-full"></div>
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {isLoading && (<LoadingComponent />)}
                    </div>
                }
            </div>
        </main>
    );
};

export default IndexComponent;