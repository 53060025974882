import React, { useEffect, useState } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import * as Axios from "api/Base.interceptor";
import Router from "./Router";
import { getAccessToken, removeAuthTokens, setAccessToken, setRefreshToken } from "helpers/Auth.helper";
import { getMyInfo } from "api/common/Auth.api";
import { setIsLogin, setInfo as setUserInfo } from "reducers/User";
import * as StudentApi from "api/student/Student.api";
import * as AgencyApi from "api/agency/Agency.api";
import * as SchoolApi from "api/school/School.api";
import { isFcmSupported, listenForForegroundMessages, requestNotificationPermission } from "helpers/Firebase.helper";
import { getAppAccessToken, getAppRefreshToken, getIsAppInitData, removeAppAccessToken, setIsAppInitData } from "helpers/appLocalStorageHelper";
import { getAppVersionInfo } from "api/App.api";
import { setIsApp } from "reducers/Common";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import GlobalLoadingContainer from "pages/common/loading/GlobalLoading.container";
import { BlockPullToRefresh } from "hooks/BlockPullToRefresh";
import ErrorBoundary from "pages/common/error/ErrorBoundary";
import { PopupProvider } from './contexts/PopupContext';
import UsePreventZoomComponent from "commons/layout/UsePreventZoom.component";
import * as commonData from "./data/common.data";
import * as AuthApi from "api/common/Auth.api";
import { setBrowserId } from "helpers/Cookie.helper";
import { initGtm } from "helpers/Gtm.helper";
import { NetworkProvider } from "contexts/NetworkContext";
import { callAppStore, getAppVersion, getIsIos, isInApp, hideSplash } from "helpers/InApp.helper";
import ScrollToTop from "commons/layout/ScrollToTop.container";
function App() {
    // 확대 방지 
    UsePreventZoomComponent();

    const [isInit, setIsInit] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("NOW LOADING...");
    const dispatch = useDispatch();

    const { isApp } = useSelector((state: any) => state.common);

    const exceptedInitUrls = [
        "/error"
    ];
    // 블록 풀 투 리프레시 
    //  활성화시 스크롤이 필요한 화면에서 스크롤이 안되는 버그 발생ㅣ
    // BlockPullToRefresh();


    // google analytics 초기화
    const initGA = () => {
        if (process.env.REACT_APP_ENV !== "local") {
            initGtm();
        }
    };

    // 브라우저 정보 초기화
    //  쿠키 생성
    const initBrowserId = async () => {
        setBrowserId();
    };

    // Axios 초기화
    const initAxios = async () => {
        setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtAxiosInit"));
        await Axios.init();
    };

    // 앱 초기화 루틴
    const initForApp = async () => {
        // 앱 루틴 시작
        if (isInApp()) {

            const isIos = await getIsIos();

            // db current app version 조회
            setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtAppVersionInfoInit"));
            const currentAppVersion = await getAppVersionInfo({
                deviceTypeCode: `deviceType:${isIos ? "ios" : "android"}`,
            });

            if (currentAppVersion?.info) {
                // device app version 조회
                const deviceAppVersion = await getAppVersion();

                // 앱 업데이트 버전이 있는 경우
                if (currentAppVersion.info.version > deviceAppVersion) {
                    // 강제 업데이트인 경우
                    if (currentAppVersion.info.needForceUpdate) {
                        alert(getLanguageValue(PAGE_TYPE.COMMON, "app.txtForceUpdate"));
                        setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtNeedToUpdateApp"));
                        await callAppStore(isIos);
                    }
                    // 단순 업데이트인 경우
                    else {
                        if (window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "app.txtNeedUpdate"))) {
                            await callAppStore(isIos);
                        }
                    }
                }
            }

            // app local storage access token 확인
            setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtAppAccessTokenInit"));
            const accessToken = await getAppAccessToken();
            if (accessToken) {
                setAccessToken(accessToken);
            }

            setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtAppRefreshTokenInit"));
            const refreshToken = await getAppRefreshToken();
            if (refreshToken) {
                setRefreshToken(refreshToken);
            }

            // 앱 여부 redux 저장
            dispatch(setIsApp(true));
        }
        // 앱 루틴 끝
    };

    // 액세스 토큰 체크 및 초기화
    const initAuthorization = async () => {
        // cookie의 access token 체크
        setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtMyInfoInit"));
        const accessToken = getAccessToken();
        if (accessToken) {
            setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtMyInfoInit"));
            const myInfoResult = await getMyInfo();

            if (myInfoResult?.info) {
                setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtMyInfoInit"));
                if (myInfoResult.info.accountTypeCode === "accountType:student") {
                    const result = await StudentApi.getInfoForStudent();
                    if (result) {
                        myInfoResult.info.profileImage = result.info.profileImageInfo ? result.info.profileImageInfo.url : null;
                    } else {
                        myInfoResult.info.profileImage = null;
                    }
                }
                if (myInfoResult.info.accountTypeCode === "accountType:agency") {
                    const result = await AgencyApi.getInfo(myInfoResult.info.userAgencyId);
                    if (result) {
                        myInfoResult.info.logoImageInfo = result.info.logoImageInfo ? result.info.logoImageInfo : null;
                        myInfoResult.info.agencyName = result.info.agencyName;
                    } else {
                        myInfoResult.info.logoImageInfo = null;
                    }
                }

                if (myInfoResult.info.accountTypeCode === "accountType:school") {
                    const result = await SchoolApi.getInfo(myInfoResult.info.userSchoolId);
                    if (result) {
                        myInfoResult.info.logoImageInfo = result.schoolInfo.logoImageInfo ? result.schoolInfo.logoImageInfo : null;
                    } else {
                        myInfoResult.info.logoImageInfo = null;
                    }
                }
                const myInfo = myInfoResult.info;
                dispatch(setIsLogin(true));
                dispatch(setUserInfo(myInfo));

                if (!isApp) {
                    setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtNotificationPermissionInit"));
                    await requestNotificationPermission();
                }
            } else {
                setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtAppAccessTokenInit"));
                removeAuthTokens();
                await removeAppAccessToken();
            }
            await listenForForegroundMessages();
        }
    };

    // 서비스 워커 초기화
    const initServiceWorker = () => {
        if (isFcmSupported()) {
            setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtNotificationPermissionInit"));
            if ("serviceWorker" in navigator) {
                setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtServiceWorkerInit"));
                navigator.serviceWorker.addEventListener("message", (event) => {
                    if (event.data && event.data.type === "RELOAD_PAGE") {
                        window.location.reload();
                    }
                });
            }
        }
    }

    const initCommonData = async () => {
        console.log("Router:setCommonData");

        setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtCommonDataInit"));
        const getAllCodeListResult = await AuthApi.getAllCodeList();
        commonData.setAllCodeList(getAllCodeListResult);
        commonData.setLanguageCode(navigator.language);
    }

    // 초기화 루틴
    const init = async () => {
        console.log("start init", isInit);

        //window.alert("initGA");
        // google analytics 초기화
        initGA();

        //window.alert("initBrowserId");
        // 디바이스 정보 초기화
        initBrowserId();

        // 스플래시 먼저 삭제
        // 앱 루틴 시작
        //window.alert("hideSplash");
        if (isInApp()) {
            //window.alert("do hideSplash");
            // app splash hide
            await hideSplash();
        }

        //window.alert("initAxios");
        // Axios 초기화
        await initAxios();

        //window.alert("initForApp");
        // 앱 초기화 루틴
        await initForApp();

        //window.alert("initAuthorization");
        // 액세스 토큰 체크 및 초기화
        await initAuthorization();

        //window.alert("initServiceWorker");
        // 서비스 워커 초기화
        initServiceWorker();

        //window.alert("initCommonData");
        // 공통 데이터 초기화
        await initCommonData();

        //window.alert("setLoadingMessage");
        setLoadingMessage(getLanguageValue(PAGE_TYPE.COMMON, "appInit.txtAppInitDone"));
        setIsInit(true);
    };

    useEffect(() => {
        const pathname = window.location.href;
        console.log({ pathname });
        const isExcepted = exceptedInitUrls.some(url => pathname.startsWith(url));
        if (isExcepted) {
            setIsInit(true);
        } else {
            init();
        }
    }, []);

    return (
        <CookiesProvider>
            {isInit ? (
                <BrowserRouter>
                    <ErrorBoundary>
                        <NetworkProvider>
                            <PopupProvider>
                                <ScrollToTop />
                                <Router />
                            </PopupProvider>
                        </NetworkProvider>
                    </ErrorBoundary>
                </BrowserRouter>
            ) :
                <GlobalLoadingContainer message={loadingMessage} />
            }

        </CookiesProvider>
    );
}

export default App;
