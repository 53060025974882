import React from "react";
import DaumPostcodeEmbed from "react-daum-postcode";

import ArtistPopup from "./ArtistPopup";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import AuthHeader from "../component/AuthHeader.component";

const AgencySignupComponent = (props: any) => {
    const {
        btnRef,
        // account
        accountParams,
        // essential
        agencyParams,
        businessCardFrontImageInfo,
        businessCardBackImageInfo,
        termList,
        isOnNetworking,
        uploadImage,
        onChangeInput,
        onChangeCheckbox,
        onClickBtnCancelSignup,
        onClickBtnSignup,
        selectedAgencyInfo,

        // 이메일 인증 관련
        emailVericationCode,
        setEmailVericationCode,
        isEmailVarified,
        setIsEmailVarified,
        isSentEmail,
        sendEmailForVerification,
        emailVerification,
        backUrl,
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen">
            <form>
                <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
                    <div className="flex items-center justify-between mb-8">
                        <h2 className="text-3xl font-bold text-gray-900">
                            {selectedAgencyInfo?.nameKo}
                        </h2>
                    </div>

                    {/* 계정 정보 섹션 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
                        <div className="p-6">
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold text-gray-900">
                                    {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtAccountInfoForLogin")}
                                </h3>
                            </div>

                            <div className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        <span className="text-red-500">*</span>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtEmail")}
                                    </label>
                                    <div className="flex">
                                        <input
                                            type="text"
                                            disabled={isSentEmail || isEmailVarified}
                                            value={accountParams.username}
                                            onChange={(evt) => onChangeInput("username", evt.target.value)}
                                            className="block w-full px-4 py-3 border-2 border-gray-200 rounded-l-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                        />
                                        <span className="inline-flex items-center px-3 border-2 border-l-0 border-gray-200 rounded-r-xl bg-gray-50 text-gray-500">
                                            @{selectedAgencyInfo?.emailDomain}
                                        </span>
                                    </div>
                                    <div className="mt-2">
                                        <button
                                            type="button"
                                            className={`px-4 py-2 text-white bg-[#CF3688] rounded-xl hover:bg-[#B52E74] transition-colors ${!accountParams.username || isEmailVarified ? "opacity-50 cursor-not-allowed" : ""}`}
                                            disabled={!accountParams.username || isOnNetworking || isEmailVarified}
                                            onClick={sendEmailForVerification}
                                        >
                                            {isSentEmail
                                                ? getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtReSendEmailVericationCode")
                                                : getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtSendEmailVericationCode")}
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        <span className="text-red-500">*</span>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtEmailVericationCode")}
                                    </label>
                                    <input
                                        type="text"
                                        disabled={!isSentEmail || isEmailVarified}
                                        placeholder={getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtPlaceHolderEmailVericationCode")}
                                        value={emailVericationCode}
                                        onChange={(evt) => setEmailVericationCode(evt.target.value.trim())}
                                        className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                    />
                                    <div className="mt-2">
                                        <button
                                            type="button"
                                            onClick={emailVerification}
                                            disabled={isEmailVarified || !emailVericationCode || !isSentEmail || isOnNetworking}
                                            className={`px-4 py-2 text-white bg-[#CF3688] rounded-xl hover:bg-[#B52E74] transition-colors ${isEmailVarified || !emailVericationCode || !isSentEmail ? "opacity-50 cursor-not-allowed" : ""}`}
                                        >
                                            {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtConfirmEmailVericationCode")}
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        <span className="text-red-500">*</span>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtPassword")}
                                    </label>
                                    <input
                                        type="password"
                                        value={accountParams.password}
                                        onChange={(evt) => onChangeInput("password", evt.target.value)}
                                        className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        <span className="text-red-500">*</span>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtConfirmPassword")}
                                    </label>
                                    <input
                                        type="password"
                                        value={accountParams.confirmedPassword}
                                        onChange={(evt) => onChangeInput("confirmedPassword", evt.target.value)}
                                        className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* 필수 정보 섹션 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
                        <div className="p-6">
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold text-gray-900">
                                    {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtEnterBasicInfo")}
                                </h3>
                            </div>

                            <div className="space-y-6">
                                <div className="flex justify-center">
                                    <div className="relative w-40 h-40">
                                        <div
                                            className="w-full h-full rounded-full bg-center bg-cover"
                                            style={{
                                                backgroundImage: `url(${businessCardFrontImageInfo?.url || "./../../imgs/icons/camera.png"})`
                                            }}
                                        />
                                        <input
                                            type="file"
                                            id="input-file-img1"
                                            className="hidden"
                                            onChange={(evt) => uploadImage("front", evt)}
                                            accept="image/*"
                                        />
                                        <label
                                            htmlFor="input-file-img1"
                                            className="absolute bottom-0 right-0 bg-[#CF3688] text-white p-2 rounded-full cursor-pointer hover:bg-[#B52E74] transition-colors"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                            </svg>
                                        </label>
                                    </div>
                                </div>

                                <div className="flex justify-center">
                                    <div className="relative w-40 h-40">
                                        <div
                                            className="w-full h-full rounded-full bg-center bg-cover"
                                            style={{
                                                backgroundImage: `url(${businessCardBackImageInfo?.url || "./../../imgs/icons/camera.png"})`
                                            }}
                                        />
                                        <input
                                            type="file"
                                            id="input-file-img2"
                                            className="hidden"
                                            onChange={(evt) => uploadImage("back", evt)}
                                            accept="image/*"
                                        />
                                        <label
                                            htmlFor="input-file-img2"
                                            className="absolute bottom-0 right-0 bg-[#CF3688] text-white p-2 rounded-full cursor-pointer hover:bg-[#B52E74] transition-colors"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                            </svg>
                                        </label>
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        <span className="text-red-500">*</span>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtName")}
                                    </label>
                                    <input
                                        type="text"
                                        value={agencyParams.name}
                                        onChange={(evt) => onChangeInput("name", evt.target.value)}
                                        className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        <span className="text-red-500">*</span>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtPhoneNumber")}
                                    </label>
                                    <input
                                        type="text"
                                        value={agencyParams.cellphone}
                                        onChange={(evt) => onChangeInput("cellphone", evt.target.value)}
                                        className="block w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#CF3688]/20 focus:border-[#CF3688] text-base transition-colors"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* 약관 목록 */}
                    <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-8">
                        <div className="p-6">
                            <div className="space-y-4">
                                {termList.map((item: any, idx: number) => (
                                    <div key={`term-${idx}`} className="flex items-center justify-between">
                                        <label className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                onChange={(evt) => onChangeCheckbox(idx, evt.target.checked)}
                                                checked={item.checked}
                                                className="h-4 w-4 text-[#CF3688] focus:ring-[#CF3688] rounded"
                                            />
                                            <span className="text-sm text-gray-700">
                                                ({item.isRequired
                                                    ? getLanguageValue(PAGE_TYPE.COMMON, "agencySignupTerms.txtRequired")
                                                    : getLanguageValue(PAGE_TYPE.COMMON, "agencySignupTerms.txtOptional")
                                                }) {item.title}
                                            </span>
                                        </label>
                                        <button
                                            type="button"
                                            onClick={() => window.open(item.isRequired ? `/term/common/${item.id}` : `/term/etc/${item.id}`)}
                                            className="text-sm text-[#CF3688] hover:text-[#B52E74] transition-colors"
                                        >
                                            ({getLanguageValue(PAGE_TYPE.COMMON, "agencySignupTerms.txtShow")})
                                        </button>
                                    </div>
                                ))}

                                <div className="pt-4 border-t">
                                    <label className="flex items-center space-x-3">
                                        <input
                                            type="checkbox"
                                            onChange={(evt) => onChangeCheckbox("all", evt.target.checked)}
                                            checked={!termList.find((i: any) => !i.checked)}
                                            className="h-4 w-4 text-[#CF3688] focus:ring-[#CF3688] rounded"
                                        />
                                        <span className="text-sm font-medium text-gray-900">
                                            {getLanguageValue(PAGE_TYPE.COMMON, "agencySignupTerms.txtAgreeWithAllTerms")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={onClickBtnCancelSignup}
                            className="px-6 py-3 text-gray-700 bg-gray-100 rounded-xl hover:bg-gray-200 transition-colors"
                        >
                            {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.btnCancel")}
                        </button>
                        <button
                            type="button"
                            ref={btnRef}
                            onClick={onClickBtnSignup}
                            disabled={isOnNetworking}
                            className="px-6 py-3 text-white bg-[#CF3688] rounded-xl hover:bg-[#B52E74] transition-colors disabled:opacity-50"
                        >
                            {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.btnSignUp")}
                        </button>
                    </div>
                </div>
            </form>
        </main>
    );
};

export default AgencySignupComponent;
