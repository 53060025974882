import AgencyProfileVideoComponent from "./AgencyProfileIntroVideo.component";
import { deleteIntroVideo, getListIntroVideo } from "api/student/v2/agencyProfile.api";
import { useState, useEffect } from "react";
import AgencyProfileIntroVideoComponent from "./AgencyProfileIntroVideo.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
const LIMIT = 10;
const AgencyProfileIntroVideoContainer = () => {

    const [list, setList] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isOnNetworking, setIsOnNetworking] = useState(false);

    const fetchList = async (page: number, limit: number) => {
        setIsLoading(true);
        const res = await getListIntroVideo(page, limit)
            .then((data: any) => {
                if (data?.list) {
                    setList([...list, ...data.list]);
                    setPage(page);
                    setHasMore(data.list.length === limit);
                }
            })
            .catch((err: any) => {
                console.error(err);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        const init = async () => {
            await fetchList(page, LIMIT);
        }
        init();
    }, []);

    const loadMore = () => {
        if (hasMore) {
            fetchList(page + 1, LIMIT);
        }
    }

    const handleDelete = async (introVideoId: number) => {
        console.log("pages/student/v2/profile/AgencyProfileIntroVideo.container::handleDelete");
        console.log({ introVideoId });

        if (isOnNetworking) return;

        if (!window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "btn.txtDeleteConfirmation"))) return;

        setIsOnNetworking(true);
        await deleteIntroVideo(introVideoId)
            .then(() => {
                const newList = list.filter((item: any) => {
                    console.log({ item, introVideoId });
                    if (item.id === introVideoId) {
                        console.log("false");
                        return false;
                    } else {
                        console.log("true");
                        return true;
                    }
                });
                setList(newList);
            })
            .catch((error) => {
                console.error("Failed to delete video", error);
            })
            .finally(() => {
                setIsOnNetworking(false);
            })
    }

    return (
        <AgencyProfileIntroVideoComponent
            list={list}
            isLoading={isLoading}
            isOnNetworking={isOnNetworking}
            hasMore={hasMore}
            loadMore={loadMore}
            onDelete={handleDelete}
        />
    )
}

export default AgencyProfileIntroVideoContainer;