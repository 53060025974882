import { useEffect } from "react";
import { useState } from "react";
import RequestChatPopupComponent from "./RequestChatPopup.component";
import { fetchRequestDetail } from "api/student/v2/popup.api";
import { acceptMessageRequest } from "api/common/Chat.api";
import { denyChatRequest } from "api/student/v2/chat.api";
import { useNetwork } from "contexts/NetworkContext";
import { useNavigate } from "react-router-dom";
const RequestChatPopupContainer = (props: any) => {
    const navigate = useNavigate();
    const { messageRequestId, onClose, onCloseCallback } = props;
    const { isOnNetworking, setIsOnNetworking } = useNetwork();

    const [isInit, setIsInit] = useState(false);
    const [info, setInfo]: any = useState({});

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setIsOnNetworking(true);
            const data = await fetchRequestDetail(messageRequestId);
            if (data?.info) {
                console.log({ data });
                setInfo(data?.info);
            }
            setIsInit(true)
        } catch (error) {
            console.error('Failed to fetch request detail:', error);
        } finally {
            setIsOnNetworking(false);
        }
    };

    const handleDenyChatRequest = async () => {
        if (isOnNetworking) return;
        try {
            setIsOnNetworking(true);
            if (window.confirm("대화 요청을 거절하시겠습니까?")) {
                const data = await denyChatRequest(messageRequestId);
                console.log({ data });
                if (data?.result) {
                    console.log({ data });
                    onClose();
                    onCloseCallback(true);
                } else {
                    alert("대화 요청 거절에 실패했습니다.");
                }
            }
        } catch (error) {
            console.error('Failed to deny chat request:', error);
        } finally {
            setIsOnNetworking(false);
        }
    };

    const handleAcceptChatRequest = async () => {
        if (isOnNetworking) return;
        try {
            setIsOnNetworking(true);
            if (window.confirm("대화 요청을 수락하시겠습니까?")) {
                const data = await acceptMessageRequest(messageRequestId);
                console.log({ data });
                if (data?.info) {
                    onClose();
                    onCloseCallback(data.info.channelId);
                } else {
                    alert("대화 요청 수락에 실패했습니다.");
                }
            }
        } catch (error) {
            console.error('Failed to accept chat request:', error);
        } finally {
            setIsOnNetworking(false);
        }
    };


    return (
        <RequestChatPopupComponent
            isOnNetworking={isOnNetworking}
            isInit={isInit}
            info={info}
            onClose={onClose}
            onDenyChatRequest={handleDenyChatRequest}
            onAcceptChatRequest={handleAcceptChatRequest}
        />
    );
};

export default RequestChatPopupContainer;