import React, { useEffect, useState } from "react";
import AgencyProfilePhotoModalPopupComponent from "./AgencyProfilePhotoModalPopup.component";
import { getImagePresignedUrl } from "api/common/Data.api";
const AgencyProfilePhotoModalPopupContainer = ({ ...props }) => {
    const { onClose, imageInfo, handleClosePopup } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
        setIsLoading(true);
        setHasError(false);
        setImageUrl("");
        init();
    }, []);

    const init = async () => {
        await getImagePresignedUrl(imageInfo.imageId).then((res: any) => {
            if (res.result) {
                setImageUrl(res.result);
            } else {
                throw new Error(res.message);
            }
        }).catch((err: any) => {
            console.log(err);
            setHasError(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return <AgencyProfilePhotoModalPopupComponent
        onClose={onClose}
        selectedPopupImageUrl={imageUrl}
        handleClosePopup={handleClosePopup}
        isLoading={isLoading}
        hasError={hasError}
        imageUrl={imageUrl}
    />;
};

export default AgencyProfilePhotoModalPopupContainer;   