import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";
import AuthHeader from "../component/AuthHeader.component";

const SchoolSignupComponent = (props: any) => {
  const {
    btnRef,
    accountParams,
    schoolParams,
    termList,
    imageInfo,
    fileInfo,
    isOnNetworking,
    uploadImage,
    uploadFile,
    onChangeInput,
    onChangeCheckbox,
    onClickBtnCancelSignup,
    onClickBtnSignup,
    backUrl,
  } = props;

  return (
    <main className="bg-gray-50 min-h-screen">
      <AuthHeader backUrl={backUrl} />
      <form>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-6 lg:pt-6">
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-3xl font-bold text-gray-900">SCHOOL SIGNUP</h2>
          </div>

          {/* 계정 정보 섹션 */}
          <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
            <div className="p-6">
              <div className="mb-6">
                <h3 className="text-xl font-semibold text-gray-900">ACCOUNT</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "schoolSignup.txtAccountInfoSubTitle"
                  )}
                </p>
              </div>

              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtEmail"
                    )}
                  </label>
                  <input
                    type="text"
                    value={accountParams.username}
                    onChange={(evt) => onChangeInput("username", evt.target.value)}
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtPassword"
                    )}
                  </label>
                  <input
                    type="password"
                    value={accountParams.password}
                    onChange={(evt) => onChangeInput("password", evt.target.value)}
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtConfirmPassword"
                    )}
                  </label>
                  <input
                    type="password"
                    value={accountParams.confirmedPassword}
                    onChange={(evt) =>
                      onChangeInput("confirmedPassword", evt.target.value)
                    }
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* 필수 정보 섹션 */}
          <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
            <div className="p-6">
              <div className="mb-6">
                <h3 className="text-xl font-semibold text-gray-900">ESSENTIAL INFORMATION</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "schoolSignup.txtEssentialInfoSubTitle"
                  )}
                </p>
              </div>

              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtLogoUpload"
                    )}
                  </label>
                  <div className="flex items-center">
                    <div
                      className={imageInfo?.url ? "logo img-upload" : "img"}
                      style={{
                        backgroundImage: `url(${
                          imageInfo?.url || "./../../imgs/icons/camera.png"
                        })`,
                      }}
                    />
                    <input
                      type="file"
                      id="input-file-img"
                      onChange={(evt) => uploadImage(evt)}
                      accept="image/*"
                      className="ml-4"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtSchoolName"
                    )}
                  </label>
                  <input
                    type="text"
                    value={schoolParams.schoolName}
                    onChange={(evt) => onChangeInput("schoolName", evt.target.value)}
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtBusinessRegistration"
                    )}
                  </label>
                  <div className="flex items-center">
                    <input
                      className="upload-file"
                      type="file"
                      id="file"
                      onChange={(evt) => uploadFile(evt)}
                    />
                    <input
                      readOnly={true}
                      className="upload-name ml-4"
                      value={fileInfo?.name}
                      placeholder={getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "schoolSignup.txtFileUpload"
                      )}
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtRepresentative"
                    )}
                  </label>
                  <input
                    type="text"
                    value={schoolParams.representative}
                    onChange={(evt) =>
                      onChangeInput("representative", evt.target.value)
                    }
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtCountry"
                    )}
                  </label>
                  <select
                    value={schoolParams.countryCode}
                    onChange={(evt) =>
                      onChangeInput("countryCode", evt.target.value)
                    }
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="" disabled selected>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "schoolSignup.txtCountryOptional"
                      )}
                    </option>
                    {commonData.getCountryCodeList().map((item: any, idx: number) => (
                      <option key={idx} value={item.code}>
                        {getCurrentLangName(item.names)}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtCity"
                    )}
                  </label>
                  <input
                    type="text"
                    value={schoolParams.city}
                    onChange={(evt) => onChangeInput("city", evt.target.value)}
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtAddress1"
                    )}
                  </label>
                  <input
                    type="text"
                    value={schoolParams.address1}
                    onChange={(evt) => onChangeInput("address1", evt.target.value)}
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtAddress2"
                    )}
                  </label>
                  <input
                    type="text"
                    value={schoolParams.address2}
                    onChange={(evt) => onChangeInput("address2", evt.target.value)}
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtZipcode"
                    )}
                  </label>
                  <input
                    type="text"
                    value={schoolParams.zipcode}
                    onChange={(evt) => onChangeInput("zipcode", evt.target.value)}
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtTelephone"
                    )}
                  </label>
                  <input
                    type="text"
                    value={schoolParams.telephone}
                    onChange={(evt) => onChangeInput("telephone", evt.target.value)}
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtHomepageUrl"
                    )}
                  </label>
                  <input
                    type="text"
                    value={schoolParams.homepageUrl}
                    onChange={(evt) => onChangeInput("homepageUrl", evt.target.value)}
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* 컨택 정보 섹션 */}
          <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
            <div className="p-6">
              <div className="mb-6">
                <h3 className="text-xl font-semibold text-gray-900">CONTACT INFORMATION</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "schoolSignup.txtContactInfoSubTitle"
                  )}
                </p>
              </div>

              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtCellphone"
                    )}
                  </label>
                  <input
                    type="text"
                    value={schoolParams.cellphone}
                    onChange={(evt) => onChangeInput("cellphone", evt.target.value)}
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    <span className="text-red-500">*</span>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "schoolSignup.txtEmail"
                    )}
                  </label>
                  <input
                    type="text"
                    value={schoolParams.contactEmail}
                    onChange={(evt) =>
                      onChangeInput("contactEmail", evt.target.value)
                    }
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* sns 정보 섹션 */}
          <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
            <div className="p-6">
              <div className="mb-6">
                <h3 className="text-xl font-semibold text-gray-900">SNS</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "schoolSignup.txtSnsInfoSubTitle"
                  )}
                </p>
              </div>

              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtInstagram")}
                  </label>
                  <div className="flex items-center">
                    <div className="prefix">
                      <p>instagram.com/</p>
                    </div>
                    <input
                      type="text"
                      value={schoolParams.instagram}
                      onChange={(evt) => onChangeInput("instagram", evt.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtYoutube")}
                  </label>
                  <div className="flex items-center">
                    <div className="prefix">
                      <p>youtube.com/@</p>
                    </div>
                    <input
                      type="text"
                      value={schoolParams.youtube}
                      onChange={(evt) => onChangeInput("youtube", evt.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtTwitter")}
                  </label>
                  <div className="flex items-center">
                    <div className="prefix">
                      <p>twitter.com/</p>
                    </div>
                    <input
                      type="text"
                      value={schoolParams.twitter}
                      onChange={(evt) => onChangeInput("twitter", evt.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtFacebook")}
                  </label>
                  <div className="flex items-center">
                    <div className="prefix">
                      <p>facebook.com/</p>
                    </div>
                    <input
                      type="text"
                      value={schoolParams.facebook}
                      onChange={(evt) => onChangeInput("facebook", evt.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtTiktok")}
                  </label>
                  <div className="flex items-center">
                    <div className="prefix">
                      <p>tiktok.com/@</p>
                    </div>
                    <input
                      type="text"
                      value={schoolParams.tiktok}
                      onChange={(evt) => onChangeInput("tiktok", evt.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* 약관 목록 섹션 */}
          <section className="bg-white rounded-2xl shadow-sm overflow-hidden mb-4">
            <div className="p-6">
              <div className="space-y-6">
                <div className="input-box agreement-form">
                  <ul className="list">
                    {termList.length > 0 &&
                      termList.map((item: any, idx: number) => (
                        <li key={`term-${idx}`} className="item">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              onChange={(evt) => {
                                onChangeCheckbox(idx, evt.target.checked);
                              }}
                              checked={item.checked}
                            />
                            <span className="txt">{`(${
                              item.isRequired
                                ? getLanguageValue(
                                    PAGE_TYPE.COMMON,
                                    "schoolSignup.txtRequired"
                                  )
                                : getLanguageValue(
                                    PAGE_TYPE.COMMON,
                                    "schoolSignup.txtOptional"
                                  )
                            }) ${item.title}`}</span>
                          </label>
                          <button
                            type="button"
                            className="btn"
                            onClick={(evt) =>
                              window.open(
                                item.isRequired
                                  ? `/term/common/${item.id}`
                                  : `/term/etc/${item.id}`
                              )
                            }>
                            {`(${getLanguageValue(
                              PAGE_TYPE.COMMON,
                              "schoolSignup.txtShow"
                            )})`}
                          </button>
                        </li>
                      ))}
                  </ul>
                  <div className="all-check">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        className="select-all"
                        onChange={(evt) => {
                          onChangeCheckbox("all", evt.target.checked);
                        }}
                        checked={!termList.find((i: any) => !i.checked)}
                      />
                      <span className="txt">
                        {getLanguageValue(
                          PAGE_TYPE.COMMON,
                          "schoolSignup.txtAgreeWithAllTerms"
                        )}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="flex justify-end">
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-md mr-4"
              onClick={(evt) => {
                onClickBtnCancelSignup();
              }}>
              {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.btnCancel")}
            </button>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
              ref={btnRef}
              onClick={(evt) => {
                onClickBtnSignup();
              }}
              disabled={isOnNetworking}>
              {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.btnSignup")}
            </button>
          </div>
        </div>
      </form>
    </main>
  );
};

export default SchoolSignupComponent;
