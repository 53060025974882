import * as BaseApi from "api/Base.api";

//* 게시글 목록 조회
//  2025.02.24
//  희문
//* Params
//  page: number 페이지 번호
//  limit: number 한 번에 불러올 포스트 수
export const getList = async (page: number, limit: number) => {
  const url = "/student/community";
  return await BaseApi.get(url, { page, limit });
};

//* 게시글 작성
//  2025.02.24
//  희문
//* Params
//  post: any 게시글 정보
export const createPost = async (text: string, imageIdList: string[]) => {
  const url = "/student/community";
  return await BaseApi.post(url, { text, imageIdList });
};

//* 게시글 삭제
//  2025.02.24
//  희문
//* Params
//  bbsId: number 게시글 아이디
export const deletePost = async (bbsId: number) => {
  const url = `/student/community/${bbsId}`;
  return await BaseApi.remove(url);
};

//* 게시글 번역 요청
//  2025.02.25
//  희문
//* Params
//  bbsId: number 게시글 아이디
export const doTranslatePostText = async (bbsId: number) => {
  const url = `/student/community/${bbsId}/translate`;
  return await BaseApi.post(url, {}).then((response: any) => {
    if (response?.status === 200) {
      return response.data.data;
    }
    throw new Error(response.response.data.message);
  });
};

//* 게시글 좋아요 토글
//  2025.02.24
//  희문
//* Params
//  bbsId: number 게시글 아이디
export const toggleLike = async (bbsId: number): Promise<any> => {
  const url = `/student/community/${bbsId}/like`;
  return await BaseApi.post(url, {}).then((response: any) => {
    if (response?.status === 200) {
      return response.data.data;
    }
    throw new Error(response.response.data.message);
  });
};

//* 게시글 신고
//  2025.02.24
//  희문
//* Params
//  bbsId: number 게시글 아이디
//  reportTypeCode: string 신고 유형 코드
//  etcReason: string 기타 사유
export const doReport = async (bbsId: number, reportTypeCode: string, etcReason: string) => {
  const url = `/student/community/${bbsId}/report`;
  return await BaseApi.post(url, { reportTypeCode, etcReason });
};

//* 댓글 목록 요청
//  2025.02.24
//  희문
//* Params
//  bbsId: number 게시글 아이디
//  page: number 페이지 번호
//  limit: number 한 번에 불러올 댓글 수
export const getCommentList = async (bbsId: number, page: number, limit: number) => {
  const url = `/student/community/${bbsId}/comment`;
  return await BaseApi.get(url, { page, limit });
};

//* 댓글 작성
//  2025.02.24
//  희문
//* Params
//  bbsId: number 게시글 아이디
//  text: string 댓글 내용
export const createComment = async (bbsId: number, text: string) => {
  const url = `/student/community/${bbsId}/comment`;
  return await BaseApi.post(url, { text });
};

//* 댓글 좋아요 토글
//  2025.02.24
//  희문
//* Params
//  bbsId: number 게시글 아이디
//  commentId: number 댓글 아이디
export const toggleLikeComment = async (bbsId: number, commentId: number): Promise<any> => {
  const url = `/student/community/${bbsId}/comment/${commentId}/like`;
  return await BaseApi.post(url, {}).then((response: any) => {
    if (response?.status === 200) {
      return response.data.data;
    }
    throw new Error(response.response.data.message);
  });
};

//* 댓글 삭제
//  2025.02.24
//  희문
//* Params
//  bbsId: number 게시글 아이디
//  commentId: number 댓글 아이디
export const deleteComment = async (bbsId: number, commentId: number) => {
  const url = `/student/community/${bbsId}/comment/${commentId}`;
  return await BaseApi.remove(url);
};

//* 댓글 신고
//  2025.02.24
//  희문
//* Params
//  bbsId: number 게시글 아이디
//  commentId: number 댓글 아이디
//  reportTypeCode: string 신고 유형 코드
//  etcReason: string 기타 사유
export const doReportComment = async (bbsId: number, commentId: number, reportTypeCode: string, etcReason: string) => {
  const url = `/student/community/${bbsId}/comment/${commentId}/report`;
  return await BaseApi.post(url, { reportTypeCode, etcReason });
};

//* 좋아요 목록 조회
//  2025.02.24
//  희문
//* Params
//  bbsId: number 게시글 아이디
//  page: number 페이지 번호
//  limit: number 한 번에 불러올 좋아요 수
export const getLikeList = async (bbsId: number, page: number, limit: number) => {
  const url = `/student/community/${bbsId}/like`;
  return await BaseApi.get(url, { page, limit });
};


//* 댓글 좋아요 목록 조회
//  2025.02.24
//  희문
//* Params
//  commentId: number 댓글 아이디
//  page: number 페이지 번호
//  limit: number 한 번에 불러올 좋아요 수
export const getLikeListForComment = async (bbsId: number, commentId: number, page: number, limit: number) => {
  const url = `/student/community/${bbsId}/comment/${commentId}/like`;
  return await BaseApi.get(url, { page, limit });
};

//* 댓글 번역 요청
//  2025.02.25
//  희문
//* Params
//  bbsId: number 게시글 아이디
//  commentId: number 댓글 아이디
export const doTranslateCommentText = async (bbsId: number, commentId: number) => {
  console.log('api/student/Community.api.tsx');
  console.log({ bbsId, commentId });

  const url = `/student/community/${bbsId}/comment/${commentId}/translate`;
  return await BaseApi.post(url, {}).then((response: any) => {
    if (response?.status === 200) {
      return response.data.data;
    }
    throw new Error(response.response.data.message);
  });
};
