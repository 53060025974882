import React, { useRef, useEffect } from 'react';

const VideoPlayerComponent = (props: any) => {
    const {
        videoUrl,
        videoRef,
        onVideoError,
        status,
        onPlayVideo
    } = props;

    return (
        <>
            {status === 'error' ? (
                <div className="rounded-lg max-w-[80vw] hover:opacity-95 transition-opacity shadow-sm bg-gray-100 p-4 flex items-center justify-center">
                    <p className="text-sm text-gray-500">비디오 로드 실패</p>
                </div>
            ) :
                <video
                    onClick={onPlayVideo}
                    ref={videoRef}
                    src={videoUrl}
                    className="w-full h-full object-cover"
                    onError={onVideoError}
                    controls
                />
            }
        </>
    )
}

export default VideoPlayerComponent;

