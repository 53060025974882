import * as BaseApi from "api/Base.api";

const BASE_URL = "/agency/v2/favorite";

//* 즐겨찾기 목록 조회
//  2025-02-27 추가
//  희문
//* Params
//  page : 페이지 번호
//  limit : 페이지 당 데이터 개수
export const getFavoriteList = async (page: number, limit: number) => {
    return await BaseApi.get(BASE_URL, { page, limit });
};

//* 즐겨찾기 추가
//  2025-03-09 추가
//  희문
//* Params
//  userStudentId : 학생 아이디
export const addFavorite = async (userStudentId: number) => {
    return await BaseApi.post(BASE_URL, { userStudentId });
};

//* 즐겨찾기 삭제
//  2025-03-01 추가
//  희문
//* Params
//  userStudentId : 학생 아이디
export const deleteFavorite = async (userStudentId: number) => {
    console.log("api/agency/v2/Favorite.api::deleteFavorite");
    console.log({ userStudentId });

    const url = `${BASE_URL}/${userStudentId}`;
    return await BaseApi.remove(url)
        .then((res: any) => {
            if (res.status === 200) {
                return res.data.data;
            }
        })
};