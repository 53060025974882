import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IndexComponent from "./Index.component";
import { getInfoForHome } from "api/agency/v2/home.api";
import FullPageStudentInfoPopupContainer from "../popup/FullPageStudentInfoPopup.container";
import { usePopup } from "contexts/PopupContext";
const IndexContainer = () => {
  const navigate = useNavigate();
  const { showPopup } = usePopup();
  const [isInit, setIsInit] = useState<boolean>(false);
  const [favoriteCount, setFavoriteCount] = useState<number>(0);
  const [todayViewCount, setTodayViewCount] = useState<number>(0);
  const [chatRequestCount, setChatRequestCount] = useState<number>(0);
  const [chattingCount, setChattingCount] = useState<number>(0);
  const [recentlySignupStudentList, setRecentlySignupStudentList] = useState<any[]>([]);
  const [updatedFavoriteList, setUpdatedFavoriteList] = useState<any[]>([]);

  const fetchHomeInfo = async () => {
    try {
      const data = await getInfoForHome();
      if (data) {
        setFavoriteCount(data?.favoriteCount || 0);
        setTodayViewCount(data?.todayViewCount || 0);
        setChatRequestCount(data?.chatRequestCount || 0);
        setChattingCount(data?.chattingCount || 0);
        setRecentlySignupStudentList(data?.recentlySignupStudentList || []);
        setUpdatedFavoriteList(data?.updatedFavoriteList || []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsInit(true);
    }
  };


  const handleClickStudent = (id: string) => {
    navigate(`/agency/student/${id}`);
  };

  const handleClickFavoriteCount = () => {
    navigate('/agency/favorite');
  };

  const handleClickTodayViewCount = () => {
    navigate('/agency/search');
  };

  const handleClickChatRequestCount = () => {
    navigate('/agency/message');
  };

  const handleClickChattingCount = () => {
    navigate('/agency/message');
  };

  useEffect(() => {
    fetchHomeInfo();
  }, []);

  const showStudentInfoPopup = (userStudentId: number) => {
    showPopup(
      <FullPageStudentInfoPopupContainer
        userStudentId={userStudentId}
      />
    );
  }


  return <IndexComponent
    isInit={isInit}
    favoriteCount={favoriteCount}
    todayViewCount={todayViewCount}
    chatRequestCount={chatRequestCount}
    chattingCount={chattingCount}
    recentlySignupStudentList={recentlySignupStudentList}
    updatedFavoriteList={updatedFavoriteList}
    handleClickStudent={handleClickStudent}
    handleClickFavoriteCount={handleClickFavoriteCount}
    handleClickTodayViewCount={handleClickTodayViewCount}
    handleClickChatRequestCount={handleClickChatRequestCount}
    handleClickChattingCount={handleClickChattingCount}
    showStudentInfoPopup={showStudentInfoPopup}
  />;
};


export default IndexContainer;
