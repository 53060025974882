import React from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getStudentNickname } from "helpers/String.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getImageUrl } from "helpers/Image.helper";

interface FullPageFollowPopupProps {
    activeTab: "followers" | "following";
    setActiveTab: (tab: "followers" | "following") => void;
    followers: any[];
    following: any[];
    isLoading: boolean;
    onClose: () => void;
    onShowStudentInfoPopup: (studentId: number) => void;
    onFollow: (studentId: number) => void;
    onUnfollow: (studentId: number) => void;
}

const FullPageFollowPopupComponent: React.FC<FullPageFollowPopupProps> = ({
    activeTab,
    setActiveTab,
    followers,
    following,
    isLoading,
    onClose,
    onShowStudentInfoPopup,
    onFollow,
    onUnfollow,
}) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-screen h-screen top-0 left-0 !m-0">
            <div className="bg-white p-6 w-full max-w-2xl mx-auto h-full max-h-screen flex flex-col">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-bold text-gray-900">Follow</h3>
                    <button className="text-gray-400 hover:text-gray-600" onClick={onClose}>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {isLoading ? (
                    <div className="flex-grow flex items-center justify-center">
                        <LoadingComponent />
                    </div>
                ) : (
                    <div className="overflow-y-auto flex-grow">
                        {activeTab === "followers" ? (
                            followers.length > 0 ? (
                                followers.map((follower, index) => (
                                    <div key={index} className="p-2 border-b border-gray-200">
                                        {follower.name}
                                    </div>
                                ))
                            ) : (
                                <div className="flex flex-col items-center justify-center py-10 text-gray-400 h-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                    <p>팔로워가 없습니다</p>
                                </div>
                            )
                        ) : (
                            following.length > 0 ? (
                                following.map((user, index) => (
                                    <div key={user.id} className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-lg">
                                        <div className="flex items-center space-x-3"
                                            onClick={() => onShowStudentInfoPopup(user.userStudentInfo?.id)}
                                        >
                                            <div className="w-10 h-10 rounded-full bg-gray-200 overflow-hidden">
                                                <img src={getImageUrl(user.userStudentInfo?.profileImageInfo)} alt="" className="w-full h-full object-cover" />
                                            </div>
                                            <div>
                                                <p className="font-medium">
                                                    {getStudentNickname(user.userStudentInfo)}
                                                </p>
                                            </div>
                                        </div>
                                        {!user.isMine && (
                                            <button
                                                className={`px-4 py-2 rounded-lg text-sm font-medium ${user.isFollowing ? 'bg-gray-100 text-gray-700 hover:bg-gray-200' : 'bg-[#CF3688] text-white hover:bg-[#CF3688]/90'}`}
                                                onClick={() => user.isFollowing ? onUnfollow(user.userStudentInfo?.id) : onFollow(user.userStudentInfo?.id)}>
                                                {activeTab === 'following' &&
                                                    <>
                                                        {((user.isFollowing && user.isFollower) && getLanguageValue(PAGE_TYPE.STUDENT, "hubFollow.btnUnfollowBack"))}
                                                        {((user.isFollowing && !user.isFollower) && getLanguageValue(PAGE_TYPE.STUDENT, "hubFollow.btnUnfollow"))}
                                                        {((!user.isFollowing && user.isFollower) && getLanguageValue(PAGE_TYPE.STUDENT, "hubFollow.btnFollowBack"))}
                                                        {((!user.isFollowing && !user.isFollower) && getLanguageValue(PAGE_TYPE.STUDENT, "hubFollow.btnFollow"))}
                                                    </>
                                                }
                                                {/* {activeTab === 'followers' &&
                                                    (user.isFollowing
                                                        ? getLanguageValue(PAGE_TYPE.STUDENT, "hubFollow.btnUnfollow")
                                                        : getLanguageValue(PAGE_TYPE.STUDENT, "hubFollow.btnFollowBack"))
                                                } */}
                                            </button>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="flex flex-col items-center justify-center py-10 text-gray-400 h-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                    <p>팔로잉하는 사용자가 없습니다</p>
                                </div>
                            )
                        )}
                    </div>
                )}

                <div className="grid grid-cols-2 mt-4 border-t border-gray-200">
                    <button
                        className={`py-3 text-center ${activeTab === "followers" ? "font-bold text-[#CF3688]" : "text-gray-500"}`}
                        onClick={() => setActiveTab("followers")}
                    >
                        Followers
                    </button>
                    <button
                        className={`py-3 text-center ${activeTab === "following" ? "font-bold text-[#CF3688]" : "text-gray-500"}`}
                        onClick={() => setActiveTab("following")}
                    >
                        Following
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FullPageFollowPopupComponent;