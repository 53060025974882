import { getCurrentLangName } from "helpers/String.helper";
import React from "react";
import { useNavigate } from "react-router-dom";

const CellphoneComponent = (props: any) => {
    const navigate = useNavigate();
    const { currentCellphone,
        setCurrentCellphone,
        newCellphone,
        setNewCellphone,
        verifyingNumber,
        setVerifyingNumber,
        verifyingId,
        countryPhoneCodeList,
        onSendVerifyNumber,
        onVerifyNumber,
        isOnSedingVerifyNumber,
        hasSentVerifyNumber,
        hasVerified,
        onSave
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen py-6">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center gap-4 mb-6">
                    <button onClick={() => navigate(-1)} className="text-gray-600 hover:text-gray-900">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <h1 className="text-2xl font-bold text-gray-900">
                        휴대전화 정보 수정
                    </h1>
                </div>

                <div className="bg-white rounded-2xl p-6 shadow-sm">
                    <div className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                현재 휴대전화 번호
                            </label>
                            <input
                                type="text"
                                disabled
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-500 text-base"
                                value={currentCellphone}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                변경할 휴대전화 번호
                            </label>
                            <div className="space-y-2">
                                <div className="flex gap-2">
                                    <input
                                        disabled={hasVerified || verifyingId}
                                        type="text"
                                        className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base"
                                        placeholder="휴대전화를 입력해주세요"
                                        value={newCellphone}
                                        maxLength={11}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => setNewCellphone(e.target.value)}
                                    />
                                </div>
                                <button
                                    onClick={() => onSendVerifyNumber()}
                                    className="w-full px-4 py-2 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors"
                                    disabled={isOnSedingVerifyNumber || hasVerified || verifyingId}>
                                    인증번호 발송
                                </button>
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                인증번호
                            </label>
                            <div className="flex gap-2">
                                <input
                                    disabled={hasVerified}
                                    maxLength={6}
                                    type="text"
                                    className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base"
                                    placeholder="인증번호를 입력해주세요"
                                    value={verifyingNumber}
                                    onChange={(e) => setVerifyingNumber(e.target.value)}
                                />
                                <button
                                    disabled={isOnSedingVerifyNumber || hasVerified}
                                    onClick={() => onVerifyNumber()}
                                    className="px-4 py-2 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors">
                                    인증
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 space-y-4">
                    <button
                        disabled={!hasVerified}
                        onClick={() => onSave()}
                        className="w-full py-3 px-4 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors disabled:bg-gray-300"
                    >
                        저장
                    </button>
                    <button
                        onClick={() => navigate(-1)}
                        className="w-full py-3 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium rounded-lg transition-colors"
                    >
                        취소
                    </button>
                </div>
            </div>
        </main>
    );
};

export default CellphoneComponent;