import * as BaseApi from "../Base.api";

export const getList = async (params?: any) => {
  const url = "/student";
  return await BaseApi.get(url, params);
};

export const getListForHome = async () => {
  const url = "/student/home";
  return await BaseApi.get(url);
};

export const getListBySchool = async (params?: any) => {
  const url = "/school/student";
  return await BaseApi.get(url, params);
};

export const getInfoForStudent = async () => {
  const url = "/student/info/student";
  return await BaseApi.get(url);
};

export const getInfoForAgency = async (studentId: number) => {
  const url = `/student/info/agency/${studentId}`;
  return await BaseApi.get(url);
};

export const getInfoForHome = async () => {
  const url = "/student/info/home";
  return await BaseApi.get(url);
};

export const getFavoriteCntList = async (params: any) => {
  const url = "/student/favorite";
  return await BaseApi.get(url, params);
};

export const getViewCntList = async (params: any) => {
  const url = "/student/view";
  return await BaseApi.get(url, params);
};

export const updateInfo = async (id: number, params: any) => {
  const url = `/student/${id}`;
  return await BaseApi.put(url, params);
};

export const updateAdditionalInfo = async (id: number, params: any) => {
  const url = `/student/additional-info/${id}`;
  return await BaseApi.patch(url, params);
};

export const updateTermsInfo = async (id: number, params: any) => {
  const url = `/student/terms/${id}`;
  return await BaseApi.put(url, params);
};

export const createFavorite = async (params: any) => {
  const url = "/student/favorite";
  return await BaseApi.post(url, params);
};

export const deleteFavorite = async (params: any) => {
  const url = "/student/favorite";
  return await BaseApi.remove(url, params);
};

export const getUpdatedFavoriteCount = async () => {
  const url = "/student/updated-favorite-count";
  return await BaseApi.get(url);
};

export const updateVerify = async (id: number, params: any) => {
  const url = `/student/verify/${id}`;
  return await BaseApi.patch(url, params);
};

export const updateActive = async (params: any) => {
  const url = `/student/active`;
  return await BaseApi.patch(url, params);
};

//* 공개프로필 활성화 여부
// 2025.02.13
// 희문
// 공개 프로필 활성화 여부 수정 시 사용
export const updateIsActiveOfPublicProfile = async (params: any) => {
  const url = `/student/info/is-active-of-public-profile`;
  return await BaseApi.patch(url, params);
};

//* 기획사용 프로필 활성화 여부
// 2025.02.13
// 희문
// 기획사용 프로필 활성화 여부 수정 시 사용
export const updateIsActiveOfAgencyProfile = async (params: any) => {
  const url = `/student/info/is-active-of-agency-profile`;
  return await BaseApi.patch(url, params);
};

export const updateViewCount = async (params: any) => {
  const url = `/student/view-count`;
  return await BaseApi.post(url, params);
};

// 필수정보 요청
// 2025.02.13
// 희문
// 공개 프로필 화면에서 필수정보 요청 시 사용
export const getPublicProfileEssentialInfo = async () => {
  const url = `/student/info/essential`;
  return await BaseApi.get(url);
};

// 필수정보 수정
// 2025.02.13
// 희문
// 공개 프로필 화면에서 필수정보 수정 시 사용
export const updatePublicProfileEssentialInfo = async (params: any) => {
  const url = `/student/info/essential`;
  return await BaseApi.put(url, params);
};

// 추가정보 요청
// 2025.02.13
// 희문
// 공개 프로필 화면에서 추가정보 요청 시 사용
export const getPublicProfileAdditionalInfo = async () => {
  const url = `/student/info/additional`;
  return await BaseApi.get(url);
};

// 추가정보 수정
// 2025.02.13
// 희문
// 공개 프로필 화면에서 추가정보 수정 시 사용
export const updatePublicProfileAdditionalInfo = async (params: any) => {
  const url = `/student/info/additional`;
  return await BaseApi.put(url, params);
};

// 공개 프로필 조회
// 2025.02.13
// 희문
// 공개 프로필 화면에서 조회 시 사용
export const getPublicProfileInfo = async () => {
  const url = `/student/info/profile/public`;
  return await BaseApi.get(url);
};

// 특정 정보 공개 여부 수정
// 2025.02.13
// 희문
// 공개 프로필 화면에서 특정 정보 공개 여부 수정 시 사용
export const updatePublicProfileBodyInfoIsPublic = async (type: string, isPublic: boolean) => {
  const url = `/student/info/body-info/${type}/is-public`;
  return await BaseApi.patch(url, { isPublic });
};

// 특정 신체 정보 조회
// 2025.02.13
// 희문 
// 공개 프로필 화면에서 특정 신체 정보 조회 시 사용
export const getPublicProfileBodyInfo = async (type: string) => {
  const url = `/student/info/body-info/${type}`;
  return await BaseApi.get(url);
};

// 특정 신체정보 등록
// 2025.02.13
// 공개 프로필 화면에서 특정 신체 정보 등록 시 사용
export const createPublicProfileBodyInfo = async (type: string, value: number) => {
  const url = `/student/info/body-info/${type}`;
  return await BaseApi.post(url, { value });
};

// 특정 신체 정보 수정
// 2025.02.13
// 희문 
// 공개 프로필 화면에서 특정 신체 정보 수정 시 사용
export const updatePublicProfileBodyInfo = async (type: string, id: number, value: number) => {
  const url = `/student/info/body-info/${type}/history/${id}`;
  return await BaseApi.put(url, { value });
};

// 특정 신체 정보 삭제
// 2025.02.13
// 희문 
// 공개 프로필 화면에서 특정 신체 정보 삭제 시 사용
export const deletePublicProfileBodyInfo = async (type: string, id: number) => {
  const url = `/student/info/body-info/${type}/history/${id}`;
  return await BaseApi.remove(url);
};

// 내 정보 요청
// 내정보 페이지
// 2025.02.17
// 희문
// 마이페이지 홈에서 호출
export const getMyInfo = async() => {
  const url = `/student/info/my`;
  return await BaseApi.get(url);
}