import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PostFormComponent from "./PostForm.component";
import { getPostInfo, updatePost } from "api/student/v2/FanPage.api";
import { uploadImage } from "api/common/Data.api";

const POST_LIMIT = 10; // 한 번에 불러올 포스트 수

const PostFormContainer = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [content, setContent] = useState("");
    const [images, setImages] = useState<Array<{ id: string; url: string; file?: File }>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isPosting, setIsPosting] = useState(false);

    // 포스트 정보 조회
    const fetchPostInfo = useCallback(async () => {
        if (!id) return;

        try {
            setIsLoading(true);
            const data = await getPostInfo(Number(id));
            if (data?.info) {
                setContent(data.info.text || "");
                setImages(data.info.postImageList || []);
            }
        } catch (error) {
            console.error('Failed to fetch post:', error);
            alert('포스트 정보를 불러오는데 실패했습니다.');
            navigate(-1);
        } finally {
            setIsLoading(false);
        }
    }, [id, navigate]);

    useEffect(() => {
        fetchPostInfo();
    }, [fetchPostInfo]);

    // 이미지 업로드 처리
    const handleImageUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;

        console.log(images);

        if (images.length + files.length > 10) {
            alert("이미지는 최대 10개까지만 업로드 가능합니다.");
            return;
        }

        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImages(prev => [...prev, {
                    id: Math.random().toString(36),
                    url: reader.result as string,
                    file
                }]);
            };
            reader.readAsDataURL(file);
        });
    }, [images.length]);

    // 이미지 제거
    const handleRemoveImage = useCallback((idx: number) => {
        setImages(prev => prev.filter((_, i) => i !== idx));
    }, []);

    // 내용 변경
    const handleContentChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(event.target.value);
    }, []);

    // 취소
    const handleCancel = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    // 저장
    const handleSubmit = useCallback(async () => {
        if (!id || !content.trim() || isPosting) return;

        console.log(images);

        try {
            setIsPosting(true);

            // 새로 추가된 이미지만 업로드
            const uploadPromises: any[] = [];

            // 기존 이미지 처리 루틴
            images.forEach((img: any) => {
                if (img.file) {
                    uploadPromises.push(uploadImage(img.file as File));
                }
            });
            const existingPostImageIds = images.filter(img => !img.file).map((img: any) => img.imageInfo.id);

            // 새로 추가된 이미지 업로드
            const uploadedImages: any[] = [];
            if (uploadPromises.length > 0) {
                const promiseResults = await Promise.all(uploadPromises);

                promiseResults.forEach((img: any) => {
                    console.log(img);
                    uploadedImages.push(img);
                });
            }
            const newImageIds = uploadedImages.map((img: any) => img.id);

            console.log(existingPostImageIds);
            console.log(newImageIds);

            // // 포스트 업데이트
            await updatePost(Number(id), content.trim(), existingPostImageIds, newImageIds);

            // navigate(-1);
        } catch (error) {
            console.error('Failed to update post:', error);
            alert('포스트 수정에 실패했습니다. 다시 시도해주세요.');
        } finally {
            setIsPosting(false);
        }
    }, [id, content, images, isPosting, navigate]);

    return (
        <PostFormComponent
            content={content}
            images={images}
            isLoading={isLoading}
            isPosting={isPosting}
            fileInputRef={fileInputRef}
            onContentChange={handleContentChange}
            onImageUpload={handleImageUpload}
            onRemoveImage={handleRemoveImage}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
        />
    );
};

export default PostFormContainer;