import React, { useEffect, useState } from "react";
import FooterComponent from "./Footer.component";
import FooterV2Component from "./FooterV2.component";
import { getHomeTermList } from "api/common/Auth.api";
import { useSelector } from "react-redux";
import { PAGE_TYPE } from "enums";

const FooterContainer = (props: any) => {
    const { pageType } = props;
    const [termList, setTermList] = useState([]);
    const { isLogin, info: userInfo } = useSelector((state: any) => state.user);

    const getTermListValue = async () => {
        const result = await getHomeTermList();

        if (result) {
            setTermList(result?.list);
        }
    };

    useEffect(() => {
        getTermListValue();
    }, [isLogin]);

    return <>{pageType === PAGE_TYPE.COMMON ? <FooterV2Component {...props} termList={termList} /> : <FooterComponent {...props} termList={termList} />}</>;
};
export default FooterContainer;
