import React from "react";
import DaumPostcodeEmbed from "react-daum-postcode";

import ArtistPopup from "./ArtistPopup";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import AuthHeader from "../component/AuthHeader.component";

const AgencySignupComponent = (props: any) => {
  const { agencyList, agencyInfo, selectedAgencyInfo, next, onAgencyChange, backUrl } =
    props;

  return (
    <main className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <AuthHeader backUrl={backUrl} />
      <div className="max-w-4xl mx-auto">
        <h2 className="md:text-4xl text-2xl font-bold text-center text-gray-900 mb-8 md:mb-12">
          CASTING MANAGER SIGNUP
        </h2>
        <div className="bg-white rounded-lg shadow-lg p-4 md:p-8 mb-6 md:mb-8">
          <h3 className="text-xl md:text-2xl font-semibold text-center text-gray-800 mb-4 md:mb-6">
            {getLanguageValue(
              PAGE_TYPE.COMMON,
              "agencySignupPre.txtSelectAgencyPrompt"
            )}
          </h3>

          <div className="max-w-md mx-auto">
            <div className="text-center mb-4 md:mb-6">
              <p className="text-gray-600 text-base md:text-lg leading-relaxed whitespace-pre-line bg-gray-50 p-4 rounded-lg border border-gray-200">
                *
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "agencySignupPre.txtSelectAgency"
                )}
              </p>
            </div>
            <select
              onChange={(e) => onAgencyChange(e.target.value)}
              className="w-full p-2 md:p-3 text-sm md:text-base border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-[#CF3688] focus:border-[#CF3688] mb-4"
            >
              <option value="" selected>
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "agencySignupPre.txtSelectAgencyPrompt"
                )}
              </option>
              {agencyList
                ? agencyList.map((item: any, idx: number) => (
                    <option key={idx} value={item.id}>
                      {item.nameKo}
                    </option>
                  ))
                : null}
            </select>
            <div className="text-center mb-4 md:mb-6">
              <p className="text-gray-600 text-base md:text-lg leading-relaxed whitespace-pre-line bg-gray-50 p-4 rounded-lg border border-gray-200">
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "agencySignupPre.txtNoAgencyFound"
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8 flex flex-col sm:flex-row gap-4 sm:gap-6 justify-center">
          <button
            className="bg-[#CF3688] text-white py-2 md:py-3 px-6 md:px-8 text-sm md:text-base rounded-md hover:bg-[#CF3688] transition-all duration-200 transform hover:scale-105"
            onClick={next}
          >
            {getLanguageValue(PAGE_TYPE.COMMON, "agencySignupPre.txtNext")}
          </button>
        </div>
      </div>
    </main>
  );
};

export default AgencySignupComponent;
