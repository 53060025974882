import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React from "react";

const ChangeParentEmailComponent = (props: any) => {
    const { currentEmail, newEmail, onEmailChange, onSubmit, isLoading } = props;

    return (
        <main className="bg-gray-50 min-h-screen py-6">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center gap-4 mb-6">
                    <button onClick={props.onCancel} className="text-gray-600 hover:text-gray-900">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <h1 className="text-2xl font-bold text-gray-900">
                        {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeParentEmail.txtTitle")}
                    </h1>
                </div>

                <div className="bg-white rounded-2xl p-6 shadow-sm">
                    <div className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeParentEmail.txtCurrentEmail")}
                            </label>
                            <input
                                type="email"
                                value={currentEmail}
                                readOnly
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base"
                                placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "myChangeParentEmail.txtCurrentEmailPlaceholder")}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                {getLanguageValue(PAGE_TYPE.STUDENT, "myChangeParentEmail.txtNewEmail")}
                            </label>
                            <input
                                type="email"
                                value={newEmail}
                                onChange={onEmailChange}
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#CF3688] focus:border-transparent text-base"
                                placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "myChangeParentEmail.txtNewEmailPlaceholder")}
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-6 px-4 sm:px-6 lg:px-8 max-w-md mx-auto">
                    <div className="flex flex-col sm:flex-row gap-4">
                        <button
                            onClick={props.onCancel}
                            disabled={isLoading}
                            className="w-full sm:w-1/2 py-3 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium rounded-lg transition-colors text-base sm:text-lg"
                        >
                            {getLanguageValue(PAGE_TYPE.COMMON, "btn.txtCancel")}
                        </button>
                        <button
                            onClick={onSubmit}
                            disabled={isLoading}
                            className="w-full sm:w-1/2 py-3 px-4 bg-[#CF3688] hover:bg-[#B82F77] text-white font-medium rounded-lg transition-colors disabled:bg-gray-300 text-base sm:text-lg"
                        >
                            {isLoading ? getLanguageValue(PAGE_TYPE.STUDENT, "myChangeParentEmail.btnChangeLoading") : getLanguageValue(PAGE_TYPE.STUDENT, "myChangeParentEmail.btnChange")}
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ChangeParentEmailComponent;