import React, {useState, useEffect } from "react";
import IndexComponent from "./Index.component";
import { getFanPageSummary } from "api/student/v2/fan.api";

const IndexContainer = () => {
    // const [isLoading, setIsLoading] = useState(false);

    const [isInit, setIsInit] = useState(false);
    const [info, setInfo] = useState<any>(null);

    const init = async() => {
        const data = await getFanPageSummary();
        if (data?.info) {
            setInfo(data.info);
        }
        setIsInit(true);
    }

    useEffect(() => {
        init();
    }, []);

    

    return <IndexComponent isInit={isInit} info={info} />;
};

export default IndexContainer;