import React, { useEffect, useState } from "react";
import QnAComponent from "./QnA.component";
import { getQnAList, deleteQnA, updateAnswer, createQuestion } from "api/student/v2/FanPage.api";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const LIMIT = 10;
const QnAContainer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMorePage, setHasMorePage] = useState(true);
    const [question, setQuestion] = useState("");
    const [isOnWritingQuestion, setIsOnWritingQuestion] = useState(false);

    const [answerList, setAnswerList] = useState([]);
    const [isOnAnswering, setIsOnAnswering] = useState(false);

    const [editingItem, setEditingItem] = useState(null);
    const [isOnDeleting, setIsOnDeleting] = useState(false);
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        await loadList(1, LIMIT);
    };

    const loadList = async (page: number, limit: number) => {
        console.log("loadList", page, limit);
        if (isLoading)
            return;

        setIsLoading(true);
        await getQnAList(page, limit)
            .then((data) => {
                setPage(page);
                setList([...list, ...data.list]);
                setHasMorePage(data.list.length === limit);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const loadMoreList = async () => {
        if (!hasMorePage) return;
        if (isLoading) return;

        await loadList(page + 1, LIMIT);
    };

    const handleWriteQuestion = async () => {
        console.log("handleWriteQuestion", question);
        if (question === "") return;
        if (isOnWritingQuestion) return;
        setIsOnWritingQuestion(true);
        try {
            const result = await createQuestion(question);
            setQuestion("");
            await loadList(1, LIMIT);
        } catch (err: any) {
            console.error(err);
            alert(err.message || "Unknown Error Occurred, Please try again later.");
        } finally {
            setIsOnWritingQuestion(false);
        }
    };

    const handleQuestionChange = (question: string) => {
        console.log("handleQuestionChange", question);
        setQuestion(question);
    };

    const handleDeleteQna = async (id: number) => {
        console.log("handleDeleteQna", id);
        if (!window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "btn.txtDeleteConfirmation"))) return;

        if (isOnDeleting) return;
        setIsOnDeleting(true);

        try {
            await deleteQnA(id);
            const newList = [...list].filter((item: any) => item.id !== id);
            setList(newList);
        } catch (err: any) {
            console.error(err);
            alert(err.message || "Unknown Error Occurred, Please try again later.");
        } finally {
            setIsOnDeleting(false);
        }
    };

    const handleAnswerButton = async (id: number) => {
        console.log("handleAnswerButton", id);
        if (isOnAnswering) return;
        if (answerList[id] == null || answerList[id] == undefined) return;
        const answer = answerList[id].trim();
        if (answer === "") return;


        setIsOnAnswering(true);

        try {
            const result = await updateAnswer(id, answer);
            console.log({ result });
            const info = result?.data?.data?.info;
            if (info) {
                const newList = [...list].map((item: any) => {
                    if (item.id === info.id) {
                        return info;
                    }
                    return item;
                });
                setList(newList);
            }
        } catch (err: any) {
            console.error(err);
            alert(err.message || "Unknown Error Occurred, Please try again later.");
        } finally {
            setIsOnAnswering(false);
        }
    };

    const handleAnswerChange = (id: number, answer: string) => {
        console.log("handleAnswerChange", id, answer);
        if (answer.includes('\n')) return;
        const newAnswerList = [...answerList];
        newAnswerList[id] = answer;
        setAnswerList(newAnswerList);
    };

    const handleEditingAnswerChange = async (answer: string) => {
        console.log("handleEditingAnswerChange", answer);
        if (answer.includes('\n')) return;
        const newEditingItem = { ...editingItem };
        newEditingItem.answer = answer;
        setEditingItem(newEditingItem);
    };

    const handleUpdateAnswerButton = async (id: number) => {
        console.log("handleUpdateAnswerButton", id);
        if (isOnAnswering) return;
        if (!editingItem) return;
        const answer = editingItem.answer.trim();
        if (answer === "") return;

        if (window.confirm("답변을 수정하시겠습니까??")) {
            setIsOnAnswering(true);
            await updateAnswer(id, answer)
                .then((response) => {
                    if (response.status !== 200) {
                        alert("답변 수정에 실패했습니다.");
                        return;
                    }

                    const result = response.data;
                    if (!result.data) {
                        alert("답변 수정에 실패했습니다.");
                        return;
                    } else if (!result.data.info) {
                        alert("답변 수정에 실패했습니다.");
                        return;
                    }

                    const info = result.data.info;
                    const newList = [...list].map((item: any) => {
                        if (item.id === info.id) {
                            return info;
                        }
                        return item;
                    });
                    setList(newList);
                    setEditingItem(null);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setIsOnAnswering(false);
                });
        }
    };

    return <QnAComponent
        isLoading={isLoading}
        list={list}
        page={page}
        setPage={setPage}
        loadList={loadList}
        loadMoreList={loadMoreList}
        hasMorePage={hasMorePage}
        answerList={answerList}
        editingItem={editingItem}
        setEditingItem={setEditingItem}
        handleWriteQuestion={handleWriteQuestion}
        handleQuestionChange={handleQuestionChange}
        question={question}
        handleDeleteQna={handleDeleteQna}
        handleAnswerButton={handleAnswerButton}
        handleAnswerChange={handleAnswerChange}
        handleUpdateAnswerButton={handleUpdateAnswerButton}
        handleEditingAnswerChange={handleEditingAnswerChange}
        isOnDeleting={isOnDeleting}
    />;
};

export default QnAContainer;