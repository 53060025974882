import React from "react";
import { getImageUrl } from "helpers/Image.helper";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getStudentNickname, getCurrentLangName, getFlagByCountryCode, getStudentName } from "helpers/String.helper";
import { get } from "api/Base.api";
import { formatTimeAgo } from "helpers/Datetime.helper";

const IndexComponent = (props: any) => {
    const {
        list,
        isInit,
        isOnLoadMore,
        hasMore,
        onShowStudentInfo,
        onDeleteFavorite
    } = props;

    return (
        <main className="bg-gray-50 min-h-screen overflow-y-auto">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6">
                <h1 className="text-3xl font-bold text-gray-900 mb-6">
                    즐겨찾기
                </h1>
                <p className="text-gray-500 mb-6">
                    관심있는 지망생들을 모아볼 수 있는 페이지입니다. 지망생의 프로필을 클릭하면 상세 정보를 확인할 수 있습니다.
                </p>
            </div>
            <div className="container mx-auto px-3 sm:px-4 lg:px-6 pt-4">
                <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                    <div className="p-4">
                        {!isInit ? (
                            <LoadingComponent />
                        ) : list?.length === 0 ? (
                            <div className="flex flex-col items-center justify-center py-6 text-gray-500">
                                <svg className="w-12 h-12 mb-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                                <p className="text-center">
                                    즐겨찾기 목록이 없습니다.
                                </p>
                            </div>
                        ) : (
                            <div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {list.map((student: any, idx: number) => (
                                        <div
                                            key={`favorite-${idx}`}
                                            onClick={() => onShowStudentInfo(student?.userStudentId)}
                                            className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-lg">
                                            <div className="flex items-center space-x-3 cursor-pointer flex-1">
                                                <div className="relative w-14 h-14">
                                                    <img
                                                        src={getImageUrl(student?.userStudentInfo.profileImageInfo)}
                                                        alt={getStudentNickname(student?.userStudentInfo)}
                                                        className="w-full h-full object-cover rounded-full bg-gray-200"
                                                    />
                                                    <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-lg">
                                                        {getFlagByCountryCode(student?.userStudentInfo.countryInfo?.code)}
                                                    </span>
                                                </div>
                                                <div className="flex-1">
                                                    <p className="text-sm text-gray-600">
                                                        {student?.userStudentInfo.birthyear}년생 · {getCurrentLangName(student?.userStudentInfo.countryInfo?.names)}
                                                    </p>
                                                    <p className="text-base font-medium text-gray-900 truncate max-w-[200px]">
                                                        {getStudentName(student?.userStudentInfo)} ({getStudentNickname(student?.userStudentInfo, '')})
                                                    </p>
                                                    <p className="text-xs text-gray-400">
                                                        {formatTimeAgo(student?.userStudentInfo.lastProfileUpdatedAt)}
                                                    </p>
                                                </div>
                                            </div>
                                            {student?.userStudentInfo?.isUpdatedProfile && (
                                                <div className="w-3 h-3 rounded-full bg-[#CF3688] animate-pulse ml-2"></div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                                {isOnLoadMore && (
                                    <LoadingComponent />
                                )}

                                {!isOnLoadMore && !hasMore && list.length > 0 && (
                                    <div className="text-center py-3 text-gray-500 text-sm">
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "favorite.txtNoMore")}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default IndexComponent;