import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as AlarmApi from "api/agency/v2/alarm.api";
import AlarmComponent from "./Index.component";
import { notificationListener } from "helpers/Firebase.helper";

const IndexContainer = (props: any) => {
  const location = useLocation();

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // pagination
  const searchParams = new URLSearchParams(location.search);
  const limit = 40;
  const [page, setPage] = useState(1);

  const getList = useCallback(async () => {
    console.log("/pages/agency/v2/alarm/Index.container:getList");
    console.log({ isLoading, hasMore })

    if (isLoading || !hasMore) return;

    setIsLoading(true);

    const result = await AlarmApi.getAlarmList(page, limit, true);
    console.log({ result })
    if (result?.list) {
      setList(prevList => [...prevList, ...result.list]);
      setPage(prevPage => prevPage + 1);
      setHasMore(result.list.length === limit);
    } else {
      setHasMore(false);
    }

    setIsLoading(false);
  }, [isLoading, hasMore, page, limit]);

  const handleScroll = () => {
    if (!hasMore) return;
    if (isLoading) return;

    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if (Math.ceil(scrollTop + clientHeight) < scrollHeight - 100) return;

    getList();
  };

  const onClickCheckNotification = async (item: any) => {
    // // if (item.isRead === 0) {
    // //   await AlarmApi.updateReadInfo(item.id, {});
    // // }

    // if (item.type === "action") {
    //   if (item.action === "reload") {
    //     window.location.reload();
    //   }
    // }
    // if (item.type === "link") {
    //   if (item.linkUrl) {
    //     window.location.href = item.linkUrl;
    //   }
    // }
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    notificationListener.on("notification", getList);

    return () => {
      notificationListener.off("notification", getList);
    };
  }, [getList]);

  return (
    <AlarmComponent
      isLoading={isLoading}
      list={list}
      onClickCheckNotification={onClickCheckNotification}
      // pagination
      limit={limit}
      page={page}
      setPage={setPage}
      hasMore={hasMore}
    />
  );
};

export default IndexContainer;
